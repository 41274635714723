// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { useModal } from '../../../contexts/modal-context';
import { Button } from '../../atoms/button';

const BottomContentVideoCampaignCA = ({
	bottomContentVideoCampaignCAContent,
	className,
	firstChildClassName,
	modalRef,
}) => {
	const {
		bottomSectionTitle,
		bottomSectionPrimaryLinkCopy,
		bottonSectionImage,
	} = bottomContentVideoCampaignCAContent;
	const { setModalRef } = useModal();
	return (
		<div className={`w-full bg-white ${className}`}>
			<div className="relative px-6 pt-10 pb-0 mx-auto bg-white sm:pb-10 lg:p-12 lg:py-28 lg:max-w-m-screen">
				<div
					className={`flex flex-row bg-[#1F2E7A] justify-center items-center rounded-[.75rem] max-h-[279px] px-10 py-20 lg:px-16 shadow-sm ${firstChildClassName}`}
				>
					<div className="flex flex-col text-2xl text-white 2xl:text-4xl lg:py-0 font-lexend-regular">
						<div className="-mt-3 text-center lg:text-left">
							{bottomSectionTitle}
						</div>
						<div className="flex items-center mx-auto mt-5 lg:mx-0">
							<Button
								onClick={() => {
									if (modalRef !== null)
										setModalRef(modalRef);
								}}
								className="inline-flex items-center hidden px-6 py-4 mr-auto text-lg leading-6 text-white transition transition-colors duration-1000 delay-150 bg-blue-400 border-2 border-blue-400 rounded-lg md:block hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-lexend-medium focus:outline-0 focus:shadow-focus whitespace-nowrap"
							>
								{bottomSectionPrimaryLinkCopy}
							</Button>
							{/* for mobile devices */}
							<Button
								onClick={() => {
									if (modalRef !== null)
										setModalRef(modalRef);
								}}
								className="inline-flex items-center block px-6 py-4 text-lg leading-6 text-white transition transition-colors duration-1000 delay-150 bg-blue-400 border-2 border-blue-400 rounded-lg md:hidden hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-lexend-medium focus:outline-0 focus:shadow-focus whitespace-nowrap"
							>
								{bottomSectionPrimaryLinkCopy}
							</Button>
						</div>
					</div>
					<img
						src={bottonSectionImage.url}
						alt=""
						className="hidden mb-28 lg:block"
					/>
				</div>
			</div>
		</div>
	);
};

export default BottomContentVideoCampaignCA;
