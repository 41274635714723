import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { IconCardList2 } from '../../molecules/icon-card-list-vap2';

import { titleProp, refProp } from '../../../types';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';

import { createHeadingHighlight } from '../../../utils/heading-highlight';
import * as headingStyles from './index.module.css';

const IconCards2 = ({
	id,
	variant,
	showContentLinks,
	iconCardsContent,
	containerClassName,
	wrapperClassName,
	titleGroupClassName,
	borderBottom,
	borderClassNames,
	isTimeline,
	cardtitle,
	cardsubtitle,
	cardcontent,
	drawerRef,
}) => {
	const { subText, title, uspCards } = iconCardsContent;

	const { setDrawerRef } = useDrawer();

	const titleText = createHeadingHighlight({
		headings: cardtitle[0].children,
		headingStyles,
	});

	const getTitle = () =>
		typeof title === 'string' ? (
			<hgroup className={titleGroupClassName}>
				<h2 className="mb-5 text-lg-f md:text-2xl">{title}</h2>
				<p className="mb-8 text-lg md:text-xl font-notosans-light">
					{subText}
				</p>
			</hgroup>
		) : (
			<HeadingTagLine
				id={id}
				title={title}
				subText={subText}
				headingClassNames={clsx(
					variant === 'StandardTextCenter'
						? 'text-center'
						: 'text-white text-left max-w-[80%] md:max-w-none'
				)}
				subTextClassNames={clsx(
					variant === 'StandardTextCenter'
						? 'text-center'
						: 'text-white text-left'
				)}
				className={clsx(
					'mb-5 md:mb-xl-f',
					titleGroupClassName,
					variant === 'StandardTextCenter' ? 'pr-4' : ''
				)}
				headingHighlightClassNames={clsx(
					variant !== 'StandardTextCenter'
						? 'text-white brightness-0 invert'
						: ''
				)}
			/>
		);

	return (
		<section
			className={clsx(
				variant === 'Dark'
					? 'bg-blue-500 py-lg-f md:py-2xl-f'
					: 'mt-lg-f lg:mt-2xl-f',
				containerClassName
			)}
			aria-labelledby={id}
		>
			<div
				className={clsx(
					'pl-4 mx-auto md:px-8 max-w-m-screen',
					wrapperClassName
				)}
			>
				{title ? (
					getTitle()
				) : (
					<h2 className="sr-only" id={id}>
						HR Services
					</h2>
				)}

				<div className="block mb-2 md:mb-5 text-xl font-semibold lg:font-normal md:text-4xl text-center text-[#1D2951] ">
					{titleText}
				</div>

				<div className="block mb-6 md:mb-10 text-md md:text-xl text-center text-[#1D2951] lg:px-8">
					{cardsubtitle}
				</div>
				<div className="-ml-[8px] -mt-[8px]">
					<IconCardList2
						cards={uspCards}
						variant={variant}
						showContentLinks={showContentLinks}
						isTimeline={isTimeline}
					/>
				</div>

				<div className="block mb-6 md:mb-10 text-md md:text-xl text-center text-[#1D2951] mt-6 lg:mt-2 lg:px-8">
					{cardcontent}
				</div>

				<div className="block mb-6 md:mb-10 text-md md:text-xl text-center mt-6 lg:mt-2 lg:px-8">
					<Button
						onClick={() => setDrawerRef(drawerRef)}
						className="whitespace-nowrap"
					>
						Speak to an expert
					</Button>
				</div>
			</div>
			{borderBottom ? <hr className={clsx(borderClassNames)} /> : null}
		</section>
	);
};

IconCards2.defaultProps = {
	id: 'hr-services',
	showContentLinks: true,
	variant: 'Default',
	containerClassName: '',
	wrapperClassName: '',
	titleGroupClassName: '',
	borderBottom: false,
	borderClassNames: '',
	isTimeline: false,
	cardtitle: '',
	cardsubtitle: '',
	cardcontent: '',
};

IconCards2.propTypes = {
	id: PropTypes.string,
	variant: PropTypes.string,
	isTimeline: PropTypes.bool,
	showContentLinks: PropTypes.bool,
	iconCardsContent: PropTypes.shape({
		title: PropTypes.oneOfType([titleProp, PropTypes.string]),
		subText: PropTypes.string,
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	containerClassName: PropTypes.string,
	wrapperClassName: PropTypes.string,
	titleGroupClassName: PropTypes.string,
	borderBottom: PropTypes.bool,
	borderClassNames: PropTypes.string,
	cardtitle: PropTypes.string,
	cardsubtitle: PropTypes.string,
	cardcontent: PropTypes.string,
	drawerRef: refProp.isRequired,
};

export { IconCards2 };
