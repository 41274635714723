// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import clsx from 'clsx';
import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';

import { Categories } from '../components/molecules/categories';
import { CardContainer } from '../components/organisms/card-container';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Title } from '../components/atoms/title';
import { Drawer } from '../components/atoms/drawer';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { useFilters } from '../hooks/filters';

import { parseDatoLocale } from '../utils/locale';

import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { HrefLang } from '../components/templates/hrefLang';
import { SearchBox } from '../components/organisms/item-search';
import { SearchBoxCA } from '../components/organisms/item-search-ca';
import { NoResultFound } from '../components/organisms/no-search-result';
import usePageQuery from '../hooks/page-query';

const ViewAllBlogsPage = ({
	data,
	categoryOriginalIds,
	location,
	parentPageData,
	locale,
}) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const searchRef = useRef(null);
	const handleChange = (value) => {
		setSearchQuery(value);
	};
	const newCategoryOriginalIds = categoryOriginalIds.map((item) => {
		if (item.includes('-')) {
			return `"${item}"`;
		}
		return item;
	});
	const queryPageName = 'allBlogPages';
	const { pageTotalQuery } = usePageQuery({
		pageName: queryPageName,
		categoriesId: newCategoryOriginalIds,
		searchTerms: searchQuery,
		locale,
		extraQuery: 'slug: {neq: null},',
	});
	const blogQuery = React.useCallback(
		({
			queryName,
			filter,
			skip,
		}) => `query allBlogs($locale: SiteLocale = ${
			parseDatoLocale[locale] || 'en'
		}) {
			${queryName}(filter: {categories: {anyIn: [${newCategoryOriginalIds}]}, slug: {neq: null}, OR: [{OR: {intro: {matches: {pattern: "^(?=.*${searchQuery}).*", caseSensitive: false}}}}, {OR: {title: {matches: {pattern: "^(?=.*${searchQuery}).*", caseSensitive: false}}}}] }, first: 12, orderBy: ${filter}, skip: ${skip}, locale: $locale, fallbackLocales: ${
			parseDatoLocale[locale] || 'en'
		})  {
				id
				title
				slug
				intro
				publishedDate: created
				publishedDateOrdinal: created
				thumbnail {
					responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 500, h: 300, ar: "43:21"}, sizes:"(max-width: 640px) 400px, 800px") {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						alt
					}
				}
				categories {
					slug
					name
					id
				}
				sectors {
					name
					id
				}
				author {
					id
					name
					jobTitle
					image {
						responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 44, h: 44}) {
						src
						width
						height
						alt
					}
				}
			}
		}
		${pageTotalQuery}
	}`,
		[newCategoryOriginalIds, pageTotalQuery, locale, searchQuery]
	);

	const {
		loading,
		error,
		pagination,
		setPagination,
		filter,
		setFilter,
		items,
		totalSearchCount: searchTotal,
	} = useFilters({
		queryName: queryPageName,
		query: blogQuery,
		data: data?.allDatoCmsBlogPage,
		itemLimit: 12,
		search: searchQuery,
		searchRef,
	});

	const metaContent = {
		noIndex: data.datoCmsAllBlogsPage.noIndex,
		noFollow: data.datoCmsAllBlogsPage.noFollow,
		metaInformation: data.datoCmsAllBlogsPage.metaInformation,
		fallbackTitle: data.datoCmsAllBlogsPage.title,
		fallbackDescription: data.datoCmsAllBlogsPage.longIntroduction,
		canonicalUrl: data.datoCmsAllBlogsPage?.canonicalUrl || location.href,
	};

	const adviceBannerContent = {
		...data.datoCmsAllBlogsPage.adviceBanner,
		title: data.datoCmsAllBlogsPage.adviceBanner.title.value.document
			.children,
	};

	const allBlogsContent = {
		title: data.datoCmsAllBlogsPage.heading,
		subtitle: data.datoCmsAllBlogsPage.subHeading,
	};

	const blogContainerContent = {
		totalCount: searchTotal,
		cards: items,
	};

	const guideContainerContent = {
		totalCount: data.allDatoCmsGuidePage.totalCount,
		cards: data.allDatoCmsGuidePage.nodes,
	};

	const downloadContainerContent = {
		totalCount: data.allDatoCmsDownloadPage.totalCount,
		cards: data.allDatoCmsDownloadPage.nodes,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.allBlogs,
			location,
			data: data.datoCmsAllBlogsPage,
			parentPageData,
		}),
	};

	const lastHubPage = location.pathname.substring(
		0,
		location.pathname.endsWith('/')
			? location.pathname.slice(0, -1).lastIndexOf('/')
			: location.pathname.lastIndexOf('/')
	);

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsAllBlogsPage.internal.type,
		data.datoCmsAllBlogsPage,
		parentPageData
	);
	const lowerCaseParentTitle = parentPageData?.title.toLowerCase();
	const parentPageDataExists =
		parentPageData?.title !== null && parentPageData?.title !== undefined;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsAllBlogsPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title={allBlogsContent.title}
				className={clsx(
					'px-3 mx-auto max-w-m-screen lg:px-8 !text-center'
				)}
				subtitle={allBlogsContent.subtitle}
			/>
			{locale === 'en-CA' ? (
				<section className="flex items-center justify-center w-full px-3 mx-auto 2xl:w-1/3 lg:w-1/2">
					<SearchBoxCA
						ref={searchRef}
						placeholder="Search by title or keyword"
						onChange={handleChange}
						query={searchQuery}
						className=""
					/>
				</section>
			) : (
				<section className="flex items-center justify-center w-full px-3 mx-auto -mt-4 2xl:w-1/3 lg:w-1/2 lg:-mt:10">
					<SearchBox
						ref={searchRef}
						placeholder="Search by title or keyword"
						onChange={handleChange}
						query={searchQuery}
						className=""
					/>
				</section>
			)}
			{blogContainerContent.cards.length > 0 ? (
				<CardContainer
					variant="ViewAllFilters"
					cardContainerContent={{
						...blogContainerContent,
						id: 'blog-container',
						title: 'Blog articles',
						buttonCopy: `View all ${lowerCaseParentTitle} blogs`,
						viewAllTextOverride: parentPageDataExists,
					}}
					cardVariant="Blog"
					loading={loading}
					error={error}
					filter={filter}
					setFilter={setFilter}
					setPagination={setPagination}
					pagination={pagination}
				/>
			) : (
				<NoResultFound />
			)}

			{guideContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollable"
					cardContainerContent={{
						...guideContainerContent,
						id: 'guides-container',
						title: 'Guides',
						toAll: `${lastHubPage}/guides`,
						buttonCopy: `View all ${lowerCaseParentTitle} guides`,
						viewAllTextOverride: parentPageDataExists,
					}}
					cardVariant="Guide"
				/>
			)}
			{downloadContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollable"
					cardContainerContent={{
						...downloadContainerContent,
						id: 'download-container',
						title: 'Free downloads',
						toAll: `${lastHubPage}/downloads`,
						buttonCopy: `View all ${lowerCaseParentTitle} downloads`,
						viewAllTextOverride: parentPageDataExists,
					}}
					cardVariant="Download"
				/>
			)}
			{data.datoCmsAllBlogsPage.categoryGroups.length > 0 && (
				<Categories
					variant="Footer"
					title={data.datoCmsAllBlogsPage.categoryGroupTitle}
					categoryGroups={data.datoCmsAllBlogsPage.categoryGroups}
					backButtonCopy="Back to resource hub"
					locale={locale}
				/>
			)}
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query ($categoryIds: [String!]!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsAllBlogsPage(locale: { eq: $locale }) {
			originalId
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			id
			internal {
				type
			}
			noIndex
			noFollow
			canonicalUrl
			heading
			subHeading
			categoryGroupTitle
			categoryGroups {
				originalId
				id
				internal {
					type
				}
				slug
				title
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				categories {
					originalId
					id
					internal {
						type
					}
					slug
					name
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
		allDatoCmsBlogPage(
			sort: { order: DESC, fields: created }
			filter: {
				categories: { elemMatch: { id: { in: $categoryIds } } }
				slug: { ne: null }
				locale: { eq: $locale }
			}
			limit: 12
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					name
					id
					slug
				}
				sectors {
					name
					id
				}
			}
		}
		allDatoCmsGuidePage(
			sort: { order: DESC, fields: created }
			filter: {
				categories: { elemMatch: { id: { in: $categoryIds } } }
				slug: { ne: null }
				locale: { eq: $locale }
			}
			limit: 3
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					name
					id
					slug
				}
				sectors {
					name
					id
				}
			}
		}
		allDatoCmsDownloadPage(
			sort: { order: DESC, fields: created }
			filter: {
				categories: { elemMatch: { id: { in: $categoryIds } } }
				slug: { ne: null }
				locale: { eq: $locale }
			}
			limit: 3
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				title
				slug
				intro
				downloadType
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					name
					id
					slug
				}
				sectors {
					name
					id
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedHome = ({ data, pageContext, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<ViewAllBlogsPage
			data={data}
			location={location}
			categoryOriginalIds={pageContext.categoryOriginalIds}
			parentPageData={pageContext.parentPageData}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedHome;
