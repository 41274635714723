import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { PBSLink } from '../../atoms/link';

import { CategoryCard } from '../category-card';

import { sendTrackingData } from '../../../utils';
import { categoryCardProp } from '../../../types';
import { buildLink } from '../../../utils/locale';

const Categories = ({
	title,
	variant,
	backButtonCopy,
	categoryGroups,
	limit,
	locale,
}) => {
	const id = title.replace(/[^A-Z0-9]/gi, '');

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			...trackData,
		});
	};

	const getCategories = () => {
		if (variant === 'Footer') {
			return categoryGroups;
		}

		const categoryOther = categoryGroups.filter(
			(item) => item.title === 'Other'
		);
		const categoryWithOutOther = categoryGroups.filter(
			(item) => item.title !== 'Other'
		);

		let sortedCategoryArr = [...categoryWithOutOther, ...categoryOther];

		if (limit) {
			sortedCategoryArr = sortedCategoryArr.slice(0, limit);
		}

		return sortedCategoryArr;
	};

	const mapCategories = (category, index) => (
		<li key={category.id}>
			<CategoryCard
				{...category}
				index={index}
				onClickProp={handleClickTracking}
				locale={locale}
			/>
		</li>
	);

	if (variant === 'Footer') {
		return (
			<section
				className="px-3 mx-auto pb-14 md:pb-24 max-w-m-screen lg:px-8"
				aria-labelledby={id}
			>
				<div className="flex flex-col justify-center lg:mx-auto">
					{title ? (
						<h2
							className="text-center text-xl-f mb-xxs-f font-lexend-light"
							id={id}
						>
							{title}
						</h2>
					) : null}
					<ul
						className={clsx(
							'w-full grid gap-5 [grid-auto-flow:column]',
							'[grid-auto-columns:minmax(300px,1fr)]',
							'md:[grid-auto-columns:minmax(340px,1fr)]',
							'[grid-auto-rows:minmax(150px,1fr)]',
							'mt-s-f md:mt-base-f pb-4 sm:pb-5',
							'overflow-x-auto overflow-y-hidden',
							'list-scrollbar'
						)}
					>
						{getCategories().map(mapCategories)}
					</ul>
					{backButtonCopy ? (
						<PBSLink
							variant="White"
							className="self-center mt-10"
							to={buildLink(locale, 'resource-hub')}
						>
							{backButtonCopy}
						</PBSLink>
					) : null}
				</div>
			</section>
		);
	}
	return (
		<section
			className="px-3 mx-auto mb-2xl-f md:mb-3xl-f max-w-m-screen lg:px-8"
			aria-labelledby={id}
		>
			<div className="flex flex-col justify-center lg:mx-auto">
				{title ? (
					<h2
						className="text-xl md:text-lg-f font-lexend-light mb-s-f md:mb-base-f"
						id={id}
					>
						{title}
					</h2>
				) : null}
				<ul
					className={clsx(
						'w-full grid gap-x-5 gap-y-2 sm:gap-y-s-f md:gap-y-base-f grid-flow-row',
						'md:grid-cols-2 xl:grid-cols-3'
					)}
				>
					{getCategories().map(mapCategories)}
				</ul>
			</div>
		</section>
	);
};

Categories.defaultProps = {
	locale: 'en',
	title: '',
	limit: 6,
	backButtonCopy: '',
	variant: 'Default',
};

Categories.propTypes = {
	locale: PropTypes.string,
	title: PropTypes.string,
	limit: PropTypes.number,
	backButtonCopy: PropTypes.string,
	variant: PropTypes.oneOf(['Default', 'Footer']),
	categoryGroups: PropTypes.arrayOf(
		PropTypes.shape({
			...categoryCardProp.isRequired,
		})
	).isRequired,
};

export { Categories };
