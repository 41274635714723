import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { CanadianBusinessReviewsHorizontal } from '../../atoms/canadian-business-reviews-hr-software';

import { titleProp, datoGatsbyImageProp, refProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import { sendTrackingData } from '../../../utils';
import { buildLink, getLocale } from '../../../utils/locale';

import { useModal } from '../../../contexts/modal-context';

import * as headingStyles from './index.module.css';

import tick from './blue_tick.svg';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const HeroHrSoftware = ({
	id,
	header,
	secondaryHeaderRows,
	secondaryHeader,
	as,
	primaryLink,
	primaryLinkCopy,
	primaryLinkOpenForm,
	primaryImage,
	// secondaryLink,
	showTrustPilot: showCanadianBusinessAwards,
	headingClassNames,
	modalRef,
	glu,
	logo,
	// ctaLabel,
}) => {
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});

	const { setModalRef } = useModal();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};

	let cta;

	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setModalRef(modalRef);
					handleClickTracking();
				}}
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink && glu) {
		cta = (
			<PBSLink
				className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
				to={primaryLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}

	return (
		<section
			role="banner"
			className={clsx(
				'px-4 mx-auto lg:max-w-m-screen -mt-5 sm:mt-0 mb-12 md:mb-0 lg:mb-0 xl:mb-0 ',
				{
					'bg-brand-pale-400': glu,
					'lg:max-w-m-screen': !glu,
				}
			)}
			aria-labelledby={id}
		>
			<div className="flow-root mt-12 ">
				<div className="float-left xl:w-[56%] lg:w-3/6 -mt-1 xl:px-6">
					<div
						className={clsx('flex mx-auto', {
							'lg:max-w-m-screen': glu,
						})}
					>
						<span
							className={clsx(
								'flex flex-col',
								headingStyles.headingContainer
							)}
						>
							{logo && (
								<img
									src={logo.url}
									alt={logo.alt || ''}
									className="max-w-[275px]"
								/>
							)}

							<div
								className={clsx(
									'inline !leading-[1.3] font-lexend-regular mx-5 xl:mx-0',
									!secondaryHeader
										? headingStyles.heading
										: null,
									glu
										? 'inline text-2xl lg:text-4xl font-bold lg:min-h-[160px]'
										: 'inline text-4xl md:text-4xl xl:text-5xl ',
									headingClassNames
								)}
							>
								<Component id={id} className="inline">
									{titleText}
								</Component>
								<span
									id="dynamic-loc"
									className="inline dynamic-loc"
								/>
								{secondaryHeaderRows.length > 0 ? (
									<ul
										className={clsx(
											'mt-8 mx-2',
											'list-none',
											'mt-2 leading-[1.2] block',
											'md:mt-6',
											' xl:leading-[1.3]',
											glu
												? 'text-xl lg:text-2xl font-lexend-regular'
												: `text-2xl md:text-3xl ${headingStyles.heading} `
										)}
									>
										{secondaryHeaderRows.map(
											({ secondaryHeaderRow }) => (
												<div className="flex flex-row my-1 gap-4">
													<div className="mt-2 min-w-[15px]">
														<img
															src={tick}
															alt=""
														/>
													</div>
													<div>
														<li className="max-w-full text-[21px] sm:text-[24px]">
															{secondaryHeaderRow}
														</li>
													</div>
												</div>
											)
										)}
									</ul>
								) : (
									<span
										className={clsx(
											'mt-2 leading-[1.2] block',
											'md:mt-2',
											' xl:leading-[1.3]',

											glu
												? 'text-xl lg:text-2xl font-lexend-regular'
												: `text-2xl md:text-3xl ${headingStyles.heading} `
										)}
									>
										{secondaryHeader}
									</span>
								)}
							</div>
							<span className="md:ml-5 xl:ml-0 xl:px-2">
								{primaryLink ? (
									<p className="flex flex-col items-center justify-center mt-8 sm:flex-row sm:justify-start">
										{cta || null}
									</p>
								) : null}
								{showCanadianBusinessAwards ? (
									<CanadianBusinessReviewsHorizontal />
								) : null}
							</span>
						</span>
					</div>
				</div>
				<div
					className={clsx(
						'float-right xl:w-[44%] lg:w-3/6 hidden lg:block scale-90 -mt-14'
						// 'flex hidden lg:block lg:w-1/2 lg:pr-6'
					)}
				>
					<img
						src={primaryImage.url}
						alt={primaryImage.alt}
						className={clsx('drop-shadow-image')}
					/>
				</div>
			</div>
		</section>
	);
};

HeroHrSoftware.defaultProps = {
	id: 'main-site-header-v2-ca',
	as: 'h1',
	secondaryHeader: '',
	headingClassNames: '',
	showTrustPilot: true,
	glu: false,
	logo: {},
	secondaryHeaderRows: [],
};

HeroHrSoftware.propTypes = {
	id: PropTypes.string,
	header: titleProp.isRequired,
	secondaryHeader: PropTypes.string,
	as: PropTypes.string,
	primaryImage: datoGatsbyImageProp.isRequired,
	// secondaryImage: datoGatsbyImageProp.isRequired,
	headingClassNames: PropTypes.string,
	primaryLink: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
	primaryLinkOpenForm: PropTypes.bool.isRequired,
	// secondaryLink: PropTypes.string.isRequired,
	showTrustPilot: PropTypes.bool,
	secondaryHeaderRows: PropTypes.arrayOf(PropTypes.string),
	// eslint-disable-next-line react/require-default-props
	modalRef: refProp,
	glu: PropTypes.bool,
	logo: datoGatsbyImageProp,

	// ctaLabel: PropTypes.string,
};

export { HeroHrSoftware };
