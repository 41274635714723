// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import * as compassStyles from './index.module.css';
import redCross from './red-cross.svg';
import blueTick from './white-tick.svg';

const ValuesCompass = ({ valuesContent, className }) => {
	const {
		valuesPrimaryList,
		valuesPrimaryImage,
		valuesSecondaryList,
		valuesSecondaryImage,
	} = valuesContent;

	return (
		<div
			className={`flex flex-col sm:flex-row justify-between items-stretch font-lexend-medium text-[#1D2951] md:space-x-10 space-x-0 gap-10 sm:gap-5 w-full text-lg xl:text-2xl xl:leading-10 xl:px-5 2xl:px-[14.5rem] ${compassStyles.macValuesCompass} ${className}`}
		>
			{/* left div */}
			<div className="flex flex-col sm:w-1/2 ">
				<div className="flex mx-auto ">
					<img
						src={valuesPrimaryImage.url}
						alt=""
						className="-mb-[85px] xl:-mb-[150px] z-10 w-[197] h-[197px] xl:min-w-[336px] xl:min-h-[336px]"
					/>
				</div>
				<div className="flex-grow content-top p-5 pt-[100px] xl:pt-[200px] 2xl:pt-[175px] border-2 border-[#E2E9F6] rounded-[.75rem] bg-black">
					{valuesPrimaryList.map((elm) => (
						<div className="flex flex-row gap-5 xl:gap-10 mx-1 mb-2 xl:px-10">
							<div className="w-[13.5px] h-[12px]">
								<img
									src={blueTick}
									alt=""
									className="min-w-[13.5px] min-h-[12px] xl:min-w-[25px] xl:min-h-[25px] mt-1 xl:m-0"
								/>
							</div>
							<div className="leading-5 xl:mb-5 text-white font-lexend-light">
								{elm.text}
							</div>
						</div>
					))}
				</div>
			</div>

			{/* right div */}
			<div className="flex flex-col sm:w-1/2">
				<div className="flex mx-auto ">
					<img
						src={valuesSecondaryImage.url}
						alt=""
						className="-mb-[85px] xl:-mb-[150px] z-10 w-[197] h-[197px] xl:min-w-[336px] xl:min-h-[336px]"
					/>
				</div>
				<div className="flex-grow content-top p-5 pt-[100px] xl:pt-[200px] 2xl:pt-[175px] border-2 border-[#5c5a5d] rounded-[.75rem] bg-[#5c5a5d]">
					{valuesSecondaryList.map((elm) => (
						<div className="flex flex-row gap-5 xl:gap-10 mx-1 mb-2 xl:px-10">
							<div className="w-[13.5px] h-[12px]">
								<img
									src={redCross}
									alt=""
									className="min-w-[13.5px] min-h-[12px] xl:min-w-[25px] xl:min-h-[25px] mt-1 xl:m-0"
								/>
							</div>
							<div className="leading-5 xl:mb-5 text-white font-lexend-light">
								{elm.text}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ValuesCompass;
