import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TagsList } from '../tags-list';

const QuoteCard = ({ as, quote, nameAndJob, company, tags, className }) => {
	const Element = as;

	return (
		<Element className={clsx('p-5 bg-white shadow-default', className)}>
			<figure className="mb-4">
				<blockquote className="mb-7">
					<p className="relative leading-8 text-brand-blue-400 text-lg-f font-notosans-light">
						<strong className="absolute top-1 text-3xl-f font-lexend-regular">
							&ldquo;
						</strong>
						<span className="pl-4 md:pl-5">{quote}</span>
						<strong className="absolute -bottom-2 text-3xl-f font-lexend-regular">
							&rdquo;
						</strong>
					</p>
				</blockquote>

				<figcaption>
					<p className="leading-7 text-blue-500 text-base-f font-notosans-medium">
						{nameAndJob}
					</p>
					<p className="leading-7 text-base-f font-notosans-regular">
						{company}
					</p>
				</figcaption>
			</figure>

			<TagsList tags={tags} />
		</Element>
	);
};

export { QuoteCard };

QuoteCard.defaultProps = {
	as: 'div',
	tags: [],
	className: '',
};

QuoteCard.propTypes = {
	as: PropTypes.elementType,
	quote: PropTypes.string.isRequired,
	nameAndJob: PropTypes.string.isRequired,
	company: PropTypes.string.isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
	className: PropTypes.string,
};
