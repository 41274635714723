// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/legislation';

import { Head } from '../components/templates/head';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchemaLegislation,
} from '../utils/seo-schema';

import { LegislationHero } from '../components/organisms/legislation-hero';
import { CardContainer } from '../components/organisms/card-container-legislation';
import { FaqCA } from '../components/organisms/faq-legislation';
import { AdviceContentContainer } from '../components/organisms/advice-content-container';
import { VideoSection } from '../components/organisms/video-section-legislation';
import { TestimonialsLegislation } from '../components/organisms/testimonials-legislation';

// import CTACompassCA from '../components/organisms/cta-compass-ca';
// import StatementCompassCA from '../components/organisms/statement-compass-ca';
// import ValuesCompassCA from '../components/organisms/values-compass-ca';
import { HrefLang } from '../components/templates/hrefLang';

const IndexPage = ({ location, data }) => {
	const metaContent = {
		noIndex: data.datoCmsLegislationPage.noIndex,
		noFollow: data.datoCmsLegislationPage.noFollow,
		metaInformation: data.datoCmsLegislationPage.metaInformation,
		fallbackTitle: data.datoCmsLegislationPage.title,
		fallbackDescription: data.datoCmsLegislationPage.longIntroduction,
		canonicalUrl:
			data.datoCmsLegislationPage?.canonicalUrl || location.href,
	};

	const heroContent = {
		title: data.datoCmsLegislationPage.mainHeaderTitle,
		content: data.datoCmsLegislationPage.mainHeaderContent,
		subtitleTwo: data.datoCmsLegislationPage.subtitleTwo,
		image: data.datoCmsLegislationPage.mainHeaderImage,
		titleImage: data.datoCmsLegislationPage.heroTitleImage,
		backgroundImage: data.datoCmsLegislationPage.backgroundHeaderImage,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsLegislationPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsLegislationPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsLegislationPage?.customFormField[0]?.customFormButton,
	};

	const videoSectionContent = {
		title: data.datoCmsLegislationPage.videoSectionTitle,
		subtitle: data.datoCmsLegislationPage.videoSectionSubtitle,
		numberedPoints: data.datoCmsLegislationPage.numberedPoints,
		numberedListIntro: data.datoCmsLegislationPage.numberedListIntro,
		buttonCopy: data.datoCmsLegislationPage.videoButtonCopy,
		video: data.datoCmsLegislationPage.media,
	};

	const { phoneNumber } = data.siteSettings;

	const combinedCards = [
		data.datoCmsLegislationPage.resourceContainer.downloadOne
			? {
					...data.datoCmsLegislationPage.resourceContainer
						.downloadOne,
					cardVariant: 'Download',
					thumbnail:
						data.datoCmsLegislationPage.resourceContainer
							.downloadOneImage,
			  }
			: null,
		data.datoCmsLegislationPage.resourceContainer.blogOne,
		data.datoCmsLegislationPage.resourceContainer.blogTwo,
	].filter(Boolean);

	const resourceContainerContent = {
		...data.datoCmsLegislationPage.resourceContainer,
		cards: combinedCards,
	};

	const adviceBannerContent = {
		...data.datoCmsLegislationPage.adviceContentContainer,
	};

	const legislationTestimonialContent = {
		...data.datoCmsLegislationPage.legislationTestimonial,
	};

	const faqContent = {
		title: data.datoCmsLegislationPage.faqContainer.title,
		subText: data.datoCmsLegislationPage.faqContainer.subText,
		questions: data.datoCmsLegislationPage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchemaLegislation({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.home,
			location,
		}),
		image: getImageSeoSchema(
			data.datoCmsLegislationPage.statementPrimaryImage
		),
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<div className="block sm:hidden">{/* add phone prop */}</div>
			<HrefLang locales={data.datoCmsLegislationPage.allSeoLocales} />
			{location?.origin && (
				<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			)}
			<LegislationHero
				heroContentCA={heroContent}
				className="py-8"
				customFormField={customFormField}
			/>
			<VideoSection
				content={videoSectionContent}
				phoneNumber={phoneNumber}
			/>
			<CardContainer
				cardContainerContent={{
					...resourceContainerContent,
					to: '/resource-hub/blogs',
					toAll: '/resource-hub/blogs',
				}}
				cardVariant="Blog"
				variant="Center"
				location={location.pathname}
			/>
			<AdviceContentContainer
				content={adviceBannerContent}
				phoneNumber={phoneNumber}
			/>
			<TestimonialsLegislation
				className=""
				firstChildClassName="mt-20"
				testimonialsLegislation={legislationTestimonialContent}
				phoneNumber={phoneNumber}
			/>
			<FaqCA faqContent={faqContent} />
		</GoogleReCaptchaProvider>
	);
};

const WrappedHome = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		// pageName="PeninsulaNow"
	>
		<IndexPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedHome;

export const query = graphql`
	query legislation($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		datoCmsLegislationPage(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			mainHeaderTitle {
				value
			}
			mainHeaderContent {
				value
			}
			heroTitleImage {
				url
				alt
			}
			mainHeaderImage {
				url
				alt
			}
			backgroundHeaderImage {
				url
				alt
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			subtitleTwo
			resourceContainer {
				id
				title {
					value
				}
				subText
				buttonCopy
				downloadIcon {
					url
					alt
				}
				downloadOneImage {
					url
					alt
				}
				blogOne {
					id
					internal {
						type
					}
					publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
					publishedDate: created
					meta {
						updatedAt
						updatedAtOrdinal: updatedAt(formatString: "MMM Do YYYY")
					}
					title
					slug
					intro
					thumbnail {
						alt
						gatsbyImageData(
							aspectRatio: 53
							placeholder: DOMINANT_COLOR
							width: 500
							sizes: "(max-width: 640px) 400px, 800px"
							height: 300
						)
					}
					author {
						id
						name
						jobTitle
						image {
							alt
							url
							gatsbyImageData(
								width: 44
								height: 44
								layout: FIXED
							)
						}
					}
					categories {
						id
						name
						slug
					}
					sectors {
						id
						name
					}
					externalLink
				}
				blogTwo {
					id
					internal {
						type
					}
					publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
					publishedDate: created
					meta {
						updatedAt
						updatedAtOrdinal: updatedAt(formatString: "MMM Do YYYY")
					}
					title
					slug
					intro
					thumbnail {
						alt
						gatsbyImageData(
							aspectRatio: 53
							placeholder: DOMINANT_COLOR
							width: 500
							sizes: "(max-width: 640px) 400px, 800px"
							height: 300
						)
					}
					author {
						id
						name
						jobTitle
						image {
							alt
							url
							gatsbyImageData(
								width: 44
								height: 44
								layout: FIXED
							)
						}
					}
					categories {
						id
						name
						slug
					}
					sectors {
						id
						name
					}
					externalLink
				}
				downloadOne {
					id
					internal {
						type
					}
					publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
					publishedDate: created
					title
					slug
					intro
					thumbnail {
						alt
						gatsbyImageData(
							aspectRatio: 53
							placeholder: DOMINANT_COLOR
							width: 500
							sizes: "(max-width: 640px) 400px, 800px"
							height: 300
						)
					}
					author {
						id
						name
						jobTitle
						image {
							alt
							url
							gatsbyImageData(
								width: 44
								height: 44
								layout: FIXED
							)
						}
					}
					categories {
						id
						name
						slug
					}
					sectors {
						id
						name
					}
				}
			}
			legislationTestimonial {
				title {
					value
				}
				subText
				testimonialCards {
					content
					name
					job
					profile {
						url
					}
				}
				primaryLinkCopy
				ctaTagline {
					value
				}
			}
			faqContainer {
				title {
					value
				}
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			adviceContentContainer {
				title {
					value
				}
				pointsContent {
					value
				}
				fineAmounts {
					structuredTextBlock {
						value
					}
				}
				adviceHeading {
					value
				}
				adviceSubheading {
					value
				}
				buttonText
				image {
					url
					alt
				}
			}
			videoSectionTitle {
				value
			}
			videoSectionSubtitle
			numberedListIntro {
				value
			}
			numberedPoints {
				structuredTextBlock {
					value
				}
			}
			media {
				media {
					url
				}
			}

			videoButtonCopy
		}
	}
`;
