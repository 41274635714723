import clsx from 'clsx';
import {
	isLink,
	isList,
	isParagraph,
	isRoot,
} from 'datocms-structured-text-utils';
import PropTypes from 'prop-types';
import React from 'react';
import { renderMarkRule, renderNodeRule, StructuredText } from 'react-datocms';

import { PBSLink } from '../link';
import { ReviewBanner } from '../review-banner';
import { ReviewImageBanner } from '../review-image-banner';
import { TickList } from '../tick-list';

import { gatsbyImageProp, structuredTextContent } from '../../../types';
import VapCalculatorBlock from '../vap-calculator-banner';

const CaseStudiesText = ({ content, className, locale }) => (
	<section className={clsx('px-3 mx-auto max-w-m-screen lg:px-8', className)}>
		{content.map((props) => {
			const { header, contentRow, __typename } = props;
			if (__typename === 'DatoCmsTestimonial') {
				const {
					content: subText,
					name: reviewer,
					job,
					profile,
				} = props;

				return (
					<div key={__typename} className="md:pb-2xl-f pb-xl-f">
						<ReviewImageBanner
							reviewImageBannerContent={{
								subText,
								reviewer,
								job,
								profile,
							}}
						/>
					</div>
				);
			}

			if (__typename === 'DatoCmsVapCalculatorBlock' && locale === 'en') {
				const {
					headerTitle,
					subHeader,
					ctaText,
					ctaSuccessText,
					ctaSuccessLink,
				} = props;
				return (
					<div key={__typename} className="md:pb-2xl-f pb-xl-f">
						<VapCalculatorBlock
							headerTitle={headerTitle}
							subHeader={subHeader}
							ctaText={ctaText}
							ctaSuccessText={ctaSuccessText}
							ctaSuccessLink={ctaSuccessLink}
						/>
					</div>
				);
			}

			return (
				<div
					key={header}
					className="md:grid md:grid-cols-[25%_1fr] md:gap-[10px] first:border-t-0 border-t-1 border-blue-200 last:border-b-1 py-xl-f md:py-2xl-f first:pt-0"
				>
					<h2 className="text-lg-f md:text-2xl pb-s-f md:pb-0">
						{header}
					</h2>
					<div className="text-lg md:text-xl">
						<StructuredText
							data={contentRow}
							customNodeRules={[
								renderNodeRule(
									isList,
									({ node, children, key }) => {
										let ListTag;
										let listClassName;

										if (node.style === 'bulleted') {
											ListTag = 'ul';
											listClassName = 'list-disc';
										}
										if (node.style === 'numbered') {
											ListTag = 'ol';
											listClassName = 'list-decimal';
										}

										return (
											<ListTag
												className={clsx(
													'my-4 md:my-5 font-lexend-light list-inside',
													listClassName
												)}
												key={key + node.style}
											>
												{children}
											</ListTag>
										);
									}
								),
								renderNodeRule(
									isLink,
									({ node, children, key }) => (
										<PBSLink
											to={node.url}
											variant="Link"
											key={key}
										>
											{children}
										</PBSLink>
									)
								),
								renderNodeRule(
									isParagraph,
									({
										adapter: { renderNode },
										node,
										children,
										key,
										ancestors,
									}) => {
										if (
											node.children[0].type ===
											'inlineItem'
										) {
											return (
												<React.Fragment key={key}>
													{children}
												</React.Fragment>
											);
										}
										if (isRoot(ancestors[0])) {
											return renderNode(
												'p',
												{
													key,
													className:
														'mb-4 md:mb-5 font-lexend-light last-of-type:mb-0',
												},
												children
											);
										}
										return (
											<React.Fragment key={key}>
												{children}
											</React.Fragment>
										);
									}
								),
							]}
							renderBlock={({ record }) => {
								// eslint-disable-next-line no-underscore-dangle
								switch (record.__typename) {
									case 'DatoCmsTickListContainer':
										return (
											<TickList
												className="grid gap-5 md:grid-cols-2 mt-lg-f"
												listItemClassName="flex items-start border-blue-200 rounded-sm bg-brand-pale-300 p-s-f border-1 !mb-0"
												textClassName="font-lexend-medium mb-[6px]"
												points={record.listItems}
											/>
										);
									case 'DatoCmsReviewBanner':
										return (
											<ReviewBanner
												reviewBannerContent={record}
												className="!font-lexend-regular !bg-brand-pale-300 border-1 border-blue-200 !text-blue-500 !leading-snug"
												titleClassName="!font-lexend-medium !leading-snug"
												quoteClassName="text-blue-400"
												inContent
											/>
										);
									default:
										return null;
								}
							}}
							customMarkRules={[
								renderMarkRule(
									'strong',
									({ children, key }) => (
										<span
											className="font-lexend-regular"
											key={key}
										>
											{children}
										</span>
									)
								),
							]}
						/>
					</div>
				</div>
			);
		})}
	</section>
);

CaseStudiesText.defaultProps = {
	className: '',
	locale: 'en',
	header: null,
	contentRow: [],
	__typename: '',
	name: '',
	job: '',
	profile: {},
	headerTitle: '',
	subHeader: '',
	ctaText: '',
	ctaSuccessText: '',
	ctaSuccessLink: '',
};

CaseStudiesText.propTypes = {
	className: PropTypes.string,
	locale: PropTypes.string,
	header: PropTypes.string,
	content: PropTypes.arrayOf(structuredTextContent).isRequired,
	contentRow: PropTypes.arrayOf(structuredTextContent),
	__typename: PropTypes.string,
	name: PropTypes.string,
	job: PropTypes.string,
	headerTitle: PropTypes.string,
	subHeader: PropTypes.string,
	ctaText: PropTypes.string,
	ctaSuccessText: PropTypes.string,
	ctaSuccessLink: PropTypes.string,
	profile: PropTypes.shape({
		gatsbyImageData: gatsbyImageProp,
		alt: PropTypes.string,
	}),
};

export { CaseStudiesText };
