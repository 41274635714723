// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
	StructuredText,
	renderNodeRule,
	renderMarkRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading, isParagraph } from 'datocms-structured-text-utils';

import PageSection from '../page-section';
import RedPlayIcon from '../../../../assets/red-video-play.svg';
import { Button } from '../../../atoms/button';
import GreetingHeader from '../../atoms/GreetingHeader';
import CourseTile from '../../molecules/course-tile';

const ProspectUnlocked = ({ data, setVideoContent, setModalOpen }) => {
	const {
		onDemandAndWebinars,
		headingImage,
		image,
		messageContent,
		headingContent,
	} = data.datoCmsGluProspect;
	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];
	return (
		<>
			<PageSection titleBlock={headingContent} theme="Dark" />
			<GreetingHeader headingImg={headingImage} />

			<div className="px-4 py-6 mx-auto lg:py-3 max-w-m-screen">
				<h2 className="mb-5 text-2xl">
					You now have access to the following:
				</h2>
				<div className="block gap-10 mb-10 lg:flex">
					<div className="w-full grid-cols-2 lg:gap-x-10 lg:grid">
						{onDemandAndWebinars.map((webinar) => (
							<div key={webinar.id}>
								<CourseTile
									title={webinar.title}
									date={webinar.date}
									introText={webinar.intro}
									iconSrc={RedPlayIcon}
									ctaText="Watch"
									onClick={() => {
										setModalOpen(true);
										setVideoContent(webinar);
									}}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<PageSection theme="Light">
				<div className="flex flex-col items-center lg:flex-row">
					<div className="text-lg text-center lg:text-left lg:pr-20 lg:text-2xl">
						<GatsbyImage
							image={mainLogo.gatsbyImageData}
							alt="GLU logo"
							className="mb-5"
						/>
						<img src={mainLogo.url} alt={mainLogo.alt || ''} />
						<StructuredText
							data={messageContent}
							customNodeRules={[
								renderNodeRule(
									isHeading,
									({ node, children, key }) => {
										const HeadingTag = `h${node.level}`;
										return (
											<HeadingTag
												className="mb-5 text-2xl lg:text-3xl font-lexend-regular"
												key={key}
											>
												{children}
											</HeadingTag>
										);
									}
								),
								renderNodeRule(
									isParagraph,
									({ children, key }) => (
										<p
											className="mb-4 text-sm font-notosans-light last-of-type:mb-0"
											key={key}
										>
											{children}
										</p>
									)
								),
							]}
							customMarkRules={[
								renderMarkRule(
									'highlight',
									({ children, key }) => (
										<span
											className="font-bold text-brand-red-400"
											key={key}
										>
											{children}
										</span>
									)
								),
							]}
						/>
						<div className="mt-10">
							<Button
								onClick={() => {
									setModalOpen(true);
									setVideoContent('Form');
								}}
							>
								Speak to an expert
							</Button>
						</div>
					</div>
					<div className="p-10">
						<GatsbyImage
							image={image.gatsbyImageData}
							alt={image.alt || ''}
						/>
					</div>
				</div>
			</PageSection>
		</>
	);
};

export default ProspectUnlocked;
