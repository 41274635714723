// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/cor-ca';

import { Head } from '../components/templates/head';

import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice-cor';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBannerCA } from '../components/organisms/advice-banner-ca';
import { ServicesBannerCA } from '../components/organisms/services-banner-ca';
import { FaqCA } from '../components/organisms/faq-ca';

import { MainHeaderCorCA } from '../components/molecules/main-header-cor-ca';
import { TextWithImageContainerCorCA } from '../components/molecules/text-with-image-container-cor-ca';
import { pageTypes } from '../utils/url-helpers';

import { Drawer } from '../components/atoms/drawer';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getFAQSeoSchema,
} from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';
import { CorV2CA } from '../components/organisms/cor-ca-v2';
import { ClientLogosCA } from '../components/atoms/client-logos-ca';

// markup
const ServiceCA = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsChildServicePageCa.noIndex,
		noFollow: data.datoCmsChildServicePageCa.noFollow,
		metaInformation: data.datoCmsChildServicePageCa.metaInformation,
		fallbackTitle: data.datoCmsChildServicePageCa.title,
		fallbackDescription: data.datoCmsChildServicePageCa.longIntroduction,
		canonicalUrl:
			data.datoCmsChildServicePageCa?.canonicalUrl || location.href,
	};

	const headerCTAContent = {
		...data.datoCmsChildServicePageCa.mainHeader,
		header: data.datoCmsChildServicePageCa.mainHeader.header.value.document
			.children,
		googleReview: data.siteSettings.googleReview,
	};

	const textWithImageContainer = {
		...data.datoCmsChildServicePageCa.textWithImageContainer,
		title: data.datoCmsChildServicePageCa.textWithImageContainer.title.value
			.document.children,
		firstContent:
			data.datoCmsChildServicePageCa.textWithImageContainer.firstContent,
		secondContent:
			data.datoCmsChildServicePageCa.textWithImageContainer.secondContent,
	};

	const servicesContent = {
		title: data.datoCmsChildServicePageCa.servicesContainer.title,
		subText: data.datoCmsChildServicePageCa.servicesContainer.subText,
		ctaLabel: data.datoCmsChildServicePageCa.servicesContainer.ctaLabel,
	};

	const corContent = {
		title: data.datoCmsChildServicePageCa.corContainerV2.title,
		subText: data.datoCmsChildServicePageCa.corContainerV2.subText,
		corCards: data.datoCmsChildServicePageCa.corContainerV2.corCards,
	};

	const faqContent = {
		title: data.datoCmsChildServicePageCa.faqContainer.title,
		subText: data.datoCmsChildServicePageCa.faqContainer.subText,
		questions: data.datoCmsChildServicePageCa.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const adviceContent = {
		title: data.datoCmsChildServicePageCa.adviceContainer.title,
		subText: data.datoCmsChildServicePageCa.adviceContainer.subText,
		ctaLabel: data.datoCmsChildServicePageCa.adviceContainer.ctaLabel,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsChildServicePageCa,
		}),
		image: getImageSeoSchema(
			data.datoCmsChildServicePageCa?.mainHeader?.primaryImage
		),
		faq: getFAQSeoSchema(faqContent?.questions),
	};
	const { locale } = data.datoCmsChildServicePageCa;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsChildServicePageCa.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<div
				className="max-w-screen"
				style={{
					background:
						'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(252,253,255,1) 100%)',
				}}
			>
				{data.datoCmsChildServicePageCa.mainHeaderDisplayOnPage && (
					<MainHeaderCorCA
						urlLocale={locale}
						drawerRef={adviceFormRef}
						{...headerCTAContent}
						reference={reference}
						response={response}
						setReference={setReference}
						setResponse={setResponse}
					/>
				)}

				{data.datoCmsChildServicePageCa
					.clientLogosBannerDisplayOnPage && (
					<>
						<ClientLogosCA className="hidden -mt-5 -mb-16 lg:block" />
						<ClientLogosCA isMobile className="block lg:hidden" />
					</>
				)}
			</div>

			{data.datoCmsChildServicePageCa.corContainerV2DisplayOnPage && (
				<CorV2CA drawerRef={adviceFormRef} corContent={corContent} />
			)}

			{data.datoCmsChildServicePageCa
				.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainerCorCA
					textWithImageContainerContent={textWithImageContainer}
					locale={locale}
				/>
			)}

			{data.datoCmsChildServicePageCa.servicesContainerDisplayOnPage && (
				<ServicesBannerCA
					drawerRef={adviceFormRef}
					servicesContent={servicesContent}
					siteSettings={data.siteSettings}
				/>
			)}

			{data.datoCmsChildServicePageCa.faqContainerDisplayOnPage && (
				<FaqCA
					faqContent={faqContent}
					className="lg:pt-5 lg:-mt-14 lg:pb-10"
				/>
			)}

			{data.datoCmsChildServicePageCa.adviceContainerDisplayOnPage && (
				<AdviceBannerCA
					drawerRef={adviceFormRef}
					adviceContent={adviceContent}
					siteSettings={data.siteSettings}
				/>
			)}

			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceFormCA
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessFormCA reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
			googleReview {
				url
				alt
			}
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsChildServicePageCa(
			slug: { eq: $slug }
			locale: { eq: $locale }
		) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			clientLogosBannerDisplayOnPage
			mainHeaderDisplayOnPage
			servicesContainerDisplayOnPage
			corContainerDisplayOnPage
			corContainerV2DisplayOnPage
			faqContainerDisplayOnPage
			adviceContainerDisplayOnPage
			textWithImageContainerDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
				showInlineForm
				formPosition
			}
			textWithImageContainer {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
			}
			servicesContainer {
				title {
					value
				}
				subText {
					text
				}
				ctaLabel
			}
			corContainerV2 {
				title {
					value
				}
				subText
				corCards {
					title
					subText
					buttonCopy
					items {
						text
					}
				}
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			adviceContainer {
				title {
					value
				}
				subText {
					text
				}
				ctaLabel
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
	}
`;

const WrappedService = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<ServiceCA data={data} location={location} />
	</DefaultLayout>
);

export default WrappedService;
