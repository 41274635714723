// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AdviceHrSoftware } from '../components/organisms/ca/forms/salesforce/advice-modal-hr-software';
import { SuccessHrSoftware } from '../components/organisms/ca/forms/salesforce/success-modal-hr-software';
import { DefaultLayout } from '../layouts/default';
import { Head } from '../components/templates/head';
import { HeroHrSoftware } from '../components/molecules/hero-hr-software';
import { Testimonials } from '../components/organisms/testimonials-hr-software';
import { FAQ } from '../components/organisms/faq';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container-hr-software';
import { pageTypes } from '../utils/url-helpers';
import { ClientLogosCA } from '../components/atoms/client-logos-ca';
import { IconCardsHrSoftware } from '../components/organisms/icon-cards-hr-software';
import { Modal } from '../components/atoms/modal-v2-ca';
import { useModal } from '../contexts/modal-context';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';

import { HrefLang } from '../components/templates/hrefLang';

const HrSoftwarePage = ({ data, location }) => {
	// eslint-disable-next-line no-unused-vars
	const { modalRef, setModalRef } = useModal();
	const [response, setResponse] = useState('');
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');

	const metaContent = {
		noIndex: data.datoCmsHrSoftwarePage.noIndex,
		noFollow: data.datoCmsHrSoftwarePage.noFollow,
		metaInformation: data.datoCmsHrSoftwarePage.metaInformation,
		fallbackTitle: data.datoCmsHrSoftwarePage.title,
		fallbackDescription: data.datoCmsHrSoftwarePage.longIntroduction,
		canonicalUrl: data.datoCmsHrSoftwarePage?.canonicalUrl || location.href,
	};

	const textWithImageContainer = {
		...data.datoCmsHrSoftwarePage.textWithImageContainer,
		firsttitle:
			data.datoCmsHrSoftwarePage.textWithImageContainer?.firstTitle?.value
				.document.children,
		secondtitle:
			data.datoCmsHrSoftwarePage.textWithImageContainer?.secondTitle.value
				.document.children,
		firstContent:
			data.datoCmsHrSoftwarePage.textWithImageContainer?.firstContent,
		secondContent:
			data.datoCmsHrSoftwarePage.textWithImageContainer?.secondContent,
	};

	const textWithImageContainerTwo = {
		...data.datoCmsHrSoftwarePage.textWithImageContainerTwo,
		firsttitle:
			data.datoCmsHrSoftwarePage.textWithImageContainerTwo?.firstTitle
				?.value.document.children,
		secondtitle:
			data.datoCmsHrSoftwarePage.textWithImageContainerTwo?.secondTitle
				.value.document.children,
		firstContent:
			data.datoCmsHrSoftwarePage.textWithImageContainerTwo?.firstContent,
		secondContent:
			data.datoCmsHrSoftwarePage.textWithImageContainerTwo?.secondContent,
	};

	const headerCTAContent = {
		...data.datoCmsHrSoftwarePage.mainHeader,
		header: data.datoCmsHrSoftwarePage.mainHeader.header.value.document
			.children,
		secondaryHeaderRows: [
			...data.datoCmsHrSoftwarePage.secondaryHeaderRows,
		],
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsHrSoftwarePage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsHrSoftwarePage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsHrSoftwarePage?.customFormField[0]?.customFormButton,
	};

	const testimonialsContent = {
		...data.datoCmsHrSoftwarePage.testimonials,
		title: data.datoCmsHrSoftwarePage.testimonials.title.value.document
			.children,
		slides: data.datoCmsHrSoftwarePage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const faqContent = {
		title: data.datoCmsHrSoftwarePage.faqContainer.title,
		subText: data.datoCmsHrSoftwarePage.faqContainer.subText,
		questions: data.datoCmsHrSoftwarePage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const { uspCardsHrSoftware } = data.datoCmsHrSoftwarePage;

	const uspCardsTitle =
		data.datoCmsHrSoftwarePage.uspCardsHrSoftwareTitle.value.document
			.children;

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsHrSoftwarePage,
		}),
		image: getImageSeoSchema(
			data.datoCmsHrSoftwarePage?.mainHeader?.primaryImage
		),
	};

	// for CTA banner
	const ctaBannerContent = {
		bannerContent: data.datoCmsHrSoftwarePage?.bannerContent,
		bannerTitle: data.datoCmsHrSoftwarePage?.bannerTitle,
		ctaLabel: data.datoCmsHrSoftwarePage?.ctaLabel,
	};

	// classnames in charge of spacing (padding or margin) for TextWithImageContainer
	const className = '-mt-20 lg:-mt-36 md:-mt-32 ';
	const className2 = '';

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsHrSoftwarePage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<HeroHrSoftware
				modalRef={adviceFormRef}
				{...headerCTAContent}
				ctaLabel={ctaBannerContent.ctaLabel}
			/>
			{/* For larger screens or desktop */}
			{data.datoCmsHrSoftwarePage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA pathname={location.pathname} />
			)}
			{/* For mobile devices */}
			{data.datoCmsHrSoftwarePage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA pathname={location.pathname} isMobile />
			)}
			{data.datoCmsHrSoftwarePage.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
					modalRef={adviceFormRef}
					className={className2}
				/>
			)}
			{data.datoCmsHrSoftwarePage
				.textWithImageContainerTwoDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainerTwo}
					modalRef={adviceFormRef}
					className={className}
				/>
			)}
			{data.datoCmsHrSoftwarePage.uspCardsHrSoftwareDisplayOnPage && (
				<IconCardsHrSoftware
					iconCardsContent={{
						uspCards: uspCardsHrSoftware,
					}}
					uspTitle={uspCardsTitle}
				/>
			)}
			{data.datoCmsHrSoftwarePage.testimonialDisplayOnPage && (
				<Testimonials
					modalRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			{data.datoCmsHrSoftwarePage.faqContainerDisplayOnPage && (
				<FAQ faqContent={faqContent} />
			)}
			<Modal
				label="Book my free advice call"
				ref={adviceFormRef}
				open={modalRef === adviceFormRef}
				hasClose
				className="!p-0 z-50"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceHrSoftware
						setReference={setReference}
						setResponse={setResponse}
						customFormField={customFormField}
					/>
				) : (
					<SuccessHrSoftware
						reference={reference}
						response={response}
						className="my-[15%]"
					/>
				)}
			</Modal>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query hrSoftware($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsHrSoftwarePage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			clientLogosBannerDisplayOnPage
			mainHeaderDisplayOnPage
			textWithImageContainerDisplayOnPage
			testimonialDisplayOnPage
			textWithImageContainerTwoDisplayOnPage
			faqContainerDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			secondaryHeaderRows {
				secondaryHeaderRow
			}
			textWithImageContainer {
				id
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 530
						height: 540
						layout: CONSTRAINED
					)
				}
				firstButtonCopy
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 530
						height: 540
						layout: CONSTRAINED
					)
				}
				secondButtonCopy
				secondDisplayCtaOnPage
			}
			textWithImageContainerTwo {
				id
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 530
						height: 540
						layout: CONSTRAINED
					)
				}
				firstButtonCopy
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 530
						height: 540
						layout: CONSTRAINED
					)
				}
				secondButtonCopy
				secondDisplayCtaOnPage
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			uspCardsHrSoftwareTitle {
				value
			}
			uspCardsHrSoftwareDisplayOnPage
			uspCardsHrSoftware {
				title
				content
				linkCopy
				link
				icon
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
	}
`;

const WrappedHrSoftware = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="Peninsula Now"
	>
		<HrSoftwarePage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedHrSoftware;
