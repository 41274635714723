/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import award1 from '../../../assets/ppc-review-1.png';
import award2 from '../../../assets/ppc-review-2.png';
// import award3 from '../../../assets/ppc-review-3.png';

const BusinessReview = () => (
	<div className="flex flex-col justify-center max-w-[235px] text-center shrink-0 w-full" />
);

const BusinessReviewHorizontal = ({
	className,
	googleReview,
	onlyGR = false,
}) => (
	<div
		className={clsx(
			'flex',
			'flex-wrap',
			'items-center',
			'w-full',
			'mt-8',
			className
		)}
	>
		<div className="flex flex-col gap-8 mx-auto md:mx-0 lg:gap-20 lg:flex-row">
			<div className="flex flex-wrap items-center gap-3 lg:gap-6 shrink-0">
				{onlyGR ? (
					<img
						src={googleReview?.url || ''}
						alt={googleReview?.alt || ''}
						className="w-[160px] md:w-[180px]"
					/>
				) : (
					<>
						<img
							src={award1}
							alt="CANADIAN SME NATIONAL BUSINESS AWARDS 2023."
							className="w-[90px] md:w-[150px]"
						/>
						<img
							src={award2}
							alt="FRANCHISE CANADA."
							className="w-[90px] md:w-[150px]"
						/>
						<img
							src={googleReview.url}
							alt={googleReview.alt}
							className="w-[108px] md:w-[180px]"
						/>
					</>
				)}
			</div>
		</div>
	</div>
);

BusinessReviewHorizontal.defaultProps = {
	className: '',
};

BusinessReviewHorizontal.propTypes = {
	className: PropTypes.string,
};

export { BusinessReview, BusinessReviewHorizontal };
