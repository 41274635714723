import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import { ButtonVariants } from '../button-ca';

const PBSLink = ({
	as,
	children,
	to,
	onClick,
	className,
	variant,
	size,
	...linkAttributes
}) => {
	let Component = as;
	// If someone puts in an external link switch to using
	// <a></a>
	if (to.indexOf('http') === 0 || to.indexOf('blob') === 0) {
		Component = 'a';
	} else if (!to.endsWith('/')) {
		// eslint-disable-next-line no-param-reassign
		to = `${to}/`;
	}

	let variantClass = '';

	const getVariantClass = () => {
		// Set to Default if not 'Unset' or 'Red'
		if (
			variant !== 'Unset' &&
			variant !== 'Red' &&
			variant !== 'RedReverse'
		) {
			variantClass = ButtonVariants.Default;
		}

		// Add bg and text for 'Default'
		if (variant === 'Default') {
			variantClass += ' bg-blue-400 text-white';
		} else {
			variantClass += ` ${ButtonVariants[variant] || ''}`;
		}

		// Return combined className
		return `${ButtonVariants[size]} ${variantClass} ${className}`;
	};

	return (
		<Component
			className={`${getVariantClass()}`}
			{...linkAttributes}
			to={typeof Component !== 'string' ? to : undefined}
			href={typeof Component === 'string' ? to : undefined}
			onClick={onClick}
		>
			{children}
		</Component>
	);
};

PBSLink.defaultProps = {
	as: Link,
	to: '',
	onClick: () => {},
	className: '',
	variant: 'Default',
	size: 'Normal',
};

PBSLink.propTypes = {
	as: PropTypes.oneOf([Link, 'a']),
	children: PropTypes.node.isRequired,
	to: PropTypes.string,
	variant: PropTypes.oneOf([
		'Default',
		'White',
		'WhiteBlue',
		'Ghost',
		'Link',
		'LinkWithSVG',
		'Unset',
	]),
	size: PropTypes.oneOf(['Small', 'Normal', 'Tall']),
	onClick: PropTypes.func,
	className: PropTypes.string,
};

export { PBSLink };
