import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button } from '../../atoms/button';

import { titleProp, refProp } from '../../../types';
import { useDrawer } from '../../../contexts/drawer-context';
import { LOCALE_CURRENCY } from '../../../utils/locale';

const AdviceStages = ({ id, drawerRef, locale }) => {
	const { setDrawerRef } = useDrawer();
	const currency = LOCALE_CURRENCY[locale || 'ca'];
	const getLinks = () => (
		<p className="flex flex-col items-center xl:flex-row lg:-ml-lg-f xl:ml-0">
			<Button
				onClick={() => setDrawerRef(drawerRef)}
				className="whitespace-nowrap"
			>
				Find out more
			</Button>
		</p>
	);

	const getAdvice1 = () => {
		const index = 0;
		const lastElement = 1;
		const marginLeft = ['lg:ml-0', 'lg:ml-s-f', `lg:ml-lg-f`];
		const marginRight = ['lg:mr-lg-f', 'lg:mr-s-f', `lg:mr-0`];
		const panelColors = [
			'bg-white',
			'bg-brand-pale-300',
			'bg-brand-pale-500',
		];
		return (
			<article
				className={clsx(
					'py-s-f px-xs-f lg:p-s-f border rounded-sm shadow-slight',
					marginLeft[index],
					marginRight[index],
					'mb-s-f lg:mb-6',
					!lastElement && 'mb-s-f lg:mb-6',
					panelColors[index]
				)}
			>
				<span className="mt-6 md:text-lg font-lexend-medium">
					You could hire someone full-time, but that is expensive…
				</span>
			</article>
		);
	};

	const getAdvice2 = () => {
		const index = 1;
		const lastElement = 2;
		const marginLeft = ['lg:ml-0', 'lg:ml-s-f', `lg:ml-lg-f`];
		const marginRight = ['lg:mr-lg-f', 'lg:mr-s-f', `lg:mr-0`];
		const panelColors = [
			'bg-white',
			'bg-brand-pale-300',
			'bg-brand-pale-500',
		];
		return (
			<article
				className={clsx(
					'py-s-f px-xs-f lg:p-s-f border rounded-sm shadow-slight',
					marginLeft[index],
					marginRight[index],
					'mb-s-f lg:mb-6',
					!lastElement && 'mb-s-f lg:mb-6 bg',
					panelColors[index]
				)}
			>
				<span
					className={clsx(
						'mt-2',
						'pb-2',
						'md:text-lg',
						'font-medium'
					)}
				>
					The <strong>annual salary for an HR Manager</strong> is
					upwards of{' '}
					<strong className="text-xl md:text-2xl">{`${currency}81,000.`}</strong>
				</span>
			</article>
		);
	};

	const getAdvice3 = () => {
		const index = 2;
		const lastElement = 3;
		const marginLeft = ['lg:ml-0', 'lg:ml-s-f', `lg:ml-lg-f`];
		const marginRight = ['lg:mr-lg-f', 'lg:mr-s-f', `lg:mr-0`];
		const panelColors = [
			'bg-white',
			'bg-brand-pale-300',
			'bg-brand-pale-500',
		];
		return (
			<article
				className={clsx(
					'py-s-f px-xs-f lg:p-s-f border rounded-sm shadow-slight',
					marginLeft[index],
					marginRight[index],
					'mb-s-f lg:mb-6',
					!lastElement && 'mb-s-f lg:mb-6',
					panelColors[index]
				)}
			>
				<span
					className={clsx(
						'mt-2',
						'pb-2',
						'md:text-lg',
						'font-medium'
					)}
				>
					And, <strong>you&apos;ll have to pay upwards of</strong>
					<strong className="text-xl md:text-2xl">
						{' '}
						{`${currency}139,000.`}
					</strong>{' '}
					for a <strong>Health & Safety Manager,</strong> or high
					hourly rates for ad-hoc, external guidance.
				</span>
			</article>
		);
	};

	return (
		<section
			aria-labelledby={id}
			className="border-b-4 bg-brand-pale-400 lg:border-b-6 border-b-blue-400"
		>
			<div className="grid grid-flow-row lg:grid-cols-[1fr_fit-content(55%)] gap-0 lg:gap-[5%] py-xl-f xl:py-2xl-f px-xs-f lg:px-8 2xl:px-0 2xl:max-w-m-screen mx-auto">
				<div className="flex flex-col justify-center">
					<div
						className="text-3xl
					md:text-5xl
					font-lexend-regular'
					leading-[1.2]
					md:leading-[1.3]"
					>
						These are costs you can easily avoid with Peninsula
					</div>
					<div className="text-base-f text-left mt-base-f mb-lg-f !lg:mt-lg-f !lg:mb-lg-f">
						Think of the time you&apos;d free up, the stress
						you&apos;d avoid, and the money you&apos;d save that you
						can put back into your business.
					</div>

					<div className="hidden lg:block">{getLinks()}</div>
				</div>
				<div className="mb-lg-f lg:mb-0">
					{getAdvice1()} {getAdvice2()} {getAdvice3()}
				</div>
				<div className="block lg:hidden">{getLinks()}</div>
			</div>
		</section>
	);
};

AdviceStages.defaultProps = {
	id: 'advice-stages',
};

AdviceStages.propTypes = {
	id: PropTypes.string,
	locale: PropTypes.string.isRequired,
	adviceStagesContent: PropTypes.shape({
		title: titleProp.isRequired,
		content: PropTypes.string,
		secondaryLink: PropTypes.string,
		primaryLinkCopy: PropTypes.string,
		secondaryLinkCopy: PropTypes.string,
		advice: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				content: PropTypes.string,
			})
		).isRequired,
	}).isRequired,
	drawerRef: refProp.isRequired,
};

export { AdviceStages };
