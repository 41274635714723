import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../atoms/button-ca';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import { sendTrackingData } from '../../../utils';

const VideoSection = ({ content, phoneNumber }) => {
	const {
		title,
		subtitle,
		video,
		buttonCopy,
		numberedPoints,
		numberedListIntro,
	} = content;
	const [isPlaying, setIsPlaying] = useState(false);

	const handleClickTracking = () => {
		if (!window.dataLayer?.find((element) => element['gtm.start'])) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'video-section',
			click_type: 'play_button',
			click_action: 'play',
			click_title: 'video-section',
		});
	};

	const videoSrc = video[0].media.url;
	const videoThumbnail = video[1].media.url;

	// Scroll to the form section
	const scrollToAdviceForm = () => {
		document.getElementById('advice-form')?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const handleButtonClick = () => {
		// Always send click tracking
		handleClickTracking();

		// Detect mobile devices
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		if (isMobile && phoneNumber) {
			// Open the phone dialer with the provided number
			window.location.href = `tel:${phoneNumber}`;
		} else {
			// Scroll to the form section on larger screens
			scrollToAdviceForm();
		}
	};

	// Extract structured text
	const extractText = (variant, textBlock) =>
		createHeadingHighlightCA({
			variant,
			headings: textBlock?.value?.document?.children[0]?.children,
		});

	// Main text content
	const titleText = extractText('brand-blue-underline', title);
	const introText = extractText('basic-content', numberedListIntro);

	return (
		<section className="w-full px-6 py-6 mx-auto mt-10 md:py-16 max-w-m-screen lg:px-12">
			{/* Title Section */}
			<h2 className="text-3xl leading-tight text-center md:text-5xl font-lexend-regular">
				<span className="text-brand-primary underline-offset-4 decoration-2">
					{titleText}
				</span>
			</h2>
			<p className="mt-8 text-xl text-center text-gray-700 font-notosans-light">
				{subtitle}
			</p>

			<div className="flex flex-col items-center gap-6 mt-10 lg:flex-row lg:items-start">
				{/* Video Player with Thumbnail */}
				<div className="w-full lg:w-[65%]">
					{!isPlaying ? (
						<div
							className="w-full "
							onClick={() => {
								setIsPlaying(true);
								handleClickTracking();
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter' || e.key === ' ') {
									setIsPlaying(true);
									handleClickTracking();
								}
							}}
							role="button"
							type="button"
							tabIndex={0}
						>
							<img
								src={videoThumbnail}
								alt="Video thumbnail"
								className="w-full shadow-md"
								style={{ borderRadius: '0.75rem' }}
							/>
						</div>
					) : (
						<video
							src={videoSrc}
							controls
							autoPlay
							className="w-full shadow-md max-w-none"
							style={{ borderRadius: '0.75rem' }}
						>
							<track
								kind="captions"
								srcLang="en"
								src="path/to/captions.vtt"
								default
							/>
						</video>
					)}
					{/* CTA Button */}
					<div className="flex justify-center mt-8 lg:mt-9">
						<Button
							onClick={handleButtonClick}
							variant="RedBook"
							className="px-6 py-3 text-lg"
						>
							{buttonCopy}
						</Button>
					</div>
				</div>

				{/* Bullet Points List */}
				<div className="lg:w-[35%] flex flex-col">
					<p className="mb-4 text-gray-900">{introText}</p>
					<ul className="space-y-3">
						{numberedPoints.map((point, index) => {
							const extractedText = extractText(
								'brand-blue-underline',
								point.structuredTextBlock
							);

							return (
								<li
									key={point.id}
									className="flex items-center p-3 bg-[#F4F4F4] rounded-sm shadow-sm"
								>
									<span className="flex items-center justify-center aspect-square min-w-[32px] min-h-[32px] text-lg font-semibold text-[#D7DDF9] bg-[#9CAAF0] rounded-full border border-[#D7DDF9] flex-shrink-0">
										{index + 1}
									</span>
									<span className="pl-3 text-gray-700">
										{extractedText}
									</span>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</section>
	);
};

VideoSection.propTypes = {
	content: PropTypes.shape({
		// Title should have a value with a document structure.
		title: PropTypes.shape({
			value: PropTypes.shape({
				schema: PropTypes.string,
				document: PropTypes.shape({
					type: PropTypes.string,
					// eslint-disable-next-line react/forbid-prop-types
					children: PropTypes.arrayOf(PropTypes.object),
				}).isRequired,
			}).isRequired,
		}).isRequired,
		numberedListIntro: PropTypes.shape({
			value: PropTypes.shape({
				schema: PropTypes.string,
				document: PropTypes.shape({
					type: PropTypes.string,
					// eslint-disable-next-line react/forbid-prop-types
					children: PropTypes.arrayOf(PropTypes.object),
				}).isRequired,
			}).isRequired,
		}).isRequired,
		subtitle: PropTypes.string.isRequired,
		video: PropTypes.arrayOf(
			PropTypes.shape({
				media: PropTypes.shape({
					url: PropTypes.string.isRequired,
				}).isRequired,
			}).isRequired
		).isRequired,
		numberedPoints: PropTypes.arrayOf(
			PropTypes.shape({
				structuredTextBlock: PropTypes.shape({
					value: PropTypes.shape({
						schema: PropTypes.string,
						document: PropTypes.shape({
							type: PropTypes.string,
							// eslint-disable-next-line react/forbid-prop-types
							children: PropTypes.arrayOf(PropTypes.object),
						}).isRequired,
					}).isRequired,
				}).isRequired,
			})
		).isRequired,
		buttonCopy: PropTypes.string.isRequired,
	}).isRequired,
	phoneNumber: PropTypes.string.isRequired,
};

export { VideoSection };
