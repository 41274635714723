// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import FeaturedSessionCard from '../../molecules/featured-session-card-ca-new';

const SessionCardContainerCA = ({
	liveWebinarSessions,
	pbsWebinar,
	locale,
}) => {
	// eslint-disable-next-line no-unused-vars
	const sortedWebinars = liveWebinarSessions?.upcomingLiveWebinars?.sort(
		(a, b) => new Date(a.dateAndTime) - new Date(b.dateAndTime)
	);

	return (
		<div className="mx-auto max-w-m-screen">
			<div className="flex flex-col mb-16 gap-x-10 md:flex-row">
				<div className="w-full pt-8 md:w-1/2 md:pt-0">
					<GatsbyImage
						image={liveWebinarSessions?.image?.gatsbyImageData}
						alt={liveWebinarSessions?.image?.alt || ''}
						className="w-full h-full"
					/>
				</div>

				<FeaturedSessionCard
					featuredCard={liveWebinarSessions?.highlightedLiveWebinar}
					pbsWebinar={pbsWebinar}
					locale={locale}
				/>
			</div>

			{/* {pbsWebinar ? (
				<PageSection
					theme="White"
					titleBlock={liveWebinarSessions.upcomingWebinarsCopy}
				>
					<div className="grid w-full grid-cols-1 gap-10 lg:grid-cols-3">
						{sortedWebinars.map((card) => (
							<LiveSessionCard
								session={card}
								pbsWebinar
								locale={locale}
							/>
						))}
					</div>
				</PageSection>
			) : (
				<>
					<h3 className="mt-10 mb-6 text-2xl font-bold">
						Upcoming sessions
					</h3>
					<div className="flex flex-col gap-10 pb-10 lg:flex-row">
						{sortedWebinars.map((card) => (
							<SessionCard session={card} locale={locale} />
						))}
					</div>
				</>
			)} */}
		</div>
	);
};

export default SessionCardContainerCA;
