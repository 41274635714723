import { clsx } from 'clsx';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonVariants } from '../button';

const PBSLink = ({
	as,
	children,
	to,
	onClick,
	className,
	variant,
	size,
	...linkAttributes
}) => {
	let Component = as;
	// If someone puts in an external link switch to using
	// <a></a>
	if (to.indexOf('http') === 0 || to.indexOf('blob') === 0) {
		Component = 'a';
	} else if (!to.endsWith('/')) {
		// eslint-disable-next-line no-param-reassign
		to = `${to}`;
	}
	return (
		<Component
			className={clsx(
				ButtonVariants[size],
				variant === 'Unset' ? null : ButtonVariants.Default,
				variant === 'Default' ? 'bg-blue-400 text-white' : null,
				ButtonVariants[variant],
				className
			)}
			{...linkAttributes}
			to={typeof Component !== 'string' ? to : undefined}
			href={typeof Component === 'string' ? to : undefined}
			onClick={onClick}
		>
			{children}
		</Component>
	);
};

PBSLink.defaultProps = {
	as: Link,
	to: '',
	onClick: () => {},
	className: '',
	variant: 'Default',
	size: 'Normal',
};

PBSLink.propTypes = {
	as: PropTypes.oneOf([Link, 'a']),
	children: PropTypes.node.isRequired,
	to: PropTypes.string,
	variant: PropTypes.oneOf([
		'Default',
		'White',
		'WhiteBlue',
		'Ghost',
		'Link',
		'LinkWithSVG',
		'Unset',
	]),
	size: PropTypes.oneOf(['Small', 'Normal', 'Tall']),
	onClick: PropTypes.func,
	className: PropTypes.string,
};

export { PBSLink };
