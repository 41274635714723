import React from 'react';
import PropTypes from 'prop-types';
import { pricingCardProps, priceCalculatedProp } from '../../../types';
import { PricingCard } from '../../atoms/pricing-card-ca';

const PricingCardsCA = ({
	content,
	priceCalculated,
	employeeCount,
	quoteButtonCopy,
}) => (
	<section className="grid grid-cols-12 gap-5 outline-none lg:container lg:mx-auto focus:shadow-focus">
		{content.map((item, index) => (
			<PricingCard
				priceCalculated={priceCalculated}
				key={`${item.id}-card`}
				content={item}
				index={index}
				employeeCount={employeeCount}
				quoteButtonCopy={quoteButtonCopy}
			/>
		))}
	</section>
);

PricingCardsCA.defaultProps = {
	employeeCount: 0,
	priceCalculated: {
		hr: '-',
		hs: '-',
		combined: '-',
	},
};

PricingCardsCA.propTypes = {
	content: PropTypes.arrayOf(pricingCardProps).isRequired,
	employeeCount: PropTypes.number,
	priceCalculated: priceCalculatedProp,
	quoteButtonCopy: PropTypes.string.isRequired,
};

export { PricingCardsCA };
