import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types'; // Add prop-types import
import { Button } from '../../atoms/button-ca';
import { sendTrackingData } from '../../../utils';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';

const AdviceContentContainer = ({ content, phoneNumber }) => {
	const {
		title,
		adviceHeading,
		adviceSubheading,
		pointsContent,
		fineAmounts,
		buttonText,
	} = content;

	// Function to handle tracking clicks
	const handleClickTracking = () => {
		if (!window.dataLayer?.find((element) => element['gtm.start'])) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-content',
			click_type: 'arrow',
			click_action: 'right',
			click_title: 'advice-',
		});
	};

	// Scroll to the form section
	const scrollToAdviceForm = () => {
		document.getElementById('advice-form')?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const handleButtonClick = () => {
		// Always send click tracking
		handleClickTracking();

		// Detect mobile devices
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		if (isMobile && phoneNumber) {
			// Open the phone dialer with the provided number
			window.location.href = `tel:${phoneNumber}`;
		} else {
			// Scroll to the form section on larger screens
			scrollToAdviceForm();
		}
	};

	// Extract structured text
	const extractText = (variant, textBlock) =>
		createHeadingHighlightCA({
			variant,
			headings: textBlock?.value?.document?.children[0]?.children,
		});

	// Main text content
	const headingText = extractText('heading-blue', adviceHeading);
	const contentText = extractText('content-black', adviceSubheading);
	const pointsIntroText = extractText('content-black', pointsContent);
	const titleText = extractText('brand-blue-underline', title);

	// Fine Amounts
	const fineTexts = fineAmounts?.map((amount) =>
		extractText('fine-details', amount.structuredTextBlock)
	);

	return (
		<div className="mt-10 md:py-8">
			{/* Compliance Warning Section */}
			<div className="p-6 mx-auto lg:max-w-m-screen">
				<div className="text-center text-3xl md:text-5xl font-lexend-regular leading-[1.2] md:leading-[1.3] mx-auto lg:w-[35%] mb-12">
					{titleText}
				</div>
				<div className="flex flex-col items-center gap-6 lg:flex-row lg:items-start">
					{/* Left Text */}
					<div className="lg:w-[35%]">
						<p className="text-lg text-gray-900 ">
							{pointsIntroText}
						</p>
					</div>
					{/* Right Fine Amounts */}
					<div className="grid grid-cols-1 gap-6 text-left lg:grid-cols-3 lg:w-[70%]">
						{fineTexts.map((fineText, index) => (
							<div
								key={fineAmounts[index].id}
								className={clsx(
									'grid-item relative',
									index < fineTexts.length - 1 &&
										'border-b border-[#DDDDDD] lg:border-b-0 lg:border-r lg:border-[#DDDDDD]',
									index < fineTexts.length - 1 &&
										'pb-6 lg:pb-0'
								)}
							>
								<p className="text-gray-900">{fineText}</p>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Advice Section */}
			<div
				className="relative mx-6 lg:mx-auto mt-10 bg-white border-1 border-[#DDDDDD] rounded-[0.75rem] lg:max-w-m-screen"
				style={{
					background:
						'linear-gradient(to bottom right, #F6FAFF, #D7DDF9)',
				}}
			>
				<div className="relative px-6 mx-auto lg:flex lg:items-center lg:justify-between lg:px-12">
					{/* Left Content */}
					<div className="text-left lg:w-[65%] py-10 lg:py-0">
						<div>
							<h2 className="text-2xl font-bold md:text-3xl lg:text-4xl text-brand-primary">
								{headingText}
							</h2>
							<p className="mt-4 text-lg text-gray-700">
								{contentText}
							</p>
						</div>
						{/* CTA Button */}
						<div className="mt-6">
							<Button
								onClick={handleButtonClick}
								variant="RedBook"
								className="inline-flex items-center px-6 py-4 text-lg font-medium text-white transition-colors duration-300 bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 md:px-8 md:text-xl"
							>
								{buttonText}
							</Button>
						</div>
					</div>

					{/* Right image */}
					{content?.image?.url && (
						<div className="hidden lg:block lg:w-[40%]">
							<img
								src={content.image.url}
								alt="HR Support"
								className="w-full"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// Prop validation

AdviceContentContainer.propTypes = {
	content: PropTypes.shape({
		title: PropTypes.shape({
			text: PropTypes.string.isRequired,
		}).isRequired,
		adviceHeading: PropTypes.shape({
			text: PropTypes.string.isRequired,
		}).isRequired,
		adviceSubheading: PropTypes.shape({
			text: PropTypes.string.isRequired,
		}).isRequired,
		pointsContent: PropTypes.shape({
			items: PropTypes.arrayOf(PropTypes.string).isRequired,
		}).isRequired,
		fineAmounts: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired, // Add `id` validation here
				structuredTextBlock: PropTypes.shape({
					text: PropTypes.string.isRequired, // Adjust as needed
				}).isRequired,
			})
		).isRequired,
		buttonText: PropTypes.string.isRequired,
		image: PropTypes.shape({
			url: PropTypes.string,
		}),
	}).isRequired,
	phoneNumber: PropTypes.string.isRequired,
};

export { AdviceContentContainer };
