import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Textarea = forwardRef(
	(
		{
			labelText,
			id,
			hintText,
			name,
			rows,
			validationMessage,
			showValidation,
			className,
			inputClassName,
			...inputAttributes
		},
		ref
	) => {
		const [value, setValue] = useState('');
		const [charLimit, setCharLimit] = useState(inputAttributes.maxLength);
		const errorId = `${id}-error`;
		const hintTextId = `${id}-hint-text`;

		const handleChange = (e) => {
			setValue(e.target.value);

			if (inputAttributes.maxLength) {
				setCharLimit(inputAttributes.maxLength - e.target.value.length);
			}
		};

		return (
			<div
				className={clsx(
					'relative before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none',
					showValidation ? 'before:bg-alert-400' : '',
					className
				)}
			>
				<div className="mb-4">
					<label
						htmlFor={id}
						className="text-brand-blue-400 text-base-f font-lexend-medium"
					>
						{labelText}
					</label>
					{hintText ? <p id={hintTextId}>{hintText}</p> : null}
				</div>

				<div>
					<div className="relative">
						<textarea
							className={clsx(
								'block px-4 py-3 mb-3 rounded-xs shadow-answer hover:bg-brand-pale-400 outline-none w-full appearance-none placeholder:text-grey-400 focus:placeholder:text-brand-blue-400 transition-colors duration-200 motion-reduce:transition-none bg-brand-pale-300 border-1 border-solid border-blue-500',
								// eslint-disable-next-line no-nested-ternary
								value.length > 0
									? inputAttributes.maxLength
										? 'shadow-brand-blue-400'
										: 'border-brand-blue-400 bg-brand-pale-300'
									: '',
								// eslint-disable-next-line no-nested-ternary
								showValidation
									? inputAttributes.maxLength
										? 'shadow-alert-400'
										: 'border-alert-400'
									: '',
								inputAttributes.maxLength
									? 'border-b-[40px] border-white hover:border-brand-pale-400 shadow-blue-200 shadow-[0px_0px_0px_1px] focus:shadow-blue-400'
									: 'border-1 border-solid border-blue-200 focus:border-blue-400',
								inputClassName
							)}
							name={name}
							id={id}
							rows={rows}
							onChange={handleChange}
							aria-invalid={!!showValidation}
							aria-describedby={
								hintText ? `${hintTextId} ${errorId}` : errorId
							}
							value={value}
							ref={ref}
							{...inputAttributes}
						/>
						{inputAttributes.maxLength ? (
							<>
								<span className="sr-only" aria-live="polite">
									{`${charLimit} characters remaining`}
								</span>
								<p
									data-testid="charsLeft"
									className={clsx(
										'absolute right-[10px] bottom-[10px]'
									)}
								>
									{charLimit}
								</p>
							</>
						) : null}
					</div>
					{showValidation ? (
						<p className="text-alert-500" id={errorId}>
							{validationMessage}
						</p>
					) : null}
				</div>
			</div>
		);
	}
);

Textarea.defaultProps = {
	hintText: undefined,
	validationMessage: '',
	showValidation: false,
	className: '',
	inputClassName: '',
};

Textarea.propTypes = {
	labelText: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hintText: PropTypes.string,
	rows: PropTypes.number.isRequired,
	validationMessage: PropTypes.string,
	showValidation: PropTypes.bool,
	className: PropTypes.string,
	inputClassName: PropTypes.string,
};

export { Textarea };
