import clsx from 'clsx';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { sendTrackingData } from '../../../../utils';
import { buildLink } from '../../../../utils/locale';
import { PBSLink } from '../../../atoms/link';

const HomeReferralCards = ({
	card,
	heroHeader,
	headingStyles,
	titleText,
	locale,
	slug,
}) => {
	const stepsRef = React.useRef(null);
	const title = 'Recommend a business';
	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'navigation',
			click_action: 'navigate',
			click_type: 'link',
			click_text: title,
			click_title: title,
		});
	};
	const handleClick = () => {
		const link = buildLink(locale, slug);
		handleClickTracking();
		navigate(link);
	};

	const Component = 'div';
	const Title = 'h1';
	return (
		<div className="mt-20 pb-10 max-w-m-screen mx-auto flex flex-col items-center justify-center flex-grow h-auto mt-16">
			<Title
				id={heroHeader}
				className={clsx(
					'!leading-[1.3] font-lexend-regular',
					'text-2xl md:text-5xl xl:text-6xl',
					headingStyles
				)}
			>
				{titleText}
			</Title>
			<div className="mt-16 grid md:grid-cols-2 grid-cols-1 2xl:gap-4 w-full mb-3 ">
				<div className="flex flex-col">
					{card.referralCards.map((referral, i) => (
						<div
							key={referral.id}
							ref={stepsRef}
							className="flex flex-col md:flex-row md:gap-4 2xl:gap-8 w-full"
						>
							<Component
								onClick={handleClick}
								className=" bg-transparent  flex-col space-y-1 2xl:p-7 p-5 transition-all duration-300 ease-in-out"
							>
								<h3 className="2xl:text-2xl text-lg !font-black pl-2">
									{i + 1}. {referral.title}
								</h3>
								<p className="2xl:text-md text-sm font-notosans-regular text-justify">
									{referral.content}
								</p>
							</Component>
						</div>
					))}
					<div className="flex justify-start ml-6 mt-7">
						<PBSLink to="/refer-a-business/">
							Refer a business
						</PBSLink>
					</div>
				</div>
				<div className="h-[100%] glu2xl:w-[70%] glulgx:w-[60%] glumd:w-full glulgx:ml-20 glu2xl:ml-32 quality-100 items-center justify-center hidden md:flex">
					<img
						src="https://www.datocms-assets.com/64336/1737036533-output-onlinegiftools-2-2.gif"
						alt="Refer a business"
						className="w-full h-full"
					/>
				</div>
			</div>
		</div>
	);
};
HomeReferralCards.propTypes = {
	locale: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	card: PropTypes.string.isRequired,
	heroHeader: PropTypes.string.isRequired,
	headingStyles: PropTypes.isRequired,
	titleText: PropTypes.string.isRequired,
};
export { HomeReferralCards };
