/* eslint-disable eslint-comments/disable-enable-pair */

import React from 'react';
import PropTypes from 'prop-types';

import { AccordionCA } from '../../atoms/accordion-ca';
import { AccordionGroup } from '../../molecules/accordion-group-ca';
import { getRteContent, sendTrackingData } from '../../../utils';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';

import { rteContent } from '../../../types';

const FaqCA = ({ id, faqContent }) => {
	const { title, subText, questions } = faqContent;

	const handleClickTracking = ({ question, index }) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'faq',
			click_type: 'link',
			click_title: question,
			click_index: index + 1,
		});
	};

	let titleText = '';

	const headings = title?.value?.document?.children?.[0]?.children;

	if (headings && headings.length > 0) {
		titleText = createHeadingHighlightCA({
			variant: 'brand-blue-underline',
			headings,
		});
	}

	const accordions = () =>
		questions.map(({ question, answer }, index) => {
			const qId = question.replace(/[^A-Z0-9]/gi, '');
			return (
				<AccordionCA
					key={question}
					title={question}
					buttonId={`accordionHead-${qId}`}
					contentId={`accordionContent-${qId}`}
					variant="Slim"
					onClickProp={() =>
						handleClickTracking({
							question,
							index,
						})
					}
				>
					{getRteContent(answer, qId)}
				</AccordionCA>
			);
		});

	return (
		<section
			aria-labelledby={id}
			className="mx-auto rounded-[0.75rem] max-w-m-screen mt-16 md:mt-28 border-1 border-[#DDDDDD]"
			style={{
				background:
					'linear-gradient(to bottom right, #F6FAFF, #D7DDF9)',
			}}
		>
			<div className="px-4 mx-auto mt-10 sm:my-xl-f md:px-8 max-w-m-screen md:my-3xl-f-">
				<h2 className="py-8 text-center pb-xs-f text-2xl-f" id={id}>
					{titleText}
				</h2>
				<p className="pb-8 text-xl text-center md:text-lg font-notosans-regular">
					{subText}
				</p>
				<div className="max-w-[780px] mx-auto pb-10">
					<AccordionGroup accordions={accordions()} />
				</div>
			</div>
		</section>
	);
};

FaqCA.defaultProps = {
	id: 'faq',
};

FaqCA.propTypes = {
	id: PropTypes.string,
	faqContent: PropTypes.shape({
		title: PropTypes.string,
		subText: PropTypes.string,
		questions: PropTypes.arrayOf(
			PropTypes.shape({
				question: PropTypes.string.isRequired,
				answer: rteContent.isRequired,
			})
		).isRequired,
	}).isRequired,
};

export { FaqCA };
