// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/cor-ca';

import { Head } from '../components/templates/head';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';

import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';

import { HrefLang } from '../components/templates/hrefLang';
import { CallUs } from '../components/atoms/call-us-ppc-v2';
import { createHeadingHighlight } from '../utils/heading-highlight';
import { createHeadingHighlightCA } from '../utils/heading-highlight-ca';

const IndexPage = ({ location, data }) => {
	const metaContent = {
		noIndex: data?.datoCmsSickkidsPageCa?.noIndex,
		noFollow: data?.datoCmsSickkidsPageCa?.noFollow,
		metaInformation: data?.datoCmsSickkidsPageCa?.metaInformation,
		fallbackTitle: data?.datoCmsSickkidsPageCa?.title,
		fallbackDescription: data?.datoCmsSickkidsPageCa?.longIntroduction,
		canonicalUrl:
			data?.datoCmsSickkidsPageCa?.canonicalUrl || location.href,
	};

	const mainHeaderImage = {
		mainHeaderImage: data?.datoCmsSickkidsPageCa?.mainHeaderImage,
	};

	const content = {
		contentTitle: data?.datoCmsSickkidsPageCa?.contentTitle,
		contentPrimaryCopy: data?.datoCmsSickkidsPageCa?.contentPrimaryCopy,
		contentPrimaryImage: data?.datoCmsSickkidsPageCa?.contentPrimaryImage,
		contentSecondaryCopy: data?.datoCmsSickkidsPageCa?.contentSecondaryCopy,
		contentSecondaryImage:
			data?.datoCmsSickkidsPageCa?.contentSecondaryImage,
		contentDisplayOnPage: data?.datoCmsSickkidsPageCa?.contentDisplayOnPage,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.home,
			location,
		}),
		image: getImageSeoSchema(
			data.datoCmsSickkidsPageCa.statementPrimaryImage
		),
	};

	const secondaryCopyData =
		content?.contentSecondaryCopy?.value?.document?.children;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<div className="block sm:hidden">
				{/* add phone prop */}
				<CallUs />
			</div>
			<HrefLang locales={data.datoCmsSickkidsPageCa.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<div className="flex flex-col items-center justify-center">
				{/* Hero Section */}
				{data?.datoCmsSickkidsPageCa?.mainHeaderDisplayOnPage && (
					<div className="w-full h-full xl:h-[80vh] 2xl:h-[90vh]">
						<img
							src={mainHeaderImage?.mainHeaderImage?.url}
							alt=""
							srcSet={mainHeaderImage?.mainHeaderImage?.srcSet}
							className="object-cover w-full h-full"
						/>
					</div>
				)}
				{/* Content section */}
				{data?.datoCmsSickkidsPageCa?.contentDisplayOnPage && (
					<div className="flex flex-col items-center justify-center gap-20 w-full px-5 pb-16 pt-20 font-lexend-light text-center lg:px-12 lg:pb-32 lg:pt-24 xl:pt-28 xl:pb-20 mx-auto lg:max-w-m-screen ">
						<div className="w-full h-full flex flex-col lg:flex-row gap-16 lg:gap-12 items-center">
							<div className="w-full lg:w-1/2 text-left order-last lg:order-first">
								<div className="flex flex-col gap-5 font-lexend-light text-[36px] font-bold">
									<div>
										{createHeadingHighlight({
											headings:
												content?.contentTitle?.value
													.document.children[0]
													.children,
										})}
									</div>
									<div>
										{createHeadingHighlightCA({
											headings:
												content?.contentPrimaryCopy
													?.value.document.children[0]
													.children,
											variant: 'black',
											className:
												'font-lexend-light text-[1.25rem] font-light',
										})}
									</div>
								</div>
							</div>
							<div className="lg:w-1/2">
								<img
									src={content.contentPrimaryImage?.url}
									alt=""
									srcSet={content.contentPrimaryImage?.srcSet}
									className="object-cover w-full h-full rounded-[1rem]"
								/>
							</div>
						</div>
						<div className="w-full h-full flex flex-col lg:flex-row gap-20 lg:gap-12 items-center">
							<div className="w-full lg:w-1/2">
								<img
									src={content.contentSecondaryImage?.url}
									alt=""
									srcSet={
										content.contentSecondaryImage?.srcSet
									}
									className="object-cover w-full h-full rounded-[1rem] -mb-5 lg:mb-0 bg-blue-200"
								/>
							</div>
							<div className="w-full lg:w-1/2 text-left !text-[1.25rem] mt-5">
								{secondaryCopyData &&
									secondaryCopyData.length > 0 &&
									secondaryCopyData.map((_, idx) =>
										createHeadingHighlightCA({
											headings:
												content?.contentSecondaryCopy
													?.value.document.children[
													idx
												].children,
											variant: 'black',
											className:
												'font-lexend-light text-[1.25rem] mb-5 text-black',
											highlightClassName:
												'lg:text-[1.25rem] font-bold uppercase',
										})
									)}
							</div>
						</div>
					</div>
				)}
			</div>
		</GoogleReCaptchaProvider>
	);
};

const WrappedHome = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		// pageName="PeninsulaNow"
	>
		<IndexPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedHome;

export const query = graphql`
	query home($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsSickkidsPageCa(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			mainHeaderDisplayOnPage
			mainHeaderImage {
				url
				sizes {
					srcSet
				}
			}
			contentDisplayOnPage
			contentTitle {
				value
			}
			contentPrimaryCopy {
				value
			}
			contentPrimaryImage {
				url
				sizes {
					srcSet
				}
			}
			contentSecondaryCopy {
				value
			}
			contentSecondaryImage {
				url
				sizes {
					srcSet
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
