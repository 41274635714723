import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useDrawer } from '../../../contexts/drawer-context';
import { Button } from '../../atoms/button';

function PeninsulaNowAdvisorBanner({ className, isOnline, setModal }) {
	const { setDrawerRef } = useDrawer();
	return (
		<div
			className={clsx(
				className,
				!isOnline && 'hidden',
				// isOnline && 'card flip',
				'flex flex-col items-center bg-brand-pale-300 md:gap-5 gap-1 -mt-10'
			)}
		>
			<div className="flex justify-end w-full -mt-5">
				<Button
					size="Tiny"
					variant="WhiteBlue"
					type="button"
					onClick={() => setDrawerRef(false)}
				>
					Close
				</Button>
			</div>

			<div className="flex flex-col items-center ">
				<p className="uppercase text-2xl text-brand-red-400 font-bold">
					Peninsula
				</p>
				<div
					className={clsx(
						'w-auto',
						'sm:text-4xl',
						'lg:text-4xl',
						'font-lexend-light bg-black text-3xl text-white flex items-center px-[5px] rounded-sm '
					)}
				>
					<span className="inline-block ml-1 mr-2 bg-brand-red-400 red-dot shrink-0" />
					NOW
				</div>
			</div>

			<Button size="Small" type="button" onClick={() => setModal()}>
				Live video call with an expert now!
			</Button>

			<p className="text-[1.2rem] uppercase md:my-4 my-1">OR</p>
		</div>
	);
}

PeninsulaNowAdvisorBanner.defaultProps = {
	className: '',
	isOnline: false,
	setModal: () => {},
};

PeninsulaNowAdvisorBanner.propTypes = {
	className: PropTypes.string,
	isOnline: PropTypes.bool,
	setModal: PropTypes.func,
};

export { PeninsulaNowAdvisorBanner };
