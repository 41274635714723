/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { ModalProvider } from '../../../contexts/modal-context';
import { DrawerProvider } from '../../../contexts/drawer-context';
import { ScreenResizerProvider } from '../../../contexts/screen-resize-context';
import { MarketoProvider } from '../../../contexts/marketo-context';

import { MasterLayout } from '../../master';

import { HeaderCA } from '../../../components/organisms/header-ppc-v2-ca';
// import { Footer as FooterPpc } from '../../../components/organisms/footer-ppc';
import { Footer } from '../../../components/organisms/footer-ppc-v2-ca';
// import { Footer as FooterMobile } from '../../../components/organisms/footer';
import * as customStyles from './index.module.css';

const DefaultLayout = ({
	children,
	className,
	siteSettings,
	navigation,
	footerData,
	footerImages,
	pageName,
}) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
			datoCmsSiteSpecificSetting {
				sideNavigationOpenCopy
				sideNavigationCloseCopy
				signInCopy
				footerAddressTitle
				footerSocialTitle
				footerInternationalSitesCopy
			}
		}
	`);

	const globalSiteSettings = {
		...siteSettings,
	};

	const headerContent = {
		...data.datoCmsSiteSpecificSetting,
		...navigation,
	};

	const footerContent = {
		...data.datoCmsSiteSpecificSetting,
		...footerData,
		...navigation,
	};

	return (
		<ScreenResizerProvider>
			<ModalProvider>
				<DrawerProvider>
					<MarketoProvider>
						<MasterLayout>
							{/* Header for Mobile */}
							<div
								className={`${customStyles.pr8Custom} fixed top-0 z-50 w-screen bg-white pl-4 pr-3 xl:pr-5 py-4 md:py-1`}
							>
								<HeaderCA
									siteTitle={data.site.siteMetadata.title}
									headerContent={headerContent}
									siteSettings={globalSiteSettings}
									pageName={pageName}
								/>
							</div>

							<main
								id="main"
								className={`${className} mt-[90px] lg:pt-10`}
							>
								{children}
							</main>
							<Footer
								footerContent={footerContent}
								siteTitle={data.site.siteMetadata.title}
								footerImages={footerImages}
							/>
						</MasterLayout>
					</MarketoProvider>
				</DrawerProvider>
			</ModalProvider>
		</ScreenResizerProvider>
	);
};

export const query = graphql`
	fragment siteSettings on DatoCmsSiteSpecificSetting {
		phoneNumber
	}
	fragment navItems on DatoCmsSiteSpecificSetting {
		phoneNumber
		primaryNavigation {
			title
			link
			treeChildren {
				title
				subText
				link
				icon
				position
				locale
			}
		}
		sideNavigation {
			title
			link
		}
	}
	fragment footerItems on DatoCmsSiteSpecificSetting {
		footerAddress
		footerNavigation {
			treeChildren {
				title
				link
				position
			}
			title
		}
		footerInternationalSites {
			title
			link
		}
		footerCopyright
	}
`;

DefaultLayout.defaultProps = {
	className: '',
	pageName: '',
	siteSettings: '',
	navigation: '',
	footerData: '',
	footerImages: '',
};

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	pageName: PropTypes.string,
	siteSettings: PropTypes.string,
	navigation: PropTypes.string,
	footerData: PropTypes.string,
	footerImages: PropTypes.node,
};

export { DefaultLayout };
