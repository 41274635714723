/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../../../../../atoms/button-ca';
import { CallUs } from '../../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../../atoms/sidebar-header-pricing-ca';
import { TextInput } from '../../../../../../atoms/inline-text-input-ca';

import { useSalesforceApiCA } from '../../../../../../../hooks/salesforce-ca';
import { sendTrackingData, pageUrlFomater } from '../../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../../hooks/salesforce-azure-secondform-ca';
import { useFormValidation } from '../../../../../../../hooks/form-validation-ca';

const initialValidations = {
	companyName: { isValid: true },
	JobTitle: { isValid: true },
	noEmployees: { isValid: true },
};

export const SuccessFormCA = ({ formTitle, reference, response }) => {
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);
	const companyNameRef = useRef(null);
	const jobTitleRef = useRef(null);
	const numberEmployeesRef = useRef(null);

	const [numberEmployees, setnumberEmployees] = useState('');
	const [jobTitle, setjobTitle] = useState('');
	const [company, setCompany] = useState('');

	const { executeRecaptcha } = useGoogleReCaptcha();
	const { fetchSalesforceCA } = useSalesforceApiCA();

	const submitButtonText = 'Submit';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = (clickText) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: response,
			click_text: clickText,
		});
	};

	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		const payloadData = {
			id: reference,
			numberOfEmployees: formEntries.noEmployees,
			jobTitle: formEntries.JobTitle,
			extraInfo: formEntries.companyName, // to be replaced with company
			pageUrl,
		};

		const sfResponse = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'PATCH'
		);

		if (sfResponse.status !== 200) {
			await fetchSalesforceAzure(payloadData);
		}

		setSubmitting(true);
		handleClickTracking(submitButtonText);

		switch (sfResponse.status) {
			case 200:
				setIsError(false);
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	return submitting ? (
		<>
			<SidebarHeader
				className="h-[563px] shadow-lg bg-white rounded-[0.75rem] border-1 border-blue-300 "
				heading={
					isError
						? "We've run into a technical error with your submission"
						: 'Thank you'
				}
				text={
					isError
						? "Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
						: 'One of our advisors will call you in the next few minutes. '
				}
				ariaLive="assertive"
			/>
			{isError ? (
				<div className="px-4 pt-10">
					<CallUs />
				</div>
			) : null}
		</>
	) : (
		<>
			<div className="flex flex-col shadow-lg bg-white rounded-[0.75rem] border-1 border-blue-300">
				{/* title + status */}
				<div>
					{/* title */}
					<div className="bg-blue-500 rounded-t-[0.75rem] text-white px-8 py-5 font-lexend-regular text-[26px]">
						{formTitle}
					</div>
					{/* status bars */}

					<div className="flex flex-row h-2 w-full">
						<div className="bg-blue-200 w-1/2">&nbsp;</div>
						<div className="bg-blue-300 w-1/2">&nbsp;</div>
					</div>
				</div>
				<div className="px-8 py-5 leading-4 sm:leading-5">
					<span className="text-brand-blue-400 font-lexend-regular text-[16px]">
						Fill in your details below to get a{' '}
						<span className="text-blue-300">custom price</span> for
						your business
					</span>
				</div>
				<form onSubmit={(e) => submitHandler(e)} id="book_a_demo">
					{/* <SidebarHeader heading={response} /> */}
					<div className="flex flex-col px-8 grow">
						<TextInput
							labelText="Job Title"
							id="JobTitle"
							name="JobTitle"
							value={jobTitle}
							onChange={(e) => setjobTitle(e.currentTarget.value)}
							aria-required="true"
							className="w-full wrapper-small pb-1 text-sm"
							validationMessage="Please enter your job title"
							showValidation={!validations.JobTitle.isValid}
							ref={jobTitleRef}
							isRequired
						/>

						<TextInput
							labelText="Number of employees"
							id="noEmployees"
							name="noEmployees"
							value={numberEmployees}
							onChange={(e) =>
								setnumberEmployees(e.currentTarget.value)
							}
							aria-required="true"
							className="w-full wrapper-small pb-1 text-sm"
							validationMessage="Please enter your number of employees"
							showValidation={!validations.noEmployees.isValid}
							ref={numberEmployeesRef}
							isRequired
						/>

						<TextInput
							labelText="Company"
							id="companyName"
							name="companyName"
							value={company}
							aria-required="true"
							className="w-full wrapper-small pb-1 text-sm"
							onChange={(e) => setCompany(e.currentTarget.value)}
							validationMessage="Please enter your company name"
							showValidation={!validations.companyName.isValid}
							ref={companyNameRef}
							isRequired
						/>
					</div>
					<div className="flex flex-row justify-between items-center px-8 pb-8 pt-5">
						<div className="wrapper-small">
							<Button
								type="submit"
								className={`${
									submitting && 'justify-center loading'
								}`}
								variant="Red"
							>
								<div
									className={`visible ${submitting} && 'invisible`}
								>
									{submitButtonText}
								</div>
							</Button>
						</div>
						<div className="text-[#A5A5A5] text-lg sm:text-xl">
							2 of 2
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

SuccessFormCA.defaultProps = {
	response: '',
};

SuccessFormCA.propTypes = {
	reference: PropTypes.string.isRequired,
	response: PropTypes.string,
};
