/* eslint-disable eslint-comments/disable-enable-pair */
// eslint-disable-next-line eslint-comments/no-duplicate-disable
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import useSWR from 'swr';
import clsx from 'clsx';
import { Button } from '../../../atoms/button';

const fetcher = (query) =>
	// eslint-disable-next-line compat/compat
	fetch(`https://graphql.datocms.com/`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_DATOAPIKEY}`,
		},
		body: JSON.stringify({ query }),
	})
		.then((res) => {
			if (!res.ok)
				throw new Error(`Error ${res.status}: ${res.statusText}`);
			return res.json();
		})
		.then((data) => data.data);

const SEARCH_QUERY = (search) => `
  query MySearchBar {
    allGluCourses(
      first: 10
      orderBy: _createdAt_DESC
      skip: 0
      locale: en
      filter: {
        OR: [
          { title: { matches: { pattern: "${search}", caseSensitive: false } } },
          { intro: { matches: { pattern: "${search}", caseSensitive: false } } }
        ],
        AND: {
          hideInOnDemandLibrary: { eq: "null" },
          hideInOnDemandLibrary: { eq: "false" },
          slug: { neq: null }
        }
      }
    ) {
      id
      title
      slug
      intro
      isWebinarCatchUp
      category {
        title
        slug
      }
	moreCategory {
		title
		slug
	  }			
      timeToWatchInMinutes
      date
    }

    _allGluCoursesMeta(
      locale: en
      filter: {
        OR: [
          { title: { matches: { pattern: "${search}", caseSensitive: false } } },
          { intro: { matches: { pattern: "${search}", caseSensitive: false } } }
        ]
      }
    ) {
      count
    }
  }
`;

const GluSearchBar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [showResults, setShowResults] = useState(false);
	const searchRef = useRef(null);

	const { data, error, isLoading } = useSWR(
		searchValue && showResults ? SEARCH_QUERY(searchValue) : null,
		fetcher
	);

	const toggleModal = () => {
		setIsOpen(!isOpen);
		if (!isOpen) {
			setSearchValue('');
			setShowResults(false);
			setTimeout(() => searchRef.current?.focus(), 0);
		}
	};

	const handleSearch = () => {
		if (searchValue.trim()) {
			setShowResults(true);
		}
	};
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleSearch(); // Trigger search on Enter key press
		}
	};

	return (
		<div className="relative">
			<button
				type="button"
				onClick={toggleModal}
				className="rounded-full focus:outline-none left-4"
			>
				<svg
					height="48px"
					id="Layer_1"
					version="1.1"
					viewBox="0 0 512 512"
					width="48px"
					xmlSpace="preserve"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z" />
				</svg>
			</button>

			{isOpen && (
				<div
					className="fixed glusm:w-full inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
					onClick={toggleModal}
				>
					<div
						className="bg-brand-pale-500 p-6  shadow-lg w-[70%] glu2xl:mt-10 glulgx:mt-10 gluxs:-mt-[40%] glulg:-mt-[77%] glumd:-mt-[50%] gluxs:w-[100%] max-w-m-screen relative"
						onClick={(e) => e.stopPropagation()}
					>
						<button
							type="button"
							onClick={toggleModal}
							className="absolute top-2 right-2 text-gray-500 bg-black hover:bg-brand-red-500 text-white rounded-lg hover:text-gray-700"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>

						<form className="relative flex flex-col grow mb-2">
							<div>
								<input
									id="search"
									name="search"
									ref={searchRef}
									aria-required="true"
									placeholder="Search courses..."
									onKeyDown={handleKeyDown}
									onChange={(e) => {
										setSearchValue(e.target.value);
										setShowResults(false);
									}}
									className="rounded-lg border-2 border-blue-400 bg-white px-6 py-4 font-lexend-light text-base-f leading-none placeholder:text-grey-500 focus:!border-black outline-none focus:ring-0 w-full wrapper-small [&>input]:pr-[107px] md:[&>input]:pr-[140px]"
								/>
								<Button
									type="button"
									onClick={handleSearch}
									className={clsx(
										'absolute leading-[1.46rem] md:leading-[1.61rem] right-[-2px]'
									)}
								>
									<span>Search</span>
								</Button>
							</div>
						</form>

						{showResults && (
							<div className="mt-4 max-h-60 overflow-y-auto">
								{isLoading && <p>Loading...</p>}
								{error && (
									<p className="text-red-500">
										Error loading results.
									</p>
								)}
								{data?.allGluCourses?.length > 0 ? (
									<ul className="divide-y divide-gray-200">
										{data.allGluCourses.map((course) => (
											<li
												key={course.id}
												className="py-2"
											>
												<a
													href={`/elearning/${
														course.category?.slug ||
														course.moreCategory
															?.slug
													}/${course.slug}`}
													className="text-blue-500 hover:underline"
												>
													{course.title}
												</a>
											</li>
										))}
									</ul>
								) : (
									<p>No courses found.</p>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default GluSearchBar;
