// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { StructuredText, renderMarkRule } from 'react-datocms';

import { PBSLink } from '../../../atoms/link';
import { buildLink } from '../../../../utils/locale';

const CatchUpWebinars = ({ data }) => {
	if (!data) {
		return null;
	}
	const {
		catchUpTitle,
		catchUpWebinarsDescription,
		catchUpWebinarsCta,
		catchUpWebinarsCtaSlug,
		locale,
	} = data;
	return (
		<div className="mx-auto flex flex-col items-center justify-center p-5 md:p-10 bg-brand-blue-400">
			<div className="flex flex-col items-center justify-center text-white w-full md:max-w-md space-y-5">
				<div className="font-lexend-regular font-bold text-2xl lg:text-3xl">
					<StructuredText
						data={catchUpTitle.value}
						customMarkRules={[
							renderMarkRule('strong', ({ children, key }) => (
								<strong
									className="text-brand-red-400"
									key={key}
								>
									{children}
								</strong>
							)),
						]}
					/>
				</div>
				<div className="font-lexend-light text-base-f">
					<StructuredText data={catchUpWebinarsDescription.value} />
				</div>
				<div className="w-full my-4 sm:w-fit sm:my-0">
					<PBSLink
						size="Small"
						variant="WhiteBlue"
						to={buildLink(locale, catchUpWebinarsCtaSlug)}
						className="justify-center w-full m:w-fit"
					>
						{catchUpWebinarsCta}
					</PBSLink>
				</div>
			</div>
		</div>
	);
};
export default CatchUpWebinars;
