// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/cor-ca';

import { Head } from '../components/templates/head';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../utils/seo-schema';

import { CallUs } from '../components/atoms/call-us-ppc-v2';
import HeroCompassCA from '../components/molecules/hero-compass-ca';
import CTACompassCA from '../components/organisms/cta-compass-ca';
import StatementCompassCA from '../components/organisms/statement-compass-ca';
import ValuesCompassCA from '../components/organisms/values-compass-ca';
import { HrefLang } from '../components/templates/hrefLang';

const IndexPage = ({ location, data }) => {
	const metaContent = {
		noIndex: data.datoCmsCompassPageCa.noIndex,
		noFollow: data.datoCmsCompassPageCa.noFollow,
		metaInformation: data.datoCmsCompassPageCa.metaInformation,
		fallbackTitle: data.datoCmsCompassPageCa.title,
		fallbackDescription: data.datoCmsCompassPageCa.longIntroduction,
		canonicalUrl: data.datoCmsCompassPageCa?.canonicalUrl || location.href,
	};

	const heroContent = {
		mainHeaderTitle: data.datoCmsCompassPageCa.mainHeaderTitle,
		mainHeaderContent: data.datoCmsCompassPageCa.mainHeaderContent,
		mainHeaderImage: data.datoCmsCompassPageCa.mainHeaderImage,
	};

	const statementContent = {
		statementPrimaryCopy: data.datoCmsCompassPageCa.statementPrimaryCopy,
		statementPrimaryImage: data.datoCmsCompassPageCa.statementPrimaryImage,
		statementSecondaryCopy:
			data.datoCmsCompassPageCa.statementSecondaryCopy,
		statementSecondaryImage:
			data.datoCmsCompassPageCa.statementSecondaryImage,
	};

	const valuesContent = {
		valuesPrimaryList: data.datoCmsCompassPageCa.valuesPrimaryList,
		valuesPrimaryImage: data.datoCmsCompassPageCa.valuesPrimaryImage,
		valuesSecondaryList: data.datoCmsCompassPageCa.valuesSecondaryList,
		valuesSecondaryImage: data.datoCmsCompassPageCa.valuesSecondaryImage,
	};

	const ctaContent = {
		ctaTitle: data.datoCmsCompassPageCa.ctaTitle,
		ctaImages: data.datoCmsCompassPageCa.ctaImages,
		ctaSubText: data.datoCmsCompassPageCa.ctaSubText,
		ctaLinks: data.datoCmsCompassPageCa.ctaLinks,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.home,
			location,
		}),
		image: getImageSeoSchema(
			data.datoCmsCompassPageCa.statementPrimaryImage
		),
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<div className="block sm:hidden">
				{/* add phone prop */}
				<CallUs />
			</div>
			<HrefLang locales={data.datoCmsCompassPageCa.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<div className="flex flex-col items-center gap-10 sm:gap-20 mx-5 xl:gap-20 xl:mx-0">
				{data.datoCmsCompassPageCa.mainHeaderDisplayOnPage && (
					<HeroCompassCA heroContent={heroContent} className="" />
				)}

				{data.datoCmsCompassPageCa.statementDisplayOnPage && (
					<StatementCompassCA
						statementContent={statementContent}
						className=""
					/>
				)}

				{data.datoCmsCompassPageCa.valuesDisplayOnPage && (
					<ValuesCompassCA
						valuesContent={valuesContent}
						className="xl:mx-5"
					/>
				)}

				{data.datoCmsCompassPageCa.ctaDisplayOnPage && (
					<CTACompassCA
						ctaContent={ctaContent}
						className="bg-black text-white"
					/>
				)}
			</div>
		</GoogleReCaptchaProvider>
	);
};

const WrappedHome = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		// pageName="PeninsulaNow"
	>
		<IndexPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedHome;

export const query = graphql`
	query home($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		datoCmsCompassPageCa(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			mainHeaderTitle {
				value
			}
			mainHeaderContent {
				value
			}
			mainHeaderImage {
				url
			}
			mainHeaderDisplayOnPage
			statementPrimaryCopy {
				value
			}
			statementPrimaryImage {
				url
			}
			statementSecondaryCopy {
				value
			}
			statementSecondaryImage {
				url
			}
			statementDisplayOnPage
			valuesPrimaryList {
				text
			}
			valuesPrimaryImage {
				url
			}
			valuesSecondaryList {
				text
			}
			valuesSecondaryImage {
				url
			}
			valuesDisplayOnPage
			ctaTitle {
				value
			}
			ctaImages {
				image {
					url
				}
			}
			ctaSubText {
				value
			}
			ctaLinks {
				buttonCopy
				link
			}
			ctaDisplayOnPage
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
