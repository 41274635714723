import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Plus from '../../../assets/plus.inline.svg';
import Minus from '../../../assets/minus.inline.svg';

const variantWrapper = {
	Default: 'p-5 md:p-8 border-2',
	Slim: 'py-4 px-3 md:p-5',
};

const variantOpen = {
	Default: 'border-blue-500',
	Slim: 'border-2 border-blue-500 bg-brand-pale-400',
};

const variantClose = {
	Default: 'border-blue-200',
	Slim: 'border-1 border-blue-500',
};

const variantContent = {
	Default: 'pt-4 mt-4 border-t md:mt-8 md:pt-8 border-t-blue-200',
	Slim: 'pt-2 md:pt-3',
};

const variantTitle = {
	Default: 'text-2xl md:text-lg-f',
	Slim: 'text-lg md:text-xl font-lexend-regular',
};

const variantIcons = {
	Default: '',
	Slim: 'text-blue-400',
};

const Accordion = ({
	buttonId,
	contentId,
	title,
	headerLevel,
	titleClassNames,
	children,
	variant,
	onClickProp,
}) => {
	const props = {};
	const Heading = headerLevel;
	const [open, setOpen] = useState(false);

	const currVariant = variant || 'Default';

	const handleClick = () => {
		setOpen(!open);
	};

	if (open) {
		props['aria-labelledby'] = buttonId;
		onClickProp();
	} else {
		props.hidden = true;
	}

	return (
		<div
			className={clsx(
				'rounded-sm shadow-slight bg-white transition-transform duration-900 ease-in-out',
				variantWrapper[currVariant],
				open ? variantOpen[currVariant] : variantClose[currVariant]
			)}
		>
			<Heading>
				<button
					onClick={handleClick}
					id={buttonId}
					aria-expanded={open}
					aria-controls={contentId}
					type="button"
					className={clsx(
						'flex',
						'items-center',
						'justify-between w-full',
						variantTitle[currVariant],
						titleClassNames
					)}
				>
					<span className="text-left">{title}</span>
					<span
						className={clsx(
							'w-[22px] md:w-[28px] ',
							variantIcons[currVariant]
						)}
					>
						{open ? <Minus /> : <Plus />}
					</span>
				</button>
			</Heading>
			<div
				id={contentId}
				aria-hidden={!open}
				className={variantContent[currVariant]}
				{...props}
			>
				{children}
			</div>
		</div>
	);
};

export { Accordion };

Accordion.defaultProps = {
	headerLevel: 'h2',
	titleClassNames: '',
	variant: 'Default',
	onClickProp: () => {},
};

Accordion.propTypes = {
	buttonId: PropTypes.string.isRequired,
	contentId: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	headerLevel: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6']),
	titleClassNames: PropTypes.string,
	variant: PropTypes.string,
	onClickProp: PropTypes.func,
};
