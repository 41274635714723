import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Field, FormikProvider } from 'formik';
import clsx from 'clsx';
import loadScript from 'load-script2';

import { useLocation } from '@reach/router';
import { sleep, sendTrackingData } from '../../../utils';
import { useMarketoFormSubmit } from '../../../hooks/marketo';
import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { useMarketo } from '../../../contexts/marketo-context';

import { Button } from '../../atoms/button';
import { TextInput } from '../../atoms/text-input';
import { getLocale } from '../../../utils/locale';

const Newsletter = ({ title, subText, variant }) => {
	const emailRef = useRef(null);
	const [locale, setLocale] = useState('');
	const [formId, setFormId] = useState('');
	// const formId = useMemo(() => (locale === 'ie' ? 1188 : 5859), [locale]);
	const { width } = useScreenResizer();
	const MktoForms2Instances = useMarketo();
	const location = useLocation();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const currentLocaleUrl = getLocale(location?.pathname);
			setLocale(currentLocaleUrl);
			if (currentLocaleUrl === 'ca') {
				setFormId(5084);
			} else {
				setFormId(currentLocaleUrl === 'ie' ? 1188 : 5859);
			}
		}
	}, [location?.pathname]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'newsletter',
			click_type: 'primary',
			click_text: 'Subscribe',
			click_title: null,
		});
	};

	const formik = useFormik({
		initialValues: {
			Email: '',
		},
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (data) => {
			await sleep(500);
			useMarketoFormSubmit(data, formId, MktoForms2Instances)
				.then(() => {
					formik.setStatus('submitted');
				})
				.catch(() => {
					formik.setStatus('error');
				})
				.finally(() => {
					handleClickTracking();
				});
		},
	});

	const validateEmail = (value) => {
		let error;
		if (!value) {
			error = 'Please enter your email address';
		} else if (
			!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/i.test(value)
		) {
			error = 'Invalid email address, please try again';
		}
		return error;
	};

	const id = 'newsletterEmail';
	const confirmation = 'Thanks for subscribing! We’ll be in touch soon.';
	const error =
		'There was an error in the sign up process, please refresh the page and try again.';
	const titleTxt = title || 'Sign up to our newsletter';
	const subTxt =
		subText ||
		'Get the latest news & tips that matter most to your business in our monthly newsletter.';

	useEffect(() => {
		if (formik.errors.Email && emailRef.current) {
			return emailRef.current.focus();
		}
	}, [formik.errors.Email]);

	useEffect(() => {
		if (!formId) return; // Ensure formId are set before proceeding

		const loadForm = () => {
			const scriptUrl =
				locale === 'ca'
					? 'https://app-ab31.marketo.com/js/forms2/js/forms2.min.js'
					: 'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js';

			const formUrl =
				locale === 'ca'
					? 'https://app-ab31.marketo.com'
					: 'https://app-lon04.marketo.com';

			const formIdValue = locale === 'ca' ? '544-LUT-177' : '023-IMK-845';

			if (!scriptUrl || !formUrl || !formIdValue) return;

			loadScript(scriptUrl)
				.then(() => {
					MktoForms2Instances.get(formUrl).loadForm(
						formUrl,
						formIdValue,
						formId
					);
				})
				.catch(() => {
					// Handle error if needed
				});
		};

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, [locale, formId, MktoForms2Instances]);

	return (
		<FormikProvider value={formik}>
			<section className={clsx('text-center bg-[#F3F3F6] mt-20')}>
				<div
					className={clsx(
						variant === 'Rte'
							? 'pt-7 md:pt-8 mx-4 sm:mx-8'
							: 'pt-2xl-f mx-5 md:mx-10'
					)}
				>
					<div
						className={clsx(
							'mx-auto',
							variant === 'Rte'
								? 'max-w-[850px] pb-8 md:pb-10'
								: 'border-b-1 border-b-blue-200 lg:container pb-2xl-f'
						)}
					>
						<form
							data-formid={formId}
							data-forminstance={
								variant === 'Rte' ? 'two' : 'one'
							}
							onSubmit={formik.handleSubmit}
						>
							<fieldset>
								<legend className="mx-auto">
									<h2
										className={clsx(
											'mb-5',
											variant === 'Rte'
												? 'text-2xl md:text-3xl'
												: 'text-2xl-f'
										)}
									>
										{titleTxt}
									</h2>
									<p
										className={clsx(
											variant === 'Rte'
												? 'text-lg md:!text-xl pb-8 font-notosans-regular'
												: 'text-base-f pb-10 font-notosans-regular'
										)}
									>
										{subTxt}
									</p>
								</legend>
								<Field name="Email" validate={validateEmail}>
									{({ field }) => (
										<TextInput
											id={id}
											variant="Rounded"
											type="email"
											name="Email"
											value={formik.values.Email}
											placeholder="Your email address"
											labelText="Your email address"
											showLabel={false}
											space={!(width >= 768)}
											className={clsx(
												'w-full inline-block sm:mr-3 !mb-3 sm:!mb-0',
												variant === 'Rte'
													? 'sm:max-w-[400px] md:max-w-[450px]'
													: 'sm:max-w-[450px]'
											)}
											ref={emailRef}
											showValidation={
												!!(
													formik.errors.Email &&
													formik.touched.Email
												)
											}
											validationMessage={
												formik.errors.Email
											}
											{...field}
										/>
									)}
								</Field>
								<Button
									type="submit"
									className={clsx(
										'w-full justify-center px-6 py-4 align-top text-base-f border-1 relative sm:w-auto sm:mt-0',
										formik.isSubmitting ? 'loading' : null
									)}
								>
									<span
										className={clsx(
											formik.isSubmitting
												? 'invisible'
												: null
										)}
									>
										Subscribe
									</span>
									{formik.isSubmitting ? (
										<span
											className="sr-only"
											aria-live="assertive"
										>
											Submitting email address, please
											wait...
										</span>
									) : null}
								</Button>
							</fieldset>
						</form>

						{formik.status && !formik.errors.Email ? (
							<div className="mt-4">
								{formik.status === 'error' && (
									<p
										className="text-alert-500 status"
										aria-live="assertive"
									>
										{error}
									</p>
								)}
								{formik.status === 'submitted' && (
									<p
										className="text-success-500 status"
										aria-live="assertive"
									>
										{confirmation}
									</p>
								)}
							</div>
						) : null}
					</div>
				</div>
			</section>
		</FormikProvider>
	);
};

export { Newsletter };

Newsletter.defaultProps = {
	title: '',
	subText: '',
	variant: '',
};

Newsletter.propTypes = {
	title: PropTypes.string,
	subText: PropTypes.string,
	variant: PropTypes.string,
};
