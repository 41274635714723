// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';
import { DefaultLayout } from '../layouts/default';
import { parseMarkdown } from '../utils/parse-md';

// markup
const PrivacyPolicyPage = ({ data }) => {
	const contentMD = data.datoCmsPrivacyPolicy.content;
	const parsedMD = parseMarkdown(contentMD);
	return (
		<>
			<section className="px-3 pt-4 pb-8 mx-auto md:pt-4 lg:pb-12 max-w-m-screen lg:px-8">
				<ul>
					<li className="text-s-f pr-2 md:pr-xxs-f after:content-['/'] md:after:pl-xxs-f after:pl-2 last:after:content-none after:font-lexend-light last:pr-0 inline">
						<a className="underline" href="/ca/">
							Home
						</a>
					</li>
					<li className="text-s-f pr-2 md:pr-xxs-f after:content-['/'] md:after:pl-xxs-f after:pl-2 last:after:content-none after:font-lexend-light last:pr-0 inline">
						Privacy Policy
					</li>
				</ul>
			</section>
			<section className="text-center px-3 !text-left px-3 mx-auto max-w-m-screen lg:px-8">
				<div className="max-w-7xl mx-auto text-lg lg:text-justify leading-normal mb-28 sm:mb-16">
					<h1 className="pb-6 text-2xl-f">Privacy Policy</h1>
					<div
						dangerouslySetInnerHTML={{ __html: parsedMD }}
						className="break-word text-xl font-lexend-light"
					/>
				</div>
			</section>
		</>
	);
};

const WrappedPrivacyPolicy = ({ data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<PrivacyPolicyPage data={data} />
	</DefaultLayout>
);

export default WrappedPrivacyPolicy;

export const query = graphql`
	query PrivacyPolicyQuery($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		datoCmsPrivacyPolicy(locale: { eq: $locale }) {
			content
		}
	}
`;
