import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { titleProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';

import * as headingStyles from './index.module.css';

const HeadingTagLine = ({
	id,
	firsttitle,
	as,
	subText,
	headingClassNames,
	subTextClassNames,
	headingHighlightClassNames,
	className,
}) => {
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: firsttitle[0].children,
		headingStyles,
		headingHighlightClassNames,
	});

	const subTextClass = clsx(
		'mt-6',
		'text-xl',
		'md:text-2xl',
		'font-lexend-light',
		subTextClassNames
	);

	return (
		<div className={clsx('text-center heading-tag-line', className)}>
			<Component
				id={id}
				className={clsx(
					'text-3xl',
					'md:text-5xl',
					'font-lexend-regular',
					'leading-[1.2]',
					'md:leading-[1.3]',
					headingClassNames
				)}
			>
				{titleText}
			</Component>
			{
				// eslint-disable-next-line no-nested-ternary
				subText ? (
					typeof subText === 'string' ? (
						<p className={subTextClass}>{subText}</p>
					) : (
						<div className={subTextClass}>{subText}</div>
					)
				) : null
			}
		</div>
	);
};

HeadingTagLine.defaultProps = {
	as: 'h2',
	headingClassNames: '',
	subTextClassNames: '',
	headingHighlightClassNames: '',
	className: '',
	subText: '',
};

HeadingTagLine.propTypes = {
	id: PropTypes.string.isRequired,
	firsttitle: titleProp.isRequired,
	as: PropTypes.string,
	subText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	headingClassNames: PropTypes.string,
	subTextClassNames: PropTypes.string,
	headingHighlightClassNames: PropTypes.string,
	className: PropTypes.string,
};

export { HeadingTagLine };
