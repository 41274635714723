import React from 'react';
import PropTypes from 'prop-types';

import { useLocation } from '@reach/router';
import { PBSLink } from '../link';
import { buildLink, getLocale } from '../../../utils/locale';

const HeadingLinksList = ({ heading, links, className }) => {
	const location = useLocation();
	const { pathname } = location;
	const locale = getLocale(pathname);
	return (
		<div className={className}>
			<h2 className="text-2xl font-lexend-medium">{heading}</h2>
			<ul className="mt-5">
				{links.map((link) => (
					<li key={`footer-nav-links-${link.title}`} className="mb-2">
						<PBSLink
							to={buildLink(locale, link.link)}
							variant="Unset"
							className="hover:underline"
						>
							{link.title}
						</PBSLink>
					</li>
				))}
			</ul>
		</div>
	);
};

HeadingLinksList.defaultProps = {
	className: '',
};

HeadingLinksList.propTypes = {
	heading: PropTypes.string.isRequired,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			link: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
};

export { HeadingLinksList };
