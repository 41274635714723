import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination } from 'swiper';

import { PBSLink } from '../../atoms/link';
import { Button } from '../../atoms/button';
import { TickList } from '../../atoms/tick-list';
import { CarouselNavigation } from '../../atoms/carousel-navigation';

import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { useDrawer } from '../../../contexts/drawer-context';
import { refProp } from '../../../types';

import * as softwareStyles from './index.module.css';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { getLocale, buildLink } from '../../../utils/locale';

export const OurSoftwareVariants = {
	Default: 'my-2xl-f',
	Dark: 'bg-brand-pale-400 border-b-4 lg:border-b-6 border-b-blue-400 py-lg-f xl:py-xl-f',
};

const OurSoftware = ({
	ourSoftwareContent,
	pagination,
	drawerRef,
	variant,
}) => {
	const {
		title,
		content,
		primaryLinkCopy,
		secondaryLinkCopy,
		secondaryLink,
		mobileImage,
		desktopImage,
		tickList,
	} = ourSoftwareContent;

	const { width } = useScreenResizer();
	const [alt, setAlt] = useState('');
	const { setDrawerRef } = useDrawer();

	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const paginationRef = useRef(null);

	const mobileImageData = getImage(mobileImage);
	const mobileAlt =
		mobileImage.alt || title || 'Peninsula HR & Health and Safty Support';
	const desktopImageData = getImage(desktopImage);
	const desktopAlt =
		desktopImage.alt || title || 'Peninsula HR & Health and Safty Support';
	const images = withArtDirection(desktopImageData, [
		{
			media: '(max-width: 767px)',
			image: mobileImageData,
		},
	]);

	const id = 'ourSoftware';

	const paginationProps = {
		type: 'fraction',
		el: paginationRef.current,
		currentClass: softwareStyles.paginationCurrent,
	};

	const renderTickListSlide = (group, index) => (
		<SwiperSlide
			key={`tick-list-${index}`}
			tag="li"
			className="flex justify-center"
		>
			<TickList
				className="sm:w-full sm:grid sm:grid-cols-2 sm:gap-x-7 md:block max-w-[315px] sm:max-w-none"
				listItemClassName="text-lg"
				points={group}
			/>
		</SwiperSlide>
	);

	// Split tickList into groups of 4
	const tickListGroup = tickList
		.map((e, i) => (i % 4 === 0 ? tickList.slice(i, i + 4) : null))
		.filter((e) => e);

	const hasMoreThanOneSlide = tickListGroup.length > 1;

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	return (
		<section
			className={clsx(OurSoftwareVariants[variant])}
			aria-labelledby={id}
		>
			<div
				className={clsx(
					'px-3 mx-auto',
					'sm:max-w-[90%]',
					'md:grid md:justify-between md:items-center md:gap-5 md:px-[2vw] md:grid-cols-[37.5%_auto_37.5%] md:max-w-none',
					'lg:px-8 lg:grid-cols-[32.5%_auto_32.5%] lg:max-w-m-screen'
				)}
			>
				<div className={clsx('text-center', 'md:text-left')}>
					<h2 className="mb-5 text-3xl lg:text-5xl" id={id}>
						{title}
					</h2>
					<p className="text-lg font-notosans-light">{content}</p>
					<div className="flex flex-col items-center md:block">
						<Button
							className="mt-10 mb-8"
							onClick={() => setDrawerRef(drawerRef)}
						>
							{primaryLinkCopy}
						</Button>
						{secondaryLink ? (
							<PBSLink
								variant="Link"
								to={buildLink(locale, secondaryLink)}
							>
								{secondaryLinkCopy}
							</PBSLink>
						) : null}
					</div>
				</div>

				<div className="text-center my-9">
					<GatsbyImage
						className={softwareStyles.artDirected}
						image={images}
						alt={alt}
						onLoad={() => {
							if (width < 768) {
								setAlt(mobileAlt);
							} else {
								setAlt(desktopAlt);
							}
							return alt;
						}}
					/>
				</div>

				<div
					className={clsx('flex justify-center flex-col', 'md:block')}
				>
					<Swiper
						className="!mx-0 sm:mb-4 lg:mb-8"
						loop
						modules={[Controller, Navigation, Pagination]}
						slidesPerView="auto"
						loopedSlides={tickListGroup.length}
						spaceBetween={20}
						wrapperTag="ul"
						autoHeight
						navigation={
							hasMoreThanOneSlide && {
								nextEl: nextRef.current,
								prevEl: prevRef.current,
							}
						}
						pagination={pagination ? paginationProps : false}
						onSwiper={(swiper) => {
							if (!hasMoreThanOneSlide) {
								swiper.disable();
							}
						}}
					>
						{tickListGroup.map(renderTickListSlide)}
					</Swiper>

					<CarouselNavigation
						className="!justify-end mx-auto"
						hasMoreThanOneSlide={hasMoreThanOneSlide}
						nextRef={nextRef}
						prevRef={prevRef}
						paginationRef={paginationRef}
					/>
				</div>
			</div>
		</section>
	);
};

OurSoftware.defaultProps = {
	ourSoftwareContent: {
		primaryLinkCopy: '',
		secondaryLinkCopy: '',
		secondaryLink: '',
		tickList: [],
	},
	pagination: true,
	variant: 'Default',
};

OurSoftware.propTypes = {
	ourSoftwareContent: PropTypes.shape({
		title: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		primaryLinkCopy: PropTypes.string,
		secondaryLinkCopy: PropTypes.string,
		secondaryLink: PropTypes.string,
		// eslint-disable-next-line react/forbid-prop-types
		mobileImage: PropTypes.object.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		desktopImage: PropTypes.object.isRequired,
		tickList: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string.isRequired,
				subText: PropTypes.string,
			})
		).isRequired,
	}),
	pagination: PropTypes.bool,
	variant: PropTypes.oneOf(['Default', 'Dark']),
	drawerRef: refProp.isRequired,
};

export { OurSoftware };
