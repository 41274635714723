import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { TimesRange } from '../../../../../molecules/times-range';
import { Button } from '../../../../../atoms/button';
import { CallUs } from '../../../../../atoms/call-us';
import { RadioButton } from '../../../../../atoms/radio-button';
import { TextInput } from '../../../../../atoms/text-input';
import { useFormValidation } from '../../../../../../hooks/form-validation-ca';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

const formatPhoneToE164 = (phoneNumber, countryCode = '1') => {
	const digits = phoneNumber.replace(/\D/g, '');
	if (phoneNumber.startsWith('+')) {
		return phoneNumber;
	}
	if (digits.startsWith(countryCode)) {
		return `+${digits}`;
	}
	return `+${countryCode}${digits}`;
};

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	companyName: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
};

const AnyQuestionsCA = ({ title, subText, id }) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [chooseTime, setChooseTime] = useState(false);
	const [isError, setIsError] = useState(false);
	const [response, setResponse] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { executeRecaptcha } = useGoogleReCaptcha();

	let isSelectedTimes = false;
	const formTitle = title || 'Any Questions?';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: formTitle,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();
		const { formEntries, isValid } = validateForm(event.currentTarget);
		const todaysDate = new Date().toISOString().split('T')[0];

		if (!isValid) return;

		setSubmitting(true);

		const formattedPhone = formatPhoneToE164(formEntries.phoneNumber);

		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'ec_form_submit',
				user_data: {
					email: formEntries.email,
					phone: formattedPhone,
				},
			});
		}

		isSelectedTimes = formEntries.callFrom && formEntries.callTo;
		const selectedDate = isSelectedTimes ? formEntries.callDay : todaysDate;
		const selectedTime = isSelectedTimes
			? `${formEntries.callFrom}-${formEntries.callTo}`
			: 'asap';

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			phoneNumber: formEntries.phoneNumber,
			company: formEntries.companyName,
			selectedDate,
			selectedTime,
			leadSource: 'Website',
			leadSourceDetails: 'Contact Us',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const res = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (res.status !== 200) {
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (res.status) {
			case 200:
				setResponse(
					`Brilliant, one of our advisors will call you ${
						isSelectedTimes
							? `between the hours of ${
									formEntries.callFrom
							  } and ${formEntries.callTo} ${
									todaysDate === formEntries.callDay
										? 'today'
										: 'tomorrow'
							  }.`
							: 'as soon as possible.'
					}`
				);
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.companyName.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	if (isError) {
		return (
			<section
				aria-labelledby="anyQuestions"
				className={clsx(
					'bg-brand-pale-400 border-blue-200 border-2 rounded-sm my-14',
					'py-base-f px-s-f md:py-base-f',
					'text-center md:text-left'
				)}
			>
				<h2 className="text-3xl md:text-4xl mb-xxs-f">
					We&apos;ve run into a technical error with your submission
				</h2>
				<p className="text-lg md:text-xl">
					Don&apos;t worry though, it just means you need to give us a
					call instead and we&apos;ll be happy to help.
				</p>
				<p className="pt-6">
					<CallUs />
				</p>
			</section>
		);
	}

	if (response) {
		return (
			<section
				aria-labelledby="anyQuestions"
				className={clsx(
					'bg-brand-pale-400 border-blue-200 border-2 rounded-sm my-14',
					'py-base-f px-s-f md:py-base-f',
					'text-center md:text-left'
				)}
			>
				<h2 className="text-3xl md:text-4xl mb-xxs-f">
					Thank you for the details
				</h2>
				<p>{response}</p>
			</section>
		);
	}

	return (
		<section
			aria-labelledby="anyQuestions"
			className={clsx(
				'bg-brand-pale-400 border-blue-200 border-2 rounded-sm my-14',
				'py-base-f px-s-f md:py-base-f',
				'text-center md:text-left'
			)}
		>
			<h2 className="text-3xl md:text-4xl mb-xxs-f">{formTitle}</h2>
			<p className="text-lg md:text-xl">
				{subText ||
					'We&apos;ll be happy to help. Complete the form and an expert will get back to you shortly.'}
			</p>

			<form
				onSubmit={submitHandler}
				className={clsx(
					'flex flex-col justify-between w-full mx-auto grow mt-base-f text-left'
				)}
				id="book_a_demo"
			>
				<div className="flex flex-col sm:flex-row sm:gap-x-6">
					<TextInput
						labelText="First name"
						id={`${id}-callbackFirstName`}
						name="firstName"
						aria-required="true"
						className="w-full wrapper-small md:!mb-5 !mb-4"
						validationMessage="Please enter your first name"
						showValidation={!validations.firstName.isValid}
						ref={firstNameRef}
					/>

					<TextInput
						labelText="Last name"
						id={`${id}-callbackLastName`}
						name="lastName"
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your last name"
						showValidation={!validations.lastName.isValid}
						ref={lastNameRef}
					/>
				</div>

				<div className="flex flex-col sm:flex-row sm:gap-x-6">
					<TextInput
						labelText="Company"
						id={`${id}-callbackCompany`}
						name="companyName"
						aria-required="true"
						className="w-full wrapper-small md:!mb-5 !mb-4"
						validationMessage="Please enter your company name"
						showValidation={!validations.companyName.isValid}
						ref={companyRef}
					/>

					<TextInput
						labelText="Best number to call you on"
						type="tel"
						id={`${id}-callbackPhoneNumber`}
						name="phoneNumber"
						aria-required="true"
						className="w-full !mb-5 md:mb-0 wrapper-small"
						validationMessage="Please enter a phone number"
						showValidation={!validations.phoneNumber.isValid}
						ref={phoneNumberRef}
					/>
				</div>

				<div
					className={clsx(
						'flex flex-col sm:flex-row gap-x-5',
						chooseTime ? 'mb-0' : 'mb-9 md:mb-10'
					)}
				>
					<fieldset
						className={clsx(
							'w-full wrapper-small',
							chooseTime ? 'mb-4' : null
						)}
						disabled={submitting}
					>
						<legend className="mb-4 text-brand-blue-400 text-base-f font-lexend-medium">
							When shall we call you?
						</legend>

						<div className="flex flex-wrap gap-2">
							<RadioButton
								defaultChecked
								labelText="As soon as possible"
								id="callAsap"
								value="callAsap"
								name="callWhen"
								onChange={() => setChooseTime(false)}
							/>
							<RadioButton
								labelText="Choose a time"
								id="chooseTime"
								value="chooseTime"
								name="callWhen"
								onChange={() => setChooseTime(true)}
							/>
						</div>
					</fieldset>
				</div>

				<div
					className="px-4 py-10 bg-brand-pale-300 mb-9 md:mb-10"
					hidden={!chooseTime}
				>
					<TimesRange
						callFromIsValid={
							chooseTime &&
							validations.callFrom &&
							validations.callFrom.isValid
						}
						callToIsValid={
							chooseTime &&
							validations.callTo &&
							validations.callTo.isValid
						}
						isDisabled={!chooseTime}
					/>
				</div>
				<div className="text-center md:text-left wrapper-small">
					<Button
						type="submit"
						className={clsx(submitting && 'justify-center loading')}
					>
						<span className={clsx(submitting ? 'invisible' : null)}>
							Book my free advice call
						</span>
						{submitting ? (
							<span className="sr-only" aria-live="assertive">
								Submitting your enquiry, please wait...
							</span>
						) : null}
					</Button>
				</div>
			</form>
		</section>
	);
};

AnyQuestionsCA.defaultProps = {
	id: 'any-questions',
	title: '',
	subText: '',
};

AnyQuestionsCA.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	subText: PropTypes.string,
};

export { AnyQuestionsCA };
