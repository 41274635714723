import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { RadioButton } from '../../atoms/radio-button';
import { Select } from '../../atoms/select';
import { useTimesRange } from '../../../hooks/times-range';

export const TimesRange = ({ callFromIsValid, callToIsValid, isDisabled }) => {
	const callFromRef = useRef(null);
	const callToRef = useRef(null);

	const {
		fromOptions,
		toOptions,
		setOptionsBasedOnDay,
		onOptionSelected,
		fromValue,
		toValue,
	} = useTimesRange();

	const todaysDate = new Date().toISOString().split('T')[0];
	const tomorrowsDate = new Date(Date.now() + 1000 * 3600 * 24)
		.toISOString()
		.split('T')[0];

	useEffect(() => {
		if (!callFromIsValid && callFromRef.current) {
			return callFromRef.current.focus();
		}

		if (!callToIsValid && callToRef.current) {
			return callToRef.current.focus();
		}
	}, [callFromIsValid, callToIsValid]);

	return (
		<div className="wrapper-small">
			<fieldset className="mb-10">
				<legend className="mb-4 text-brand-blue-400 text-base-f font-lexend-medium">
					When is best for you?
				</legend>

				<div className="flex flex-wrap gap-2">
					<RadioButton
						defaultChecked
						labelText="Today"
						id="today"
						name="callDay"
						value={todaysDate}
						onChange={() => setOptionsBasedOnDay('today')}
					/>

					<RadioButton
						labelText="Tomorrow"
						id="tomorrow"
						name="callDay"
						onChange={() => setOptionsBasedOnDay('tomorrow')}
						value={tomorrowsDate}
					/>
				</div>
			</fieldset>

			<div
				className={clsx(
					'relative wrapper-small  before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none',
					!callFromIsValid || !callToIsValid
						? 'before:bg-alert-400'
						: ''
				)}
			>
				<fieldset>
					<legend className="mb-1 text-brand-blue-400 text-base-f font-lexend-medium">
						What time are you free?
					</legend>

					<div className="flex flex-col justify-between sm:flex-row sm:gap-x-6">
						<Select
							disabled={isDisabled}
							labelText="From"
							id="callFrom"
							name="callFrom"
							className="flex-1 mr-1 sm:mr-2 md:!mb-0 !mb-4"
							aria-required="true"
							options={fromOptions}
							onChange={onOptionSelected}
							validationMessage="Please select a time"
							showValidation={!callFromIsValid}
							ref={callFromRef}
							labelClassName="!font-lexend-regular !text-s-f"
							value={fromValue}
						/>

						<Select
							disabled={isDisabled}
							labelText="To"
							id="callTo"
							name="callTo"
							className="flex-1"
							options={toOptions}
							onChange={onOptionSelected}
							validationMessage="Please select a time"
							showValidation={!callToIsValid}
							ref={callToRef}
							labelClassName="!font-lexend-regular !text-s-f"
							value={toValue}
						/>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

TimesRange.defaultProps = {
	isDisabled: true,
	callFromIsValid: true,
	callToIsValid: true,
};

TimesRange.propTypes = {
	isDisabled: PropTypes.bool,
	callFromIsValid: PropTypes.bool,
	callToIsValid: PropTypes.bool,
};
