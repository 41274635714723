export const bannerDefaultContent = {
	bannerImage: {
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: 'https://www.datocms-assets.com/64336/1733778337-calc-image-1-2.png?auto=format%2C%20compresss%2C%20enhance&dpr=2&fm=webp&q=85&w=512',
					srcSet: 'https://www.datocms-assets.com/64336/1733778337-calc-image-1-2.png?auto=format%2C%20compresss%2C%20enhance&dpr=0.25&fm=webp&q=85&w=512 128w,\nhttps://www.datocms-assets.com/64336/1733778337-calc-image-1-2.png?auto=format%2C%20compresss%2C%20enhance&dpr=0.5&fm=webp&q=85&w=512 256w,\nhttps://www.datocms-assets.com/64336/1733778337-calc-image-1-2.png?auto=format%2C%20compresss%2C%20enhance&dpr=2&fm=webp&q=85&w=512 512w',
					sizes: '(min-width: 512px) 512px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAMAAAAhxq8pAAAAAXNSR0IArs4c6QAAAblQTFRFR3BM////////////////////////////////////////////////////6v//////5On/ytH/////////eoz/8///////q7v/UWz/ztf/////eIn/jZv/////m6j/1N3/vMj/rLf/Xnb/fI3/WXL/SWf/Ynn/eIv/Smj/+P//6fT/b4L/ZHv/maz/boL/VnL/Q2T/6e7/dYr/tra2X3j/oaGmW3T/1OP/vb29097/0dG5xMTEVG//yMjCh5n/////4uLius//srKuuLi4zc3Bp67mysrGAAAA29vb5OSuwMC+k5702dnZz8/PtLfP0c/F2dnZmKX/wsK+y8vLl6L11NTR1tbW0NDMtbnfzs7O09PT29vb29vbzs7O+PHUaoD/v7++Xnb/TGr/T2v/P2H/QmP/VG//QWL/QGL/Rmb/V3H/VXD/RWX/PmL/XXb/Xnb/UGz/WXH/R2b/kZ3/P2L/XXX/XnT/WHL/QWP/Tmv/Ol//QmL/S2r/S2n/SGf/YXj/w8PDIVf/iJPv5eXlYHb/dYX6xcXF4uLi4uHh6Ojo4+Pj3Nzc5OTkVG7/zs7Oz8/P29vbm6LoYnr/16atVgAAAJN0Uk5TAAMIDAIFBwEEBhALCQ0MCi9DHw6KKRRA5T8YppIcdTxUXO2o4PbJgvUiLqrvKIm68C6LFdEu5RJhFwvD9X6MESMlPHaY+/kD9BOK7vyL3bU1d9+h1fcy/fSdz/4/2iSvyPj/////////////////////////////////////////////////////////////////BAPvGwAAAPdJREFUGNNjYEAAdwZ0YOXslRBsjybo1Nbd2eeLImTp6Bk0oas/EsplZ2dg0DUys4n3C++JjQkIc+AECTLwqesUl1XlJEb4x0VF+3hYGwMFOUSSkgtTUqsbJ4YEhna0NzeYApVyK0rkpqSmp9c29U7ydnOxteBhZ2Bn4ZKXVs0oyk6qy85qdbUzZ2AEqmRhYJVVUMtPSi4tz2yprzExBNnEyMvMwiQqo5RXkJNWWZGhr8XJzsDBxMrJxMgmJqmclZZZYqCnyQZUyAF0JycXPwOHlJyKhrY4NwcbB8T9HOwcPKycwgKCQqxAHicLzJ/szGwgLiMDTgAA8/Qv4viji6sAAAAASUVORK5CYII=',
			},
			width: 512,
			height: 421,
		},
	},
	bannerHeading: {
		value: {
			schema: 'dast',
			document: {
				type: 'root',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								type: 'span',
								marks: ['highlight'],
								value: 'Ask us how',
							},
							{
								type: 'span',
								value: ' we can save your time and money',
							},
						],
					},
				],
			},
		},
	},
	bannerContent:
		'We’ve helped over **6,500 Canadian businesses grow** by taking the load of HR and health & safety compliance off their backs. Call our experienced advisors today and get answers to all your HR and health & safety queries.',
	ctaLabel: 'Get a free demo',
};
