import PropTypes from 'prop-types';
import React from 'react';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { TextWithImage } from '../../atoms/text-with-image-ca';

import {
	datoGatsbyImageProp,
	refProp,
	structuredTextContent,
	titleProp,
} from '../../../types';

const TextWithImageContainerCorCA = ({
	textWithImageContainerContent,
	showOnlyFirst,
	drawerRef,
}) => {
	const { id } = textWithImageContainerContent;

	let textWithImages = [textWithImageContainerContent];

	if (
		!Object.prototype.hasOwnProperty.call(
			textWithImageContainerContent,
			'content'
		)
	) {
		const {
			title,
			firstContent,
			firstImage,
			secondContent,
			secondImage,
			buttonCopy,
			buttonLink,
			showForm,
			showFirstRow,
			showSecondRow,
		} = textWithImageContainerContent;

		const elementOne = {
			titleProps: {
				id,
				title,
				headingClassNames:
					'text-left text-3xl md:text-5xl font-lexend-regular leading-[1.2] md:leading-[1.3] lg:text-4xl xl:text-5xl xl:leading-[1.3]',
			},
			as: HeadingTagLine,
			content: firstContent,
			image: firstImage,
			showContent: showFirstRow,
			className: 'items-center mb-8 lg:mb-2xl-',
		};

		const elementTwo = {
			content: secondContent,
			image: secondImage,
			buttonLink,
			buttonCopy,
			showForm,
			drawerRef,
			showContent: showSecondRow,
			className: 'items-center',
		};

		textWithImages = showOnlyFirst
			? [elementOne]
			: [elementOne, elementTwo];
	}

	return (
		<section
			className="px-4 mx-auto md:px-8 max-w-m-screen my-xl-f xl:mt-[6rem] xl:mb-2xl-f"
			aria-labelledby={id}
		>
			{textWithImages.map((elContent, index) => {
				let flipSecond = false;
				if (textWithImages.length > 1) {
					flipSecond = true;
				}
				return (
					<>
						<div className="hidden lg:block">
							<TextWithImage
								// eslint-disable-next-line react/no-array-index-key
								key={`${id}${index}`}
								textWithImageContent={elContent}
								{...(flipSecond && index === 1
									? { flip: false }
									: { flip: true })}
								drawerRef={drawerRef}
							/>
						</div>
						<div className="block lg:hidden">
							<TextWithImage
								// eslint-disable-next-line react/no-array-index-key
								key={`${id}${index}`}
								textWithImageContent={elContent}
								{...(flipSecond &&
									index === 1 && { flip: true })}
								drawerRef={drawerRef}
							/>
						</div>
					</>
				);
			})}
		</section>
	);
};

TextWithImageContainerCorCA.defaultProps = {
	showOnlyFirst: false,
	locale: 'en',
};
TextWithImageContainerCorCA.propTypes = {
	textWithImageContainerContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.oneOfType([titleProp, PropTypes.string]).isRequired,
		buttonCopy: PropTypes.string,
		buttonLink: PropTypes.string,
		showForm: PropTypes.bool,
		content: structuredTextContent,
		firstContent: structuredTextContent,
		secondContent: structuredTextContent,
		image: datoGatsbyImageProp,
		firstImage: datoGatsbyImageProp,
		showFirstRow: PropTypes.bool,
		showSecondRow: PropTypes.bool,
		secondImage: datoGatsbyImageProp,
	}).isRequired,
	showOnlyFirst: PropTypes.bool,
	drawerRef: refProp.isRequired,
	locale: PropTypes.string,
};

export { TextWithImageContainerCorCA };
