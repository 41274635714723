import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from '../../atoms/nav-link';
import { MobileMegaMenu } from '../../molecules/mobile-mega-menu';
import { sendTrackingData } from '../../../utils';

const NavMobile = ({ links, className, listClassName, ...navAttributes }) => {
	const linkRefs = useRef({});
	const megaRefs = useRef({});
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [hiddenMega, setHiddenMega] = useState(true);

	const toggleOpen = (index) => {
		setSelectedIndex(index);

		if (selectedIndex === index) {
			setSelectedIndex(null);
			setHiddenMega(true);
		} else {
			setHiddenMega(false);
		}
	};

	const handleClickTracking = (clickTitle) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'navigation',
			click_action: 'open section',
			click_type: 'link',
			click_text: clickTitle,
			click_title: clickTitle,
		});
	};

	return (
		<nav className="pb-8 " {...navAttributes}>
			<ul className={clsx('w-full', listClassName)}>
				{links?.map((link, index) =>
					link?.treeChildren && link?.treeChildren?.length > 0 ? (
						<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
							<NavLink
								// eslint-disable-next-line no-return-assign
								ref={(ref) => (linkRefs.current[index] = ref)}
								as="button"
								onClick={() => {
									toggleOpen(index);
									handleClickTracking(link.title);
								}}
								aria-expanded={selectedIndex === index}
								className={clsx(
									'justify-between w-full text-xl font-lexend-regular rounded-none',
									selectedIndex === index
										? 'bg-brand-pale-400'
										: null
								)}
								hasMegaMenu
							>
								{link.title}
							</NavLink>
							<MobileMegaMenu
								// eslint-disable-next-line no-return-assign
								ref={(ref) => (megaRefs.current[index] = ref)}
								links={link.treeChildren}
								aria-label={`${link.title} menu`}
								hidden={selectedIndex !== index || hiddenMega}
								parentTrackingId={navAttributes[
									'aria-label'
								]?.replace(' menu', '')}
							/>
						</li>
					) : (
						<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
							<NavLink
								className="w-full text-xl rounded-none font-lexend-regular"
								to={link.link}
							>
								{link.title}
							</NavLink>
						</li>
					)
				)}
			</ul>
		</nav>
	);
};

export { NavMobile };

NavMobile.defaultProps = {
	className: '',
	listClassName: '',
};

NavMobile.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			link: PropTypes.string,
			treeChildren: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subText: PropTypes.string.isRequired,
					link: PropTypes.string.isRequired,
					icon: PropTypes.string.isRequired,
				})
			),
		})
	).isRequired,
	className: PropTypes.string,
	listClassName: PropTypes.string,
};
