import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { refProp, priceCalculatedProp, pricingCardProps } from '../../../types';
import { useDrawer } from '../../../contexts/drawer-context';
import { Button } from '../button';
import { TickList } from '../tick-list';
import { sendTrackingData } from '../../../utils';
import * as pricingCardStyles from './index.module.css';

const getCardPrice = ({ cardType, priceCalculated }) => {
	let price = '-';

	if (cardType === 'human resources' && priceCalculated?.hr) {
		price = priceCalculated.hr;
	}

	if (cardType === 'health and safety' && priceCalculated?.hs) {
		price = priceCalculated.hs;
	}

	if (cardType === 'combined' && priceCalculated?.combined) {
		price = priceCalculated.combined;
	}

	return price;
};

const PricingCard = ({
	content,
	employeeCount,
	drawerRef,
	setFormTitle,
	priceCalculated,
	index,
}) => {
	const buttonText = 'Get Price';
	const {
		title,
		prePriceCopy,
		postPriceCopy,
		tickListHeader,
		tickList,
		cardType,
	} = content;
	const { setDrawerRef } = useDrawer();

	const handleClickTracking = (clickType) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			component_name: 'pricing-card',
			click_type: clickType,
			click_action: 'card click',
			click_state: `${employeeCount}_no_employees`,
			click_title: title,
			click_text: buttonText,
			click_index: index + 1,
		});
	};

	const handleClick = () => {
		setDrawerRef(drawerRef);
		setFormTitle(title);
	};

	const priceBlock = (stacked) => (
		<>
			<hr
				className={clsx(
					stacked ? 'mb-s-f' : 'mb-base-f',
					'mt-auto border-t-blue-200'
				)}
			/>
			<div className="flex flex-col items-start justify-between xs:items-center xs:flex-row">
				<p className="text-sm xl:max-w-none md:max-w-[100px] ">
					{prePriceCopy}
					<strong
						className={clsx(
							stacked
								? 'block text-2xl md:text-3xl'
								: 'inline pl-2 text-xl md:text-2xl',
							'text-blue-400 font-notosans-regular'
						)}
					>
						{getCardPrice({ cardType, priceCalculated })}
					</strong>
					<span className={clsx(stacked ? 'inline' : 'block')}>
						{postPriceCopy}
					</span>
				</p>
				<Button
					onClick={() => {
						handleClick();
						handleClickTracking(stacked ? 'primary' : 'secondary');
					}}
					className="!font-lexend-regular xs:mt-0 mt-s-f mx-auto xs:mx-0"
				>
					{buttonText}
				</Button>
			</div>
		</>
	);

	return (
		<article
			className={clsx(
				'flex',
				'flex-col',
				'col-span-12',
				'md:col-span-6',
				'lg:col-span-4',
				'bg-white',
				'border-2',
				'border-blue-200',
				'rounded-sm',
				'shadow-slight',
				pricingCardStyles.pricingCard
			)}
		>
			<header className="flex flex-col border-b-2 p-s-f md:p-6 bg-brand-pale-400 border-b-brand-pale-500">
				<h2 className="text-xl mb-s-f md:text-2xl font-lexend-medium">
					{title}
				</h2>
				{priceBlock(true)}
			</header>
			<div className="flex flex-col p-s-f md:p-6 grow">
				<h3 className="mb-4 text-sm font-lexend-medium">
					{tickListHeader}
				</h3>
				<TickList
					points={tickList}
					className="mb-6"
					listItemClassName="text-sm"
					iconClassName="lg:mt-[8px]"
				/>
				{priceBlock()}
			</div>
		</article>
	);
};

PricingCard.defaultProps = {
	setFormTitle: () => null,
	index: 0,
	employeeCount: 0,
	priceCalculated: {
		hr: '-',
		hs: '-',
		combined: '-',
	},
};

PricingCard.propTypes = {
	setFormTitle: PropTypes.func,
	drawerRef: refProp.isRequired,
	priceCalculated: priceCalculatedProp,
	content: pricingCardProps.isRequired,
	index: PropTypes.number,
	employeeCount: PropTypes.number,
};

export { PricingCard };
