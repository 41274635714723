/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';

import { datoGatsbyImageProp, refProp, titleProp } from '../../../types';
import { sendTrackingData } from '../../../utils';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import { buildLink, getLocale } from '../../../utils/locale';

import { useDrawer } from '../../../contexts/drawer-context';
import { TrustpilotBusinessReviewHorizontal } from '../../atoms/trustpilot-business-review';

import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { CallUs } from '../../atoms/call-us-cta-ppc';
import AdviceFormInlineUK from '../../organisms/advise-form-inline-uk';
import { SuccessForm } from '../../organisms/success-form';
import * as headingStyles from './index.module.css';
import { BusinessReviewHorizontal } from '../../atoms/business-reviews';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const MainHeader = ({
	id,
	urlLocale,
	trustpilot,
	header,
	secondaryHeader,
	as,
	primaryImage,
	secondaryImage,
	primaryLink,
	primaryLinkCopy,
	primaryLinkOpenForm,
	secondaryLink,
	secondaryLinkCopy,
	thirdLinkCopy,
	thirdLink,
	showTrustPilot,
	headingClassNames,
	drawerRef,
	glu,
	logo,
	badge,
	openModal,
	pageName,
	peninsulaNow,
	showInlineForm,
	formPosition,
	note,
	ctaText,
	setReference,
	setResponse,
	reference,
	response,
	rating,
	googleReview,
}) => {
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});
	const { width } = useScreenResizer();
	const primaryImageData = getImage(primaryImage);
	const secondaryImageData = getImage(secondaryImage);
	const { setDrawerRef } = useDrawer();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};
	let cta;
	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					if (
						urlLocale !== 'ca' &&
						peninsulaNow &&
						pageName === 'childService'
					) {
						openModal();
					} else {
						setDrawerRef(drawerRef);
					}
					handleClickTracking();
				}}
				className="w-max"
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink && glu) {
		cta = (
			<PBSLink
				className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
				to={primaryLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}

	return (
		<section
			role="banner"
			className={clsx(
				'px-4 pt-10 pb-6 lg:py-6 lg:mx-auto lg:pr-0',
				showInlineForm ? 'lg:mt-10 mt-3' : 'mt-0',
				{
					'bg-brand-pale-500 flex': glu,
					'lg:max-w-m-screen': !glu,
				}
			)}
			aria-labelledby={id}
		>
			<div
				className={clsx('flex mx-auto', {
					'lg:max-w-m-screen gap-10': glu,
				})}
			>
				<div
					className={clsx(
						'flex flex-col shrink lg:mr-8 lg:pl-8 xl:mr-12 lg:col-span-3 col-span-5',
						urlLocale !== 'ca' && showInlineForm
							? 'justify-start'
							: 'justify-center',
						urlLocale !== 'ca' &&
							showInlineForm &&
							formPosition === 'Left'
							? 'lg:order-last lg:!pl-20 lg:gap-4'
							: 'order-first  lg:mr-8  xl:mr-12 lg:gap-4',
						headingStyles.headingContainer
					)}
				>
					{logo && (

						<img
							src={logo.url}
							alt={logo.alt || ''}
							className="max-w-[275px] mb-5"
						/>

					)}
					<Component
						id={id}
						className={clsx(
							'!leading-[1.3] font-lexend-regular',
							!secondaryHeader ? headingStyles.heading : null,
							glu
								? 'text-2xl lg:text-4xl font-bold lg:min-h-[160px]'
								: 'text-4xl md:text-5xl xl:text-6xl',
							headingClassNames
						)}
					>
						{titleText}
						{secondaryHeader ? (
							<span
								className={clsx(
									'mt-4 leading-[1.2] block',
									'md:mt-8',
									' xl:leading-[1.3]',
									glu
										? 'text-xl lg:text-2xl font-lexend-regular'
										: `text-2xl md:text-4xl xl:text-5xl ${headingStyles.heading}`
								)}
							>
								{secondaryHeader}
							</span>
						) : null}
					</Component>
					{(locale !== 'ca' || urlLocale !== 'en-CA') && (
						<div className="max-w-m-screen mr-auto mt-6 ">
							{showTrustPilot ? (
								<>
									{trustpilot ? (
										<TrustpilotBusinessReviewHorizontal
											locale={urlLocale}
											pageName="safe-check"
											className="!px-0 !mx-0 !py-0"
											rating={rating}
										/>
									) : (
										<>
											<TrustpilotBusinessReviewHorizontal
												locale={urlLocale}
												rating={rating}
											/>
										</>
									)}
								</>
							) : null}
						</div>
					)}

					{badge.url && (
						<img
							src={badge.url}
							alt={badge.alt || ''}
							width="189"
							height="80"
							className="mt-8"
						/>
					)}
					{primaryLink || secondaryLink ? (
						<p className="flex flex-col items-center justify-center mt-8 sm:flex-row sm:justify-start">
							{width > 600 && showInlineForm ? (
								<CallUs
									type={showInlineForm ? 'button' : 'cta'}
									className="inline-flex items-center px-6 py-4 mb-2 mr-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-lexend-medium focus:outline-0 focus:shadow-focus w-max"
								/>
							) : (
								cta || null
							)}
							{secondaryLink && secondaryLinkCopy ? (
								<PBSLink
									to={
										glu
											? secondaryLink
											: buildLink(locale, secondaryLink)
									}
									variant="White"
									className="justify-center w-full mt-4 sm:w-max sm:mt-0 sm:ml-4"
								>
									{secondaryLinkCopy}
								</PBSLink>
							) : null}
							{thirdLink && thirdLinkCopy ? (
								<PBSLink
									to={
										glu
											? thirdLink
											: buildLink(locale, thirdLink)
									}
									variant="White"
									className="justify-center w-full mt-4 sm:w-max sm:mt-0 sm:ml-4"
								>
									{thirdLinkCopy}
								</PBSLink>
							) : null}
						</p>
					) : null}
					{urlLocale === 'en-CA' && showTrustPilot && (
						<div>
							<BusinessReviewHorizontal
								googleReview={googleReview}
								onlyGR
							/>
						</div>
					)}
				</div>
				{urlLocale !== 'ca' && showInlineForm ? (
					<div
						className={clsx(
							'lg:col-span-2 2xl:col-span-5 lg:mt-0 mt-8 hidden',
							'lg:block',
							'grow',
							'shrink-0 card',
							headingStyles.imageContainer,
							reference === '' ? '' : 'w-[40%] flip'
						)}
					>
						{reference === '' ? (
							<AdviceFormInlineUK
								note={note}
								ctaText={ctaText}
								setReference={setReference}
								setResponse={setResponse}
							/>
						) : (
							<SuccessForm
								reference={reference}
								response={response}
							/>
						)}
					</div>
				) : (
					<div
						className={clsx(
							'hidden',
							'grid-cols-12',
							'lg:grid',
							'grow',
							'shrink-0',
							'w-[36%]',
							headingStyles.imageContainer
						)}
					>
						<GatsbyImage
							image={primaryImageData}
							alt={primaryImage.alt || ''}
							key={primaryImage.url}
							className={clsx(
								'border-8',
								'border-white',
								'drop-shadow-image',
								headingStyles.image1,
								'2xl:min-w-[400px]'
							)}
						/>
						<GatsbyImage
							image={secondaryImageData}
							alt={secondaryImage.alt || ''}
							key={secondaryImage.url}
							className={clsx(
								'border-8',
								'border-white',
								'drop-shadow-image',
								headingStyles.image2,
								'2xl:min-w-[330px]'
							)}
						/>
					</div>
				)}
			</div>
		</section>
	);
};

MainHeader.defaultProps = {
	id: 'main-site-header',
	as: 'h1',
	urlLocale: '',
	secondaryHeader: '',
	headingClassNames: '',
	showTrustPilot: true,
	glu: false,
	logo: {},
	primaryLink: '',
	primaryLinkCopy: '',
	secondaryLink: '',
	secondaryLinkCopy: '',
	thirdLink: '',
	thirdLinkCopy: '',
	badge: {},
	openModal: () => {},
	peninsulaNow: false,
	trustpilot: false,
	pageName: '',
	showInlineForm: false,
	formPosition: 'right',
	note: '',
	ctaText: 'Book a free advice call',
	setReference: () => {},
	setResponse: () => {},
	reference: '',
	response: '',
	rating: '',
};

MainHeader.propTypes = {
	id: PropTypes.string,
	urlLocale: PropTypes.string,
	header: titleProp.isRequired,
	secondaryHeader: PropTypes.string,
	as: PropTypes.string,
	primaryImage: datoGatsbyImageProp.isRequired,
	secondaryImage: datoGatsbyImageProp.isRequired,
	headingClassNames: PropTypes.string,
	primaryLink: PropTypes.string,
	primaryLinkCopy: PropTypes.string,
	primaryLinkOpenForm: PropTypes.bool.isRequired,
	secondaryLink: PropTypes.string,
	secondaryLinkCopy: PropTypes.string,
	thirdLink: PropTypes.string,
	thirdLinkCopy: PropTypes.string,
	trustpilot: PropTypes.bool,
	showTrustPilot: PropTypes.bool,
	note: PropTypes.string,
	ctaText: PropTypes.string,
	// eslint-disable-next-line react/require-default-props
	drawerRef: refProp,
	glu: PropTypes.bool,
	logo: datoGatsbyImageProp,
	badge: PropTypes.objectOf,
	openModal: PropTypes.func,
	peninsulaNow: PropTypes.bool,
	pageName: PropTypes.string,
	showInlineForm: PropTypes.bool,
	formPosition: PropTypes.string,
	setReference: PropTypes.func,
	setResponse: PropTypes.func,
	reference: PropTypes.string,
	response: PropTypes.string,
	rating: PropTypes.string,
};

export { MainHeader };
