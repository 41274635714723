/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { useLocation } from '@reach/router';

import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';

import * as headingStyles from './index.module.css';

import { AdviceFormCA } from '../ca/forms/salesforce/legislation-inline-two-steps/advice-step-one';
import { SuccessFormCA } from '../ca/forms/salesforce/legislation-inline-two-steps/success-step-two';

const LegislationHero = ({ heroContentCA, className, customFormField }) => {
	const { content, image, backgroundImage, titleImage, subtitleTwo } =
		heroContentCA;
	const [response, setResponse] = useState('');
	const [reference, setReference] = useState('');
	const id = 'main-site-header-v2-ca';
	const as = 'h1';
	const Component = as;

	const contentText = createHeadingHighlightCA({
		variant: 'subtitle-blue',
		headings: content.value?.document?.children[0]?.children,
		headingStyles,
	});

	const [locationParam, setLocationParam] = useState(null);
	const url = useLocation();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(url.search);
			const param = urlParams.get('h1');

			setLocationParam(param);
		}
	}, [url.search]);

	const formTitle = 'Book a free callback';

	return (
		<section
			role="banner"
			className={`${headingStyles?.bgGradientPpc} px-4 xl:px-8 mx-auto lg:min-w-m-screen sm:mt-0 md:mb-0 lg:mb-0 xl:mb-0v  ${className}`}
			aria-labelledby={id}
		>
			<div className="absolute inset-0 flex items-center justify-center mx-auto mb-32 lg:mr-12 -z-9">
				<img
					src={backgroundImage.url}
					alt={backgroundImage.alt}
					className="h-[50%] hidden lg:block"
				/>
			</div>
			<div className="flex flex-row items-center justify-center py-10 mx-auto max-w-m-screen lg:p-6 xl:px-2 2xl:px-10">
				{/* left panel */}
				<div className="relative w-full lg:mb-2 xl:m-0">
					<div className="flex mx-auto">
						<div className="flex flex-col">
							<div className="flex  flex-col gap-1 lg:gap-5 !leading-[1.1] font-lexend-regular lg:mx-0 -mt-16">
								{/* Title div */}
								<div>
									<img
										className="-mb-5"
										src={image.url}
										alt={image.alt}
									/>
									<span id="dynamic-loc" className="" />
									<img
										src={titleImage.url}
										alt={titleImage.alt}
									/>
								</div>
								<Component id={id} className="inline w-4/5">
									{locationParam || contentText}
								</Component>
								<hr
									style={{
										borderColor: '#C9D1F6',
										margin: '10px 0',
									}}
								/>
								<span className="mb-10 text-xl md:mb-0 lg:mt-2 font-lexend-regular">
									{subtitleTwo}
								</span>
								<div
									className="block md:hidden scroll-mt-[425px]"
									id="adviceForm"
								>
									{reference === '' ? (
										<AdviceFormCA
											pageName="LegislationPage"
											formTitle={
												customFormField.customFormTitle
											}
											setReference={setReference}
											setResponse={setResponse}
											customFormFieldContent={
												customFormField
											}
										/>
									) : (
										<SuccessFormCA
											formTitle={formTitle}
											reference={reference}
											response={response}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* right panel */}
				<div
					className="hidden w-2/3 z-10 text-left md:block scroll-mt-[136px]"
					id="advice-form"
				>
					{reference === '' ? (
						<AdviceFormCA
							pageName="LegislationPage"
							formTitle={formTitle}
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							formTitle={formTitle}
							reference={reference}
							response={response}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

LegislationHero.propTypes = {};

export { LegislationHero };
