// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import {
	renderMarkRule,
	renderNodeRule,
	StructuredText,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';
import * as compassStyles from './index.module.css';

const StatementCompassCA = ({ statementContent, className }) => {
	const {
		statementPrimaryCopy,
		statementPrimaryImage,
		statementSecondaryCopy,
		statementSecondaryImage,
	} = statementContent;

	return (
		<div
			className={`flex flex-col sm:flex-row justify-between font-lexend-regular items-stretch gap-10 sm:gap-5 lg:text-lg xl:text-2xl xl:leading-10 xl:mx-5 2xl:mx-[14.5rem] ${compassStyles.macStatementCompass} ${className}`}
		>
			{/* left div */}
			<div className="flex flex-col sm:w-1/2 text-white">
				<div className="flex flex-grow">
					<img
						src={statementPrimaryImage.url}
						alt=""
						className="w-full rounded-t-[.75rem] sm:rounded-[.75rem] sm:-mb-2 z-10"
					/>
				</div>
				<div className="flex-grow content-center p-5 sm:pt-7 bg-blue-300 rounded-b-[.75rem] leading-6 xl:p-10 xl:leading-8 content-center">
					<StructuredText
						data={statementPrimaryCopy}
						customNodeRules={[
							renderNodeRule(
								isParagraph,
								({
									adapter: { renderNode },
									node,
									children,
									key,
									ancestors,
								}) => {
									if (
										node.children[0].type === 'inlineItem'
									) {
										return (
											<React.Fragment key={key}>
												{children}
											</React.Fragment>
										);
									}
									if (isRoot(ancestors[0])) {
										return renderNode(
											'p',
											{
												key,
												className: '',
											},
											children
										);
									}
									return (
										<React.Fragment key={key}>
											{children}
										</React.Fragment>
									);
								}
							),
						]}
						customMarkRules={[
							renderMarkRule('strong', ({ children, key }) => (
								<span className="" key={key}>
									{children}
								</span>
							)),
						]}
					/>
				</div>
			</div>

			{/* right div */}
			<div className="flex flex-col sm:w-1/2 text-black">
				<div className="flex-grow content-center bg-[#E30138] rounded-t-[.75rem] sm:rounded-[.75rem] sm:-mb-2 z-10">
					<img
						src={statementSecondaryImage.url}
						alt=""
						className="m-auto w-1/5 sm:w-2/5 h-auto object-contain rounded-t-[.75rem] my-10"
					/>
				</div>
				<div className="flex-grow p-5 sm:pt-7 bg-[#EDF4FF] rounded-b-[.75rem] xl:p-10 leading-6 xl:leading-8 content-center">
					<StructuredText
						data={statementSecondaryCopy}
						customNodeRules={[
							renderNodeRule(
								isParagraph,
								({
									adapter: { renderNode },
									node,
									children,
									key,
									ancestors,
								}) => {
									if (
										node.children[0].type === 'inlineItem'
									) {
										return (
											<React.Fragment key={key}>
												{children}
											</React.Fragment>
										);
									}
									if (isRoot(ancestors[0])) {
										return renderNode(
											'p',
											{
												key,
												className: '',
											},
											children
										);
									}
									return (
										<React.Fragment key={key}>
											{children}
										</React.Fragment>
									);
								}
							),
						]}
						customMarkRules={[
							renderMarkRule('strong', ({ children, key }) => (
								<span className="" key={key}>
									{children}
								</span>
							)),
						]}
					/>
				</div>
			</div>
		</div>
	);
};

export default StatementCompassCA;