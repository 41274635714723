// eslint-disable-next-line import/no-extraneous-dependencies
import FingerprintJS from '@fingerprintjs/fingerprintjs';

let visitorIds = '';
let isTracking = false;
let userData = {};

const saveToKV = async (data) => {
	try {
		// eslint-disable-next-line compat/compat
		const response = await fetch('/api/save-user', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		});
		if (response.status !== 200) {
			// eslint-disable-next-line no-console
			console.error('Error saving to KV:', response);
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error saving to KV:', error);
	}
};

export const initializeUserTracking = async (email, isClient, loc) => {
	try {
		const fp = await FingerprintJS.load();
		const { visitorId: vId } = await fp.get();

		visitorIds = vId;
		userData = {
			id: visitorIds,
			email,
			isClient,
			locale: loc === 'ie' ? 'Republic of Ireland' : 'United Kingdom',
			date: new Date().toISOString(),
			landingPage: window.location.href,
			visitedPages: [window.location.href],
		};
		await saveToKV(userData);
		isTracking = true;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error initializing tracking:', error);
	}
};

export const trackPageChange = async (url) => {
	if (!isTracking || userData.visitedPages.length >= 61) return;
	// chcek if url is in visitedPages
	if (userData.visitedPages.includes(url)) return;

	userData.visitedPages = [...userData.visitedPages, url];

	if (userData.visitedPages.length === 61) {
		isTracking = false;
	}

	await saveToKV(userData);
};
