// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { CardContainer } from '../components/organisms/card-container-press';
import { RteContent } from '../components/organisms/rte-content-pressradio';

import { ResourceHeader } from '../components/molecules/resource-header-pressradio';
import { JumpTo } from '../components/molecules/jump-to';

import { Drawer } from '../components/atoms/drawer';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { LongIntro } from '../components/atoms/long-intro';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';

// markup
const BlogPage = ({ data, location, parentPageData }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsPressRadioPage.noIndex,
		noFollow: data.datoCmsPressRadioPage.noFollow,
		metaInformation: data.datoCmsPressRadioPage.metaInformation,
		fallbackTitle: data.datoCmsPressRadioPage.title,
		fallbackDescription: data.datoCmsPressRadioPage.longIntroduction,
		canonicalUrl: data.datoCmsPressRadioPage?.canonicalUrl || location.href,
		hreflangs: {
			uk: data.datoCmsPressRadioPage.hreflangEnGb,
			ie: data.datoCmsPressRadioPage.hreflangEnIe,
		},
	};

	// console.log(data.datoCmsPressRadioPage);
	// return false;
	const resourceHeaderContent = {
		id: data.datoCmsPressRadioPage.originalId,
		title: data.datoCmsPressRadioPage.title,
		categories: data.datoCmsPressRadioPage.categories,
		author: data.datoCmsPressRadioPage.author,
		createdDate: data.datoCmsPressRadioPage.created,
		createdDateOrdinal: data.datoCmsPressRadioPage.createdOrdinal,
		lastUpdated: data.datoCmsPressRadioPage.meta.updatedAt,
		lastUpdatedOrdinal: data.datoCmsPressRadioPage.meta.updatedAtOrdinal,
		image: data.datoCmsPressRadioPage.thumbnail,
		pageUrl: location.href,
		topLogo: data.datoCmsPressRadioPage.logo,
		audio: data.datoCmsPressRadioPage.audio?.url,
	};

	// console.log(resourceHeaderContent);

	const rteContent = {
		...data.datoCmsPressRadioPage.content,
	};

	const pressRadioContainerContent = {
		totalCount: data.allDatoCmsPressRadioPage.totalCount,
		cards: data.allDatoCmsPressRadioPage.nodes,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.blog,
			location,
			data: data.datoCmsPressRadioPage,
			parentPageData,
		}),
		image: getImageSeoSchema(
			data.datoCmsPressRadioPage?.mainHeader?.primaryImage
		),
	};

	let pagePath = location.pathname;
	if (pagePath.endsWith('/')) {
		pagePath = location.pathname.slice(0, -1);
	}

	const lastHubPage = location.pathname.substring(
		0,
		location.pathname.lastIndexOf('/')
	);

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsPressRadioPage.internal.type,
		data.datoCmsPressRadioPage,
		parentPageData
	);

	const socialShareContent = {
		title: data.datoCmsPressRadioPage.title,
		pageUrl: location.href,
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPressRadioPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<ResourceHeader content={resourceHeaderContent} />
			{data.datoCmsPressRadioPage.intro ? (
				<LongIntro content={data.datoCmsPressRadioPage.intro} />
			) : null}
			{data.datoCmsPressRadioPage.showJumpSection ? (
				<JumpTo content={rteContent} />
			) : null}
			<RteContent
				containerClassName="max-w-content mx-auto mb-14 md:mb-20 px-3 md:px-8 xl:px-0"
				content={rteContent}
				drawerRef={adviceFormRef}
				shareContent={socialShareContent}
			/>
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			{pressRadioContainerContent.totalCount > 0 && (
				<CardContainer
					cardContainerContent={{
						...pressRadioContainerContent,
						id: 'blog-container',
						title: 'More radio',
						to: `${lastHubPage}`,
						toAll: '/press',
						buttonCopy: 'Back to Peninsula in the Press',
					}}
					variant="Center"
					cardVariant="PressArticle"
				/>
			)}

			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceFormCA
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessFormCA reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query blogPage($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsPressRadioPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			title
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			originalId
			internal {
				type
			}
			slug
			categories {
				name
				slug
			}
			sectors {
				name
			}
			thumbnail {
				alt
				url
				gatsbyImageData(
					layout: CONSTRAINED
					aspectRatio: 45
					placeholder: DOMINANT_COLOR
					width: 851
					sizes: "(max-width: 640px) 400px, 800px"
					height: 315
				)
			}
			logo {
				alt
				gatsbyImageData(width: 99, height: 45)
			}
			audio {
				url
			}
			created
			createdOrdinal: created(formatString: "MMM Do YYYY")
			meta {
				updatedAt
				updatedAtOrdinal: updatedAt(formatString: "MMM Do YYYY")
			}
			longIntroduction
			intro
			showJumpSection
			content {
				value
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			hreflangEnGb
			hreflangEnIe
		}
		allDatoCmsPressRadioPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: { slug: { ne: null }, locale: { eq: $locale } }
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 400
						sizes: "(max-width: 640px) 400px, 800px"
						height: 200
					)
				}
				logo {
					alt
					gatsbyImageData(width: 99, height: 45)
				}
				sectors {
					id
					name
				}
			}
		}
	}
`;

const WrappedBlog = ({ data, pageContext, location }) => (
	<DefaultLayout
		className="bg-white"
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<BlogPage
			data={data}
			location={location}
			slug={pageContext.slug}
			locale={pageContext.locale}
			parentPageData={pageContext.parentPageData}
		/>
	</DefaultLayout>
);

export default WrappedBlog;
