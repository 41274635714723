import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pricingCardProps } from '../../../types';

import { TitlePricingCA } from '../../atoms/title-pricing-ca';
import { PricingCardsCA } from '../../molecules/pricing-cards-ca';
import { PricingCalculatorCA } from '../../molecules/pricing-calculator-ca';

const PricingSectionCA = ({ pricingContent, customFormField }) => {
	const [priceCalculated, setPriceCalculated] = useState({
		combined: '-',
		hr: '-',
		hs: '-',
	});
	const [employeeCount, setEmployeeCount] = useState(0);

	const { title, subtitle, cards, quoteButtonCopy } = pricingContent;

	return (
		<section className="relative w-full">
			<div className="px-4 xl:px-10 py-14 sm:py-16 mx-auto max-w-m-screen md:px-4">
				<TitlePricingCA title={title} />
				<PricingCalculatorCA
					title={title}
					subtitle={subtitle}
					setPriceCalculated={setPriceCalculated}
					setEmployeeCount={setEmployeeCount}
					customFormField={customFormField}
				/>
				<PricingCardsCA
					priceCalculated={priceCalculated}
					content={cards}
					employeeCount={employeeCount}
					quoteButtonCopy={quoteButtonCopy}
				/>
			</div>
		</section>
	);
};

PricingSectionCA.propTypes = {
	pricingContent: PropTypes.shape({
		title: PropTypes.string,
		title2: PropTypes.string,
		subtitle: PropTypes.string,
		subtitle2: PropTypes.string,
		quoteButtonCopy: PropTypes.string,
		table: PropTypes.instanceOf(Array),
		cards: PropTypes.arrayOf(pricingCardProps),
	}).isRequired,
	customFormField: PropTypes.node.isRequired,
};

export { PricingSectionCA };
