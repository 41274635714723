import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { titleProp } from '../../../types';
import * as headingStyles from './index.module.css';

const createHeadingHighlight = ({ headings, headingHighlightClassNames }) =>
	headings.map((item) =>
		// Check to see if there are any highlighted words
		// eslint-disable-next-line no-nested-ternary
		item.marks ? (
			<span
				key={`heading-text-${item.value}-${Math.random()}`}
				className={clsx(
					'text-[#E30138] pb-1',
					'md:bg-[length:100%_8px]',
					headingStyles?.highlight,
					headingHighlightClassNames
				)}
			>
				{item.value}
			</span>
		) : item.value.includes('\n') ? (
			// Check for new lines and handle them
			item.value.split('\n').map((line, index) =>
				// We don't want the first one as it'll be part of the original text
				index > 0 ? (
					<span
						className="text-[#1D2951]"
						key={`heading-text-${line}-${Math.random()}`}
					>
						<br />
						{line}
					</span>
				) : (
					<span
						className="text-[#1D2951]"
						key={`heading-text-${line}`}
					>
						{line}
					</span>
				)
			)
		) : (
			<span className="text-[#1D2951]" key={`heading-text-${item.value}`}>
				{item.value}
			</span>
		)
	);

const VapHeadingTagLine = ({
	id,
	title,
	as,
	subText,
	headingClassNames,
	subTextClassNames,
	headingHighlightClassNames,
	className,
}) => {
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: title[0].children,
		headingStyles, // Pass headingStyles as an argument here
		headingHighlightClassNames,
	});

	const subTextClass = clsx(
		'mt-6',
		'text-xl',
		'md:text-2xl',
		'font-lexend-light',
		subTextClassNames
	);

	return (
		<div className={clsx('text-center heading-tag-line', className)}>
			<Component
				id={id}
				className={clsx(
					'text-3xl',
					'md:text-5xl',
					'font-lexend-regular',
					'leading-[1.2]',
					'md:leading-[1.3]',
					headingClassNames
				)}
			>
				{titleText}
			</Component>
			{
				// eslint-disable-next-line no-nested-ternary
				subText ? (
					typeof subText === 'string' ? (
						<p className={subTextClass}>{subText}</p>
					) : (
						<div className={subTextClass}>{subText}</div>
					)
				) : null
			}
		</div>
	);
};

VapHeadingTagLine.defaultProps = {
	as: 'h2',
	headingClassNames: '',
	subTextClassNames: '',
	headingHighlightClassNames: '',
	className: '',
	subText: '',
	id: '',
};

VapHeadingTagLine.propTypes = {
	id: PropTypes.string,
	title: titleProp.isRequired,
	as: PropTypes.string,
	subText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	headingClassNames: PropTypes.string,
	subTextClassNames: PropTypes.string,
	headingHighlightClassNames: PropTypes.string,
	className: PropTypes.string,
};

export { VapHeadingTagLine };
