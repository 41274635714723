import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Vimeo } from '../../atoms/vimeo';

import QuotationMark from '../../../assets/quotation-mark.inline.svg';

import { datoGatsbyImageProp } from '../../../types';

const CaseStudyHeader = ({ content }) => {
	const { id, quote, logo, video, image, clientName } = content;

	const imageAlt = image?.alt || '';
	const imageData = getImage(image);

	const logoAlt = logo?.alt || '';
	const logoData = getImage(logo);

	return (
		<section
			aria-labelledby={id}
			className={clsx(
				'max-w-m-screen mb-14 mx-auto px-3',
				'sm:gap-10 sm:grid',
				'md:grid-cols-[1fr_45%] md:mb-20',
				'lg:gap-24 lg:px-8 lg:grid-cols-[1fr_40%]',
				'xl:items-center'
			)}
		>
			<div className="mb-8 sm:mb-0 w-fit">
				<QuotationMark
					className="w-5 h-auto pb-2 text-blue-400"
					aria-hidden="true"
				/>
				<blockquote>
					<h1
						id={id}
						className="leading-snug md:!leading-snug text-2xl-f lg:text-5xl font-lexend-regular"
					>
						{quote}
					</h1>
				</blockquote>
				<hr className="border-blue-200 my-base-f" />
				<div className="flex flex-row items-center">
					<GatsbyImage
						className="max-w-[165px]"
						image={logoData}
						alt={logoAlt}
					/>
					<p className="ml-6 text-2xl">{clientName}</p>
				</div>
			</div>
			{video ? (
				<Vimeo video={video} responsive color="3955E0" />
			) : (
				<GatsbyImage
					className={clsx(
						'border-8',
						'border-white',
						'drop-shadow-image max-h-[230px] max-w-full xs:max-h-[320px] md:max-h-[280px]'
					)}
					image={imageData}
					quality={80}
					alt={imageAlt}
				/>
			)}
		</section>
	);
};

export { CaseStudyHeader };

CaseStudyHeader.defaultProps = {};

CaseStudyHeader.propTypes = {
	content: PropTypes.shape({
		id: PropTypes.string.isRequired,
		quote: PropTypes.string.isRequired,
		logo: datoGatsbyImageProp.isRequired,
		image: datoGatsbyImageProp,
		video: PropTypes.string,
		clientName: PropTypes.string,
	}).isRequired,
};
