import { getLocale } from './locale';

export const pageTypes = {
	home: 'DatoCmsHomepage',
	pricing: 'DatoCmsPricingPage',
	legalDocuments: 'DatoCmsLegalDocument',
	contactUs: 'DatoCmsContactUsPage',
	service: 'DatoCmsServicePage',
	chlidService: 'DatoCmsChildServicePage',
	resourceHub: 'DatoCmsResourcesPage',
	peninsulaPress: 'DatoCmsPeninsulaPressPage',
	allBlogs: 'DatoCmsAllBlogsPage',
	allPressArticles: 'datoCmsAllPressarticlesPage',
	allPressRadios: 'datoCmsAllPressradiosPage',
	allGuides: 'DatoCmsAllGuidesPage',
	allDownloads: 'DatoCmsAllDownloadsPage',
	blog: 'DatoCmsBlogPage',
	pressArticle: 'DatoCmsPressArticlePage',
	pressRadio: 'DatoCmsPressRadioPage',
	guide: 'DatoCmsGuidePage',
	download: 'DatoCmsDownloadPage',
	compass: 'DatoCmsCompassPage',
	categoryGroup: 'DatoCmsCategoryGrouping',
	category: 'DatoCmsCategory',
	caseStudyHub: 'DatoCmsCaseStudiesHubPage',
	caseStudy: 'DatoCmsCaseStudyPage',
	eventsHub: 'DatoCmsAllEventsPage',
	event: 'DatoCmsEventPage',
	aboutUs: 'DatoCmsAboutUsPage',
	faq: 'DatoCmsFaqPage',
	allSectors: 'DatoCmsAllSectorsPage',
	sector: 'DatoCmsSectorPage',
	childSector: 'DatoCmsChildSectorPage',
	Sectors: 'DatoCmsSectorsPage',
	careers: 'DatoCmsCareersPage',
	jobPost: 'DatoCmsJobPost',
	webinar: 'DatoCmsWebinarsPage',
	liveWebinar: 'DatoCmsLiveWebinar',
	onDemandWebinar: 'DatoCmsOnDemandWebinar',
	// GLU Elearning
	gluHome: 'DatoCmsGluHomepage',
	gluClientPage: 'DatoCmsGluClientPage',
	gluCourse: 'DatoCmsGluCourse',
	gluWebinarsPage: 'DatoCmsGluWebinarsPage',
	gluLiveWebinar: 'DatoCmsGluLiveWebinar',
	gluProspect: 'DatoCmsGluProspect',
	gluGossip: 'DatoCmsGluGossip',
	gluAdPage: 'DatoCmsGluAdPage',
};

const homeBreadCrumb = {
	url: '',
	title: 'Home',
	hierarchyPosition: 1,
};

const servicesBreadCrumb = {
	url: 'services/',
	title: 'Services',
	hierarchyPosition: 2,
};

const resourcesBreadCrumb = {
	url: 'resource-hub/',
	title: 'Resources',
	hierarchyPosition: 2,
};

const peninsulapressBreadCrumb = {
	url: 'press/',
	title: 'Peninsula in the Press',
	hierarchyPosition: 2,
};

const caseStudiesBreadCrumb = {
	url: 'case-studies/',
	title: 'Case Studies',
	hierarchyPosition: 2,
};

const eventsHubBreadCrumb = {
	url: 'events/',
	title: 'Events',
	hierarchyPosition: 2,
};

const allSectorsBreadCrumb = {
	url: 'about/sectors/',
	title: 'Sectors',
	hierarchyPosition: 2,
};

// const SectorsBreadCrumb = {
// 	url: '',
// 	title: 'Sectors',
// 	hierarchyPosition: 2,
// };

const careersBreadCrumb = {
	url: 'about/careers/',
	title: 'Careers',
	hierarchyPosition: 2,
};

const webinarsBreadCrumb = {
	url: '/elearning/webinars/',
	title: 'Webcasts',
	hierarchyPosition: 2,
};

const gluHomeBreadCrumb = {
	url: 'elearning/',
	title: 'GLU Home',
	hierarchyPosition: 1,
};

const gluHProspectBreadCrumb = {
	url: 'prospect/',
	title: 'Glu Prospect',
	hierarchyPosition: 3,
};

const gluWebinarsPageBreadcrumb = {
	url: 'webinars/',
	title: ' Webinars',
	hierarchyPosition: 2,
};

const gluLiveWebinarBreadcrumb = {
	url: 'webinar/',
	title: 'Glu Live Webinar',
	hierarchyPosition: 3,
};

const gluGossipBreadcrumb = {
	url: 'gossip/',
	title: 'Glu Gossip',
	hierarchyPosition: 3,
};

export const getBreadcrumbs = (pageType, data, parentPageData) => {
	// eslint-disable-next-line default-case
	switch (pageType) {
		case pageTypes.home:
			return [homeBreadCrumb];
		case pageTypes.service:
			return [
				homeBreadCrumb,
				servicesBreadCrumb,
				{
					url: `${servicesBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.chlidService:
			return [
				homeBreadCrumb,
				servicesBreadCrumb,
				{
					url: `${servicesBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.jobPost:
			return [
				homeBreadCrumb,
				careersBreadCrumb,
				{
					url: `${careersBreadCrumb.url}${data.slug}/`,
					title: data.jobTitle,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.contactUs:
			return [
				homeBreadCrumb,
				{
					url: 'contact-us/',
					title: 'Contact Us',
					hierarchyPosition: 2,
				},
			];
		case pageTypes.compass:
			return [
				homeBreadCrumb,
				{
					url: data.slug,
					title: data.title,
					hierarchyPosition: 2,
				},
			];
		case pageTypes.aboutUs:
			return [
				homeBreadCrumb,
				{
					url: 'about-us/',
					title: 'About Us',
					hierarchyPosition: 2,
				},
			];
		case pageTypes.faq:
			return [
				homeBreadCrumb,
				{
					url: 'faq/',
					title: 'FAQ',
					hierarchyPosition: 2,
				},
			];
		case pageTypes.careers:
			return [homeBreadCrumb, careersBreadCrumb];
		case pageTypes.legalDocuments:
			return [
				homeBreadCrumb,
				{
					title: `${data.title}`,
					hierarchyPosition: 2,
				},
			];
		case pageTypes.pricing:
			return [
				homeBreadCrumb,
				{
					url: 'pricing/',
					title: 'Pricing',
					hierarchyPosition: 2,
				},
			];
		case pageTypes.category:
			return [
				homeBreadCrumb,
				resourcesBreadCrumb,
				{
					url: `${resourcesBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.categoryGroup:
			return [
				homeBreadCrumb,
				resourcesBreadCrumb,
				{
					url: `${resourcesBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.allGuides:
			if (parentPageData) {
				return [
					homeBreadCrumb,
					resourcesBreadCrumb,
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/`,
						title: parentPageData.title,
						hierarchyPosition: 3,
					},
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/guides/`,
						title: 'Guides',
						hierarchyPosition: 4,
					},
				];
			}
			return [
				homeBreadCrumb,
				resourcesBreadCrumb,
				{
					url: `${resourcesBreadCrumb.url}guides/`,
					title: 'Guides',
					hierarchyPosition: 3,
				},
			];
		case pageTypes.allBlogs:
			if (parentPageData) {
				return [
					homeBreadCrumb,
					resourcesBreadCrumb,
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/`,
						title: parentPageData.title,
						hierarchyPosition: 3,
					},
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/blogs/`,
						title: 'Blogs',
						hierarchyPosition: 4,
					},
				];
			}
			return [
				homeBreadCrumb,
				resourcesBreadCrumb,
				{
					url: `${resourcesBreadCrumb.url}blogs/`,
					title: 'Blogs',
					hierarchyPosition: 3,
				},
			];
		case pageTypes.allPressArticles:
			if (parentPageData) {
				return [
					homeBreadCrumb,
					peninsulapressBreadCrumb,
					{
						url: `${peninsulapressBreadCrumb.url}${parentPageData.slug}/`,
						title: parentPageData.title,
						hierarchyPosition: 3,
					},
					{
						url: `${peninsulapressBreadCrumb.url}${parentPageData.slug}/articles/`,
						title: 'Blogs',
						hierarchyPosition: 4,
					},
				];
			}
			return [
				homeBreadCrumb,
				peninsulapressBreadCrumb,
				{
					url: `${peninsulapressBreadCrumb.url}`,
					title: 'Latest articles',
					hierarchyPosition: 3,
				},
			];
		case pageTypes.allPressRadios:
			if (parentPageData) {
				return [
					homeBreadCrumb,
					peninsulapressBreadCrumb,
					{
						url: `${peninsulapressBreadCrumb.url}${parentPageData.slug}/`,
						title: parentPageData.title,
						hierarchyPosition: 3,
					},
					{
						url: `${peninsulapressBreadCrumb.url}${parentPageData.slug}/radios/`,
						title: 'Radio',
						hierarchyPosition: 4,
					},
				];
			}
			return [
				homeBreadCrumb,
				peninsulapressBreadCrumb,
				{
					url: `${peninsulapressBreadCrumb.url}`,
					title: 'Radio',
					hierarchyPosition: 3,
				},
			];
		case pageTypes.allDownloads:
			if (parentPageData) {
				return [
					homeBreadCrumb,
					resourcesBreadCrumb,
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/`,
						title: parentPageData.title,
						hierarchyPosition: 3,
					},
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/downloads/`,
						title: 'Downloads',
						hierarchyPosition: 4,
					},
				];
			}
			return [
				homeBreadCrumb,
				resourcesBreadCrumb,
				{
					url: `${resourcesBreadCrumb.url}downloads/`,
					title: 'Downloads',
					hierarchyPosition: 3,
				},
			];
		case pageTypes.resourceHub:
			return [homeBreadCrumb, resourcesBreadCrumb];
		case pageTypes.peninsulaPress:
			return [homeBreadCrumb, peninsulapressBreadCrumb];
		case pageTypes.blog:
		case pageTypes.guide:
		case pageTypes.download:
			if (parentPageData) {
				return [
					homeBreadCrumb,
					resourcesBreadCrumb,
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/`,
						title: parentPageData.title,
						hierarchyPosition: 3,
					},
					{
						url: `${resourcesBreadCrumb.url}${parentPageData.slug}/${data.slug}/`,
						title: data.title,
						hierarchyPosition: 4,
					},
				];
			}
			return [
				homeBreadCrumb,
				resourcesBreadCrumb,
				{
					url: `${resourcesBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.pressArticle:
			return [
				homeBreadCrumb,
				peninsulapressBreadCrumb,
				{
					url: `${peninsulapressBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.pressRadio:
			return [
				homeBreadCrumb,
				peninsulapressBreadCrumb,
				{
					url: `${peninsulapressBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.caseStudyHub:
			return [homeBreadCrumb, caseStudiesBreadCrumb];
		case pageTypes.caseStudy:
			return [
				homeBreadCrumb,
				caseStudiesBreadCrumb,
				{
					url: `${caseStudiesBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.eventsHub:
			return [homeBreadCrumb, eventsHubBreadCrumb];
		case pageTypes.event:
			return [
				homeBreadCrumb,
				eventsHubBreadCrumb,
				{
					url: `${eventsHubBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.allSectors:
			return [homeBreadCrumb, allSectorsBreadCrumb];
		case pageTypes.Sectors:
			return [
				homeBreadCrumb,
				{
					url: `$sectors/${data.slug}/`,
					title: data.internalTitle,
					hierarchyPosition: 2,
				},
			];
		case pageTypes.childSector:
			return [
				homeBreadCrumb,
				{
					url: `sectors/${parentPageData.slug}/`,
					title: parentPageData.title,
					hierarchyPosition: 2,
				},
				{
					url: `${allSectorsBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.sector:
			return [
				homeBreadCrumb,
				allSectorsBreadCrumb,
				{
					url: `${allSectorsBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.webinar:
			return [homeBreadCrumb, webinarsBreadCrumb];
		case pageTypes.liveWebinar:
			return [
				homeBreadCrumb,
				webinarsBreadCrumb,
				{
					url: `${webinarsBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.onDemandWebinar:
			return [
				homeBreadCrumb,
				{
					url: 'on-demand/',
					title: 'Webcast on Demand',
					hierarchyPosition: 2,
				},
				{
					url: `${webinarsBreadCrumb.url}${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.gluHome:
			return [homeBreadCrumb, gluHomeBreadCrumb];
		case pageTypes.gluProspect:
			return [homeBreadCrumb, gluHomeBreadCrumb, gluHProspectBreadCrumb];
		case pageTypes.gluClientPage:
			return [
				gluHomeBreadCrumb,
				{
					url: `${data.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.gluWebinarsPage:
			return [gluHomeBreadCrumb, gluWebinarsPageBreadcrumb];
		case pageTypes.gluAdPage:
			return [
				gluHomeBreadCrumb,
				{
					url: `${data?.slug}/`,
					title: data?.title,
					hierarchyPosition: 3,
				},
			];
		case pageTypes.gluLiveWebinar:
			return [
				gluHomeBreadCrumb,
				gluLiveWebinarBreadcrumb,
				{
					url: `${data.slug}/`,
					title: data.title,
					hierarchyPosition: 4,
				},
			];
		case pageTypes.gluGossip:
			return [homeBreadCrumb, gluHomeBreadCrumb, gluGossipBreadcrumb];
		case pageTypes.gluCourse:
			return [
				gluHomeBreadCrumb,
				{
					url: `elearning/${
						data.category !== null
							? data.category.slug
							: data.moreCategory?.slug
					}/`,
					title:
						data.category !== null
							? data.category.title
							: data.moreCategory?.title,
					hierarchyPosition: 2,
				},
				{
					url: `${data?.slug}/`,
					title: data.title,
					hierarchyPosition: 3,
				},
			];
	}
};

export const resolveUrl = (data, parentPageData) => {
	// eslint-disable-next-line default-case
	switch (data.internal.type) {
		case pageTypes.home:
			return '';
		case pageTypes.pricing:
			return 'pricing/';
		case pageTypes.compass:
			return `${data.slug}/`;
		case pageTypes.contactUs:
			return 'contact-us/';
		case pageTypes.service:
			return `services/${data.slug}/`;
		case pageTypes.chlidService:
			return `services/${data.parentPage.slug}/${data.slug}/`;
		case pageTypes.resourceHub:
			return 'resource-hub/';
		case pageTypes.peninsulaPress:
			return 'peninsula-press/';
		case pageTypes.allBlogs:
			return 'resource-hub/blogs/';
		// case pageTypes.allPressArticles:
		// 	return 'press/articles/';
		case pageTypes.allGuides:
			return 'resource-hub/guides/';
		case pageTypes.allDownloads:
			return 'resource-hub/downloads/';
		case pageTypes.blog:
		case pageTypes.guide:
		case pageTypes.download:
			return parentPageData?.slug
				? `resource-hub/${parentPageData?.slug}/${data.slug}/`
				: `resource-hub/${data.slug}/`;
		case pageTypes.categoryGroup:
		case pageTypes.category:
			return `resource-hub/${data.slug}/`;
		case pageTypes.caseStudyHub:
			return 'case-studies/';
		case pageTypes.caseStudy:
			return `case-studies/${data.slug}/`;
		case pageTypes.eventsHub:
			return 'events/';
		case pageTypes.event:
			return `events/${data.slug}/`;
		case pageTypes.aboutUs:
			return 'about-us/';
		case pageTypes.faq:
			return 'faq/';
		case pageTypes.sector:
			return `about/sectors/${data.slug}/`;
		case pageTypes.allSectors:
			return 'sectors/';
		case pageTypes.gluHome:
			return 'elearning/';
		case pageTypes.gluProspect:
			return 'elearning/what-is-elearning/';
		case pageTypes.gluClientPage:
			return `elearning/${data.slug}/`;
		case pageTypes.gluWebinarsPage:
			return 'elearning/webinars/';
		case pageTypes.gluAdPage:
			return `elearning/${data.slug}/`;
		case pageTypes.gluLiveWebinar:
			return `elearning/webinar/${data.slug}/`;
		case pageTypes.gluCourse:
			return `elearning/${
				data.category !== null
					? data.category.slug
					: data.moreCategory?.slug
			}/${data.slug}/`;
	}
};

export const urlBuilder = (parent, slug) => {
	if (parent) {
		return `${parent}/${slug}`;
	}

	return slug;
};

export const getBreadcrumLocale = (location) =>
	getLocale(location.pathname) === ''
		? ''
		: `${getLocale(location.pathname)}/`;
