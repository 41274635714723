import React from 'react';
import PropTypes from 'prop-types';

export const AccordionGroup = ({ accordions }) => (
	<section>
		<ul>
			{accordions.map((accordion) => (
				<li className="mb-4 bg-white" key={accordion.key}>
					{accordion}
				</li>
			))}
		</ul>
	</section>
);

AccordionGroup.propTypes = {
	accordions: PropTypes.arrayOf(PropTypes.node).isRequired,
};
