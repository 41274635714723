/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-array-index-key */
/* eslint-disable compat/compat */
import React from 'react';
import clsx from 'clsx';
import reactStringReplace from 'react-string-replace';

import { PBSLink } from '../components/atoms/link';
import { truncateWithEllipsis } from './truncateWithEllipsis';

export const getDayOfCall = (day) => {
	// Starting at Sunday due to getDay() method starting with this
	const dayNames = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	const nextWorkingDay = [1, 2, 3, 4, 5, 1, 1];
	const now = new Date();
	const currDay = now.getDay();
	let dayName;

	if (day === 'Tomorrow') {
		if (nextWorkingDay[currDay] !== 1) {
			dayName = 'Tomorrow';
		} else {
			dayName = dayNames[nextWorkingDay[currDay]];
		}
	}
	if (day === 'DayAfterTomorrow') {
		if (nextWorkingDay[currDay] !== 1) {
			dayName = dayNames[nextWorkingDay[currDay + 1]];
		} else {
			dayName = dayNames[nextWorkingDay[currDay] + 1];
		}
	}

	return dayName;
};

export const getDateOfCall = (value) => {
	const dayName = getDayOfCall(value);

	// The current day
	const date = new Date();
	const now = date.getDay();

	// Days of the week
	const days = [
		'sunday',
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
	];

	// The index for the day you want
	const day = days.indexOf(dayName.toLowerCase());

	// Find the difference between the current day and the one you want
	// If it's the same day as today (or a negative number), jump to the next week
	const dayMinusNow = day - now;
	const diff = dayMinusNow < 1 ? 7 + dayMinusNow : dayMinusNow;

	// Get the timestamp for the desired day
	const nextDayTimestamp = date.getTime() + 1000 * 60 * 60 * 24 * diff;

	// Get the next day
	return new Date(nextDayTimestamp).toISOString().split('T')[0];
};

export const filterTimeRangeOptions = (options, value, direction) => {
	if (!value || value === '') return options;

	const valueIndex = options.findIndex((option) => option.value === value);

	if (valueIndex === -1) return options;

	return options.filter((_option, currentIndex) =>
		direction ? currentIndex > valueIndex : currentIndex < valueIndex
	);
};

// Returns a nicely formatted date string from the selected time value
export const getNiceHoursFromValue = (formEntries) => {
	const arrHours = formEntries.callTime.split('-');
	const strDay = getDayOfCall(
		formEntries.callWhen === 'callTomorrow'
			? 'Tomorrow'
			: 'DayAfterTomorrow'
	);
	return `${
		strDay === 'Tomorrow' ? 'tomorrow' : strDay
	} between the hours of ${arrHours[0]} and ${arrHours[1]}`;
};

export const getGAClientId = async () => {
	const gTagTrackingId = process.env.GATSBY_GA_TRACKING_ID;
	const hasGtag = typeof window.gtag === 'function';
	let id = '';

	if (!hasGtag) return '';

	const clientIdPromise = new Promise((resolve) => {
		if (!hasGtag) {
			id = '';
			resolve();
		}

		window.gtag('get', gTagTrackingId, 'client_id', resolve);
	});

	await clientIdPromise.then((clientId) => {
		id = clientId;
	});

	return id;
};

export const getTrustpilotStarLabel = (star) => {
	/**
	 * Function to return a star label that is not provided by gatsby-source-trustpilot-api
	 * @see https://support.trustpilot.com/hc/en-us/articles/201748946-TrustScore-and-star-rating-explained#from-trustscore-to-star-rating-1
	 */

	let starLabel;

	if (star > 1 && star <= 1.7) {
		starLabel = 'Bad';
	} else if (star >= 1.8 && star <= 2.7) {
		starLabel = 'Poor';
	} else if (star >= 2.8 && star <= 3.7) {
		starLabel = 'Average';
	} else if (star >= 3.8 && star <= 4.2) {
		starLabel = 'Great';
	} else if (star >= 4.3 && star <= 5.0) {
		starLabel = 'Excellent';
	} else {
		return;
	}

	return starLabel;
};

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const getRteContent = (content, id) =>
	content.map((paragraph, index) => (
		<p
			className={clsx(
				'mb-4 text-lg font-notosans-light md:text-xl last-of-type:mb-0'
			)}
			key={`twoColTextPara${id}${index}`}
		>
			{paragraph.children.map((paragraphContents, contentIndex) => {
				let element;
				if (paragraphContents.type === 'link') {
					const linkCopy = paragraphContents.children[0].value;
					element = (
						<PBSLink
							to={paragraphContents.url}
							variant="Link"
							key={`twoColTextParaLink${id}${contentIndex}`}
						>
							{linkCopy}
						</PBSLink>
					);
				}
				if (paragraphContents.type === 'span') {
					element = paragraphContents.value;
				}
				if (paragraphContents.type === 'listItem') {
					const ListTag = 'ul';
					const listClassName = 'list-disc';
					element = (
						<ListTag
							className={clsx(
								'mb-1 font-lexend-light list-inside',
								listClassName
							)}
							key={`twoColTextPara${id}${contentIndex}`}
						>
							<li>
								{
									paragraphContents.children[0].children[0]
										.value
								}
							</li>
						</ListTag>
					);
				}
				if (
					paragraphContents.marks &&
					paragraphContents.marks.length > 0
				) {
					const Marks = paragraphContents.marks[0];
					element = (
						<span
							className="font-lexend-regular"
							key={`twoColTextPara${Marks}${id}${contentIndex}`}
						>
							{paragraphContents.value}
						</span>
					);
				}
				return element;
			})}
		</p>
	));

export const truncateCategories = ({
	categories = [],
	limit = 1,
	withEllipsis = false,
}) => {
	const getName = ({ name }) =>
		withEllipsis ? truncateWithEllipsis(name, 14) : name;
	const categoriesLen = categories.length;

	if (categoriesLen > limit) {
		const visibleCategories = categories.slice(0, limit);

		return [
			...visibleCategories.map(getName),
			`+ ${categoriesLen - limit}`,
		];
	}

	return categories.map(getName);
};

export const sendTrackingData = (event, data) => {
	window.dataLayer.push({
		event,
		...data,
	});
};

const ordinal = (theDateArg) => {
	const theODate = Math.abs(theDateArg);
	const cent = theODate % 100;
	if (cent >= 10 && cent <= 20) return 'th';
	const dec = theODate % 10;
	if (dec === 1) return 'st';
	if (dec === 2) return 'nd';
	if (dec === 3) return 'rd';
	return 'th';
};

const formatDate = (theDate) => {
	const date = new Date(theDate);
	const year = date.getFullYear();
	const day = date.getDate();

	const month = new Intl.DateTimeFormat('en-US', {
		month: 'long',
	}).format(date);

	return `${month} ${day}${ordinal(day)} ${year}`;
};

export const mapToGatsbyFormat = (item) => {
	let obj = {
		...item,
	};

	if (item.publishedDate) {
		obj = {
			...obj,
			publishedDateOrdinal: formatDate(item.publishedDate),
		};
	}

	return obj;
};

export const getMultiParagraphText = (paragraphs, classNames, id) =>
	paragraphs.map((paragraph, index) => {
		let result = paragraph;

		const doubleAsteriskRegex = /\*\*([^*]*(?:\*(?!\*)[^*]*)*)\*\*/g;
		if (doubleAsteriskRegex.test(paragraph)) {
			result = reactStringReplace(
				paragraph,
				doubleAsteriskRegex,
				(match) => (
					<span
						key={id + match.replace(/[^A-Z0-9]+/gi, '_') + index}
						className="font-lexend-regular"
					>
						{match}
					</span>
				)
			);
		}

		const httpsRegex = /(http(s?)):\/\//i;
		if (httpsRegex.test(paragraph)) {
			result = reactStringReplace(
				result,
				/(\[.*?\]\(.*?\))/gi,
				(match) => {
					const linkCopy = match.match(/\[(.*?)\]/)[1];
					const link = match.match(/\((.*?)\)/)[1];

					return (
						<PBSLink
							key={
								id +
								linkCopy.replace(/[^A-Z0-9]+/gi, '_') +
								index
							}
							variant="Link"
							to={link}
						>
							{linkCopy}
						</PBSLink>
					);
				}
			);
		}

		const htmlEntitiesRegex = /&[#A-Za-z0-9]+;/gi;
		if (htmlEntitiesRegex.test(paragraph)) {
			const isBrowser = typeof window !== 'undefined';
			if (isBrowser) {
				const span = document.createElement('span');
				result = result.replace(htmlEntitiesRegex, (match) => {
					span.innerHTML = match;
					return span.innerText;
				});
			}
		}

		return result ? (
			<p
				key={id + paragraph.replace(/[^A-Z0-9]+/gi, '_') + index}
				className={clsx(classNames, 'font-notosans-light')}
			>
				{result}
			</p>
		) : null;
	});

export const splitParagraphs = (text, className) =>
	text.split('\n').map((paragraph) =>
		paragraph ? (
			<p key={paragraph} className={className}>
				{paragraph}
			</p>
		) : null
	);

export const getCookie = (name) => {
	const escape = (s) => s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1');
	const match =
		typeof document !== 'undefined'
			? document.cookie.match(
					RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`)
			  )
			: null;
	return match ? match[1] : null;
};

export const filterObject = (filteredKeys, object) =>
	filteredKeys.reduce((obj, key) => ({ ...obj, [key]: object[key] }), {});

const splitUrl = (url) => {
	if (url.includes('amp;')) {
		return url.split('amp;');
	}
	return url.split('&');
};

export const pageUrlFomater = (url) => {
	if (!url) return '/';
	return `${url.pathname}${[...new Set(splitUrl(url.search))]
		.map((item) => item.replace('&', ''))
		.filter(
			(item) =>
				item.includes('utm_source') ||
				item.includes('utm_campaign') ||
				item.includes('utm_medium') ||
				item.includes('utm_term')
		)
		.join('&')}`;
};

export const generateUtmData = (url, utmName) => {
	const utmData = [...new Set(splitUrl(url))]
		.map((item) => item.replace('&', ''))
		.find((item) => item.includes(utmName))
		?.split('=')[1]
		?.replace(/%20/g, ' ');
	return getCookie(utmName) !== null || getCookie(utmName) !== '(not set)'
		? getCookie(utmName)
		: utmData || '(not set)';
};

export const stringCipherEncrypt = (text, shift = 3) =>
	text
		.split('')
		.map((char) => {
			if (char.match(/[a-z]/i)) {
				const code = char.charCodeAt(0);
				const base = code >= 65 && code <= 90 ? 65 : 97;
				return String.fromCharCode(((code - base + shift) % 26) + base);
			}
			return char;
		})
		.join('');

export const stringCipherDecrypt = (text, shift = 3) =>
	stringCipherEncrypt(text, -shift);

export const gluUnlockKey = 'GluUnlockKey';
