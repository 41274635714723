import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';

import { TagsList } from '../../../molecules/tags-list';
import { cardContents } from '../../../../types';
import { Button } from '../../../atoms/button';
import { LikeButton } from '../../atoms/like-button';

import { buildLink } from '../../../../utils/locale';
import CPDLogo from '../../../../assets/cpd-logo.svg';

const Card = ({ cardContent, onClickProp, index, logo, locale }) => {
	const {
		as,
		id,
		thumbnail,
		title,
		intro,
		category,
		timeToWatchInMinutes,
		link,
		showCpdLogo,
	} = cardContent;

	const Element = as || 'article';

	const linkStyles = `inline bg-gradient-to-r [background-size:0_2px] [background-position:0_100%] bg-no-repeat transition-all duration-300 motion-reduce:transition-none before:absolute before:top-0 before:left-0 before:w-full before:h-full focus:[background-size:100%_2px] group-hover:[background-size:100%_2px] focus:outline-none`;

	const fullSlug = cardContent.category?.slug
		? `${cardContent.category?.slug}/${cardContent.slug}`
		: cardContent.slug;

	const Header = () => {
		if (!thumbnail) {
			return (
				<img
					src={logo.url}
					alt={logo.alt || ''}
					width={412}
					className="mx-auto h-[162px]"
				/>
			);
		}
		if (thumbnail) {
			return (
				<div className="relative ">
					<img
						src={logo.url}
						alt="GLU logo"
						className="absolute z-10 left-5 top-5"
						width={88}
						height={64}
					/>
					<GatsbyImage
						image={thumbnail.gatsbyImageData}
						alt={
							thumbnail.alt ||
							'Peninsula HR & Health and Safty Support'
						}
						className="w-full"
					/>
					{showCpdLogo ? (
						<img
							src={CPDLogo}
							alt="CPD logo"
							className="absolute right-5 top-5"
						/>
					) : null}
				</div>
			);
		}
		return <p>header</p>;
	};

	return (
		<Element
			className={clsx(
				'flex flex-col overflow-hidden ',
				'group relative bg-white mb-4 md:mb-0',
				'border-2 border-blue-200 border-solid rounded-sm shadow-card',
				'transition-shadow duration-300 motion-reduce:transition-none',
				'hover:shadow-default-hover focus-within:shadow-default-hover',
				'focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2'
			)}
			aria-labelledby={`${id}-card`}
		>
			<Header />

			<div className="flex flex-col justify-between p-4 pt-8 grow">
				<div className="flex flex-col h-full mb-8">
					<div className="flex gap-3">
						<LikeButton likes={423} courseId={cardContent.id} />
					</div>

					<h3
						className="pt-4 mb-4 overflow-hidden text-xl font-lexend-medium line-clamp-2"
						id={`${id}-card`}
					>
						<Link
							to={
								link ||
								buildLink(locale, `/elearning/${fullSlug}`)
							}
							className={clsx(linkStyles)}
							onClick={() =>
								onClickProp({
									click_text: title,
									click_index: index + 1,
								})
							}
							target="_blank"
						>
							{title}
						</Link>
					</h3>
					{intro ? (
						<p
							className={clsx(
								'overflow-hidden text-base-f font-notosans-light line-clamp-3 mb-4'
							)}
						>
							{intro}
						</p>
					) : null}
					<Button
						variant="White"
						size="Small"
						className="justify-center w-full mt-auto text-blue-400"
					>
						Start Course
					</Button>
				</div>

				<div className="flex items-center justify-between pt-4 border-t border-grey-400">
					{category ? <TagsList category={category.title} /> : null}
					{timeToWatchInMinutes && (
						<p className="text-blue-400">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="19"
								height="19"
								fill="none"
								className="inline-block mr-2"
							>
								<path
									fill="#3954E0"
									fillRule="evenodd"
									d="M9.5 2.997a.527.527 0 0 0-.527.527v5.912c0 .29.236.527.527.527h5.517a.527.527 0 1 0 0-1.055h-4.99V3.524a.527.527 0 0 0-.527-.527Z"
									clipRule="evenodd"
								/>
								<path
									fill="#3954E0"
									fillRule="evenodd"
									d="M9.5.436c-4.966 0-9 4.034-9 9 0 4.965 4.034 9 9 9s9-4.035 9-9c0-4.966-4.034-9-9-9Zm0 1.054a7.94 7.94 0 0 1 7.946 7.946A7.94 7.94 0 0 1 9.5 17.38a7.94 7.94 0 0 1-7.946-7.945A7.94 7.94 0 0 1 9.5 1.49Z"
									clipRule="evenodd"
								/>
							</svg>
							{timeToWatchInMinutes} mins
						</p>
					)}
				</div>
			</div>
		</Element>
	);
};

export { Card };

Card.defaultProps = {
	cardContent: {
		as: 'article',
	},
	index: 1,
	onClickProp: () => {},
	logo: null,
	locale: 'en',
};

Card.propTypes = {
	cardContent: cardContents,
	onClickProp: PropTypes.func,
	index: PropTypes.number,
	logo: PropTypes.string,
	locale: PropTypes.string,
};
