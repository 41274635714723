import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isHeading,
	isParagraph,
	isLink,
	isRoot,
	isList,
} from 'datocms-structured-text-utils';

import { PBSLink } from '../link';
import * as textWithImageStyles from './index.module.css';
import { getLocale, buildLink } from '../../../utils/locale';

const TextWithImage = ({ textWithImageContent, flip }) => {
	const { bioTitle, content, image, className } = textWithImageContent;
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);
	const Heading = 'h2';
	const imageData = getImage(image);

	return (
		<div
			className={clsx(
				'lg:grid lg:grid-cols-[1fr_46%] lg:gap-[60px]',
				className
			)}
		>
			<div className={clsx(flip ? 'order-2' : null)}>
				{bioTitle ? (
					<Heading className="mb-5 text-3xl lg:text-4xl font-lexend-regular">
						{bioTitle}
					</Heading>
				) : null}

				<StructuredText
					data={content}
					customNodeRules={[
						renderNodeRule(isHeading, ({ node, children, key }) => {
							const HeadingTag = `h${node.level}`;
							return (
								<HeadingTag
									className="mb-5 text-2xl lg:text-3xl font-lexend-regular"
									key={key}
								>
									{children}
								</HeadingTag>
							);
						}),
						renderNodeRule(isLink, ({ node, children, key }) => (
							<PBSLink
								to={buildLink(locale, node.url)}
								variant="Link"
								key={key}
							>
								{children}
							</PBSLink>
						)),
						renderNodeRule(isList, ({ node, children, key }) => {
							let ListTag;
							let listClassName;

							if (node.style === 'bulleted') {
								ListTag = 'ul';
								listClassName = 'list-disc';
							}
							if (node.style === 'numbered') {
								ListTag = 'ol';
								listClassName = 'list-decimal';
							}

							return (
								<ListTag
									className={clsx(
										'mb-4 text-lg font-lexend-light md:text-xl list-inside',
										listClassName,
										textWithImageStyles.list
									)}
									key={
										key +
										node.style +
										textWithImageStyles.list
									}
								>
									{children}
								</ListTag>
							);
						}),
						renderNodeRule(
							isParagraph,
							({ children, key, ancestors }) => {
								if (isRoot(ancestors[0])) {
									return (
										<p
											className="mb-4 text-lg font-notosans-light md:text-xl last-of-type:mb-0"
											key={key}
										>
											{children}
										</p>
									);
								}
								return children;
							}
						),
					]}
					customMarkRules={[
						renderMarkRule('strong', ({ children, key }) => (
							<span className="font-lexend-regular" key={key}>
								{children}
							</span>
						)),
					]}
				/>
			</div>
			<div className={clsx('text-center mt-8 lg:mt-0 lg:text-text')}>
				<GatsbyImage
					className={clsx(
						'border-8',
						'border-white',
						'drop-shadow-image',
						'self-start',
						'justify-self-center',
						'md:justify-self-start'
					)}
					image={imageData}
				/>
			</div>
		</div>
	);
};

TextWithImage.defaultProps = {
	textWithImageContent: '',
	flip: false,
};

TextWithImage.propTypes = {
	textWithImageContent: PropTypes.node,
	flip: PropTypes.bool,
};

export { TextWithImage };
