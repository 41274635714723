import { clsx } from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import GoogleSvg from '../../../assets/google-rating.inline.svg';
import Logo from '../../../assets/trustpilot-logo.inline.svg';
import Star from '../../../assets/trustpilot-star.inline.svg';

import { PBSLink } from '../link';

import { getTrustpilotStarLabel } from '../../../utils';
// import { getLocale } from '../../../utils/locale';

const TrustpilotBusinessReview = () => {
	const data = useStaticQuery(graphql`
		query {
			datoCmsTrustpilotRating {
				id

				trustscore {
					id
					originalId
					stars
					trustscore
				}
			}
		}
	`);
	return (
		<div className="flex flex-col justify-center max-w-[235px] text-center shrink-0 w-full">
			<h2 className="mb-2 text-lg font-lexend-medium">
				{getTrustpilotStarLabel(
					data.datoCmsTrustpilotRating.trustscore[0].stars
				)}
			</h2>
			<div
				className="grid grid-cols-[repeat(auto-fit,_40px)] gap-2 justify-center mb-3"
				aria-label={`${data.datoCmsTrustpilotRating.trustscore[0].stars} Trustpilot stars`}
				role="img"
			>
				{[
					...Array(data.datoCmsTrustpilotRating.trustscore[0].stars),
				].map((_, i) => (
					<Star
						// eslint-disable-next-line react/no-array-index-key
						key={`business-unit-star-${i}`}
						aria-hidden="true"
						width={40}
						height={40}
					/>
				))}
			</div>
			<p className="mb-2 font-notosans-regular">
				Based on{' '}
				<PBSLink
					variant="Link"
					className="md:text-base text-brand-blue-400"
					to="https://uk.trustpilot.com/review/peninsulagrouplimited.com"
				>
					{
						data.datoCmsTrustpilotRating.numberOfReview[0]
							.usedForTrustScoreCalculation
					}{' '}
					reviews
				</PBSLink>
			</p>
			<a href="https://uk.trustpilot.com/" aria-label="Trustpilot UK">
				<Logo width={100} className="mx-auto" />
			</a>
		</div>
	);
};

const TrustpilotBusinessReviewHorizontal = ({ rating, className }) => (
	<div
		className={clsx(
			'flex flex-col items-center justify-center -mt-2',
			className
		)}
	>
		<p className="inline-flex items-center gap-1 text-xs text-gray-700 md:text-lg">
			Rated{' '}
			<span className="font-bold">
				{rating?.trustscore[0]?.trustscore}
			</span>
			/ 5 based on <GoogleSvg className="w-6 h-6" /> Google Reviews
		</p>
	</div>
);

TrustpilotBusinessReviewHorizontal.defaultProps = {
	rating: [],
	className: '',
};
TrustpilotBusinessReviewHorizontal.propTypes = {
	rating: PropTypes.string,
	className: PropTypes.string,
};

export { TrustpilotBusinessReview, TrustpilotBusinessReviewHorizontal };
