// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import {
	StructuredText,
	renderNodeRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading, isListItem } from 'datocms-structured-text-utils';
import { PBSLink } from '../../../atoms/link';
import ClockIcon from '../../../../assets/clock.inline.svg';
import CalendarIcon from '../../../../assets/calendar.inline.svg';
import TickIcon from '../../../../assets/tick.inline.svg';
import { buildLink } from '../../../../utils/locale';

const FeaturedSessionCard = ({ featuredCard, pbsWebinar, locale }) => {
	const date = format(new Date(featuredCard.dateAndTime), 'MMM d, yyyy');
	const time = `${format(
		new Date(featuredCard.dateAndTime),
		`h:mm${pbsWebinar ? 'a' : ''}`
	)} ${featuredCard.timeEnd ? `- ${featuredCard.timeEnd}` : ''}`;
	return (
		<div className="flex flex-col justify-between w-full p-10 text-white rounded-sm rounded-t-none lg:rounded-t-sm md:w-1/2 bg-brand-blue-400">
			<div>
				<h3 className="mb-6 text-2xl font-bold font-lexend-regular">
					Upcoming{' '}
					<span className="text-brand-red-400">live session</span>
				</h3>
				<p className="mb-6 text-xl font-light">{featuredCard.title}</p>
				<p className="mb-6 text-xl">
					<CalendarIcon
						className="w-[22px] text-blue-300 inline-block mr-4"
						aria-hidden
					/>
					{date}
				</p>
				<p className="mb-6 text-xl">
					<ClockIcon
						className="w-[22px] text-blue-300 inline-block mr-4"
						aria-hidden
					/>
					{time}
				</p>
				{featuredCard.shortenedContent ? (
					<div className="mb-5">
						<StructuredText
							data={featuredCard.shortenedContent.value}
							customNodeRules={[
								renderNodeRule(
									isHeading,
									({ node, children, key }) => {
										const HeadingTag = `h${node.level}`;
										return (
											<HeadingTag
												className="mb-5 text-2xl font-bold lg:text-3xl font-lexend-regular"
												key={key}
											>
												{children}
											</HeadingTag>
										);
									}
								),
								renderNodeRule(
									isListItem,
									({ children, key }) => (
										<li
											className="flex items-center mt-5 mb-4 text-sm last-of-type:mb-0 font-lexend-light"
											key={key}
										>
											<TickIcon
												className="w-3 h-auto mr-4 text-blue-400 shrink-0"
												aria-hidden="true"
											/>

											{children}
										</li>
									)
								),
							]}
						/>
					</div>
				) : null}
			</div>
			<div>
				<PBSLink
					to={`${
						pbsWebinar
							? buildLink(locale, '/webinars/')
							: buildLink(locale, '/elearning/webinar/')
					}${featuredCard.slug}`}
					className="text-center !text-sm"
					size="Small"
					target="_blank"
				>
					Register your place
				</PBSLink>
			</div>
		</div>
	);
};

export default FeaturedSessionCard;
