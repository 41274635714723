// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { keyframes } from 'styled-components';
import { titleProp, datoGatsbyImageProp } from '../../../types';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import * as headingStyles from './index.module.css';
import { ClientLogosCA } from '../../atoms/client-logos-ca';
import CTAbuttonsCA from '../../atoms/cta-buttons-ca';

const HeroVideoCampaignCA = ({
	heroVideoCampaignCAContent,
	location,
	className,
	modalRef,
	drawerRef,
	useModalForm,
}) => {
	const {
		primaryHeaderText,
		secondaryHeaderText,
		primaryLinkCopy,
		primaryLink,
		primaryLinkOpenForm,
		secondaryLinkCopy,
		secondaryLink,
		primaryImage,
		clientLogosBannerDisplayOnPage,
	} = heroVideoCampaignCAContent;

	const titleText = createHeadingHighlightCA({
		headings: primaryHeaderText.value.document.children[0].children,
		variant: 'blue',
	});

	const fadeIn = keyframes`
	0% {
        opacity: 0;
    }
	40% {
        opacity: 0;
    }
    75% {
        opacity: .75;
    }	
    100% {
        opacity: 1;
    }
  `;

	const BackgroundFadeInDiv = styled.div`
		animation: ${fadeIn} 2s linear;
	`;

	return (
		<div
			aria-label="video-campaign-page-ca"
			className={`h-full relative xs:pb-4 ${className}`}
		>
			<BackgroundFadeInDiv
				id="hero-video-campaign-ca"
				className="absolute top-0 left-0 z-0 h-full w-full"
				style={{
					background:
						'radial-gradient(farthest-corner at -10% -10%, rgba(247,236,183,1) 0%, rgba(246,235,185,1) 7%, rgba(241,232,190,1) 15%, rgba(220,222,234,1) 25%, rgba(228,230,239,1) 30%, rgba(235,236,243,1) 35%, rgba(239,240,245,1) 40%, rgba(244,244,247,1) 45%, rgba(255,254,251,1) 97%, rgba(255,254,251,1) 100%, rgba(255,255,255,1) 100%)',
				}}
			/>
			<div
				aria-label="video-campaign-page-ca"
				className="grid grid-flow-col grid-cols-11 auto-rows-max px-6 pt-10 pb-5 lg:px-12 lg:py-12 md:py-20 lg:py-28 xl:py-12 gap-5 2xl:py-24 relative h-fit mx-auto lg:max-w-m-screen"
			>
				{/* left panel */}
				<div className="flex flex-col items-left justify-center gap-5 col-span-11 lg:col-span-5 z-10 lg:-mt-6 xl:-mt-32 2xl:-mt-20">
					<div
						className={clsx(
							'inline font-lexend-regular xl:mx-0 inline text-4xl md:text-4xl lg:text-[2rem] xl:text-[2.5rem] 2xl:text-[2.75rem]',
							'max-h-2/5 flex items-center xl:leading-[1.1] '
						)}
					>
						{titleText}
					</div>
					<div
						className={clsx(
							'leading-[1.2] block',
							`text-[22px] md:text-2xl font-lexend-regular ${headingStyles.heading}
						leading-[1.2] xl:leading-[1.3]  
						`,
							'max-h-1/5 flex items-center'
						)}
					>
						{secondaryHeaderText}
					</div>
					<CTAbuttonsCA
						primaryLinkCopy={primaryLinkCopy}
						primaryLink={primaryLink}
						primaryLinkOpenForm={primaryLinkOpenForm}
						secondaryLinkCopy={secondaryLinkCopy}
						secondaryLink={secondaryLink}
						modalRef={modalRef}
						useModalForm={useModalForm}
						drawerRef={drawerRef}
					/>
				</div>
				{/* right panel */}
				<div className="flex items-center justify-center hidden lg:block lg:col-span-6 z-10 lg:scale-150 lg:mr-[6.5rem] xl:-mr-30 lg:mt-5 xl:scale-[150%] xl:mt-14 xl:mr-32 xl:mb-28">
					<img
						src={primaryImage?.url}
						placeholder=""
						alt=""
						className={clsx('drop-shadow-image')}
					/>
				</div>
			</div>
			{clientLogosBannerDisplayOnPage && (
				<>
					<ClientLogosCA
						pathname={location.pathname}
						className="-mt-28 -mb-10 lg:-mb-0 lg:-mt-28 xl:-mt-32 xl:-mb-5 2xl:-mt-40 2xl:-mb-5 relative z-0 "
					/>
					<ClientLogosCA
						pathname={location.pathname}
						className="relative -mt-5 -mb-5 block lg:hidden"
						isMobile
					/>
				</>
			)}
		</div>
	);
};

HeroVideoCampaignCA.defaultProps = {
	secondaryHeaderText: '',
};

HeroVideoCampaignCA.propTypes = {
	primaryHeaderText: titleProp.isRequired,
	secondaryHeaderText: PropTypes.string,
	primaryLinkCopy: PropTypes.string.isRequired,
	primaryImage: datoGatsbyImageProp.isRequired,
};

export { HeroVideoCampaignCA };
