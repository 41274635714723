// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { buildLink } from '../../../utils/locale';
import { PBSLink } from '../../atoms/link';

function CtaSection({ ctaSectionContent }) {
	const { ctaTitle, ctaSubHeading, ctaButtons } = ctaSectionContent;

	if (!ctaSectionContent) {
		return null;
	}
	return (
		<section className="px-3 mx-auto max-w-m-screen lg:px-8 mt-20  bg-white">
			<div className="flex flex-col items-center justify-center md:gap-y-10 gap-y-5 bg-white pb-10">
				<div className="lg:w-[60%] w-full">
					<h2 className="md:text-3xl-f text-2xl-f font-lexend-regular text-center ">
						{ctaTitle}
					</h2>
				</div>

				<div className="lg:w-[80%] w-full">
					<p className="font-notosans-light md:text-3xl text-xl text-center">
						{ctaSubHeading}
					</p>
				</div>
				<div className="flex flex-col md:flex-row items-center justify-center gap-3">
					{ctaButtons.map((button) => (
						<PBSLink
							size="Small"
							key={button.id}
							to={buildLink(button.locale, button.link)}
						>
							{button.title}
						</PBSLink>
					))}
				</div>
			</div>
		</section>
	);
}

export default CtaSection;
