import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { parallaxSlideProp } from '../../../types';

const ParallaxSlide = ({ parallaxSlide, index }) => {
	const { title, as, subText, image } = parallaxSlide;
	const Heading = as || 'h3';
	const Alt = image.alt || title || 'Peninsula HR & Health and Safty Support';
	const imageData = getImage(image);
	const subTextArr = subText.split('\n');

	return (
		<div
			className={clsx(
				'md:grid md:gap-4 md:grid-cols-[1fr_1fr]',
				'lg:gap-6',
				index % 2 === 0
					? 'lg:grid-cols-[1fr_fit-content(45%)]'
					: 'lg:grid-cols-[fit-content(45%)_1fr]',
				'xl:gap-[60px]'
			)}
		>
			<div
				className={clsx(index % 2 === 0 ? 'md:order-2' : 'md:order-1')}
				data-parallax-val="-300"
				data-swiper-parallax="-300"
			>
				<GatsbyImage
					className="w-full border-8 border-white drop-shadow-image"
					image={imageData}
					alt={Alt}
				/>
			</div>
			<div
				className={clsx(
					'md-p3',
					index % 2 === 0 ? 'md:order-1' : 'md:order-2 py-32'
				)}
			>
				<Heading
					className="text-3xl mt-lg-f md:mt-0 lg:text-5xl font-lexend-regular"
					data-parallax-val="-300"
					data-swiper-parallax="-300"
				>
					{title}
				</Heading>
				<div
					className="mt-4 text-lg font-notosans-light lg:mt-8 lg:text-xl"
					data-parallax-val="-200"
					data-swiper-parallax="-200"
				>
					{subTextArr.map((paragraph) => (
						<p
							key={paragraph.replace(/[^A-Z0-9]+/gi, '_')}
							className="mb-2 lg:mb-4"
						>
							{paragraph}
						</p>
					))}
				</div>
				<div
					className={clsx(
						'flex flex-col items-center mt-8',
						'md:mt-6 md:items-start',
						'lg:mt-8',
						'xl:items-center xl:flex-row'
					)}
					data-parallax-val="-100"
					data-swiper-parallax="-100"
				/>
			</div>
		</div>
	);
};

ParallaxSlide.defaultProps = {
	index: 1,
};

ParallaxSlide.propTypes = {
	parallaxSlide: parallaxSlideProp.isRequired,
	index: PropTypes.number,
};

export { ParallaxSlide };
