import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HeadingTagLine } from '../heading-tag-line';

const TitlePricingCA = ({
	title,
	subtitle,
	className,
	id = title.replace(/[^A-Z0-9]+/gi, '-'),
}) => (
	<section
		className={clsx('text-center px-3', className)}
		aria-labelledby={id}
	>
		<div className="px-3 md:container md:mx-auto">
			<HeadingTagLine
				id="pricing-calculator-title"
				title={title?.value?.document?.children}
				subText={subtitle}
				headingClassNames="pb-6"
			/>
		</div>
	</section>
);
export { TitlePricingCA };

TitlePricingCA.defaultProps = {
	id: 'title-component',
	className: '',
	subtitle: '',
	children: '',
};

TitlePricingCA.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
};
