import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Button } from '../button';

import * as hamburgerStyles from './index.module.css';

const Hamburger = ({
	label,
	activeLabel,
	labelVisibleMobile,
	labelVisibleDesktop,
	width,
	isOpen,
	onClick,
	className,
}) => (
	<Button
		className={clsx(
			isOpen ? hamburgerStyles.active : undefined,
			'inline-flex',
			'items-center',
			'overflow-hidden',
			'font-lexend-medium',
			className
		)}
		onClick={() => onClick()}
		aria-pressed={isOpen}
		aria-label={labelVisibleMobile || labelVisibleDesktop ? null : label}
		variant="Unset"
	>
		{labelVisibleDesktop ? (
			<span className="inline-flex mr-2 max-md:hidden">
				{isOpen ? activeLabel : label}
			</span>
		) : null}
		{labelVisibleMobile ? (
			<span className="inline-flex mr-2 md:hidden">
				{isOpen ? activeLabel : label}
			</span>
		) : null}
		<svg
			className={hamburgerStyles.ham}
			viewBox="20 20 60 60"
			width={width}
		>
			<path
				className={`${hamburgerStyles.line} ${hamburgerStyles.top}`}
				d="m 70,36 h -40 c 0,0 -7,-0.149796 -7,7 0,7.149796 7,7 7,7 h 20 v -20"
			/>
			<path className={hamburgerStyles.line} d="m 70,50 h -40" />
			<path
				className={`${hamburgerStyles.line} ${hamburgerStyles.bottom}`}
				d="m 30,64 h 40 c 0,0 7,0.149796 7,-7 0,-7.149796 -7,-7 -7,-7 h -20 v 20"
			/>
		</svg>
	</Button>
);

Hamburger.defaultProps = {
	label: 'Close',
	activeLabel: 'Close',
	labelVisibleMobile: true,
	labelVisibleDesktop: true,
	width: 40,
	isOpen: false,
	onClick: () => {},
	className: '',
};

Hamburger.propTypes = {
	label: PropTypes.string,
	activeLabel: PropTypes.string,
	labelVisibleMobile: PropTypes.bool,
	labelVisibleDesktop: PropTypes.bool,
	width: PropTypes.number,
	isOpen: PropTypes.bool,
	onClick: PropTypes.func,
	className: PropTypes.string,
};

export { Hamburger };
