import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { sendTrackingData } from '../../../utils';

const MegaMenuLink = ({
	as,
	to,
	title,
	text,
	icon,
	parentTrackingId,
	...linkAttributes
}) => {
	let Component = as;
	let iconSrc;
	let props;

	const id = `${title.replace(/[^A-Z0-9]/gi, '')}SubText`;

	// If someone puts in an external link switch to using <a></a>
	if (to.indexOf('http') === 0) {
		Component = 'a';
	} else if (!to.endsWith('/')) {
		// eslint-disable-next-line no-param-reassign
		to = `${to}/`;
	}

	if (icon) {
		iconSrc =
			// eslint-disable-next-line import/no-dynamic-require, global-require
			require(`!!file-loader!../../../assets/${icon}.inline.svg`).default;
	}

	if (text) {
		props = {
			'aria-describedby': id,
		};
	}

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'navigation',
			click_action: 'navigate',
			click_type: 'link',
			click_text: parentTrackingId,
			click_title: title,
		});
	};

	return (
		<div
			className={clsx(
				'group',
				'relative',
				'inline-flex justify-center',
				'p-4',
				'font-lexend-regular',
				'rounded-xs',
				'hover:bg-brand-pale-400'
			)}
		>
			{iconSrc ? (
				<div
					className="mr-4 text-blue-300 group-hover:text-blue-400"
					aria-hidden
				>
					<SVG src={iconSrc} width={24} height={24} />
				</div>
			) : null}
			<div className="flex flex-col">
				<Component
					to={typeof Component !== 'string' ? to : undefined}
					href={typeof Component === 'string' ? to : undefined}
					onClick={handleClickTracking}
					className={clsx(
						'before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0',
						'before:w-full before:h-full',
						'before:rounded-xs',
						'focus:outline-none',
						'focus:before:outline-0 focus:before:shadow-focus focus:before:text-blue-400'
					)}
					{...props}
					{...linkAttributes}
				>
					<span
						className={clsx(
							'text-xl text-brand-blue-400 group-hover:text-blue-400'
						)}
					>
						{title}
					</span>
				</Component>
				{text ? (
					<p
						id={id}
						className={clsx(
							'text-blue-500 text-[13px] group-hover:text-brand-blue-400 mt-2'
						)}
					>
						{text}
					</p>
				) : null}
			</div>
		</div>
	);
};

MegaMenuLink.defaultProps = {
	as: Link,
	to: '',
	text: '',
	icon: '',
	parentTrackingId: '',
};

MegaMenuLink.propTypes = {
	as: PropTypes.oneOf([Link, 'a']),
	to: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	icon: PropTypes.string,
	parentTrackingId: PropTypes.string,
};

export { MegaMenuLink };
