// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */

import React from 'react';
import { vapCalculatedProp, refProp } from '../../../types';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';

const vapFooterText =
	'The values presented in this value calculator are based on first-party data from over 6,500 businesses in Canada and over 100,000 requests for HR or health and safety advice.';

const VapCard = ({
	priceCalculated,
	drawerRef,
	unSelectedVapItems,
	selectedVapItems,
	unSelectedVapItemsInitialState,
}) => {
	const { setDrawerRef } = useDrawer();
	return (
		<>
			<section className="mb-20 relative">
				{/* Sticky header CA adjusted vapcard anchor */}
				<span id="vapcard" className="invisible absolute -mt-36" />
				<div
					className="focus-within:outline-blue-400/[0.20] border-2 border-blue-300 p-6 md:p-12 lg:p-12 rounded-sm lg:mt-4 grid grid-cols-1 lg:grid-cols-2 lg:gap-12"
					style={{
						backgroundImage: `linear-gradient(180deg, rgba(215,221,249,1) 0%, rgba(199,207,247,1) 50%, rgba(156,170,240,1) 100%)`,
					}}
				>
					<div className="col-1 bg-brand-pale-400 focus-within:outline-blue-400/[0.20]  border-1 border-blue-300 px-s-f py-base-f rounded-sm h-fit shadow-xl xs:mb-5 lg:mb-0">
						<div className="text-2xl my-3 font-black">
							Your total cost breakdown
						</div>
						{selectedVapItems.map((elm, idx, arr) => (
							<>
								<div
									className={`${
										unSelectedVapItems.length !== 0 ||
										(selectedVapItems.length ===
											unSelectedVapItemsInitialState.length &&
											idx + 1 !== arr.length)
											? 'border-b '
											: ''
									}flex flex-col justify-between py-8 lg:py-3 lg:flex-row border-[#1F2E7A] font-bold`}
								>
									<div className="flex flex-row items-center">
										<div className="font-lexend-regular shrink-0 text-[#1D2951] text-[20px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px] xs:w-[250px]">
											{elm.label}
										</div>
									</div>
									<div className="font-lexend-regular flex items-center pt-5 lg:pt-0 shrink-0 text-[#3954E0] font-normal text-[20px] lg:text-[20px] xl:text-[22px] font-black">
										{priceCalculated[`${elm.name}`]}
									</div>
								</div>
							</>
						))}
						{unSelectedVapItems.map((elm, idx, arr) => (
							<>
								<div
									className={`${
										idx + 1 !== arr.length
											? 'border-b '
											: ''
									}flex flex-col justify-between py-8 lg:py-3 lg:flex-row border-[#1F2E7A]`}
								>
									<div className="flex flex-row items-center">
										<div className="font-lexend-regular shrink-0 text-[#1D2951] text-[20px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px] xs:w-[250px]">
											{elm.label}
										</div>
									</div>
									<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-[#3954E0] font-normal text-[20px] lg:text-[20px] xl:text-[22px]">
										{priceCalculated[`${elm.name}`]}
									</div>
								</div>
							</>
						))}
					</div>

					<div className="sm:mt-5 lg:mt-0">
						<div className="col-2 bg-[#E30138] focus-within:outline-blue-400/[0.20] border-1 border-blue-300 px-s-f py-base-f rounded-sm h-fit shadow-xl">
							<div className="flex flex-col justify-between py-8 lg:py-3 lg:flex-row border-[#1F2E7A]">
								<div className="flex flex-row items-center">
									<div className="shrink-1 text-[24px] md:text-[26px] font-semibold leading-[32px]">
										<p className="text-white w-[250px]">
											Annual cost to your business
										</p>
									</div>
								</div>
								<div className="flex items-center text-white  pt-5 lg:pt-0 shrink-0 text-[#3954E0] font-semibold text-[24px] md:text-[30px] lg:text-[32px] leading-[32px]">
									{priceCalculated.total_cost}
								</div>
							</div>
						</div>

						<div className="my-5 col-2 bg-brand-pale-400 focus-within:outline-blue-400/[0.20] border-1 border-blue-300 px-s-f py-base-f rounded-sm h-fit shadow-xl">
							<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
								<div className="flex flex-row items-center">
									<div className="shrink-1 text-[#1D2951] text-[24px] md:text-[26px] font-semibold leading-[32px]">
										<p className="hidden lg:block">
											Annual cost of <br />
											Peninsula service
										</p>
										<p className="lg:hidden w-[200px]">
											Annual cost of Peninsula service
										</p>
									</div>
								</div>
								<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-[#3954E0] font-semibold text-[24px] md:text-[30px] lg:text-[32px] leading-[32px]">
									{priceCalculated.annual_cost}
								</div>
							</div>
							<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
								<div className="flex flex-row items-center">
									<div className="shrink-0 text-[#1D2951] text-[24px] md:text-[26px] font-semibold leading-[32px] sm:w-xs">
										Return on Investment
									</div>
								</div>
								<div className="lg:text-right text-[#3954E0] ">
									<h3 className="lg:pl-28 pt-4 lg:pt-0 text-[24px] md:text-[30px] font-semibold lg:text-[32px]">
										{priceCalculated.annual_roi}
									</h3>
									<p className="text-[20px] lg:text-[15px] xl:text-[20px]">
										for every $1 spent
									</p>
								</div>
							</div>
							<div className="flex flex-col justify-between py-8 lg:py-3 lg:flex-row">
								<div className="flex flex-row items-center">
									<div className="shrink-0 text-[#1D2951] text-[24px] md:text-[26px] font-semibold leading-[32px]">
										Time saved
									</div>
								</div>
								<div className="lg:text-right text-[#3954E0]">
									<h3 className="lg:pl-28 pt-4 lg:pt-0 text-[25px] md:text-[30px] font-semibold lg:text-[32px]">
										{priceCalculated.time_saved}
									</h3>
									<p className="text-[20px] lg:text-[15px] xl:text-[20px]">
										hours of your time
									</p>
								</div>
							</div>
							<div className="text-center mt-6 ">
								<p className="">
									<Button
										onClick={() => setDrawerRef(drawerRef)}
										className="hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-lexend-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-lexend-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
									>
										Save today
									</Button>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-5 text-black text-xl">{vapFooterText}</div>
			</section>
		</>
	);
};

VapCard.defaultProps = {
	priceCalculated: {
		lateness_absence: '-',
		staff_turnover: '-',
		hr_docs: '-',
		hs_docs: '-',
		disciplinary: '-',
		ohs_fines: '-',
		general_hr: '-',
		cost_accidents: '-',
		average_wrongful: '-',
		cost_hr_manager: '-',
		cost_hs_manager: '-',
		total_cost: '-',
		annual_cost: '-',
		cost_per_employee: '-',
		annual_roi: '-',
		time_saved: '-',
	},
};

VapCard.propTypes = {
	priceCalculated: vapCalculatedProp,
	drawerRef: refProp.isRequired,
};

export { VapCard };
