/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-param-reassign */
export const splitFullName = (fullName) => {
	// Trim leading and trailing spaces
	fullName = fullName.trim();

	// Split the name into parts based on spaces
	const nameParts = fullName.split(/\s+/);
	const firstName = nameParts[0];
	const lastName =
		nameParts.length > 1 ? nameParts.slice(1).join(' ') : 'N/A';

	return { firstName, lastName };
};
