// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { ClientLogos } from '../components/atoms/client-logos';
import { Drawer } from '../components/atoms/drawer';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { FAQ } from '../components/organisms/faq-new';
import { SuccessForm } from '../components/organisms/success-form';
import { TestimonialsFAQ } from '../components/organisms/testimonials-faq';
import { Head } from '../components/templates/head';
import { useDrawer } from '../contexts/drawer-context';
import { DefaultLayout } from '../layouts/default/faq';
import { DefaultLayout as DefaultLayoutPpcV2 } from '../layouts/default/ppc-v2-new';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

const FaqPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const [categoryParam, setCategoryParam] = useState(null);
	const [ppcHeaderIndex, setPpcHeaderIndex] = useState(-1);

	// Check if the code is running in the browser
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(window.location.search);
			const category = urlParams.get('category');
			if (category) {
				// Clean category value by removing slashes
				setCategoryParam(category.replace(/^\/+|\/+$/g, ''));
			}
		}
	}, []);

	// Update ppcHeaderIndex when categoryParam changes
	useEffect(() => {
		if (categoryParam) {
			const ppcHeaderArr = data?.allDatoCmsPpcPage?.nodes || [];
			const index = ppcHeaderArr.findIndex(
				(elm) => elm?.siteNavigation[0]?.category === categoryParam
			);
			setPpcHeaderIndex(index);
		}
	}, [categoryParam, data?.allDatoCmsPpcPage?.nodes]);

	const metaContent = {
		noIndex: data.datoCmsFaqPage.noIndex,
		noFollow: data.datoCmsFaqPage.noFollow,
		metaInformation: data.datoCmsFaqPage.metaInformation,
		fallbackTitle: data.datoCmsFaqPage.title,
		fallbackDescription: data.datoCmsFaqPage.longIntroduction,
		canonicalUrl: data.datoCmsFaqPage?.canonicalUrl || location.href,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.faq,
			location,
		}),
	};

	const faqContent = {
		title: data.datoCmsFaqPage.faqContainer.title,
		subText: data.datoCmsFaqPage.faqContainer.subText,
		questions: data.datoCmsFaqPage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const testimonialsContent = {
		...data.datoCmsFaqPage.testimonials,
		title: data.datoCmsFaqPage.testimonials.title.value.document.children,
		slides: data.datoCmsFaqPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const adviceBannerContent = {
		...data.datoCmsFaqPage.adviceBanner,
		title: data.datoCmsFaqPage.adviceBanner.title.value.document.children,
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsFaqPage.internal.type,
		data.datoCmsFaqPage
	);
	const { locale } = data.datoCmsFaqPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			{ppcHeaderIndex === -1 && (
				<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			)}
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<FAQ faqContent={faqContent} />
			<ClientLogos pathname={location.pathname} />
			<TestimonialsFAQ
				drawerRef={adviceFormRef}
				testimonialsContent={testimonialsContent}
			/>
			{/* {locale !== 'en-CA' ? <FeefoTestimonials locale={locale} /> : null} */}
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>
			<TrustpilotReviews
				locale={locale}
				className="pt-12 !pb-0"
				wrapperClassName="pb-12 border-b-1 border-b-blue-200"
			/>

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedFaqPage = ({ location, data }) => {
	const { locale } = data.datoCmsFaqPage;
	const [categoryParam, setCategoryParam] = useState(null);
	const [ppcHeaderIndex, setPpcHeaderIndex] = useState(-1);

	// Check if the code is running in the browser
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(window.location.search);
			const category = urlParams.get('category');
			if (category) {
				// Clean category value by removing slashes
				setCategoryParam(category.replace(/^\/+|\/+$/g, ''));
			}
		}
	}, []);

	// Update ppcHeaderIndex when categoryParam changes
	useEffect(() => {
		if (categoryParam) {
			const ppcHeaderArr = data?.allDatoCmsPpcPage?.nodes || [];
			const index = ppcHeaderArr.findIndex(
				(elm) => elm?.siteNavigation[0]?.category === categoryParam
			);
			setPpcHeaderIndex(index);
		}
	}, [categoryParam, data?.allDatoCmsPpcPage?.nodes]);

	// Conditional rendering based on locale and ppcHeaderIndex
	return locale === 'en-CA' && ppcHeaderIndex !== -1 ? (
		<DefaultLayoutPpcV2
			siteSettings={data.siteSettings}
			navigation={
				data?.allDatoCmsPpcPage?.nodes[ppcHeaderIndex]
					?.siteNavigation[0]
			}
			footerData={data.footerItems}
			pageName="PeninsulaNow"
		>
			<FaqPage location={location} data={data} />
		</DefaultLayoutPpcV2>
	) : (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			pageName="PeninsulaNow"
		>
			<FaqPage location={location} data={data} />
		</DefaultLayout>
	);
};

export default WrappedFaqPage;

export const query = graphql`
	query aboutUs($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsFaqPage(locale: { eq: $locale }) {
			locale
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			originalId
			internal {
				type
			}

			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
		}
		allDatoCmsPpcPage(filter: { locale: { eq: $locale } }) {
			nodes {
				siteNavigation {
					title
					category
					homeLink
					headerNavigationItems {
						title
						link
						treeChildren {
							title
							link
							position
							icon
						}
					}
					footerNavigationItems {
						title
						link
					}
				}
			}
		}
	}
`;
