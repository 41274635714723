// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { DefaultLayout } from '../layouts/default';
import { Head } from '../components/templates/head';
// import { HeroBrightHR } from '../components/molecules/hero-bright-hr';
import { MainHeaderBusinessSize } from '../components/molecules/main-header-business-size';
import { Testimonials } from '../components/organisms/testimonials';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container-business-size';
import { pageTypes } from '../utils/url-helpers';
import { ClientLogosCA } from '../components/atoms/client-logos-ca';
import { IconCardsBusinessSize } from '../components/organisms/icon-cards-business-size';
import { Drawer } from '../components/atoms/drawer';
import { useDrawer } from '../contexts/drawer-context';
import { BlueBannerText } from '../components/molecules/blue-banner-text';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';

import { HrefLang } from '../components/templates/hrefLang';

// markup
const BusinessSizePage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsBusinessSizePage.noIndex,
		noFollow: data.datoCmsBusinessSizePage.noFollow,
		metaInformation: data.datoCmsBusinessSizePage.metaInformation,
		fallbackTitle: data.datoCmsBusinessSizePage.title,
		fallbackDescription: data.datoCmsBusinessSizePage.longIntroduction,
		canonicalUrl:
			data.datoCmsBusinessSizePage?.canonicalUrl || location.href,
	};

	const textWithImageContainer = {
		...data.datoCmsBusinessSizePage.textWithImageContainer,
		firsttitle:
			data.datoCmsBusinessSizePage.textWithImageContainer?.firstTitle
				?.value.document.children,
		secondtitle:
			data.datoCmsBusinessSizePage.textWithImageContainer?.secondTitle
				.value.document.children,
		firstContent:
			data.datoCmsBusinessSizePage.textWithImageContainer?.firstContent,
		secondContent:
			data.datoCmsBusinessSizePage.textWithImageContainer?.secondContent,
	};

	const textWithImageContainerTwo = {
		...data.datoCmsBusinessSizePage.textWithImageContainerTwo,
		firsttitle:
			data.datoCmsBusinessSizePage.textWithImageContainerTwo?.firstTitle
				?.value.document.children,
		secondtitle:
			data.datoCmsBusinessSizePage.textWithImageContainerTwo?.secondTitle
				.value.document.children,
		firstContent:
			data.datoCmsBusinessSizePage.textWithImageContainerTwo
				?.firstContent,
		secondContent:
			data.datoCmsBusinessSizePage.textWithImageContainerTwo
				?.secondContent,
	};

	const headerCTAContent = {
		...data.datoCmsBusinessSizePage.mainHeader,
		header: data.datoCmsBusinessSizePage.mainHeader.header.value.document
			.children,
		googleReview: data.siteSettings.googleReview,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsBusinessSizePage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsBusinessSizePage?.customFormField[0]
				?.customFormSubtitle,
		customFormButton:
			data.datoCmsBusinessSizePage?.customFormField[0]?.customFormButton,
	};

	const testimonialsContent = {
		...data.datoCmsBusinessSizePage.testimonials,
		title: data.datoCmsBusinessSizePage.testimonials.title.value.document
			.children,
		slides: data.datoCmsBusinessSizePage.testimonials.slides.map(
			(slide) => ({
				...slide,
				tags: slide.tags.split(','),
			})
		),
	};

	const blueBannerContent = {
		textContent: data.datoCmsBusinessSizePage.blueBannerStructuredText,
		primaryLink: data.datoCmsBusinessSizePage.blueBannerPrimaryLink,
		primaryLinkOpenForm:
			data.datoCmsBusinessSizePage.blueBannerPrimaryLinkOpenForm,
		primaryLinkCopy: data.datoCmsBusinessSizePage.blueBannerPrimaryLinkCopy,
	};

	const { uspCardsBusinessSize } = data.datoCmsBusinessSizePage;

	const uspCardLinks = {
		displayOnPage:
			data.datoCmsBusinessSizePage.uspCardsBusinessSizeDisplayOnPage,
		primaryLink:
			data.datoCmsBusinessSizePage.uspCardsBusinessSizePrimaryLink,
		primaryLinkCopy:
			data.datoCmsBusinessSizePage.uspCardsBusinessSizePrimaryLinkCopy,
		primaryLinkOpenForm:
			data.datoCmsBusinessSizePage.uspCardsPrimaryLinkOpenForm,
	};

	const uspCardsTitle =
		data.datoCmsBusinessSizePage.uspCardsBusinessSizeTitle.value.document
			.children;

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsBusinessSizePage,
		}),
		image: getImageSeoSchema(
			data.datoCmsBusinessSizePage?.mainHeader?.primaryImage
		),
	};

	const locale = data.datoCmsBusinessSizePage;

	// classnames in charge of spacing for TextWithImageContainer
	const className = '';
	const className2 = '';

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsBusinessSizePage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<MainHeaderBusinessSize
				urlLocale={locale}
				drawerRef={adviceFormRef}
				{...headerCTAContent}
				reference={reference}
				response={response}
				setReference={setReference}
				setResponse={setResponse}
			/>
			{/* For larger screens or desktop */}
			{data.datoCmsBusinessSizePage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA pathname={location.pathname} />
			)}
			{/* For mobile devices */}
			{data.datoCmsBusinessSizePage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA pathname={location.pathname} isMobile />
			)}
			{data.datoCmsBusinessSizePage.blueBannerDisplayOnPage && (
				<BlueBannerText
					content={blueBannerContent}
					drawerRef={adviceFormRef}
				/>
			)}
			{data.datoCmsBusinessSizePage
				.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
					drawerRef={adviceFormRef}
					className={className}
				/>
			)}
			{data.datoCmsBusinessSizePage.uspCardsBusinessSizeDisplayOnPage && (
				<IconCardsBusinessSize
					iconCardsContent={{
						uspCards: uspCardsBusinessSize,
					}}
					uspTitle={uspCardsTitle}
					drawerRef={adviceFormRef}
					links={uspCardLinks}
				/>
			)}
			{data.datoCmsBusinessSizePage
				.textWithImageContainerTwoDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainerTwo}
					drawerRef={adviceFormRef}
					className={className2}
				/>
			)}
			<Testimonials
				drawerRef={adviceFormRef}
				testimonialsContent={testimonialsContent}
			/>
			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceFormCA
						setReference={setReference}
						setResponse={setResponse}
						customFormFieldContent={customFormField}
					/>
				) : (
					<SuccessFormCA reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query businessSize($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
			googleReview {
				alt
				url
			}
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsBusinessSizePage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			clientLogosBannerDisplayOnPage
			textWithImageContainerDisplayOnPage
			textWithImageContainerTwoDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			blueBannerPrimaryLinkCopy
			blueBannerPrimaryLink
			blueBannerPrimaryLinkOpenForm
			blueBannerDisplayOnPage
			blueBannerStructuredText {
				value
			}
			textWithImageContainer {
				id
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				firstButtonCopy
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondButtonCopy
				secondDisplayCtaOnPage
			}
			textWithImageContainerTwo {
				id
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				firstButtonCopy
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondButtonCopy
				secondDisplayCtaOnPage
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			uspCardsBusinessSizeTitle {
				value
			}
			uspCardsBusinessSizeDisplayOnPage
			uspCardsBusinessSizePrimaryLinkCopy
			uspCardsPrimaryLinkOpenForm
			uspCardsBusinessSizePrimaryLink
			uspCardsBusinessSize {
				title
				content
				linkCopy
				link
				icon
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
	}
`;

const WrappedBusinessSizePage = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="Peninsula Now"
	>
		<BusinessSizePage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedBusinessSizePage;
