import React from 'react';
import PropTypes from 'prop-types';
import { TextWithImage } from '../../atoms/text-with-image-bdm';
import { HeadingTagLine } from '../../atoms/heading-tag-line-bdm';
import { HeadingTagLine2 } from '../../atoms/heading-tag-line-bdm2';
import { HeadingTagLine3 } from '../../atoms/heading-tag-line-bdm3';

import { createHeadingHighlight } from '../../../utils/heading-highlight';
import * as headingStyles from './index.module.css';

import {
	datoGatsbyImageProp,
	titleProp,
	structuredTextContent,
	refProp,
} from '../../../types';

const TextWithImageContainer = ({
	textWithImageContainerContent,
	drawerRef,
}) => {
	const { id } = textWithImageContainerContent;
	let textWithImages = [textWithImageContainerContent];

	const header = textWithImageContainerContent?.header;
	const headerText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});

	if (
		!Object.prototype.hasOwnProperty.call(
			textWithImageContainerContent,
			'content'
		)
	) {
		const {
			firsttitle,
			firstContent,
			firstImage,
			firstButtonCopy,
			firstButtonLink,
			firstDisplayCtaOnPage,
			secondtitle,
			secondContent,
			secondImage,
			secondButtonCopy,
			secondButtonLink,
			secondDisplayCtaOnPage,
			thirdtitle,
			thirdContent,
			thirdImage,
			thirdButtonCopy,
			thirdButtonLink,
			thirdDisplayCtaOnPage,
		} = textWithImageContainerContent;

		const elementOne = {
			titleProps: {
				id,
				firsttitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine,
			content: firstContent,
			image: firstImage,
			firstButtonCopy,
			firstButtonLink,
			firstDisplayCtaOnPage,
			className: 'items-center mb-8 lg:mb-2xl-f bg-red-200',
		};

		const elementTwo = {
			titleProps2: {
				id,
				secondtitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine2,
			content: secondContent,
			image: secondImage,
			secondButtonCopy,
			secondButtonLink,
			secondDisplayCtaOnPage,
			className: 'items-center mb-8 lg:mb-2xl-f bg-red-200',
		};

		const elementThree = {
			titleProps3: {
				id,
				thirdtitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine3,
			content: thirdContent,
			image: thirdImage,
			thirdButtonCopy,
			thirdButtonLink,
			thirdDisplayCtaOnPage,
			className: 'items-center',
		};

		textWithImages = [elementOne, elementTwo, elementThree];
	}

	return (
		<section className="py-10 md:py-16">
			<div
				className="px-4 mx-auto md:px-8 max-w-m-screen "
				aria-labelledby={id}
			>
				<h2 className="mb-10 mr-3 text-4xl text-center lg:mr-0 lg:text-4xl font-lexend-regular">
					{headerText}
				</h2>

				{textWithImages.map((elContent, index) => {
					let flipSecond = false;
					if (textWithImages.length > 1) {
						flipSecond = true;
					}

					return (
						<TextWithImage
							// eslint-disable-next-line react/no-array-index-key
							key={`${id}${index}`}
							textWithImageContent={elContent}
							drawerRef={drawerRef}
							{...(flipSecond && index === 1
								? { flip: true }
								: null)}
						/>
					);
				})}
			</div>
		</section>
	);
};

TextWithImageContainer.propTypes = {
	textWithImageContainerContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firsttitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		secondtitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		thirdtitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		header: PropTypes.string,
		firstButtonCopy: PropTypes.string,
		secondButtonCopy: PropTypes.string,
		thirdButtonCopy: PropTypes.string,
		firstButtonLink: PropTypes.string,
		secondButtonLink: PropTypes.string,
		thirdButtonLink: PropTypes.string,
		buttonLink: PropTypes.string,
		content: structuredTextContent,
		firstContent: structuredTextContent,
		secondContent: structuredTextContent,
		thirdContent: structuredTextContent,
		image: datoGatsbyImageProp,
		firstImage: datoGatsbyImageProp,
		secondImage: datoGatsbyImageProp,
		thirdImage: datoGatsbyImageProp,
		firstDisplayCtaOnPage: PropTypes.bool,
		secondDisplayCtaOnPage: PropTypes.bool,
		thirdDisplayCtaOnPage: PropTypes.bool,
	}).isRequired,
	drawerRef: refProp.isRequired,
};

export { TextWithImageContainer };
