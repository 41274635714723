import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../button';
import { SidebarHeader } from '../sidebar-header';

import { useDrawer } from '../../../contexts/drawer-context';
import { sendTrackingData } from '../../../utils';
import { CallUs } from '../call-us-download';

const DownloadSuccessCA = ({ page }) => {
	const { setDrawerRef } = useDrawer();

	const heading =
		'Thank you for telling us your details, you will receive an email containing your free download shortly.';
	const subHeading = 'Before you go...';
	const content =
		'Did you know you can get a free advice call from Peninsula over the phone? It’s the easy way to solve your HR and health & safety problems.';
	const returnButtonText =
		page === 'safecheck' ? 'Go back' : 'Return to download';

	const handleClickTracking = (clickText) => {
		const gtmStartedEvent = window?.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'download-form',
			click_type: 'primary',
			click_title: heading,
			click_text: clickText,
		});
	};

	return (
		<div className="flex flex-col h-full max-h-screen">
			<SidebarHeader heading={heading} ariaLive="assertive" />
			<div className="flex flex-col justify-between h-full px-4 pt-8 pb-14">
				<div>
					{page !== 'safecheck' && (
						<>
							<h3 className="mb-4 text-lg font-lexend-medium md:text-xl">
								{subHeading}
							</h3>
							<p className="text-lg md:text-xl">{content}</p>
							<hr className="border-blue-200 my-7 md:my-10" />
						</>
					)}
					<p className="flex items-center mb-3 text-lg font-notosans-medium md:text-xl">
						Call us on{' '}
						<CallUs
							altMobile
							className="ml-2" // Add margin for spacing between "Call us on" and CallUs
							onClickProp={(trackData) =>
								handleClickTracking({
									click_type: 'tel',
									...trackData,
								})
							}
						/>
					</p>
				</div>
				<Button
					variant="White"
					className="mt-8 w-max"
					onClick={() => {
						setDrawerRef('');
						handleClickTracking(returnButtonText);
					}}
				>
					{returnButtonText}
				</Button>
			</div>
		</div>
	);
};

export { DownloadSuccessCA };

DownloadSuccessCA.defaultProps = {
	page: 'safecheck',
};

DownloadSuccessCA.propTypes = {
	page: PropTypes.string,
};
