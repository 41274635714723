import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { sendTrackingData } from '../../../utils';

import ChevronIcon from '../../../assets/chevron.inline.svg';

const NavLink = forwardRef(
	(
		{ as, to, icon, className, children, hasMegaMenu, ...linkAttributes },
		ref
	) => {
		let Component = as;
		let iconSrc;

		// If someone puts in an external link switch to using <a></a>
		if (to?.indexOf('http') === 0) {
			Component = 'a';
		} else if (!to?.endsWith('/')) {
			// eslint-disable-next-line no-param-reassign
			to = `${to}/`;
		}

		if (icon) {
			iconSrc =
				// eslint-disable-next-line import/no-dynamic-require, global-require
				require(`!!file-loader!../../../assets/${icon}.inline.svg`).default;
		}

		const handleClickTracking = () => {
			const gtmStartedEvent = window.dataLayer?.find(
				(element) => element['gtm.start']
			);
			if (!gtmStartedEvent) return;

			sendTrackingData('click', {
				event_name: 'navigation',
				click_action: 'navigate',
				click_type: 'link',
				click_title: 'Menu',
				click_text: children,
			});
		};

		return (
			<Component
				ref={ref}
				className={clsx(
					'group',
					'px-4',
					'py-2',
					'font-lexend-regular',
					'rounded-xs',
					'z-10',
					'inline-flex items-center',
					'hover:bg-brand-pale-400 hover:text-blue-400',
					'focus:outline-0 focus:shadow-focus focus:text-blue-400',
					className
				)}
				to={typeof Component !== 'string' ? to : undefined}
				href={typeof Component === 'string' ? to : undefined}
				onClick={handleClickTracking}
				{...linkAttributes}
			>
				{iconSrc ? (
					<SVG
						src={iconSrc}
						className="mr-4 text-blue-400"
						aria-hidden="true"
						width={24}
						height={24}
					/>
				) : null}
				{children}{' '}
				{hasMegaMenu ? (
					<ChevronIcon
						aria-hidden="true"
						className={clsx(
							'rotate-180',
							'w-[0.9rem]',
							'h-[0.45rem]',
							'lg:w-[0.625rem]',
							'lg:h-[0.313rem]',
							'ml-2',
							linkAttributes['aria-expanded'] ? 'rotate-0' : null
						)}
					/>
				) : null}
			</Component>
		);
	}
);

NavLink.defaultProps = {
	as: Link,
	to: '',
	className: '',
	hasMegaMenu: false,
	icon: '',
};

NavLink.propTypes = {
	as: PropTypes.oneOf([Link, 'a', 'button']),
	to: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	hasMegaMenu: PropTypes.bool,
	icon: PropTypes.string,
};

export { NavLink };
