import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

const HoverImageCardReferral = ({ card }) => {
	const stepsRef = React.useRef(null);
	const [activeStep, setActiveStep] = React.useState(null);
	const changeBgImage = (i) => {
		switch (i) {
			case 0:
				setActiveStep(
					<StaticImage
						alt="referal-step-1"
						placeholder="none"
						src="../../../assets/how-it-works-step-1.png"
						objectFit="contain"
						style={{
							width: '100%',
							height: '100%',
						}}
					/>
				);
				break;
			case 1:
				setActiveStep(
					<StaticImage
						alt="referal-step-2"
						placeholder="none"
						src="../../../assets/how-it-works-step-2.png"
						objectFit="contain"
						style={{
							width: '100%',
							height: '100%',
						}}
					/>
				);
				break;
			case 2:
				setActiveStep(
					<StaticImage
						alt="referal-step-3"
						placeholder="none"
						src="../../../assets/how-it-works-step-3.png"
						objectFit="contain"
						style={{
							width: '100%',
							height: '100%',
						}}
					/>
				);
				break;
			default:
				setActiveStep(
					<StaticImage
						alt="referal-step-1"
						placeholder="none"
						src="../../../assets/how-it-works-step-1.png"
						objectFit="contain"
						style={{
							width: '100%',
							height: '100%',
						}}
					/>
				);
				break;
		}
	};
	const Component = 'div';
	return (
		<div className="max-w-m-screen mx-auto flex flex-col items-center justify-center flex-grow h-auto mt-16">
			<h2 className="2xl:text-4xl text-3xl font-lexend-regular mb-10">
				{card.heading}
			</h2>
			<div className="grid md:grid-cols-2 grid-cols-1 2xl:gap-4 w-full mb-3 ">
				<div>
					{card.referralCards.map((referral, i) => (
						<div
							key={referral.id}
							ref={stepsRef}
							className="flex flex-col md:flex-row md:gap-4 2xl:gap-8 w-full"
						>
							<Component
								onMouseOver={() => changeBgImage(i)}
								className=" bg-white flex-col space-y-1 2xl:p-7 p-5 md:hover:cursor-pointer md:hover:drop-shadow-2xl 2xl:w-[86%] w-full 2xl:hover:border-l-[10px] md:hover:border-l-[8px] hover:border-l-[0px]  md:hover:border-blue-400 hover:rounded-sm transition-all duration-300 ease-in-out md:hover:-translate-y-1 md:hover:scale-110"
							>
								<h3 className="2xl:text-2xl text-lg !font-black pl-2">
									{i + 1}. {referral.title}
								</h3>
								<p className="2xl:text-md text-sm font-notosans-regular text-justify">
									{referral.content}
								</p>
							</Component>
						</div>
					))}
				</div>

				<div className="hidden md:block 2xl:h-auto col-span-1 bg-contain bg-left bg-no-repeat w-full transition-all duration-300 ease-in-out">
					{activeStep || (
						<StaticImage
							alt="referal-step-1"
							placeholder="none"
							src="../../../assets/how-it-works-step-1.png"
							objectFit="contain"
							style={{
								width: '100%',
								height: '100%',
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
HoverImageCardReferral.propTypes = {
	card: PropTypes.string.isRequired,
};
export { HoverImageCardReferral };
