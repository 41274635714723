// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Drawer } from '../components/atoms/drawer';
import { AdviceForm } from '../components/organisms/advice-form-vap';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice-vap';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { SuccessForm } from '../components/organisms/success-form';
import { VapSection } from '../components/organisms/vap-section';
import { Head } from '../components/templates/head';
import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import { DefaultLayout } from '../layouts/default/vap';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { pageTypes } from '../utils/url-helpers';
// import { MainHeader } from '../components/molecules/main-header-vap';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container-vap';
import { AdviceStages } from '../components/organisms/advice-stages-vap';
import { AdviceStagesUk } from '../components/organisms/advise-stages-vap-uk';
import { IconCards } from '../components/organisms/icon-cards-vap1';
import { IconCards2 } from '../components/organisms/icon-cards-vap2';
import { VapSectionIE } from '../components/organisms/vap-section-ie';
import { VapSectionUK } from '../components/organisms/vap-section-uk';
import { getLocale } from '../utils/locale';
// import { AdviceStagesIE } from '../components/organisms/advise-stages-vap-ie';

// markup
const PricingPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsVapPage.noIndex,
		noFollow: data.datoCmsVapPage.noFollow,
		metaInformation: data.datoCmsVapPage.metaInformation,
		fallbackTitle: data.datoCmsVapPage.title,
		fallbackDescription: data.datoCmsVapPage.longIntroduction,
		canonicalUrl: data.datoCmsVapPage?.canonicalUrl || location.href,
	};

	// const headerCTAContent = {
	// 	...data.datoCmsVapPage.mainHeader,
	// 	header: data.datoCmsVapPage.mainHeader.header.value.document.children,
	// };

	const uspBanner = data.datoCmsVapPage.uspCtaCardsBanner1;
	const { cardTitle1 } = data.datoCmsVapPage;

	const uspBanner2 = data.datoCmsVapPage.uspCtaCardsBanner2;
	// const cardTitle2 = data.datoCmsVapPage.cardTitle2;
	const cardTitle2 = data.datoCmsVapPage.cardTitle2.value.document.children;
	const { cardSubtitle2 } = data.datoCmsVapPage;
	const { cardContent2 } = data.datoCmsVapPage;

	const textWithImage = {
		...data.datoCmsVapPage.textWithImage,
	};

	const pricingContent = {
		title: data.datoCmsVapPage.heading,
		subtitle: data.datoCmsVapPage.subheading,
		cards: data.datoCmsVapPage.cards,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.pricing,
			location,
		}),
	};
	const locationUrl = useLocation();
	const locale = getLocale(locationUrl.pathname);
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsVapPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{/* {locale !== 'ca' && headerCTAContent ? (
				<MainHeader drawerRef={adviceFormRef} {...headerCTAContent} />
			) : null} */}
			{/* {locale !== 'ca' &&
			data.datoCmsVapPage.uspCtaCardsBannerDisplayOnPage1 ? (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					cardtitle={cardTitle1}
				/>
			) : null} */}

			{locale === 'ca' && (
				<VapSection
					pricingContent={pricingContent}
					drawerRef={adviceFormRef}
				/>
			)}
			{locale === 'ie' && (
				<VapSectionIE
					pricingContent={pricingContent}
					drawerRef={adviceFormRef}
					locale={locale}
				/>
			)}
			{locale === '' && (
				<VapSectionUK
					pricingContent={pricingContent}
					drawerRef={adviceFormRef}
					locale={locale}
				/>
			)}

			{locale === 'ca' && <AdviceStages drawerRef={adviceFormRef} />}

			{locale === '' && <AdviceStagesUk drawerRef={adviceFormRef} />}

			{data.datoCmsVapPage.uspCtaCardsBannerDisplayOnPage1 && (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					cardtitle={cardTitle1}
				/>
			)}

			{data.datoCmsVapPage.textWithImageDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImage}
				/>
			)}

			{data.datoCmsVapPage.uspCtaCardsBannerDisplayOnPage2 && (
				<IconCards2
					iconCardsContent={{
						uspCards: uspBanner2,
					}}
					cardtitle={cardTitle2}
					cardsubtitle={cardSubtitle2}
					cardcontent={cardContent2}
					drawerRef={adviceFormRef}
				/>
			)}

			{locale !== 'ca' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedPricing = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<PricingPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedPricing;

export const query = graphql`
	query vap($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		datoCmsVapPage(locale: { eq: $locale }) {
			originalId
			cardTitle1
			cardTitle2 {
				value
			}
			cardSubtitle2
			cardContent2
			uspCtaCardsBannerDisplayOnPage1
			uspCtaCardsBannerDisplayOnPage2
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			textWithImageDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			uspCtaCardsBanner1 {
				content {
					value
				}
				icon
			}
			uspCtaCardsBanner2 {
				title
				content {
					value
				}
				icon
			}
			id
			heading
			subheading
			textWithImage {
				id
				title
				tickList {
					id
					text
				}
				buttonCopy
				buttonLink
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
			}

			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
