// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DownloadSuccess } from '../components/atoms/download-success';
import { Drawer } from '../components/atoms/drawer';
import { MainHeader as PpcResourcHeader } from '../components/molecules/main-header';
import { MainHeader } from '../components/molecules/main-header-safecheck';
import { SafeCheckTextListContent } from '../components/molecules/safcheck-text-content';
import { SafeCheckTextImageContent } from '../components/molecules/safecheck-text-image-container';
import { SafeCheckTextWithDarkBgContent } from '../components/molecules/safecheck-with-dark-bgcontent';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { TwoTextVideoColumn } from '../components/molecules/two-text-video-safecheck';
import { AdviceForm } from '../components/organisms/advice-form';
import { CardContainer } from '../components/organisms/card-container';
import { DownloadForm } from '../components/organisms/download-form';
import { FAQ } from '../components/organisms/faq';
import { IconCards } from '../components/organisms/icon-cards';
import { SuccessForm } from '../components/organisms/success-form';
import { Testimonials } from '../components/organisms/testimonials';
import { Head } from '../components/templates/head';
import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import { DefaultLayout } from '../layouts/default';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { pageTypes } from '../utils/url-helpers';
import ExitPopUp from '../components/molecules/exit-pop-up';

const PpcResource = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = React.useRef(null);
	const downloadFormRef = React.useRef(null);
	const [reference, setReference] = React.useState('');
	const [response, setResponse] = React.useState('');
	const [downloadResponse, setDownloadResponse] = React.useState('');

	const metaContent = {
		noIndex: data.datoCmsPpcResource.noIndex,
		noFollow: data.datoCmsPpcResource.noFollow,
		metaInformation: data.datoCmsPpcResource.metaInformation,
		fallbackTitle: data.datoCmsPpcResource.title,
		fallbackDescription: data.datoCmsPpcResource.longIntroduction,
		canonicalUrl: data.datoCmsPpcResource?.canonicalUrl || location.href,
	};
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.pricing,
			location,
		}),
	};
	const headerCTAContent = data.datoCmsPpcResource
		.mainHeaderDisplayOnPage && {
		...data.datoCmsPpcResource.mainHeader,
		header: data.datoCmsPpcResource.mainHeader.header.value.document
			.children,
	};

	const headerVideoContent = data.datoCmsPpcResource
		.heroHeaderWithVideoDisplayOnPage && {
		...data.datoCmsPpcResource.heroHeaderWithVideo[0],
		locale: data.datoCmsPpcResource.locale,
		header: data.datoCmsPpcResource.heroHeaderWithVideo[0].headerContent
			.value.document.children,
	};
	const textImageDarkBgContent = {
		...data.datoCmsPpcResource.textWithDarkBackground[0],
		header: data.datoCmsPpcResource.textWithDarkBackground[0].titleHeader
			.value.document.children,
	};
	const blogs = data.allDatoCmsBlogPage.nodes;
	const guides = data.allDatoCmsGuidePage.nodes;
	// add the blog and guide nodes to the related content container
	const mergedContent = [...blogs, ...guides].sort((a, b) =>
		new Date(a.publishedDate) > new Date(b.publishedDate) ? -1 : 1
	);
	const blogContainerContent = {
		...data.datoCmsPpcResource.relatedContentContainer,
		cards: mergedContent.slice(0, 3),
	};
	const testimonialsContent = {
		...data.datoCmsPpcResource.testimonials,
		title: data.datoCmsPpcResource.testimonials.title.value.document
			.children,
		slides: data.datoCmsPpcResource.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};
	const { locale } = data.datoCmsPpcResource;
	const faqContent = {
		title: data.datoCmsPpcResource.faqContainer.title,
		subText: data.datoCmsPpcResource.faqContainer.subText,
		questions: data.datoCmsPpcResource.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};
	const uspBanner = data.datoCmsPpcResource.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsPpcResource.uspCtaCardsBannerDisplayLinks;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPpcResource.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{data.datoCmsPpcResource.mainHeaderDisplayOnPage && (
				<PpcResourcHeader
					urlLocale={locale}
					drawerRef={adviceFormRef}
					{...headerCTAContent}
					reference={reference}
					response={response}
					setReference={setReference}
					setResponse={setResponse}
					rating={data.datoCmsTrustpilotRating}
				/>
			)}
			{locale === 'en' && <ExitPopUp />}
			{data.datoCmsPpcResource.heroHeaderWithVideoDisplayOnPage && (
				<MainHeader
					urlLocale={locale}
					drawerRef={adviceFormRef}
					donwloadDrawerRef={downloadFormRef}
					{...headerVideoContent}
					reference={reference}
					response={response}
					setReference={setReference}
					setResponse={setResponse}
					rating={data.datoCmsTrustpilotRating}
				/>
			)}
			{data.datoCmsPpcResource.uspCtaCardsBannerDisplayOnPage && (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					cardtitle={
						data.datoCmsPpcResource.cardTitle.value.document
							.children
					}
					showContentLinks={uspBannerDisplayLinks}
				/>
			)}
			{data.datoCmsPpcResource.trustPilotBannerDisplayOnPage && (
				<TrustpilotReviews
					pageName="safecheck"
					showRatings
					reviews={data.allDatoCmsTrustpilotReview.nodes}
				/>
			)}
			{data.datoCmsPpcResource.busniessProtectionDisplayOnPage && (
				<SafeCheckTextListContent
					drawerRef={adviceFormRef}
					pageName={data.datoCmsPpcResource.slug}
					textContent={
						data.datoCmsPpcResource.textWithCtaContainer[0]
					}
					listContent={data.datoCmsPpcResource.listWithImageAndText}
				/>
			)}
			{data.datoCmsPpcResource.textWithImageCtaDisplayOnPage && (
				<SafeCheckTextImageContent
					drawerRef={adviceFormRef}
					textImageContent={
						data.datoCmsPpcResource.textWithImageAndCta[0]
					}
					header={
						data.datoCmsPpcResource.textWithImageAndCta[0].title
							.value.document.children
					}
				/>
			)}

			{data.datoCmsPpcResource.textWithDarkBgDisplayOnPage && (
				<SafeCheckTextWithDarkBgContent
					donwloadDrawerRef={downloadFormRef}
					{...textImageDarkBgContent}
				/>
			)}

			{data.datoCmsPpcResource.relatedContentDisplayOnPage && (
				<CardContainer
					cardContainerContent={{
						...blogContainerContent,
						to: '/resource-hub/blogs',
						toAll: '/resource-hub/blogs',
					}}
					cardVariant="Blog"
					variant="Center"
					location={location.pathname}
				/>
			)}
			{data.datoCmsPpcResource.testimonialDisplayOnPage && (
				<Testimonials
					drawerRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			{data.datoCmsPpcResource.twoColumnTextVideoDisplayOnPage && (
				<TwoTextVideoColumn
					{...data.datoCmsPpcResource.twoColumnTextWithVideo[0]}
					cardTitle={
						data.datoCmsPpcResource.twoColumnTextWithVideo[0].title
							.value.document.children
					}
					drawerRef={adviceFormRef}
					locale={data.datoCmsPpcResource.locale}
				/>
			)}
			{data.datoCmsPpcResource.gotQuestionDisplayOnPage && (
				<div className="bg-white w-full py-1">
					<FAQ faqContent={faqContent} />
				</div>
			)}
			<Drawer
				label="Take the first step towards a safer workplace"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceForm
						leadSource={data.datoCmsPpcResource.leadSource}
						locale={locale}
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessForm reference={reference} response={response} />
				)}
			</Drawer>
			<Drawer
				label="Get your free download"
				ref={downloadFormRef}
				open={drawerRef === downloadFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{downloadResponse === '' ? (
					<DownloadForm
						setResponseSent={setDownloadResponse}
						pageUrl={location.href}
						locale={locale}
						ctaText="Get my free guide"
						leadSource="Website - Mediation download"
						formId={data.datoCmsPpcResource?.mkFormId || 7191}
						formType="SafeCheck"
						gtmTag={
							data.datoCmsPpcResource.slug !==
							'workplace-mediation'
								? 'PPCResource'
								: ''
						}
						title={
							data.datoCmsPpcResource?.formTitle ||
							'Get your sample SafeCheck report'
						}
						subText={
							data.datoCmsPpcResource?.formBody ||
							'With SafeCheck, you receive a detailed report which highlights the risks in your business. See what that looks like with your free sample copy – fill in the form to receive yours:'
						}
					/>
				) : (
					<DownloadSuccess page={data.datoCmsPpcResource.slug} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};
const WrappedPpcResource = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		className="bg-white"
		pageName={
			data.datoCmsPpcResource.slug === 'workplace-mediation'
				? 'workplace-mediation'
				: ''
		}
	>
		<PpcResource data={data} location={location} />
	</DefaultLayout>
);

export default WrappedPpcResource;

export const query = graphql`
	query ppcResource(
		$slug: String!
		$locale: String!
		$relatedTagContent: String!
	) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		allDatoCmsTrustpilotReview(
			sort: { order: ASC, fields: rating }
			filter: { source: { ne: "trustpilot" }, locale: { eq: $locale } }
		) {
			nodes {
				id
				isVerified
				locale
				originalId
				pageTag
				source
				personName
				content {
					value
				}
				rating
				title
				date(fromNow: true)
			}
		}
		datoCmsPpcResource(slug: { eq: $slug }, locale: { eq: $locale }) {
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			title
			intro
			slug
			mkFormId
			formTitle
			leadSource
			formBody
			noFollow
			noIndex
			locale
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			heroHeaderWithVideoDisplayOnPage
			heroHeaderWithVideo {
				id
				locale
				headerContent {
					value
				}
				subHeader
				primaryButtonText
				primaryButtonOpenForm
				primaryButtonLink
				secondaryButtonText
				secondaryButtonOpenForm
				secondaryButtonLink
				showTrustpilot
				safecheckVideo {
					title
					showVideo
					thumbnail {
						alt
						url
						gatsbyImageData(
							aspectRatio: 53
							placeholder: DOMINANT_COLOR
							width: 500
							sizes: "(max-width: 640px) 400px, 800px"
							height: 300
						)
					}
					vimeoLink {
						thumbnailUrl
						providerUid
						title
						url
						height
						width
					}
				}
			}
			mainHeaderDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
				showInlineForm
				formPosition
				note
				ctaText
			}
			uspCtaCardsBannerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}
			trustPilotBannerDisplayOnPage
			busniessProtectionDisplayOnPage
			textWithCtaContainer {
				id
				title {
					value
				}
				content {
					value
				}
				ctaText
				ctaOpenForm
			}
			listWithImageAndText {
				content
				imageType
				id
			}
			textWithImageCtaDisplayOnPage
			textWithImageAndCta {
				title {
					value
				}
				content {
					value
				}
				checkListField {
					id
					text
				}
				ctaText
				ctaOpenForm
				id
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
					alt
				}
			}
			textWithDarkBgDisplayOnPage
			textWithDarkBackground {
				titleHeader {
					value
				}
				subTitle
				ctaPrimaryText
				ctaLink
				id
				imageBlock {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
			}
			relatedContentDisplayOnPage
			relatedContentContainer {
				id
				originalId
				subText
				title
				buttonCopy
			}
			businessRiskDisplayOnPage
			cardTitle {
				value
			}
			cards {
				id
				title
				icon
				content {
					value
				}
			}
			testimonialDisplayOnPage
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			twoColumnTextVideoDisplayOnPage
			twoColumnTextWithVideo {
				id
				title {
					value
				}
				content
				ctaOpenForm
				ctaText
				showVideo
				thumbnail {
					alt
					url
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				videoLink {
					url
					thumbnailUrl
					providerUid
					title
					width
					height
				}
			}
			gotQuestionDisplayOnPage
			faqContainer: gotAQuestionContainer {
				title
				subText
				locale
				id
				internalTitle
				questions {
					id
					question
					locale
					answer {
						value
					}
				}
			}
		}
		allDatoCmsBlogPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: "en" }
				sectors: { elemMatch: { name: { eq: $relatedTagContent } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
				externalLink
			}
		}
		allDatoCmsGuidePage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: "en" }
				sectors: { elemMatch: { name: { eq: $relatedTagContent } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		datoCmsSiteSpecificSetting {
			trialServiceCopy
			trialServiceLink
			trialServiceOpenForm
			logo {
				url
			}
		}
	}
`;
