import clsx from 'clsx';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { StructuredText, renderNodeRule } from 'react-datocms';
import useModal from '../../../hooks/modal';
import { Button } from '../../atoms/button';
import ModalWrapper from '../../glu/molecules/modal-wrapper';

const MainHeader = ({ heroHeader, headingStyles, titleText }) => {
	const {
		subHeading,
		content,
		ctaText,
		// id,
		// originalId,
		primaryImage,
		secondaryImage,
	} = heroHeader;
	const Component = 'h1';
	const ImageDiv = 'div';
	const primaryImageData = getImage(primaryImage);
	const secondaryImageData = getImage(secondaryImage);
	const ref = React.useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	return (
		<>
			<div className="max-w-m-screen mx-auto grid grid-cols-2 mb-5 md:mb-0 pb-10">
				<div className="md:col-span-1 col-span-2 flex-col flex gap-5 pl-2 xl:pb-10">
					<Component
						id={heroHeader.id}
						className={clsx(
							'!leading-[1.3] font-lexend-regular',
							'text-4xl md:text-5xl xl:text-6xl',
							headingStyles.heading
						)}
					>
						{titleText}
					</Component>
					<p className="text-3xl font-notosans-medium md:mt-10 mt-5 font-light">
						{subHeading}
					</p>
					<StructuredText
						data={content}
						customNodeRules={[
							renderNodeRule(
								isParagraph,
								({ children, key, ancestors }) => {
									if (isRoot(ancestors[0])) {
										return (
											<p
												className="2xl:text-2xl xl:text-lg text-sm font-notosans-light leading-6"
												key={key}
											>
												{children}
											</p>
										);
									}
									return children;
								}
							),
						]}
					/>
				</div>

				<div className="md:flex md:col-span-1 col-span-2 items-center justify-center flex-col w-full md:-mt-16 mt-5">
					<ImageDiv
						onClick={() => setModalOpen(true)}
						className=" cursor-pointer"
					>
						<GatsbyImage
							image={primaryImageData}
							alt={primaryImage.alt || ''}
							key={primaryImage.url}
							className={clsx('2xl:min-w-[400px] object-fit')}
						/>
					</ImageDiv>
					<div className="flex w-full justify-center md:px-10 lg:px-0 sm:px-0">
						<Button
							type="button"
							onClick={() => setModalOpen(true)}
							variant="Default"
							size="Small"
							className="md:!px-2"
						>
							<p className="md:text-sm lg:text-base-f  line-clamp-1">
								{ctaText}
							</p>
						</Button>
					</div>
				</div>
			</div>
			{isModalOpen && (
				<ModalWrapper>
					<div
						className="relative 2xl:w-7/12 xl:w-[100%]  m-auto opacity-100 h-auto w-full 2xl:rounded-md shadow-xl bg-white overflow-auto"
						ref={ref}
					>
						<GatsbyImage
							image={secondaryImageData}
							alt={secondaryImage.alt || ''}
							key={secondaryImage.url}
						/>
					</div>
				</ModalWrapper>
			)}
		</>
	);
};

MainHeader.propTypes = {
	heroHeader: PropTypes.string.isRequired,
	headingStyles: PropTypes.isRequired,
	titleText: PropTypes.string.isRequired,
};
export { MainHeader };
