import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../button';
import { TickList } from '../tick-list';

import { sendTrackingData } from '../../../utils';

import DownloadIcon from '../../../assets/download.inline.svg';
import { refProp } from '../../../types';

import { useDrawer } from '../../../contexts/drawer-context';

const HeaderDownloadPromo = ({ promoTickList, drawerRef }) => {
	const { setDrawerRef } = useDrawer();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			click_type: 'primary',
			component_name: `header-download-promo`,
		});
	};

	return (
		<article className="gradient flex justify-between flex-col px-4 py-5 lg:px-5 lg:py-6 rounded-tr-[100px]">
			<div>
				<h2 className="mb-3 text-2xl text-white pr-7 font-lexend-regular">
					Get your free download
				</h2>
				<TickList
					listItemClassName="text-white text-lg md:text-xl !mb-2"
					iconClassName="text-white !mr-2"
					points={promoTickList}
				/>
			</div>
			<div className="mt-2">
				<Button
					variant="White"
					onClick={() => {
						setDrawerRef(drawerRef);
						handleClickTracking();
					}}
				>
					<DownloadIcon
						width={25}
						height={25}
						aria-hidden="true"
						className="mr-2"
					/>{' '}
					Download for free
				</Button>
			</div>
		</article>
	);
};

export { HeaderDownloadPromo };

HeaderDownloadPromo.propTypes = {
	promoTickList: PropTypes.arrayOf(
		PropTypes.shape({ text: PropTypes.string })
	).isRequired,
	drawerRef: refProp.isRequired,
};
