import React from 'react';
import PropTypes from 'prop-types';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import { isHeading, isParagraph, isRoot } from 'datocms-structured-text-utils';
import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { refProp, structuredTextContent } from '../../../types';
import { useDrawer } from '../../../contexts/drawer-context';
import { buildLink, getLocale } from '../../../utils/locale';
import { sendTrackingData } from '../../../utils';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const BlueBannerText = ({ content, drawerRef }) => {
	const {
		title,
		textContent,
		primaryLinkCopy,
		primaryLink,
		primaryLinkOpenForm,
	} = content;

	const { setDrawerRef } = useDrawer();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = title;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};

	let cta;

	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setDrawerRef(drawerRef);
					handleClickTracking();
				}}
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}

	const customNodeRules = [
		renderNodeRule(isHeading, ({ node, children, key }) => {
			if (node.level === 1) {
				return (
					<h2
						className="font-lexend-medium text-2xl md:text-3xl lg:text-4xl mb-6"
						key={key}
					>
						{children}
					</h2>
				);
			}
			if (node.level === 3) {
				return (
					<p
						className="text-xl md:text-2xl lg:text-3xl mb-4"
						key={key}
					>
						{children}
					</p>
				);
			}
			return children;
		}),
		renderNodeRule(isParagraph, ({ children, key, ancestors }) => {
			if (isRoot(ancestors[0])) {
				return (
					<div
						className="text-base lg:text-xl font-lexend-light"
						key={key}
					>
						{children}
					</div>
				);
			}
			return children;
		}),
	];

	const customMarkRules = [
		renderMarkRule('strong', ({ children, key }) => (
			<strong key={key}>{children}</strong>
		)),
	];

	return (
		<div className="font-lexend-medium flex justify-center py-6">
			<div className="max-w-6xl w-full bg-[#1F2E7A] text-white p-8 md:p-12 sm:rounded-sm shadow-lg">
				<div className="lg:flex lg:justify-stretc lg:items-center">
					<div className="lg:w-full">
						<div className="text-sm md:text-base lg:text-lg lg:columns-2 gap-8 lg:mb-0 order-1">
							<StructuredText
								data={textContent}
								customNodeRules={customNodeRules}
								customMarkRules={customMarkRules}
							/>
							<p className="flex flex-col items-center justify-center mt-12 lg:mt-32 xl:mt-24 sm:flex-row sm:justify-start">
								{cta || null}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

BlueBannerText.propTypes = {
	content: PropTypes.shape({
		title: PropTypes.string.isRequired,
		textContent: structuredTextContent.isRequired,
		primaryLinkCopy: PropTypes.string.isRequired,
		savingsFigure: PropTypes.string,
		primaryLink: PropTypes.string,
		primaryLinkOpenForm: PropTypes.bool,
	}).isRequired,
	drawerRef: refProp.isRequired,
};

export { BlueBannerText };
