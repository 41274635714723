/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

export const DatePicker = ({ className, onDateChange }) => {
	const [minDate, setMinDate] = useState('');

	// Set today's date as the minimum date when the component mounts
	useEffect(() => {
		const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
		setMinDate(today);
	}, []);

	const handleDateChange = (e) => {
		// Call the onDateChange function when the date changes
		onDateChange(e.target.value);
	};

	return (
		<input
			type="date"
			id="start"
			name="callWhen"
			min={minDate}
			max="9999-12-31"
			className={className}
			onChange={handleDateChange} // Update parent on date change
		/>
	);
};
