// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import {
	StructuredText,
	renderNodeRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading, isListItem } from 'datocms-structured-text-utils';
import { EventDate } from '../../../atoms/event-date';
import TickIcon from '../../../../assets/tick.inline.svg';
import { PBSLink } from '../../../atoms/link';
import { buildLink } from '../../../../utils/locale';

const LiveSessionCard = ({ session, pbsWebinar, locale }) => {
	const { dateAndTime, timeEnd, title, shortenedContent, slug } = session;
	const date = format(new Date(dateAndTime), 'MMM d, yyyy');
	const time = `${format(
		new Date(dateAndTime),
		`h:mm${pbsWebinar ? 'a' : ''}`
	)} - ${timeEnd}`;

	return (
		<div className="flex flex-col h-full pt-5 overflow-hidden rounded-sm shadow-2xl">
			<div className="flex flex-col px-8 pt-8 bg-brand-blue-400 grow">
				<h4 className="mb-5 text-2xl font-bold text-white">{title}</h4>
				<EventDate date={date} time={time} theme="Light" />
			</div>
			<div className="flex flex-col justify-between h-full p-8 bg-white grow">
				<div className="mb-8">
					<StructuredText
						data={shortenedContent.value}
						customNodeRules={[
							renderNodeRule(
								isHeading,
								({ node, children, key }) => {
									const HeadingTag = `h${node.level}`;
									return (
										<HeadingTag
											className="mb-5 text-2xl font-bold lg:text-3xl font-lexend-regular"
											key={key}
										>
											{children}
										</HeadingTag>
									);
								}
							),
							renderNodeRule(isListItem, ({ children, key }) => (
								<li
									className="flex items-center mt-5 mb-4 text-sm last-of-type:mb-0 font-lexend-light"
									key={key}
								>
									<TickIcon
										className="w-3 h-auto mr-4 text-blue-400 shrink-0"
										aria-hidden="true"
									/>

									{children}
								</li>
							)),
						]}
					/>
				</div>

				<PBSLink
					to={`${
						pbsWebinar
							? buildLink(locale, '/webinars/')
							: buildLink(locale, '/elearning/webinar/')
					}${slug}`}
					className="justify-center w-full !text-sm text-center !block mt-auto"
					size="Small"
					target="_blank"
				>
					Register your place
				</PBSLink>
			</div>
		</div>
	);
};

export default LiveSessionCard;
