// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import tick from './tick.png';
import { VapCalculatorVideoCampaignCA } from '../../molecules/vap-calculator-video-campaign-ca';

const VAPSectionVideoCampaignCA = ({
	vAPSectionVideoCampaignCAContent,
	className,
	modalRef,
}) => {
	const {
		sectionTitle,
		subText,
		vapSectionPrimaryLinkCopy,
		bannerImage,
		bannerTitle,
		items,
		primaryLinkCopy,
		primaryImage,
	} = vAPSectionVideoCampaignCAContent;

	const [vapLinkShow, setVapLinkShow] = useState(true);

	const VAP_FOOTER_NOTE =
		'The values presented in this value calculator are based on first-party data from over 6,500 businesses in Canada and over 100,000 requests for HR or health and safety advice.';

	return (
		<div className={`w-full bg-white scroll-smooth ${className}`}>
			<div className="flex flex-col items-center justify-center w-full text-black bg-white gap-10 lg:gap-16 px-4 pt-8 pb-10 lg:px-10 lg:pt-24 lg:pb-24 mx-auto lg:max-w-m-screen xl:pt-20">
				{/* title row */}
				<div className="flex flex-col items-center justify-start -mb-3 lg:mb-auto">
					{/* title */}
					<div className="pb-5">
						{createHeadingHighlightCA({
							headings:
								sectionTitle.value.document.children[0]
									.children,
							variant: 'red',
							className: 'text-center',
						})}
					</div>
					{/* subText */}
					<div className="text-center text-[20px] font-lexend-light">
						{subText}
					</div>
				</div>
				{/* content row */}
				<div className="grid grid-flow-row auto-cols-max grid-cols-1 lg:grid-flow-row lg:grid-cols-3 rounded-[.75rem] px-2 gap-x-0 gap-y-5 lg:gap-5 w-full">
					{/* left panel */}
					<div
						className={`bg-[#1F2E7A] col-span-1 ${
							!vapLinkShow ? 'h-fit' : ''
						} p-10 justify-end lg:py-16 rounded-[.75rem] text-white flex flex-col gap-5 order-last lg:order-first`}
					>
						<div>
							<img
								className="object-scale-down mx-auto"
								src={bannerImage.url}
								alt=""
							/>
							{primaryImage}
						</div>
						<div className="font-lexend-regular text-[36px] lg:text-3xl xl:text-[36px] leading-10">
							{bannerTitle}
						</div>
						{items.map((elm) => (
							<>
								<div className="flex flex-row items-center justify-start gap-3 ">
									<div className="min-w-[20px] min-h-[20px]">
										<img src={tick} alt="" className="" />
									</div>
									<div className="font-lexend-regular ">
										{createHeadingHighlightCA({
											headings:
												elm.structuredTextBlock.value
													.document.children[0]
													.children,
											variant: 'purple',
											className:
												'text-left text-[16px] lg:text-sm xl:text-[16px] lg:-mb-1 xl:mb-0',
										})}
									</div>
								</div>
							</>
						))}
						<div>
							<a
								href="tel:1(833)247-3652"
								className="inline-flex transition delay-150 duration-1000 items-center px-6 py-4 mt-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-lexend-medium focus:outline-0 focus:shadow-focus w-max"
							>
								<span className="rulerclick">
									{primaryLinkCopy}
								</span>
							</a>
						</div>
					</div>
					{/* right panel */}
					<div className="bg-white col-span-1 lg:col-span-2 rounded-[.75rem] ">
						<VapCalculatorVideoCampaignCA
							vapSectionPrimaryLinkCopy={
								vapSectionPrimaryLinkCopy
							}
							modalRef={modalRef}
							vapLinkShow={vapLinkShow}
							setVapLinkShow={setVapLinkShow}
						/>
					</div>
					<div className="col-span-1 hidden lg:block">&nbsp;</div>
					<div className="col-span-2 font-lexend-regular text-center text-xs mx-10">
						{VAP_FOOTER_NOTE}
					</div>
				</div>
			</div>
		</div>
	);
};

export default VAPSectionVideoCampaignCA;
