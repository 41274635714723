// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';

import { Categories } from '../components/molecules/categories';
import { CardContainer } from '../components/organisms/card-container';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Title } from '../components/atoms/title';
import { Drawer } from '../components/atoms/drawer';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';

const ResourcesPage = ({ location, data, locale }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsResourcesPage.noIndex,
		noFollow: data.datoCmsResourcesPage.noFollow,
		metaInformation: data.datoCmsResourcesPage.metaInformation,
		fallbackTitle: data.datoCmsResourcesPage.title,
		fallbackDescription: data.datoCmsResourcesPage.longIntroduction,
		canonicalUrl: data.datoCmsResourcesPage?.canonicalUrl || location.href,
	};

	const resourcesContent = {
		title: data.datoCmsResourcesPage.heading,
		subtitle: data.datoCmsResourcesPage.subheading,
	};

	const adviceBannerContent = {
		...data.datoCmsResourcesPage.adviceBanner,
		title: data.datoCmsResourcesPage.adviceBanner.title.value.document
			.children,
	};

	const guideContainerContent = {
		totalCount: data.allDatoCmsGuidePage.totalCount,
		cards: data.allDatoCmsGuidePage.nodes,
	};

	const blogContainerContent = {
		totalCount: data.allDatoCmsBlogPage.totalCount,
		cards: data.allDatoCmsBlogPage.nodes,
	};

	const downloadContainerContent = {
		totalCount: data.allDatoCmsDownloadPage.totalCount,
		cards: data.allDatoCmsDownloadPage.nodes,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.resourceHub,
			location,
			data: data.datoCmsResourcesPage,
		}),
	};

	let pagePath = location.pathname;
	if (pagePath.endsWith('/')) {
		pagePath = location.pathname.slice(0, -1);
	}

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsResourcesPage.internal.type
	);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsResourcesPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title={resourcesContent.title}
				subtitle={resourcesContent.subtitle}
			/>
			<Categories
				title={data.datoCmsResourcesPage.categoryGroupTitle}
				categoryGroups={data.datoCmsResourcesPage.categoryGroups}
				locale={locale}
			/>
			{guideContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollable"
					cardContainerContent={{
						...guideContainerContent,
						id: 'guides-container',
						title: 'Guides',
						to: pagePath,
						toAll: `${pagePath}/guides`,
					}}
					cardVariant="Guide"
				/>
			)}
			{blogContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollable"
					cardContainerContent={{
						...blogContainerContent,
						id: 'blog-container',
						title: 'Blog articles',
						to: pagePath,
						toAll: `${pagePath}/blogs`,
					}}
					cardVariant="Blog"
				/>
			)}
			{downloadContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollable"
					cardContainerContent={{
						...downloadContainerContent,
						id: 'download-container',
						title: 'Free downloads',
						to: pagePath,
						toAll: `${pagePath}/downloads`,
					}}
					cardVariant="Download"
				/>
			)}
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query resourceHub($categoryIds: [String!]!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsResourcesPage(locale: { eq: $locale }) {
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			originalId
			id
			internal {
				type
			}
			noIndex
			noFollow
			canonicalUrl
			heading
			subheading
			categoryGroupTitle
			categoryGroups {
				originalId
				id
				internal {
					type
				}
				slug
				title
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				categories {
					originalId
					id
					name
					internal {
						type
					}
					slug
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
		allDatoCmsBlogPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				categories: { elemMatch: { id: { in: $categoryIds } } }
				slug: { ne: null }
				locale: { eq: $locale }
			}
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		allDatoCmsGuidePage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				categories: { elemMatch: { id: { in: $categoryIds } } }
				slug: { ne: null }
				locale: { eq: $locale }
			}
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		allDatoCmsDownloadPage(
			sort: { order: DESC, fields: created }
			filter: {
				categories: { elemMatch: { id: { in: $categoryIds } } }
				slug: { ne: null }
				locale: { eq: $locale }
			}
			limit: 3
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				title
				slug
				intro
				downloadType
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedHome = ({ location, data, pageContext }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<ResourcesPage
			location={location}
			data={data}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedHome;
