import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '../../atoms/button-ca';

import { cardContents, cardVariants } from '../../../types';

import LogoIcon from '../../../assets/logo.inline.svg';

import * as styles from './index.module.css';
import { buildLink } from '../../../utils/locale';

const DownloadCard = ({
	cardContent,
	variant,
	onClickProp,
	index,
	location,
	icon,
	buttonCopy,
	loading,
	locale,
}) => {
	const {
		as,
		id,
		downloadType,
		thumbnail,
		title,
		externalLink,
		categories,
		className,
	} = cardContent;

	const Element = as || 'article';

	const iconImage = <img src={icon.url} alt="" />;

	if (loading) {
		return (
			<Element
				className={clsx(
					'group relative bg-white h-full w-full',
					'border-2 border-solid rounded-md shadow-card',
					styles.borderLoading,
					className
				)}
				aria-labelledby={`${id}-card`}
			>
				<div
					className={clsx('w-full h-[244px] block', styles.bgLoading)}
				/>

				<div className="p-4 pt-8">
					{variant !== 'Download' ? (
						<div
							className={clsx(
								'flex h-[22px] w-[50%]',
								styles.bgLoading
							)}
						/>
					) : null}
					<div className="mb-4">
						{variant !== 'Download' ? (
							<div
								className={clsx(
									'mt-4 flex h-[28px]',
									styles.bgLoading
								)}
							/>
						) : null}

						<div
							className={clsx(
								'mt-4 flex h-[100px]',
								styles.bgLoading
							)}
						/>
					</div>

					<div
						className={clsx(
							'flex py-4 border-t',
							styles.breakBorderLoading
						)}
					>
						<div
							className={clsx(
								'flex w-12 h-12 rounded-[100%] mr-4',
								styles.bgLoading
							)}
						/>
						<div className="flex flex-col w-[50%]">
							<div
								className={clsx(
									'flex h-[20px] w-[50%]',
									styles.bgLoading
								)}
							/>
							<div
								className={clsx(
									'mt-2 flex h-[20px]',
									styles.bgLoading
								)}
							/>
						</div>
					</div>

					<div className="flex flex-row">
						<div
							className={clsx(
								'flex h-[30px] w-[35%]',
								styles.bgLoading
							)}
						/>
						<div
							className={clsx(
								'ml-3 flex h-[30px] w-[9%]',
								styles.bgLoading
							)}
						/>
					</div>
				</div>
			</Element>
		);
	}

	const Header = () => {
		if (variant === 'Download') {
			if (!thumbnail)
				return (
					<LogoIcon className="max-w-full aspect-[408/245] p-12" />
				);

			if (thumbnail.responsiveImage) {
				return (
					<picture className="">
						<source
							type="image/webp"
							sizes={thumbnail.responsiveImage.sizes}
							srcSet={thumbnail.responsiveImage.webpSrcSet}
						/>
						<img
							srcSet={thumbnail.responsiveImage.srcSet}
							sizes={thumbnail.responsiveImage.sizes}
							src={thumbnail.url}
							alt={thumbnail.responsiveImage.alt || ''}
							width={thumbnail.responsiveImage.width}
							height={thumbnail.responsiveImage.height}
							loading="lazy"
							className="w-full"
						/>
					</picture>
				);
			}

			return (
				<img
					src={thumbnail.url}
					alt={
						thumbnail.alt ||
						'Peninsula HR & Health and Safty Support'
					}
					className="w-full rounded-t-[0.75rem]"
				/>
			);
		}
	};

	// const truncatedCategoriesArray = truncateCategories({ categories });

	return (
		<Element
			className={clsx(
				'flex flex-col h-full',
				'group relative bg-white w-full',
				'border-2 border-blue-200 border-solid rounded-[0.75rem] shadow-card',
				'transition-shadow duration-300 motion-reduce:transition-none',
				'hover:shadow-default-hover focus-within:shadow-default-hover',
				'focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2',
				className
			)}
			aria-labelledby={`${id}-card`}
		>
			<Header />
			<p
				className={clsx(
					'absolute px-2 py-1 text-sm text-blue-400 bg-white rounded-[0.75rem] top-4 left-4 font-notosans-medium',
					variant === 'Download' && 'm-4'
				)}
			>
				{variant === 'Download' ? downloadType : variant}
			</p>
			<div className="flex flex-col p-6 grow">
				{/* Title and Icon Row */}
				<div className="flex items-start justify-between mb-4">
					<div>
						<p className="text-sm text-gray-500 font-notosans-light">
							Download expert <br /> documentation for FREE:
						</p>
						{title ? (
							<p className="pt-2 overflow-hidden text-2xl font-notosans-regular line-clamp-3">
								{title}
							</p>
						) : null}
					</div>
					{icon && (
						<div className="w-[130px] h-[130px]">{iconImage}</div>
					)}
				</div>
				<div className="mt-auto">
					<Button
						onClick={() => {
							const url =
								location === '/' && externalLink !== null
									? externalLink
									: buildLink(
											locale,
											`/resource-hub/${
												categories[0]?.slug
													? `${categories[0]?.slug}/`
													: ''
											}${cardContent.slug}`
									  );

							onClickProp({
								click_text: title,
								click_index: index + 1,
							});

							navigate(url);
						}}
						variant="RedReverse"
					>
						{buttonCopy}
					</Button>
				</div>
			</div>
		</Element>
	);
};

export { DownloadCard };

DownloadCard.defaultProps = {
	cardContent: {
		as: 'article',
	},
	variant: 'Blog',
	index: 1,
	onClickProp: () => {},
	location: '',
	loading: false,
	locale: 'en',
	icon: null,
	buttonCopy: 'Click to Download',
};

DownloadCard.propTypes = {
	cardContent: cardContents,
	variant: cardVariants,
	onClickProp: PropTypes.func,
	index: PropTypes.number,
	location: PropTypes.string,
	loading: PropTypes.bool,
	locale: PropTypes.string,
	icon: PropTypes.shape({
		url: PropTypes.string.isRequired,
	}),
	buttonCopy: PropTypes.string,
};
