import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { PBSLink } from '../../atoms/link';
import { Button } from '../../atoms/button';
import { CallUs } from '../../atoms/call-us';
import { Select } from '../../atoms/select';
import { Textarea } from '../../atoms/textarea';
import { TextInput } from '../../atoms/text-input';
import { RadioButton } from '../../atoms/radio-button';
import { SidebarHeader } from '../../atoms/sidebar-header';

import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
	generateUtmData,
} from '../../../utils';
import { useSalesforceApi } from '../../../hooks/salesforce';
import { useFormValidation } from '../../../hooks/form-validation';
import { getLocale } from '../../../utils/locale';
import usePrivacyLink from '../../../hooks/usePrivacyLink';
import { contactUs, contactUsIe } from '../../../utils/constants';

import { useSalesforceAzureApi } from '../../../hooks/salesforce-azure-ca';

const initialValidations = {
	speakTo: { isValid: true },
	fullName: { isValid: true },
	firstName: { isValid: true },
	lastName: { isValid: true },
	emailAddress: { isValid: true },
	phoneNumber: { isValid: true },
	discuss: { isValid: true },
};

const EnquireForm = ({ id, enquireOnlineContent, inDrawer }) => {
	const {
		locationFormTitle,
		locationFormSubText,
		locationFormSuccessTitle,
		locationFormSuccessSubText,
		locationFormSuccessBody,
	} = enquireOnlineContent;
	const locationUrl = useLocation();
	const locale = getLocale(locationUrl.pathname);
	const titleRef = useRef(null);
	const fullNameRef = useRef(null);
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const emailAddressRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const speakToRef = useRef(null);
	const discussRef = useRef(null);
	const [isClient, setIsClient] = useState('yes');
	const [isEmployer, setIsEmployer] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const { privacyLink } = usePrivacyLink(locale);
	const leadSourceDetail = locale !== 'ca' ? '' : 'Contact Us';

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const submitButtonText = 'Get my free advice';
	const returnButtonText = 'Return to home';
	// eslint-disable-next-line no-nested-ternary
	const homeURL = locale === 'ca' ? '/ca' : locale === 'ie' ? '/ie' : '/';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = (clickText) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'enquire-form',
			click_type: 'primary',
			click_title: locationFormTitle,
			click_text: clickText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: locationFormTitle,
		});
	};
	const pageUrl = pageUrlFomater(locationUrl);
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();
		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;
		if (locale === '') {
			// split full name into first and last name
			const nameParts = formEntries.fullName.split(' ');
			// eslint-disable-next-line prefer-destructuring
			formEntries.firstName = nameParts[0];
			formEntries.lastName = nameParts.slice(1).join(' ');
		}

		setSubmitting(true);

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			email: formEntries.emailAddress,
			phoneNumber: formEntries.phoneNumber,
			reasonForEnquiry: formEntries.discuss,
			existingClient: isClient && isClient === 'yes',
			leadSource: 'Website - Become a Client',
			locale,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
		};

		if (locale === '' && (locale !== 'ca' || locale !== 'ie')) {
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ca') {
			payloadData.leadSource = 'Website';
			payloadData.leadSourceDetails = leadSourceDetail;
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ie') {
			payloadData.leadSource = 'Website - ROI Contact us';
			payloadData.utmCampaign = generateUtmData(pageUrl, 'utm_campaign');
			payloadData.utmMedium = generateUtmData(pageUrl, 'utm_medium');
			payloadData.utmSource = generateUtmData(pageUrl, 'utm_source');
			payloadData.utmTerm = generateUtmData(pageUrl, 'utm_term');
		}

		if (isClient === 'yes') {
			payloadData.speakTo = formEntries.speakTo;
		}

		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (locale === 'ca' && response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.emailAddress);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking(submitButtonText);

		switch (response.status) {
			case 200:
				setIsSuccess(true);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}

		titleRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		if (!validations?.speakTo?.isValid && speakToRef?.current) {
			return speakToRef.current.focus();
		}

		if (locale === '') {
			if (!validations.fullName.isValid && fullNameRef.current) {
				return fullNameRef.current.focus();
			}
		} else {
			if (!validations.firstName.isValid && firstNameRef.current) {
				return firstNameRef.current.focus();
			}

			if (!validations.lastName.isValid && lastNameRef.current) {
				return lastNameRef.current.focus();
			}
		}

		if (!validations.emailAddress.isValid && emailAddressRef.current) {
			return emailAddressRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.discuss.isValid && discussRef.current) {
			return discussRef.current.focus();
		}
	}, [locale, validations]);

	if (isError) {
		return (
			<div
				className={clsx(
					'flex flex-col justify-between w-full mx-auto grow',
					!inDrawer &&
						'rounded-sm shadow-slight border-2 border-blue-200'
				)}
			>
				<SidebarHeader
					heading="We've run into a technical error with your submission"
					text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
					className={clsx('rounded-sm', !inDrawer && '!py-10 !px-8')}
					ariaLive="assertive"
				/>
				<div
					className={clsx(
						'flex flex-col grow px-4 pt-12 bg-white pb-7 text-base-f',
						!inDrawer && '!px-8'
					)}
				>
					<CallUs />
				</div>
			</div>
		);
	}

	if (isSuccess) {
		return (
			<div
				ref={titleRef}
				className={clsx(
					'flex flex-col justify-between w-full mx-auto grow',
					!inDrawer &&
						'rounded-sm shadow-slight border-2 border-blue-200'
				)}
			>
				<SidebarHeader
					heading={locationFormSuccessTitle}
					text={locationFormSuccessSubText}
					className={clsx('rounded-sm', !inDrawer && '!py-10 !px-8')}
					ariaLive="assertive"
				/>
				<div
					className={clsx(
						'flex flex-col grow px-4 pt-12 bg-white pb-7 text-base-f',
						!inDrawer && '!px-8'
					)}
				>
					{locationFormSuccessBody}
					<div className="mt-10">
						<Button
							variant="White"
							onClick={() => {
								handleClickTracking(returnButtonText);
								window.location = homeURL;
							}}
						>
							{returnButtonText}
						</Button>
					</div>
				</div>
			</div>
		);
	}
	return (
		<form
			onSubmit={submitHandler}
			className={clsx(
				'flex flex-col justify-between w-full mx-auto grow',
				!inDrawer && 'rounded-sm shadow-slight border-2 border-blue-200'
			)}
		>
			<SidebarHeader
				heading={locationFormTitle}
				text={locationFormSubText}
				className={clsx(
					'rounded-sm',
					!inDrawer ? '!py-10 !px-8' : null
				)}
			/>
			<div className={clsx('flex flex-col grow')}>
				<div
					className={clsx(
						'flex flex-col bg-white grow pt-5',
						!inDrawer ? 'px-8' : 'px-4'
					)}
				>
					<fieldset>
						<legend className="mb-5 text-brand-blue-400 text-base-f font-lexend-medium">
							Are you an employer?
						</legend>

						<div className="flex flex-wrap gap-2 mb-9">
							<RadioButton
								defaultChecked={isEmployer === 'no'}
								labelText="No"
								id={`${id}-employerNo`}
								name="isEmployer"
								value="no"
								onChange={() => setIsEmployer('no')}
							/>

							<RadioButton
								labelText="Yes"
								id={`${id}-employerYes`}
								name="isEmployer"
								value="yes"
								onChange={() => setIsEmployer('yes')}
							/>
						</div>
					</fieldset>
				</div>
				{isEmployer === 'yes' && (
					<div
						className={clsx(
							'flex flex-col pt-1 bg-white grow',
							!inDrawer ? 'px-8' : 'px-4'
						)}
					>
						<fieldset>
							<legend className="mb-5 text-brand-blue-400 text-base-f font-lexend-medium">
								Are you a Peninsula client?
							</legend>

							<div className="flex flex-wrap gap-2 mb-9">
								<RadioButton
									labelText="No"
									id={`${id}-enquireNo`}
									name="isClient"
									value="no"
									onChange={() => setIsClient('no')}
								/>

								<RadioButton
									defaultChecked
									labelText="Yes"
									id={`${id}-enquireYes`}
									name="isClient"
									value="yes"
									onChange={() => setIsClient('yes')}
								/>
							</div>
						</fieldset>

						{isClient === 'yes' ? (
							<Select
								name="speakTo"
								id={`${id}-enquireSpeakTo`}
								labelText="Who would you like to speak to?"
								className="mb-9"
								aria-required="true"
								placeholder="Please make a selection"
								options={
									locale === 'ie' ? contactUsIe : contactUs
								}
								validationMessage="Please make a selection"
								showValidation={!validations?.speakTo?.isValid}
								ref={speakToRef}
							/>
						) : null}
						{locale === '' ? (
							<TextInput
								labelText="Full name"
								id={`${id}-enquireFullName`}
								name="fullName"
								aria-required="true"
								className="w-full wrapper-small"
								validationMessage="Please enter your full name"
								showValidation={!validations.fullName.isValid}
								ref={fullNameRef}
							/>
						) : (
							<>
								<TextInput
									labelText="First name"
									id={`${id}-enquireFirstName`}
									name="firstName"
									aria-required="true"
									className="w-full wrapper-small"
									validationMessage="Please enter your first name"
									showValidation={
										!validations.firstName.isValid
									}
									ref={firstNameRef}
								/>

								<TextInput
									labelText="Last name"
									id={`${id}-enquireLastName`}
									name="lastName"
									aria-required="true"
									className="w-full wrapper-small"
									validationMessage="Please enter your last name"
									showValidation={
										!validations.lastName.isValid
									}
									ref={lastNameRef}
								/>
							</>
						)}

						<TextInput
							labelText="Email address"
							id={`${id}-enquireEmail`}
							name="emailAddress"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.emailAddress.isValid}
							ref={emailAddressRef}
						/>

						<TextInput
							labelText="Telephone number"
							type="tel"
							id={`${id}-enquirePhoneNumber`}
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>

						<Textarea
							labelText="What would you like to discuss?"
							id={`${id}-enquiredDiscuss`}
							name="discuss"
							rows={6}
							validationMessage="Please enter your message"
							showValidation={!validations.discuss.isValid}
							ref={discussRef}
							maxLength={500}
						/>

						<p className="mt-6 text-base-f">
							View our{' '}
							<PBSLink to={privacyLink} variant="Link">
								privacy notice{' '}
							</PBSLink>
						</p>
					</div>
				)}
				{isEmployer === 'no' && (
					<div
						className={clsx(
							'flex flex-col bg-white grow pt-5 font-bold text-base-f text-2xl',
							!inDrawer ? 'px-8 pb-10' : 'px-4'
						)}
					>
						{' '}
						This service is for employers only
					</div>
				)}
			</div>
			{isEmployer === 'yes' && (
				<div
					className={clsx(
						'py-12 bg-white',
						!inDrawer ? 'px-8' : 'px-4'
					)}
				>
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your enquiry, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			)}
		</form>
	);
};

export { EnquireForm };

EnquireForm.defaultProps = {
	id: 'enquire-online',
	inDrawer: false,
};

EnquireForm.propTypes = {
	id: PropTypes.string,
	inDrawer: PropTypes.bool,
	enquireOnlineContent: PropTypes.shape({
		locationFormTitle: PropTypes.string.isRequired,
		locationFormSubText: PropTypes.string,
		locationFormSuccessTitle: PropTypes.string.isRequired,
		locationFormSuccessSubText: PropTypes.string,
		locationFormSuccessBody: PropTypes.string,
	}).isRequired,
};
