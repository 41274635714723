// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { MainHeader } from '../components/molecules/main-header-safecheck';
import { Head } from '../components/templates/head';
import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import { DefaultLayout } from '../layouts/default';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { pageTypes } from '../utils/url-helpers';
// import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { DownloadSuccess } from '../components/atoms/download-success';
import { Drawer } from '../components/atoms/drawer';
import ExcellentReview from '../components/molecules/custom-trustpilot-excellent-reviews';
import { SafeCheckTextListContent } from '../components/molecules/safcheck-text-content';
import { SafeCheckTextImageContent } from '../components/molecules/safecheck-text-image-container';
import { SafeCheckTextWithDarkBgContent } from '../components/molecules/safecheck-with-dark-bgcontent';
import { TwoTextVideoColumn } from '../components/molecules/two-text-video-safecheck';
import { AdviceForm } from '../components/organisms/advise-form-safecheck';
import { CardContainer } from '../components/organisms/card-container';
import { DownloadForm } from '../components/organisms/download-form';
import { FAQ } from '../components/organisms/faq';
import { IconCards } from '../components/organisms/icon-cards-safecheck';
import { SuccessForm } from '../components/organisms/success-form';
import { Testimonials } from '../components/organisms/testimonials';

const SafeCheckPage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = React.useRef(null);
	const downloadFormRef = React.useRef(null);
	const [reference, setReference] = React.useState('');
	const [response, setResponse] = React.useState('');
	const [downloadResponse, setDownloadResponse] = React.useState('');

	const metaContent = {
		noIndex: data.datoCmsSafecheckPage.noIndex,
		noFollow: data.datoCmsSafecheckPage.noFollow,
		metaInformation: data.datoCmsSafecheckPage.metaInformation,
		fallbackTitle: data.datoCmsSafecheckPage.title,
		fallbackDescription: data.datoCmsSafecheckPage.longIntroduction,
		canonicalUrl: data.datoCmsSafecheckPage?.canonicalUrl || location.href,
	};
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.pricing,
			location,
		}),
	};
	const headerCTAContent = {
		...data.datoCmsSafecheckPage.heroHeader[0],
		locale: data.datoCmsSafecheckPage.locale,
		header: data.datoCmsSafecheckPage.heroHeader[0].headerContent.value
			.document.children,
	};
	const textImageDarkBgContent = {
		...data.datoCmsSafecheckPage.textWithDarkBackground[0],
		header: data.datoCmsSafecheckPage.textWithDarkBackground[0].titleHeader
			.value.document.children,
	};
	const blogs = data.allDatoCmsBlogPage.nodes;
	const guides = data.allDatoCmsGuidePage.nodes;
	// add the blog and guide nodes to the related content container
	const mergedContent = [...blogs, ...guides].sort((a, b) =>
		new Date(a.publishedDate) > new Date(b.publishedDate) ? -1 : 1
	);
	const blogContainerContent = {
		...data.datoCmsSafecheckPage.relatedContentContainer,
		cards: mergedContent.slice(0, 3),
	};
	const uspBanner = data.datoCmsSafecheckPage.cards;
	const testimonialsContent = {
		...data.datoCmsSafecheckPage.testimonials,
		title: data.datoCmsSafecheckPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsSafecheckPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};
	const { locale } = data.datoCmsSafecheckPage;
	const faqContent = {
		title: data.datoCmsSafecheckPage.faqContainer.title,
		subText: data.datoCmsSafecheckPage.faqContainer.subText,
		questions: data.datoCmsSafecheckPage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsSafecheckPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<MainHeader
				donwloadDrawerRef={downloadFormRef}
				drawerRef={adviceFormRef}
				{...headerCTAContent}
				rating={data.datoCmsTrustpilotRating}
			/>
			{data.datoCmsSafecheckPage.trustPilotBannerDisplayOnPage && (
				<ExcellentReview
					className="bg-black"
					pageName="safecheck"
					reviews={data.allDatoCmsTrustpilotReview.nodes}
					rating={data.datoCmsTrustpilotRating}
				/>
			)}
			{data.datoCmsSafecheckPage.busniessProtectionDisplayOnPage && (
				<SafeCheckTextListContent
					drawerRef={adviceFormRef}
					textContent={
						data.datoCmsSafecheckPage.textWithCtaContainer[0]
					}
					listContent={data.datoCmsSafecheckPage.listWithImageAndText}
				/>
			)}
			{data.datoCmsSafecheckPage.textWithImageCtaDisplayOnPage && (
				<SafeCheckTextImageContent
					drawerRef={adviceFormRef}
					textImageContent={
						data.datoCmsSafecheckPage.textWithImageAndCta[0]
					}
					header={
						data.datoCmsSafecheckPage.textWithImageAndCta[0].title
							.value.document.children
					}
				/>
			)}

			{data.datoCmsSafecheckPage.textWithDarkBgDisplayOnPage && (
				<SafeCheckTextWithDarkBgContent
					donwloadDrawerRef={downloadFormRef}
					{...textImageDarkBgContent}
				/>
			)}

			{data.datoCmsSafecheckPage.relatedContentDisplayOnPage && (
				<CardContainer
					cardContainerContent={{
						...blogContainerContent,
						to: '/resource-hub/blogs',
						toAll: '/resource-hub/blogs',
					}}
					cardVariant="Blog"
					variant="Center"
					location={location.pathname}
				/>
			)}
			{data.datoCmsSafecheckPage.businessRiskDisplayOnPage && (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					cardtitle={
						data.datoCmsSafecheckPage.cardTitle.value.document
							.children
					}
				/>
			)}
			{data.datoCmsSafecheckPage.testimonialDisplayOnPage && (
				<Testimonials
					drawerRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			{data.datoCmsSafecheckPage.twoColumnTextVideoDisplayOnPage && (
				<TwoTextVideoColumn
					{...data.datoCmsSafecheckPage.twoColumnTextWithVideo[0]}
					cardTitle={
						data.datoCmsSafecheckPage.twoColumnTextWithVideo[0]
							.title.value.document.children
					}
					drawerRef={adviceFormRef}
					locale={data.datoCmsSafecheckPage.locale}
				/>
			)}
			{data.datoCmsSafecheckPage.gotQuestionDisplayOnPage && (
				<div className="bg-white w-full py-1">
					<FAQ faqContent={faqContent} />
				</div>
			)}
			<Drawer
				label="Take the first step towards a safer workplace"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceForm
						locale={locale}
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessForm reference={reference} response={response} />
				)}
			</Drawer>
			<Drawer
				label="Get your free download"
				ref={downloadFormRef}
				open={drawerRef === downloadFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{downloadResponse === '' ? (
					<DownloadForm
						downloadId={
							data.datoCmsSafecheckPage.safecheckDownloadId
						}
						setResponseSent={setDownloadResponse}
						pageUrl={location.href}
						locale={locale}
						leadSource="Website - SafeCheck Report"
						formId={7191}
						formType="SafeCheck"
						title="Get your sample SafeCheck report"
						subText="With SafeCheck, you receive a detailed report which highlights the risks in your business. See what that looks like with your free sample copy – fill in the form to receive yours:"
					/>
				) : (
					<DownloadSuccess page="safecheck" />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

function WrappedSafeCheck({ data, location }) {
	return (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			className="bg-white"
			pageName="safecheck"
		>
			<SafeCheckPage data={data} location={location} />
		</DefaultLayout>
	);
}

export default WrappedSafeCheck;

export const query = graphql`
	query safeCheck($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			footerImageCa {
				url
				alt
			}
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		allDatoCmsTrustpilotReview(
			sort: { order: ASC, fields: rating }
			filter: { source: { ne: "trustpilot" }, locale: { eq: $locale } }
		) {
			nodes {
				id
				isVerified
				locale
				originalId
				pageTag
				source
				personName
				content {
					value
				}
				rating
				title
				date(fromNow: true)
			}
		}
		datoCmsSafecheckPage(locale: { eq: $locale }) {
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			title
			intro
			slug
			noFollow
			noIndex
			locale
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			heroHeader {
				id
				locale
				headerContent {
					value
				}
				subHeader
				primaryButtonText
				primaryButtonOpenForm
				primaryButtonLink
				secondaryButtonText
				secondaryButtonOpenForm
				secondaryButtonLink
				showTrustpilot
				safecheckVideo {
					title
					thumbnail {
						alt
						url
						gatsbyImageData(
							aspectRatio: 53
							placeholder: DOMINANT_COLOR
							width: 500
							sizes: "(max-width: 640px) 400px, 800px"
							height: 300
						)
					}
					vimeoLink {
						thumbnailUrl
						providerUid
						title
						url
						height
						width
					}
				}
			}
			trustPilotBannerDisplayOnPage
			busniessProtectionDisplayOnPage
			textWithCtaContainer {
				id
				title {
					value
				}
				content {
					value
				}
				ctaText
				ctaOpenForm
			}
			safecheckDownloadId
			listWithImageAndText {
				content
				imageType
				id
			}
			textWithImageCtaDisplayOnPage
			textWithImageAndCta {
				title {
					value
				}
				content {
					value
				}
				checkListField {
					id
					text
				}
				ctaText
				ctaOpenForm
				id
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
					alt
				}
			}
			textWithDarkBgDisplayOnPage
			textWithDarkBackground {
				titleHeader {
					value
				}
				subTitle
				ctaPrimaryText
				ctaLink
				id
				imageBlock {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
			}
			relatedContentDisplayOnPage
			relatedContentContainer {
				id
				originalId
				subText
				title
				buttonCopy
			}
			businessRiskDisplayOnPage
			cardTitle {
				value
			}
			cards {
				id
				title
				icon
				content {
					value
				}
			}
			testimonialDisplayOnPage
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			twoColumnTextVideoDisplayOnPage
			twoColumnTextWithVideo {
				id
				title {
					value
				}
				content
				ctaOpenForm
				ctaText
				thumbnail {
					alt
					url
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				videoLink {
					url
					thumbnailUrl
					providerUid
					title
					width
					height
				}
			}
			gotQuestionDisplayOnPage
			faqContainer: gotAQuestionContainer {
				title
				subText
				locale
				id
				internalTitle
				questions {
					id
					question
					locale
					answer {
						value
					}
				}
			}
		}
		allDatoCmsBlogPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: "en" }
				sectors: { elemMatch: { name: { eq: "SafeCheck" } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
				externalLink
			}
		}
		allDatoCmsGuidePage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: "en" }
				sectors: { elemMatch: { name: { eq: "SafeCheck" } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		datoCmsSiteSpecificSetting {
			trialServiceCopy
			trialServiceLink
			trialServiceOpenForm
			logo {
				url
			}
		}
	}
`;
