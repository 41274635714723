import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Field, FormikProvider } from 'formik';
import clsx from 'clsx';
import loadScript from 'load-script2';

import { Button } from '../../../atoms/button';
import { TextInput } from '../../../atoms/text-input';

import { sleep, sendTrackingData, getCookie } from '../../../../utils';
import {
	validateString,
	validateEmail,
	validatePhoneNumber,
} from '../../../../utils/marketo';
import { useFormValidation } from '../../../../hooks/form-validation';
import { useMarketo } from '../../../../contexts/marketo-context';

const CertificateForm = ({ setResponse, pageUrl, className }) => {
	const MktoForms2Instances = useMarketo();
	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');
	const [loading, setLoading] = useState(false);
	const title = 'Access your content';
	const formId = 7372;

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const initialValidations = {
		FirstName: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
		Title: { isValid: true },
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);

	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const { validations, validateForm } = useFormValidation(initialValidations);

	useEffect(() => {
		if (!validations?.FirstName?.isValid && firstNameRef?.current) {
			return firstNameRef.current.focus();
		}

		if (!validations?.LastName.isValid && lastNameRef?.current) {
			return lastNameRef.current.focus();
		}

		if (!validations?.Email?.isValid && emailRef?.current) {
			return emailRef.current.focus();
		}

		if (!validations?.Phone?.isValid && phoneNumberRef?.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations?.Company?.isValid && companyRef?.current) {
			return companyRef.current.focus();
		}
	}, [validations]);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		Title: '',
		PersonSource: 'PBS GLU E-Learning',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
	});
	const submitHandler = async (event) => {
		setLoading(true);
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) {
			setLoading(false);
			return;
		}

		const payloadData = {
			...initialValues,
			FirstName: formEntries.FirstName,
			LastName: formEntries.LastName,
			Phone: formEntries.Phone,
			Email: formEntries.Email,
			Company: formEntries.Company,
			Title: formEntries.Title,
			PersonSource: 'Certificate download',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
		};

		await sleep(500);
		const respData = {
			payloadData,
			formId,
			MktoForms2Instances,
		};
		setResponse(respData);
		handleClickTracking();
	};

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}

		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId]);
	const btnText = 'Generate Certificate';

	return (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className={clsx(
					'flex flex-col justify-between w-full mx-auto overflow-hidden rounded-sm grow card',
					className
				)}
			>
				<div className="flex flex-col grow bg-white">
					<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-10 lg:px-8 grow">
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="FirstName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="FirstName"
											className="text-brand-blue-400 font-lexend-medium"
										>
											First name&#42;
										</label>
										<TextInput
											id="FirstName"
											name="FirstName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={firstNameRef}
											showValidation={
												!validations?.FirstName
													?.isValid &&
												!firstNameRef?.current?.value
											}
											validationMessage={
												!validations?.FirstName?.isValid
													? 'Please enter your first name'
													: null
											}
											{...field}
											space={false}
											placeholder="Enter Your First Name..."
										/>
									</div>
								)}
							</Field>

							<Field name="LastName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="LastName"
											className="text-brand-blue-400 font-lexend-medium"
										>
											Last name&#42;
										</label>
										<TextInput
											id="LastName"
											name="LastName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={lastNameRef}
											showValidation={
												!validations?.LastName
													?.isValid &&
												!lastNameRef?.current?.value
											}
											validationMessage={
												!validations?.LastName?.isValid
													? 'Please enter your last name'
													: null
											}
											{...field}
											space={false}
											placeholder="Enter You Last Name..."
										/>
									</div>
								)}
							</Field>
						</div>
						<Field name="Company" validate={validateString}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Company"
										className="text-brand-blue-400 font-lexend-medium"
									>
										Company&#42;
									</label>
									<TextInput
										id="Company"
										name="Company"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={companyRef}
										showValidation={
											!validations?.Company?.isValid &&
											!companyRef?.current?.value
										}
										validationMessage={
											!validations?.Company?.isValid
												? 'Please enter your company'
												: null
										}
										{...field}
										space={false}
										placeholder="Company LTD"
									/>
								</div>
							)}
						</Field>

						<Field name="Email" validate={validateEmail}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Email"
										className="text-brand-blue-400 font-lexend-medium"
									>
										Email&#42;
									</label>
									<TextInput
										id="Email"
										type="email"
										name="Email"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={emailRef}
										showValidation={
											!validations?.Email?.isValid &&
											!emailRef?.current?.value
										}
										validationMessage="Please enter your email address"
										{...field}
										space={false}
										placeholder="xxxxxxx@gmail.com"
									/>
								</div>
							)}
						</Field>

						<Field name="Phone" validate={validatePhoneNumber}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Phone"
										className="text-brand-blue-400 font-lexend-medium"
									>
										Phone&#42;
									</label>
									<TextInput
										id="Phone"
										name="Phone"
										type="tel"
										title="Please enter numbers only."
										pattern="[0-9]{10,14}"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={phoneNumberRef}
										showValidation={
											!validations?.Phone?.isValid &&
											!phoneNumberRef?.current?.value
										}
										validationMessage="Please enter a phone number"
										{...field}
										space={false}
										placeholder="xxxxxxxxxx"
									/>
								</div>
							)}
						</Field>
						<br />
						<div>
							<label
								htmlFor="NOTE"
								className="text-brand-blue-500 font-lexend-medium"
							>
								<span className="flash-effect text-brand-red-400 font-bold">
									{' '}
									&#42;NOTE :
								</span>{' '}
								Take a moment to review your entries, as any
								mistakes made at this stage will be reflected in
								the generated certificate.
							</label>{' '}
						</div>
					</fieldset>
				</div>
				<div className="flex flex-col lg:items-center item-start lg:px-8 px-4 pt-3 pb-6 bg-white lg:flex-row lg:justify-between">
					<div className="wrapper-small">
						<Button
							type="submit"
							disabled={formik.isSubmitting}
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
						>
							<span
								className={clsx(
									'text-sm',
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{loading ? 'please wait...' : btnText}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	);
};

CertificateForm.defaultProps = {
	className: '',
	headerContent: 'Unlock your free e-learning content',
	subTextContent: 'Fill out the details below to gain access',
};

CertificateForm.propTypes = {
	setResponse: PropTypes.func.isRequired,
	pageUrl: PropTypes.string.isRequired,
	setModalOpen: PropTypes.func.isRequired,
	className: PropTypes.string,
	headerContent: PropTypes.string,
	subTextContent: PropTypes.string,
};

export { CertificateForm };
