// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Drawer } from '../components/atoms/drawer';
import { ContactUsCA } from '../components/organisms/ca/forms/salesforce/contact-us';
import { EnquireFormCA } from '../components/organisms/ca/forms/salesforce/enquire';
import { ContactUs } from '../components/organisms/contact-us';
import { EnquireForm } from '../components/organisms/enquire-form';
import { Head } from '../components/templates/head';

import { pageTypes } from '../utils/url-helpers';

import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import {
	getBreadcrumbsSeoSchema,
	getLocalBusinessSeoSchema,
} from '../utils/seo-schema';

const ContactUsPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const enquireFormRef = useRef(null);

	const { locale } = data.datoCmsContactUsPage;

	const metaContent = {
		noIndex: data.datoCmsContactUsPage.noIndex,
		noFollow: data.datoCmsContactUsPage.noFollow,
		metaInformation: data.datoCmsContactUsPage.metaInformation,
		fallbackTitle: data.datoCmsContactUsPage.title,
		fallbackDescription: data.datoCmsContactUsPage.longIntroduction,
		canonicalUrl: data.datoCmsContactUsPage?.canonicalUrl || location.href,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.contactUs,
			location,
		}),
		localBusiness: getLocalBusinessSeoSchema({
			locations: data.datoCmsContactUsPage.countryTabs,
			url: location.origin,
		}),
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsContactUsPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{locale === 'en-CA' ? (
				<ContactUsCA
					drawerRef={enquireFormRef}
					contactusContent={data.datoCmsContactUsPage}
					enquireOnlineContent={data.datoCmsSiteSpecificSetting}
				/>
			) : (
				<ContactUs
					drawerRef={enquireFormRef}
					contactusContent={data.datoCmsContactUsPage}
					enquireOnlineContent={data.datoCmsSiteSpecificSetting}
				/>
			)}

			{locale !== 'en-CA' ? (
				<Drawer
					label="Enquire online"
					ref={enquireFormRef}
					open={drawerRef === enquireFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					<EnquireForm
						inDrawer
						enquireOnlineContent={data.datoCmsSiteSpecificSetting}
					/>
				</Drawer>
			) : (
				<Drawer
					label="Enquire online"
					ref={enquireFormRef}
					open={drawerRef === enquireFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					<EnquireFormCA
						inDrawer
						enquireOnlineContent={data.datoCmsSiteSpecificSetting}
					/>
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedContactUs = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<ContactUsPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedContactUs;

export const query = graphql`
	query contactUs($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		datoCmsContactUsPage(locale: { eq: $locale }) {
			originalId
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			locale
			heading
			subHeading
			countryTabsTitle
			countryTabs {
				name
				icon
				locations {
					name
					addressTitle
					address
					addressLinkCopy
					addressLink
					contactDetails {
						name
						phoneNumber
						emailAddress
					}
					image {
						url
						alt
					}
				}
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
		}
		datoCmsSiteSpecificSetting {
			locationFormTitle
			locationFormSubText
			locationFormSuccessTitle
			locationFormSuccessSubText
			locationFormSuccessBody
			logo {
				url
			}
		}
	}
`;
