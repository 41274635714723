import React from 'react';
import PropTypes from 'prop-types';
import { TabItem } from './tab-item';

const TabList = ({ title, options }) => {
	const createTab = (option) => {
		const id = option.tabTitle.replaceAll(' ', '-').toLowerCase();

		return <TabItem item={option} tabId={`${id}-tab`} />;
	};

	return (
		<>
			{title ? (
				<h2 className="mb-5 text-xl text-center font-lexend-regular">
					{title}
				</h2>
			) : null}
			{options.map(createTab)}
		</>
	);
};

TabList.defaultProps = {
	title: null,
};

TabList.propTypes = {
	title: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			tabTitle: PropTypes.string.isRequired,
			tabSubTitle: PropTypes.string,
			tabIcon: PropTypes.node,
		})
	).isRequired,
};

export { TabList };
