import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import Spinner from '../../../assets/spinner.inline.svg';

export const ButtonVariants = {
	Default:
		'hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-lexend-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400',
	White: 'bg-white text-blue-400 hover:text-white hover:bg-blue-200 hover:border-blue-400 focus:text-white focus:shadow-focus focus:outline-focus',
	WhiteBlue:
		'bg-white hover:!bg-blue-400 text-blue-400 hover:text-white hover:border-blue-400 focus:text-white focus:shadow-focus focus:outline-focus',
	Ghost: 'bg-transparent text-white border-white focus:shadow-focus focus:outline-focus',
	Link: 'bg-transparent hover:bg-transparent focus:bg-transparent text-blue-400 !border-0 !p-0 underline decoration-1 hover:text-brand-blue-400 focus:shadow-focus focus:outline-focus',
	LinkWithSVG:
		'bg-transparent hover:bg-transparent focus:bg-transparent text-blue-400 border-0 border-b-1 border-b-blue-400 hover:border-b-brand-blue-400 !p-0 rounded-none hover:text-brand-blue-400 focus:shadow-focus focus:outline-focus',
	Small: '!py-2 text-sm md:px-5',
	Tall: 'md:!py-5',
	Tiny: '!py-1 !px-4 text-sm md:!px-4 md:!text-sm !leading-7',
	Normal: '',
	Unset: '',
};

const Button = ({
	children,
	type,
	onClick,
	className,
	variant,
	size,
	isSubmitting,
	...buttonAttributes
}) => (
	<button
		className={clsx(
			ButtonVariants[size],
			variant === 'Unset' ? null : ButtonVariants.Default,
			variant === 'Default' ? 'bg-blue-400 text-white' : null,
			ButtonVariants[variant],
			className
		)}
		{...buttonAttributes}
		type={type} // eslint-disable-line react/button-has-type
		onClick={onClick}
	>
		{isSubmitting ? (
			<span className="flex items-center">
				{children}{' '}
				<Spinner
					width="24"
					height="24"
					fill="currentColor"
					className="ml-2 animate-spin"
				/>
			</span>
		) : (
			children
		)}
	</button>
);

Button.defaultProps = {
	type: undefined,
	onClick: () => {},
	className: '',
	variant: 'Default',
	size: 'Normal',
	isSubmitting: false,
	children: '',
};

Button.propTypes = {
	children: PropTypes.node,
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
	variant: PropTypes.oneOf([
		'Default',
		'White',
		'WhiteBlue',
		'Ghost',
		'Link',
		'Unset',
	]),
	size: PropTypes.oneOf(['Small', 'Normal', 'Tall', 'Tiny']),
	onClick: PropTypes.func,
	className: PropTypes.string,
	isSubmitting: PropTypes.bool,
};

export { Button };
