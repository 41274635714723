import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { PBSLink } from '../../atoms/link';
import { Select } from '../../atoms/select';
import ArrowIcon from '../../../assets/arrow.inline.svg';

const isClient = typeof window === 'object';

const CardFilters = ({
	filterVariant,
	totalCount,
	pagination,
	filter,
	setFilter,
	setPagination,
	error,
	glu,
}) => {
	const { currentPage, totalPages } = pagination;
	const nextPage = currentPage + 1;
	const prevPage = currentPage - 1;

	const disablePrev = currentPage === 0;
	const disableNext = nextPage === totalPages;
	const hasPagination = Object.keys(pagination).length;
	const hasFilters = filter && typeof setFilter === 'function';
	const disabledStyles = 'hover:text-grey-400 text-grey-400 no-underline';

	// eslint-disable-next-line no-shadow
	const onClick = ({ e, currentPage, disabled }) => {
		e.preventDefault();
		if (disabled) return;
		if (isClient) {
			const url = new URL(window.location);
			url.searchParams.set('p', currentPage + 1);
			// eslint-disable-next-line no-unused-expressions
			currentPage === 0
				? url.searchParams.delete('p')
				: url.searchParams.set('p', currentPage + 1);
			window.history.pushState({}, '', url);
		}

		setPagination({
			...pagination,
			currentPage,
		});
	};

	return (
		<div
			className={clsx(
				'flex flex-col w-full',
				error ? 'md:items-baseline' : 'md:items-center',
				filterVariant === 'Top'
					? 'mb-7 md:mb-base-f flex-col-reverse justify-between sm:flex-row sm:items-center'
					: 'mt-base-f md:mt-14 md:mb-10 md:flex-row'
			)}
		>
			<div
				className={clsx(
					'flex flex-row justify-center text-sm md:w-1/4 lg:w-1/3 md:justify-start',
					filterVariant === 'Top' && '!justify-start'
				)}
			>
				{glu && filterVariant === 'Top' ? (
					<h2 className="text-3xl font-bold">On demand sessions</h2>
				) : (
					`${totalCount} results`
				)}
			</div>
			{!hasFilters && error ? (
				<div className="text-brand-red-400">{error}</div>
			) : null}

			{hasPagination ? (
				<div className="flex items-center gap-2 mx-auto mt-s-f md:mt-0 max-w-[380px] w-full md:w-1/2 lg:w-1/3 md:max-w-none md:mx-0">
					<div className="w-1/3">
						<PBSLink
							variant="Link"
							className={clsx(
								disablePrev && disabledStyles,
								'align-middle',
								'focus-visible:outline-none'
							)}
							onClick={(e) =>
								onClick({
									e,
									currentPage: prevPage,
									disabled: disablePrev,
								})
							}
						>
							<ArrowIcon
								viewBox="0 0 33 34"
								className="w-4 h-auto mr-3 pt-[1px]"
							/>
							Previous
						</PBSLink>
					</div>
					<div className="inline w-1/3 text-center text-base-f">
						<p>
							<span className="font-notosans-medium">
								{nextPage}{' '}
							</span>
							of {totalPages}
						</p>
					</div>
					<div className="w-1/3 text-right">
						<PBSLink
							variant="Link"
							className={clsx(
								disableNext && disabledStyles,
								'align-middle',
								'focus-visible:outline-none'
							)}
							onClick={(e) => {
								onClick({
									e,
									currentPage: nextPage,
									disabled: disableNext,
								});
							}}
						>
							Next
							<ArrowIcon
								viewBox="0 0 33 34"
								className={clsx(
									'w-4 h-auto ml-3 pt-[1px] -scale-x-100'
								)}
							/>
						</PBSLink>
					</div>
				</div>
			) : null}

			{hasFilters && filterVariant === 'Top' ? (
				<div className="flex flex-col mb-s-f sm:mb-0 sm:items-end">
					<Select
						id="sort"
						name="sort"
						aria-required="true"
						labelOption={false}
						labelText="Sort by:"
						labelWrapperClassName="sm:!mb-0 sm:self-center sm:mr-3"
						labelClassName="!text-sm"
						selectClassName="sm:w-44 !text-sm"
						className="w-full sm:flex sm:justify-end"
						value={filter}
						options={[
							{
								value: glu ? `_createdAt_DESC` : `created_DESC`,
								label: 'Most recent',
							},
							{
								value: glu ? `_createdAt_ASC` : `created_ASC`,
								label: 'Oldest to newest',
							},
						]}
						onChange={(e) => {
							setFilter(e.target.value);
						}}
					/>
					{error && (
						<div className="mt-2 text-brand-red-400">{error}</div>
					)}
				</div>
			) : null}
		</div>
	);
};

CardFilters.defaultProps = {
	error: null,
	pagination: {},
	filter: '',
	setFilter: () => {},
	setPagination: () => {},
	glu: false,
};

CardFilters.propTypes = {
	filterVariant: PropTypes.oneOf(['Top', 'Bottom']).isRequired,
	totalCount: PropTypes.number.isRequired,
	error: PropTypes.string,
	setFilter: PropTypes.func,
	setPagination: PropTypes.func,
	filter: PropTypes.string,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number,
	}),
	glu: PropTypes.bool,
};

export { CardFilters };
