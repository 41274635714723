import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { SocialShare } from '../../molecules/social-share';

import CalendarIcon from '../../../assets/calendar.inline.svg';

import { authorProp } from '../../../types';

const Author = ({ displayClassNames, layoutClassNames, content }) => {
	const {
		author,
		createdDate,
		createdDateOrdinal,
		lastUpdated,
		lastUpdatedOrdinal,
		pageUrl,
		title,
	} = content;

	const authorImageAlt =
		author.image.alt ||
		author.name ||
		'Peninsula HR & Health and Safty Support';

	return (
		<div className={displayClassNames}>
			<div
				className={clsx(
					'mt-7 lg:items-center lg:flex-row lg:justify-between',
					layoutClassNames
				)}
			>
				<div className="flex">
					<img
						src={author.image.url}
						alt={authorImageAlt}
						className="w-12 h-12 rounded-[100%] mr-4 flex-shrink-0"
					/>
					<div>
						<p className="text-sm font-notosans-medium">
							{author.name},{' '}
							<span
								className={clsx({
									'block font-notosans-light': !createdDate,
								})}
							>
								{author.jobTitle}
							</span>
						</p>

						{createdDate ? (
							<p>
								<CalendarIcon
									className="inline-block mr-2 align-text-top mt-[2px] text-grey-500 w-[18px]"
									aria-hidden="true"
								/>
								<time
									className="inline-block mr-1 text-sm font-lexend-regular text-grey-500"
									dateTime={createdDate}
								>
									{createdDateOrdinal}
								</time>
								{lastUpdated ? (
									<span className="inline-block text-sm font-notosans-regular text-grey-500">
										(Last updated{' '}
										<time dateTime={lastUpdated}>
											{lastUpdatedOrdinal}
										</time>
										)
									</span>
								) : null}
							</p>
						) : null}
					</div>
				</div>
				<div className="flex items-center mt-6 lg-w-full lg:ml-2">
					{pageUrl ? (
						<SocialShare
							title={title}
							url={pageUrl}
							className="flex items-center lg:mt-0 [&>li]:mr-4 [&>li:last-child]:mr-0"
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

Author.defaultProps = {
	displayClassNames: '',
	layoutClassNames: '',
};

Author.propTypes = {
	content: PropTypes.shape({
		title: PropTypes.string.isRequired,
		author: authorProp,
		createdDate: PropTypes.string,
		createdDateOrdinal: PropTypes.string,
		lastUpdated: PropTypes.string,
		lastUpdatedOrdinal: PropTypes.string,
		pageUrl: PropTypes.string,
	}).isRequired,
	displayClassNames: PropTypes.string,
	layoutClassNames: PropTypes.string,
};

export { Author };
