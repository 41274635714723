import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../../../../atoms/button';
// eslint-disable-next-line import/no-cycle
import { CallUs } from '../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../atoms/sidebar-header';
import { Textarea } from '../../../../../atoms/textarea';
import { TextInput } from '../../../../../atoms/text-input';
import { Select } from '../../../../../atoms/select';

import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import { useDrawer } from '../../../../../../contexts/drawer-context';
import { sendTrackingData, pageUrlFomater } from '../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-secondform-ca';

export const SuccessFormCA = ({ reference, response }) => {
	const [details, setDetails] = useState('');
	const [isError, setIsError] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const { setDrawerRef } = useDrawer();

	const numberEmployeesRef = useRef(null);
	const jobTitleRef = useRef(null);
	const industryRef = useRef(null);

	const [numberEmployees, setnumberEmployees] = useState('');
	const [jobTitle, setjobTitle] = useState('');
	const [industry, setIndustry] = useState('');

	const { executeRecaptcha } = useGoogleReCaptcha();
	const { fetchSalesforceCA } = useSalesforceApiCA();

	const submitButtonText = 'Submit';
	const returnButtonText = 'Return to home';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = (clickText) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: response,
			click_text: clickText,
		});
	};

	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const payloadData = {
			id: reference,
			extraInfo: details,
			numberOfEmployees: numberEmployees,
			jobTitle,
			industry,
			pageUrl,
		};

		const sfResponse = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'PATCH'
		);

		if (sfResponse.status !== 200) {
			await fetchSalesforceAzure(payloadData);
		}

		setIsSubmitted(true);
		handleClickTracking(submitButtonText);

		switch (sfResponse.status) {
			case 200:
				setIsError(false);
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	return isSubmitted ? (
		<>
			<SidebarHeader
				heading={
					isError
						? "We've run into a technical error with your submission"
						: 'Thank you for the extra details'
				}
				text={
					isError
						? "Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
						: `One of our advisors will review these and call
								you ${response.split('call you ')[1]}`
				}
				ariaLive="assertive"
			/>
			{isError ? (
				<div className="px-4 pt-10">
					<CallUs />
				</div>
			) : null}
		</>
	) : (
		<>
			<form onSubmit={submitHandler} id="book_a_demo">
				<SidebarHeader heading={response} />
				<div className="flex flex-col px-4 py-10 bg-white grow">
					<p className="mb-12 text-lg font-lexend-regular">
						In preparation for the call, it would be useful if you
						could provide our team with more details about your
						matter. Don&apos;t worry if you&apos;re too busy, they
						can discuss this with you tomorrow.
					</p>

					<TextInput
						labelText="Number of employees"
						id="numberEmployees"
						name="numberEmployees"
						value={numberEmployees}
						onChange={(e) =>
							setnumberEmployees(e.currentTarget.value)
						}
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your number of employees"
						ref={numberEmployeesRef}
					/>

					<TextInput
						labelText="Job Title"
						id="jobTitle"
						name="jobTitle"
						value={jobTitle}
						onChange={(e) => setjobTitle(e.currentTarget.value)}
						aria-required="true"
						className="w-full wrapper-small"
						validationMessage="Please enter your job title"
						ref={jobTitleRef}
					/>

					<Select
						name="industry"
						id="industry"
						value={industry}
						onChange={(e) => setIndustry(e.currentTarget.value)}
						labelText="Industry"
						className="mb-9"
						aria-required="true"
						placeholder="Please make a selection"
						options={[
							{
								value: 'Agricultural',
								label: 'Agriculture',
							},
							{
								value: 'Automotive',
								label: 'Automotive',
							},
							{
								value: 'Construction/Landscaping',
								label: 'Construction',
							},
							{
								value: 'Daycare',
								label: 'Daycare',
							},
							{
								value: 'Dentists',
								label: 'Dental',
							},
							{
								value: 'Educational Service',
								label: 'Education',
							},
							{
								value: 'Beauty/Hair/Spa',
								label: 'Hair & Beauty',
							},
							{
								value: 'Travel and Tourism',
								label: 'Leisure & Hospitality',
							},
							{
								value: 'Medical and Other Health Laboratories',
								label: 'Medical',
							},
							{
								value: 'Manufacturing',
								label: 'Manufacturing',
							},
							{
								value: 'Retail',
								label: 'Retail',
							},
							{
								value: 'Transportation and Storage',
								label: 'Transport & Warehousing',
							},
							{
								value: 'Miscellaneous',
								label: 'Office',
							},
							{
								value: 'Animals - Pets/Groomers',
								label: 'Veterinary',
							},
							{
								value: 'None',
								label: 'Other',
							},
						]}
						validationMessage="Please make a selection"
						ref={industryRef}
					/>

					<Textarea
						labelText="Details about your enquiry - optional"
						id="details"
						value={details}
						name="details"
						onChange={(e) => setDetails(e.currentTarget.value)}
						rows={6}
					/>

					<div className="flex gap-2">
						<Button type="submit">{submitButtonText}</Button>
						<Button
							variant="White"
							onClick={() => {
								setDrawerRef('');
								handleClickTracking(returnButtonText);
							}}
						>
							{returnButtonText}
						</Button>
					</div>
				</div>
			</form>
		</>
	);
};

SuccessFormCA.defaultProps = {
	response: '',
};

SuccessFormCA.propTypes = {
	reference: PropTypes.string.isRequired,
	response: PropTypes.string,
};
