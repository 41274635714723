import React, { useRef, useLayoutEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { PBSLink } from '../../atoms/link';
import { JobCard } from '../../molecules/job-card';
import { CardFilters } from '../../molecules/card-filters';
import { cardContents, cardVariants, eventsCardProp } from '../../../types';

import { sendTrackingData } from '../../../utils';
import { getLocale } from '../../../utils/locale';

const JobCardContainer = ({
	cardContainerContent,
	cardVariant,
	variant,
	location,
	setPagination,
	pagination,
	loading,
	error,
}) => {
	const {
		id,
		title,
		subText,
		buttonCopy,
		toAll,
		cards,
		totalCount,
		viewAllTextOverride,
	} = cardContainerContent;
	const scrollRef = useRef();
	const [currentPageRef, setCurrentPageRef] = useState(
		pagination.currentPage
	);

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	const doScroll = useCallback(() => {
		if (pagination.currentPage !== currentPageRef) {
			setTimeout(() => {
				window.scrollTo({
					top:
						cardVariant === 'Events'
							? scrollRef?.current?.offsetTop
							: 0,
					left: 0,
					behavior: 'smooth',
				});
				setCurrentPageRef(pagination.currentPage);
			}, 500);
		}
	}, [pagination.currentPage, currentPageRef, cardVariant]);

	useLayoutEffect(() => {
		doScroll();
	}, [cards, doScroll]);

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: `${cardVariant?.toLowerCase()}-card`,
			click_type: 'block',
			click_title: title,
			...trackData,
		});
	};

	const Wrapper = ({ className, children }) => {
		Wrapper.defaultProps = {
			className: '',
		};

		Wrapper.propTypes = {
			className: PropTypes.string,
			children: PropTypes.node.isRequired,
		};

		return (
			<section
				className={clsx(
					'px-3 mx-auto max-w-m-screen lg:px-8',
					className
				)}
				aria-labelledby={id}
			>
				{children}
			</section>
		);
	};

	const WrapperScrollable = ({ className, children }) => {
		WrapperScrollable.defaultProps = {
			className: '',
		};

		WrapperScrollable.propTypes = {
			className: PropTypes.string,
			children: PropTypes.node.isRequired,
		};

		return (
			<ul
				className={clsx(
					'w-full grid gap-5 [grid-auto-flow:column]',
					'[grid-auto-columns:minmax(300px,1fr)]',
					'[grid-auto-rows:minmax(150px,1fr)]',
					'md:[grid-auto-columns:minmax(340px,0)]',
					'my-5 py-4 sm:py-5',
					'overflow-x-auto overflow-y-hidden',
					'list-scrollbar',
					cards.length <= 3 ? 'lg:grid-cols-3' : null,
					className
				)}
			>
				{children}
			</ul>
		);
	};

	const WrapperNonScrollable = ({ className, children }) => {
		WrapperNonScrollable.defaultProps = {
			className: '',
		};

		WrapperNonScrollable.propTypes = {
			className: PropTypes.string,
			children: PropTypes.node.isRequired,
		};

		return (
			<ul
				className={clsx(
					'w-full grid gap-x-5 gap-y-2 sm:gap-y-s-f md:gap-y-base-f grid-flow-row',
					'md:grid-cols-2 xl:grid-cols-3 pb-4 sm:pb-5',
					className
				)}
			>
				{children}
			</ul>
		);
	};

	const ViewAllButton = ({ className, textOverride }) => {
		ViewAllButton.defaultProps = {
			className: '',
			textOverride: false,
		};

		ViewAllButton.propTypes = {
			className: PropTypes.string,
			textOverride: PropTypes.bool,
		};

		return (
			<PBSLink
				to={toAll}
				variant="White"
				className={clsx(className)}
				onClick={() =>
					handleClickTracking({
						click_type: 'button',
						click_text: textOverride
							? buttonCopy
							: `View all ${title.toLowerCase()}`,
					})
				}
			>
				{textOverride ? buttonCopy : `View all ${title.toLowerCase()}`}
			</PBSLink>
		);
	};

	const HeadingTitle = ({ className }) => {
		HeadingTitle.defaultProps = {
			className: '',
		};

		HeadingTitle.propTypes = {
			className: PropTypes.string,
		};

		return (
			<h2
				className={clsx(
					'leading-10 font-lexend-regular',
					className
				)}
				id={id}
			>
				{title}
			</h2>
		);
	};

	const renderCard = (card, index) => (
		<li key={`${card.id}-${card.title}${Math.random()}`}>
			<JobCard
				variant={cardVariant}
				index={index}
				cardContent={card}
				onClickProp={handleClickTracking}
				location={location}
				loading={loading}
				locale={locale}
			/>
		</li>
	);

	if (variant === 'Center') {
		return (
			<Wrapper className="py-9 md:py-16">
				<div className="flex flex-col items-center justify-center lg:mx-auto">
					<div className="flex flex-col items-center">
						<HeadingTitle className="text-2xl-f" />
						{subText ? (
							<p className="mt-4 leading-8 text-lg-f font-notosans-light sm:mt-5">
								{subText}
							</p>
						) : null}
					</div>
					<WrapperScrollable>
						{cards.map(renderCard)}
					</WrapperScrollable>
					{buttonCopy ? (
						<PBSLink
							to={`/${locale || ''}${toAll}`}
							variant="White"
							onClick={() =>
								handleClickTracking({
									click_type: 'button',
									click_text: buttonCopy,
									click_index: null,
								})
							}
						>
							{buttonCopy}
						</PBSLink>
					) : null}
				</div>
			</Wrapper>
		);
	}

	if (variant === 'ViewAllScrollable') {
		return (
			<Wrapper className="mb-2xl-f md:mb-20">
				<div className="flex flex-col justify-center lg:mx-auto">
					<div className="flex flex-row items-center">
						<HeadingTitle className="text-xl-f" />
						<span className="pt-1 ml-xxs-f text-s-f md:text-base-f font-lexend-light">
							{totalCount && `(${totalCount})`}
						</span>
						<ViewAllButton
							className="self-center !text-s-f !py-xxs-f !px-4 ml-5 hidden sm:block"
							textOverride={viewAllTextOverride}
						/>
					</div>
					{subText && (
						<p className="my-4 text-lg md:text-xl md:my-6 font-notosans-light">
							{subText}
						</p>
					)}
					<WrapperScrollable
						className={clsx(
							'!pt-0 mt-s-f md:mt-base-f',
							cardVariant === 'Events'
								? '[grid-auto-columns:minmax(295px,1fr)] md:[grid-auto-columns:minmax(410px,0)] lg:[grid-auto-columns:minmax(460px,0)]'
								: null
						)}
					>
						{cards.map(renderCard)}
					</WrapperScrollable>
					<ViewAllButton
						className="self-center mt-s-f !text-s-f !py-3 !px-4 sm:hidden block"
						textOverride={viewAllTextOverride}
					/>
				</div>
			</Wrapper>
		);
	}

	if (variant === 'ViewAllFilters') {
		return (
			<Wrapper className="mt-14 mb-2xl-f md:mb-20">
				<div
					className="flex flex-col justify-center lg:mx-auto"
					ref={scrollRef}
				>
					<WrapperNonScrollable>
						{cards.map(renderCard)}
					</WrapperNonScrollable>
					<CardFilters
						filterVariant="Bottom"
						totalCount={totalCount}
						setPagination={setPagination}
						pagination={pagination}
						error={error}
					/>
				</div>
			</Wrapper>
		);
	}

	return (
		<Wrapper className="mt-14 md:mt-20 mb-2xl-f md:mb-20">
			<div className="flex flex-col justify-center lg:mx-auto">
				<div className="flex flex-row items-center">
					<HeadingTitle className="text-xl-f" />
					<span className="pt-1 ml-xxs-f text-s-f md:text-base-f font-lexend-light">
						({totalCount})
					</span>
					<ViewAllButton
						className="self-center !text-s-f !py-xxs-f !px-4 ml-5 hidden sm:block"
						textOverride={viewAllTextOverride}
					/>
				</div>
				<WrapperNonScrollable className="pb-4 mb-5 sm:pb-5 mt-s-f md:mt-base-f">
					{cards.map(renderCard)}
				</WrapperNonScrollable>
				<ViewAllButton
					className="self-center !text-s-f !py-3 !px-4 sm:hidden block"
					textOverride={viewAllTextOverride}
				/>
			</div>
		</Wrapper>
	);
};

JobCardContainer.defaultProps = {
	location: '',
	variant: '',
	loading: false,
	error: null,
	pagination: {},
	setPagination: () => {},
};

JobCardContainer.propTypes = {
	variant: PropTypes.string,
	cardVariant: cardVariants.isRequired,
	cardContainerContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		to: PropTypes.string,
		toAll: PropTypes.string,
		totalCount: PropTypes.number,
		title: PropTypes.string.isRequired,
		subText: PropTypes.string,
		buttonCopy: PropTypes.string,
		viewAllTextOverride: PropTypes.bool,
		cards: PropTypes.oneOfType([
			PropTypes.arrayOf(cardContents),
			PropTypes.arrayOf(eventsCardProp.cardContent),
		]),
	}).isRequired,
	location: PropTypes.string,
	loading: PropTypes.bool,
	error: PropTypes.string,
	setPagination: PropTypes.func,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number,
	}),
};

export { JobCardContainer };
