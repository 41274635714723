import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Hamburger } from '../hamburger-ca';

import * as modalStyles from './index.module.css';
import { useModal } from '../../../contexts/modal-context';

const Modal = forwardRef(
	(
		{
			label,
			anchor = 'right',
			children,
			className,
			elevation = 20,
			hideBackdrop = false,
			onClose,
			open = false,
			hasClose = false,
			...other
		},
		ref
	) => {
		const modal = useRef(null);
		const { setModalRef } = useModal();

		// Closes the modal when the user clicks outside of it
		const handleClose = (e) => {
			if (e.target === ref.current) {
				setModalRef('');
				if (open && onClose) {
					onClose();
				}
			}
		};

		// Closes the modal when the user presses the escape key
		const handleEscape = (e) => {
			if (e.key === 'Escape' && open) {
				setModalRef('');
				if (onClose) {
					onClose();
				}
			}
		};

		const getKeyboardFocusableElements = (element) =>
			[
				...element.querySelectorAll(
					'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
				),
			].filter(
				(el) =>
					!el.hasAttribute('disabled') &&
					!el.getAttribute('aria-hidden')
			);

		useEffect(() => {
			if (open) {
				modal.current.focus();

				const focusableElements = getKeyboardFocusableElements(
					modal.current
				);
				const firstFocusableElement = focusableElements[0];
				const focusableContent = focusableElements;
				const lastFocusableElement =
					focusableContent[focusableContent.length - 1];

				document.addEventListener('keydown', (e) => {
					if (e.key === 'Escape') {
						setModalRef('');
						if (open && onClose) {
							onClose();
						}
					}

					if (e.shiftKey) {
						if (document.activeElement === firstFocusableElement) {
							if (e.key !== 'Enter') {
								lastFocusableElement.focus();
								e.preventDefault();
							}
						}
					} else if (
						document.activeElement === lastFocusableElement
					) {
						if (e.key !== 'Enter') {
							firstFocusableElement.focus();
							e.preventDefault();
						}
					}
				});
			}

			return () => {
				document.removeEventListener('keyup', handleEscape);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [open]);

		return (
			<div
				role="presentation"
				ref={ref}
				className={clsx(
					modalStyles.modal,
					open ? 'open' : 'closed',
					open ? modalStyles.open : '',
					hideBackdrop ? '' : 'bg-black/20',
					'z-50 !important',
					'fixed',
					'left-0',
					'top-0',
					'min-h-screen',
					'w-full',
					'transition-opacity',
					`z-${elevation}`,
					open ? '' : 'delay-100',
					open ? 'pointer-events-auto' : 'pointer-events-none'
				)}
				onClick={handleClose}
				onKeyUp={handleEscape}
				data-testid="modal"
				{...other}
			>
				<section
					role="dialog"
					aria-modal="true"
					aria-label={label}
					aria-hidden={!open}
					tabIndex="-1"
					ref={modal}
					className={clsx(
						'bg-white',
						'border-2 border-[#9CAAF0] rounded-[1rem]',
						'shadow-modal',
						'fixed',
						'overflow-y-auto',
						'overscroll-contain',
						'm-[8%]',
						'max-w-[90%]',
						'max-h-[90%]',
						'xs:max-h-[95%]',
						'xs:max-w-[90%]',
						'sm:max-h-[90%]',
						'sm:max-w-[90%]',
						'md:max-h-[85%]',
						'md:max-w-[28%]',
						'ml-[8%]',
						'md:ml-[24%]',
						'lg:ml-[28%]',
						'xl:ml-[39%]',
						'xl:-top-[10%]',
						'[&::-webkit-scrollbar]:hidden',
						'transition-transform',
						'ease-in-out',
						'duration-700',
						`z-${elevation}`,
						children && children.props && children.props.className
							? children.props.className
							: '',
						open // eslint-disable-line no-nested-ternary
							? 'translate-0'
							: anchor === 'left'
							? '-translate-full'
							: 'translate-full'
					)}
				>
					{hasClose ? (
						<div className="flex justify-end absolute right-0 top-0">
							<Hamburger
								className="max-w-[200px] -mr-2 m-2 w-[50px]"
								isOpen={open}
								onClick={() => {
									setModalRef('');
									if (open && onClose) {
										onClose();
									}
								}}
								label=""
								activeLabel=""
								width="25"
							/>
						</div>
					) : null}
					{children}
				</section>
			</div>
		);
	}
);

Modal.defaultProps = {
	anchor: 'right',
	children: <></>,
	className: '',
	elevation: 20,
	hideBackdrop: false,
	open: false,
	hasClose: false,
	onClose: () => {},
};

Modal.propTypes = {
	label: PropTypes.string.isRequired,
	anchor: PropTypes.oneOf(['left', 'right']),
	children: PropTypes.node,
	className: PropTypes.string,
	elevation: PropTypes.number,
	hideBackdrop: PropTypes.bool,
	open: PropTypes.bool,
	hasClose: PropTypes.bool,
	onClose: PropTypes.func,
};

export { Modal };
