/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';

import { TagsList } from '../tags-list';
import { gatsbyImageProp } from '../../../types';
import { truncateCategories } from '../../../utils';

import * as styles from '../card/index.module.css';
import { useMediaQuery } from '../../../utils/useMediaQuery';

import { getLocale, buildLink } from '../../../utils/locale';

const CardCaseStudy = ({
	as,
	cardContent,
	to,
	location,
	loading,
	className,
	placement,
}) => {
	const {
		id,
		thumbnail,
		title,
		slug,
		clientName,
		introduction,
		categories,
		externalLink,
	} = cardContent;

	const Element = as || 'article';

	const linkStyles = `inline bg-gradient-to-r [background-size:0_2px] [background-position:0_100%] bg-no-repeat transition-all duration-300 motion-reduce:transition-none before:absolute before:top-0 before:left-0 before:w-full before:h-full focus:[background-size:100%_2px] group-hover:[background-size:100%_2px] focus:outline-none`;

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	if (loading) {
		return (
			<Element
				className={clsx(
					'group relative bg-white h-full w-full',
					'border-2 border-solid rounded-sm shadow-card',
					styles.borderLoading,
					className
				)}
				aria-labelledby={`${id}-card`}
			>
				<div
					className={clsx(
						'w-full h-[220px] md:h-[260px] block',
						styles.bgLoading
					)}
				/>

				<div className="p-4 pt-base-f">
					<div
						className={clsx(
							'flex h-[30px] w-[50%]',
							styles.bgLoading
						)}
					/>

					<div className="mb-base-f">
						<div
							className={clsx(
								'mt-4 flex h-[40px]',
								styles.bgLoading
							)}
						/>

						<div
							className={clsx(
								'mt-4 flex h-[100px]',
								styles.bgLoading
							)}
						/>
					</div>

					<div
						className={clsx(
							'flex pt-4 border-t',
							styles.breakBorderLoading
						)}
					>
						<div
							className={clsx(
								'flex h-[30px] w-[35%] mr-3',
								styles.bgLoading
							)}
						/>
						<div
							className={clsx(
								'flex h-[30px] w-[35%]',
								styles.bgLoading
							)}
						/>
					</div>
				</div>
			</Element>
		);
	}

	const truncatedCategoriesArray = truncateCategories({
		categories,
		limit: 2,
		withEllipsis:
			placement === 'ViewAllScrollable' &&
			useMediaQuery('(max-width: 769px)'),
	});

	return (
		<Element
			className={clsx(
				'flex flex-col',
				'group relative bg-white h-full w-full',
				'border-2 border-blue-200 border-solid rounded-sm shadow-card',
				'transition-shadow duration-300 motion-reduce:transition-none',
				'hover:shadow-default-hover focus-within:shadow-default-hover',
				'focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2',
				className
			)}
			aria-labelledby={`${id}-card`}
		>
			{thumbnail?.responsiveImage ? (
				<picture>
					<source
						type="image/webp"
						sizes={thumbnail.responsiveImage.sizes}
						srcSet={thumbnail.responsiveImage.webpSrcSet}
					/>
					<img
						srcSet={thumbnail.responsiveImage.srcSet}
						sizes={thumbnail.responsiveImage.sizes}
						src={thumbnail.responsiveImage.src}
						alt={thumbnail.responsiveImage.alt || ''}
						width={thumbnail.responsiveImage.width}
						height={thumbnail.responsiveImage.height}
						loading="lazy"
						className="w-full"
					/>
				</picture>
			) : (
				<GatsbyImage
					image={thumbnail?.gatsbyImageData}
					alt={
						thumbnail?.alt ||
						'Peninsula HR & Health and Safty Support'
					}
					className="w-full"
				/>
			)}

			<div className="flex flex-col justify-between p-4 pt-base-f grow">
				<div className="mb-8">
					<p className="text-blue-400 font-notosans-medium text-base-f">
						{clientName}
					</p>

					<h3
						className="overflow-hidden pt-xs-f text-lg-f font-lexend-medium line-clamp-2"
						id={`${id}-card`}
					>
						<Link
							to={
								location === '/'
									? buildLink(locale, externalLink)
									: buildLink(locale, `${to}/${slug}/`)
							}
							className={clsx(
								linkStyles,
								'from-blue-400 to-blue-400 hover:text-blue-400'
							)}
						>
							“{title}”
						</Link>
					</h3>
					<p className="pt-4 overflow-hidden text-base-f font-notosans-light line-clamp-3">
						{introduction}
					</p>
				</div>

				<div className="flex flex-row pt-4 border-t border-brand-pale-500">
					{truncatedCategoriesArray?.length > 0 ? (
						<TagsList tags={truncatedCategoriesArray} />
					) : null}
				</div>
			</div>
		</Element>
	);
};

export { CardCaseStudy };

CardCaseStudy.defaultProps = {
	as: 'article',
	className: '',
	location: '',
	loading: false,
	placement: '',
};

CardCaseStudy.propTypes = {
	className: PropTypes.string,
	as: PropTypes.string,
	to: PropTypes.string.isRequired,
	cardContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		thumbnail: PropTypes.shape({
			alt: PropTypes.string.isRequired,
			gatsbyImageData: gatsbyImageProp.isRequired,
		}),
		title: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
		introduction: PropTypes.string.isRequired,
		clientName: PropTypes.string.isRequired,
		categories: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
			})
		),
		externalLink: PropTypes.string,
	}).isRequired,
	location: PropTypes.string,
	loading: PropTypes.bool,
	placement: PropTypes.string,
};
/* eslint-enable react/prop-types */
