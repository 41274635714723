import React from 'react';

export const SuccessHrSoftware = () => (
	<>
		<div className="flex flex-col justify-between w-full max-w-sm px-4 pt-6 pb-10 mx-auto grow bg-brand-pale-300">
			<div className="flex text-[#E30138] font-lexend-regular text-xl p-4 ">
				Booking Confirmed
			</div>
			<div className="px-4 font-lexend-medium">
				Our HR software experts will get in touch with you as specified.
				In the meantime, you can find out more about our services{' '}
				<a
					href="/ca/"
					className="text-blue-400 underline transition-colors hover:bg-brand-blue-400 focus:bg-brand-blue-500 focus:outline-0 focus:shadow-focus hover:bg-transparent focus:bg-transparent decoration-1 hover:text-brand-blue-400 focus:outline-focus"
				>
					here
				</a>
				.
			</div>
		</div>
	</>
);
