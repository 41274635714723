// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import '../styles/careers.css';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';
import { JobCardContainer } from '../components/organisms/job-card-container';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Title } from '../components/atoms/title';
import VideoPlayer from '../components/glu/molecules/video-player';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { parseDatoLocale } from '../utils/locale';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

import { useFilters } from '../hooks/filters';
import { HrefLang } from '../components/templates/hrefLang';
import { JobCardContainerCA } from '../components/organisms/job-card-container-ca';

const CareersPage = ({ data, location, parentPageData, locale }) => {
	const jobPostQuery = ({
		queryName,
		filter,
		skip,
	}) => `query allJobPosts($locale: SiteLocale = ${
		parseDatoLocale[locale] || 'en'
	}) {
			${queryName}( filter: {slug: {neq: null}}, first: 12, orderBy: ${filter}, skip: ${skip}, locale: $locale, fallbackLocales: ${
		parseDatoLocale[locale] || 'en'
	})  {
			internalJobPost
			jobTitle
			location
			salary
			jobDescription
			slug
		}
	}`;
	const { title, pageContent, video, campanyValuesTitle, companyValuesList } =
		data.datoCmsCareersPage;

	const {
		loading,
		error,
		pagination,
		setPagination,
		filter,
		setFilter,
		items,
	} = useFilters({
		queryName: 'allJobPosts',
		query: jobPostQuery,
		data: data?.allDatoCmsJobPost,
		itemLimit: 12,
		customFilter: 'createdAt_DESC',
	});

	const metaContent = {
		metaInformation: data.datoCmsCareersPage.metaInformation,
		fallbackTitle: data.datoCmsCareersPage.title,
	};

	const blogContainerContent = {
		totalCount: data.allDatoCmsJobPost.totalCount,
		cards: items,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.careers,
			location,
			data: data.datoCmsCareersPage,
			parentPageData,
		}),
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsCareersPage.internal.type,
		data.datoCmsCareersPage
	);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsCareersPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title={title}
				className="!text-left px-3 mx-auto max-w-m-screen lg:px-8"
			/>
			<div className="px-3 mx-auto max-w-m-screen lg:px-8 flex flex-col lg:block content">
				<div className="lg:float-right order-2 lg:w-1/2">
					{video?.providerUid && (
						<div className="mb-8 lg:mb-4 lg:ml-8 aspect-video">
							<VideoPlayer videoEmbed={video} />
						</div>
					)}
				</div>
				<div
					className="mb-8 lg:mb-0 order-1"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: pageContent }}
				/>
			</div>

			{locale === 'en-CA' ? (
				<JobCardContainerCA
					locale={locale}
					variant="ViewAllFilters"
					cardContainerContent={{
						...blogContainerContent,
						id: 'jobPost-container',
						title: 'Job Posts',
					}}
					loading={loading}
					error={error}
					filter={filter}
					setFilter={setFilter}
					setPagination={setPagination}
					pagination={pagination}
				/>
			) : (
				<JobCardContainer
					variant="ViewAllFilters"
					cardContainerContent={{
						...blogContainerContent,
						id: 'jobPost-container',
						title: 'Job Posts',
					}}
					loading={loading}
					error={error}
					filter={filter}
					setFilter={setFilter}
					setPagination={setPagination}
					pagination={pagination}
				/>
			)}

			<div className="px-3 mx-auto max-w-m-screen lg:px-8 md:mb-20">
				<h3 className="text-2xl">{campanyValuesTitle}</h3>
				<div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-8 my-8">
					{companyValuesList.map((companyValue) => (
						<div className="bg-brand-pale-400 text-center px-s-f py-base-f md:px-xs-f rounded-sm transition-colors duration-200 border-2 border-blue-300">
							<p className="py-4">{companyValue.text}</p>
						</div>
					))}
				</div>
			</div>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query careersPage($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
		}
		datoCmsCareersPage(locale: { eq: $locale }) {
			internal {
				type
			}
			title
			allSeoLocales: _allSeoLocales {
				locale
			}
			seo {
				description
				title
				twitterCard
				image {
					gatsbyImageData
				}
			}
			pageContent
			video {
				width
				url
				title
				thumbnailUrl
				providerUid
				provider
				height
			}
			campanyValuesTitle
			companyValuesList {
				text
			}
		}
		allDatoCmsJobPost(
			sort: { order: DESC, fields: meta___createdAt }
			limit: 12
			filter: { locale: { eq: $locale } }
		) {
			totalCount
			nodes {
				internalJobPost
				jobTitle
				location
				salary
				jobDescription
				slug
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedCareersPage = ({ data, pageContext, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<CareersPage
			data={data}
			location={location}
			categoryOriginalIds={pageContext.categoryOriginalIds}
			parentPageData={pageContext.parentPageData}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedCareersPage;
