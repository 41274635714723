// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
	StructuredText,
	renderNodeRule,
	renderMarkRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading, isListItem } from 'datocms-structured-text-utils';
import { Button } from '../../../atoms/button';

const ChecklistWithImage = ({ listImage, alt, content, onClick }) => (
	<section className="flex flex-col items-center py-5 lg:py-10 lg:flex-row">
		<div className="w-full lg:w-1/2 lg:pr-10 [&>ul]:list-disc [&>ul]:pl-8">
			<StructuredText
				data={content.value}
				customNodeRules={[
					renderNodeRule(isHeading, ({ node, children, key }) => {
						const HeadingTag = `h${node.level}`;
						return (
							<HeadingTag
								className="my-5 text-4xl lg:text-5xl font-lexend-regular !leading-[3.5rem]"
								key={key}
							>
								{children}
							</HeadingTag>
						);
					}),
					renderNodeRule(isListItem, ({ children, key }) => (
						<li
							className="mt-5 mb-4 text-sm font-lexend-light"
							key={key}
						>
							{children}
						</li>
					)),
				]}
				customMarkRules={[
					renderMarkRule('highlight', ({ children, key }) => (
						<span
							className="text-brand-red-400 pb-1 bg-no-repeat bg-underline bg-bottom bg-[length:100%_6px] md:bg-[length:100%_8px]"
							key={key}
						>
							{children}
						</span>
					)),
				]}
			/>
			<div className="flex flex-col items-center gap-5 mt-8 2xl:w-1/2 xl:w-2/3 w-full lg:flex-row lg:pt-0 shrink-0">
				<Button
					onClick={onClick}
					className="text-center !text-sm !block w-full"
					size="Small"
					type="button"
				>
					Access your premium GLU content
				</Button>
			</div>
		</div>
		<div className="w-full p-8 lg:w-1/2">
			<GatsbyImage image={listImage.gatsbyImageData} alt={alt || ''} />
		</div>
	</section>
);

export default ChecklistWithImage;
