import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import loadScript from 'load-script2';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isHeading,
	isParagraph,
	isLink,
	isRoot,
	isList,
} from 'datocms-structured-text-utils';

import { InlineRecord } from './inline-record';
import { PBSLink } from '../../atoms/link';
import { TickList } from '../../atoms/tick-list';

import { structuredTextContent, refProp } from '../../../types';

import * as rteContentStyles from './index.module.css';
import '../../../styles/blog.css';
import VideoPlayer from '../../glu/molecules/video-player';
import { getCookie } from '../../../utils';
import DownloadIcon from '../../../assets/download.inline.svg';
// import { Button } from '../../atoms/button';

const RteContent = ({
	content,
	containerClassName,
	drawerRef,
	borderTop,
	borderBottom,
	buttonFormRef,
}) => {
	let headingId = 0;
	const headingStyles = {
		h2: 'my-4 md:my-8 first:mt-0 text-2xl md:text-3xl font-lexend-regular',
		h3: 'my-4 md:my-5 text-xl md:text-2xl',
		h4: 'my-4 md:my-5 text-lg md:text-xl',
		h5: 'my-4 md:my-5 text-lg md:text-xl',
		h6: 'my-4 md:my-5 text-lg md:text-xl',
	};
	const [videoFinished, setVideoFinished] = React.useState();
	const [scriptReady, setScriptReady] = React.useState(false);
	React.useEffect(() => {
		const loadVideoScript = () =>
			loadScript('https://player.vimeo.com/api/player.js')
				.then(() => {
					setScriptReady(true);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadVideoScript);
		} else {
			setTimeout(loadVideoScript);

			setTimeout(() => {
				loadVideoScript();
			}, 100);
		}
	}, []);

	const videosWatchedList = React.useMemo(() => {
		if (getCookie('videoPlayed')) {
			return getCookie('videoPlayed').includes('%')
				? getCookie('videoPlayed').split('%2C')
				: [getCookie('videoPlayed')];
		}
		return [];
	}, []);
	return (
		<section
			className={clsx(
				rteContentStyles.container,
				containerClassName,
				borderTop || borderBottom
					? 'border-solid border-blue-200 my-xl-f py-xl-f'
					: null,
				borderTop ? 'border-t-1' : null,
				borderBottom ? 'border-b-1' : null
			)}
		>
			<StructuredText
				data={content}
				renderInlineRecord={({ record }) => (
					<InlineRecord
						record={record}
						buttonFormRef={buttonFormRef || ''}
						drawerRef={drawerRef || ''}
					/>
				)}
				customNodeRules={[
					renderNodeRule(isHeading, ({ node, children, key }) => {
						const HeadingTag = `h${node.level}`;
						const headingClassNames = headingStyles[HeadingTag];

						let id = null;
						if (node.level === 2) {
							id = `t-${headingId}`;
							headingId += 1;
						}

						return (
							<HeadingTag
								className={clsx(headingClassNames)}
								key={key}
								id={id}
							>
								{children}
							</HeadingTag>
						);
					}),
					renderNodeRule(isLink, ({ node, children, key }) => (
						<PBSLink to={node.url} variant="Link" key={key}>
							{children}
						</PBSLink>
					)),
					renderNodeRule(isList, ({ node, children, key }) => {
						let ListTag;
						let listClassName;

						if (node.style === 'bulleted') {
							ListTag = 'ul';
							listClassName = 'list-disc';
						}
						if (node.style === 'numbered') {
							ListTag = 'ol';
							listClassName = 'list-decimal';
						}

						return (
							<ListTag
								className={clsx(
									'mb-4 md:mb-5 text-lg font-lexend-light md:text-xl list-inside',
									listClassName,
									rteContentStyles.list
								)}
								key={key + node.style + rteContentStyles.list}
							>
								{children}
							</ListTag>
						);
					}),
					renderNodeRule(
						isParagraph,
						({
							adapter: { renderNode },
							node,
							children,
							key,
							ancestors,
						}) => {
							if (node.children[0].type === 'inlineItem') {
								return (
									<React.Fragment key={key}>
										{children}
									</React.Fragment>
								);
							}
							if (isRoot(ancestors[0])) {
								return renderNode(
									'p',
									{
										key,
										className:
											'mb-4 text-lg md:mb-5 font-lexend-light md:text-xl last-of-type:mb-0',
									},
									children
								);
							}
							return (
								<React.Fragment key={key}>
									{children}
								</React.Fragment>
							);
						}
					),
				]}
				renderBlock={({ record }) => {
					// eslint-disable-next-line no-underscore-dangle
					switch (record.__typename) {
						case 'DatoCmsTickListContainer':
							return (
								<TickList
									className="grid text-lg gap-y-5 gap-x-8 md:grid-cols-2 mt-lg-f md:text-xl"
									listItemClassName="flex items-start !mb-0"
									textClassName="mb-[6px]"
									points={record.listItems}
								/>
							);
						case 'DatoCmsBlogTable':
							return (
								<>
									<div
										dangerouslySetInnerHTML={{
											__html: record.tableContent,
										}}
										className="my-8 md:mb-5 text-lg font-lexend-light md:text-xl blog-custom "
									/>
								</>
							);
						case 'DatoCmsImageBlock':
							return (
								<div className="grid grid-cols-1 gap-5">
									<div className="bg-white flex flex-col items-center my-2">
										<img
											src={record.image.url}
											alt={record?.image.alt}
											// width={record.image.width}
											// height={record.image.height}
											className="object-cover bg-white w-full md:w-[90%] h-auto"
										/>
										{record.hasImageCredit && (
											<div className=" bg-brand-pale-400 flex items-start justify-start border-blue-200 border-2 w-full md:w-[90%] h-auto">
												<span className="text-lg text-black py-2 px-3 font-lexend-medium">
													{record.imageCredit}
												</span>
											</div>
										)}
									</div>
								</div>
							);
						case 'DatoCmsQuoteBlock':
							return (
								<blockquote className=" relative p-5 my-5 text-lg font-lexend-light bg-brand-pale-400 rounded-sm border-blue-200 border-2">
									<div className="flex flex-col border-l-[6px] border-blue-400 pl-5">
										<cite className="mb-4  text-xl md:text-2xl font-lexend-light">
											{record.quote}
										</cite>
										<span className="text-sm font-bold text-brand-blue-200">
											{record.quoteCredit}
										</span>
									</div>
								</blockquote>
							);
						case 'DatoCmsVideoBlock':
							return (
								<div className="bg-white flex flex-col my-1">
									{record?.videoLink?.providerUid && (
										<div className="mb-2 lg:mb-0 lg:basis-1/2 aspect-video">
											<VideoPlayer
												videoEmbed={record?.videoLink}
												setVideoFinished={
													setVideoFinished
												}
												scriptReady={scriptReady}
												videosWatchedList={
													videosWatchedList
												}
											/>
											{record.videoCaption &&
												videoFinished && (
													<div className="bg-brand-pale-400 flex items-start justify-start w-full border-blue-200 border-2 h-auto">
														<span className="text-lg text-black py-2 px-3 font-lexend-medium">
															{
																record.videoCaption
															}
														</span>
													</div>
												)}
										</div>
									)}
								</div>
							);
						case 'DatoCmsDownloadBlock':
							return (
								<section className="px-3 mx-auto max-w-content mb-14 md:mb-20 md:px-8 xl:px-0">
									<article className="gradient px-4 py-5 lg:p-7 rounded-tr-[120px]">
										<hgroup className="max-w-[85%]">
											<h2 className="mb-4 text-2xl text-white md:text-3xl">
												{record.title}
											</h2>
										</hgroup>
										<PBSLink
											variant="White"
											className="mt-7"
											to={record.asset.url}
											target="_blank"
										>
											<DownloadIcon
												width={25}
												height={25}
												aria-hidden="true"
												className="mr-2"
											/>{' '}
											{record.ctaText}
										</PBSLink>
									</article>
								</section>
							);
						default:
							return null;
					}
				}}
				customMarkRules={[
					renderMarkRule('strong', ({ children, key }) => (
						<span className="font-lexend-regular" key={key}>
							{children}
						</span>
					)),
				]}
			/>
		</section>
	);
};

export { RteContent };

RteContent.defaultProps = {
	containerClassName: '',
	drawerRef: () => {},
	buttonFormRef: () => {},
	borderTop: false,
	borderBottom: false,
};

RteContent.propTypes = {
	content: structuredTextContent.isRequired,
	containerClassName: PropTypes.string,
	drawerRef: refProp,
	buttonFormRef: refProp,
	borderTop: PropTypes.bool,
	borderBottom: PropTypes.bool,
};
