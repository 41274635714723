import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { PBSLink } from '../../atoms/link';
import { Button } from '../../atoms/button';
import { HeadingTagLine } from '../../atoms/heading-tag-line';

import { titleProp, refProp } from '../../../types';
import { useDrawer } from '../../../contexts/drawer-context';
import { getLocale, buildLink } from '../../../utils/locale';

const AdviceStages = ({ id, adviceStagesContent, drawerRef }) => {
	const {
		title,
		content,
		advice,
		secondaryLink,
		primaryLinkCopy,
		secondaryLinkCopy,
	} = adviceStagesContent;

	const { setDrawerRef } = useDrawer();

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	const getLinks = () => (
		<p className="flex flex-col items-center xl:flex-row lg:-ml-lg-f xl:ml-0">
			{primaryLinkCopy ? (
				<Button
					onClick={() => setDrawerRef(drawerRef)}
					className="whitespace-nowrap"
				>
					{primaryLinkCopy}
				</Button>
			) : null}
			{secondaryLink && secondaryLinkCopy ? (
				<PBSLink
					to={buildLink(locale, secondaryLink)}
					variant="Link"
					className={clsx(
						'text-center',
						'mt-base-f xl:ml-base-f xl:mt-0',
						'xl:max-w-[190px]',
						!primaryLinkCopy && '!ml-0 !max-w-none'
					)}
				>
					{secondaryLinkCopy}
				</PBSLink>
			) : null}
		</p>
	);

	const getAdvice = (
		{ title: adviceTitle, content: adviceContent },
		index
	) => {
		const num = index + 1;
		const numTitle = `${num}. `;
		const lastElement = advice.length === num;
		const marginLeft = ['lg:ml-0', 'lg:ml-s-f', `lg:ml-lg-f`];
		const marginRight = ['lg:mr-lg-f', 'lg:mr-s-f', `lg:mr-0`];
		const panelColors = [
			'bg-white',
			'bg-brand-pale-300',
			'bg-brand-pale-500',
		];
		return (
			<article
				key={adviceTitle.replace(/[^A-Z0-9]/gi, '')}
				className={clsx(
					'py-s-f px-xs-f lg:p-s-f border rounded-sm shadow-slight',
					marginLeft[index],
					marginRight[index],
					!lastElement && 'mb-s-f lg:mb-6',
					panelColors[index]
				)}
			>
				<span className="mt-6 text-xl md:text-2xl font-lexend-medium">
					{numTitle}
					{adviceTitle}
				</span>
				<p className={clsx('mt-3', 'text-base-f', 'font-notosans-light')}>
					{adviceContent}
				</p>
			</article>
		);
	};

	const contentHtml = content.split('\n\n').map((item, i, arr) => (
		<p
			key={item.replace(/[^A-Z0-9]/gi, '')}
			className={clsx('text-base-f', i !== arr.length - 1 && 'mb-s-f')}
		>
			{item}
		</p>
	));

	return (
		<section
			aria-labelledby={id}
			className="border-b-4 bg-brand-pale-400 lg:border-b-6 border-b-blue-400"
		>
			<div className="grid grid-flow-row lg:grid-cols-[1fr_fit-content(55%)] gap-0 lg:gap-[5%] py-xl-f xl:py-2xl-f px-xs-f lg:px-8 2xl:px-0 2xl:max-w-m-screen mx-auto">
				<div className="flex flex-col justify-center">
					<HeadingTagLine
						id={id}
						title={title}
						subText={contentHtml}
						headingClassNames="text-left"
						subTextClassNames="text-left mt-base-f mb-lg-f !lg:mt-lg-f !lg:mb-lg-f"
					/>
					<div className="hidden lg:block">{getLinks()}</div>
				</div>
				<div className="mb-lg-f lg:mb-0">{advice.map(getAdvice)}</div>
				<div className="block lg:hidden">{getLinks()}</div>
			</div>
		</section>
	);
};

AdviceStages.defaultProps = {
	id: 'advice-stages',
};

AdviceStages.propTypes = {
	id: PropTypes.string,
	adviceStagesContent: PropTypes.shape({
		title: titleProp.isRequired,
		content: PropTypes.string,
		secondaryLink: PropTypes.string,
		primaryLinkCopy: PropTypes.string,
		secondaryLinkCopy: PropTypes.string,
		advice: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				content: PropTypes.string,
			})
		).isRequired,
	}).isRequired,
	drawerRef: refProp.isRequired,
};

export { AdviceStages };
