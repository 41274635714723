// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isHeading,
	isParagraph,
	isLink,
	isRoot,
	isList,
} from 'datocms-structured-text-utils';

import { InlineRecord } from './inline-record';
import { PBSLink } from '../../atoms/link';
import { TickList } from '../../atoms/tick-list';

import { structuredTextContent, refProp } from '../../../types';

import * as rteContentStyles from './index.module.css';
import { SocialShare } from '../../molecules/social-share';

const RteContent = ({
	content,
	containerClassName,
	drawerRef,
	borderTop,
	borderBottom,
	buttonFormRef,
	shareContent,
}) => {
	let headingId = 0;
	const headingStyles = {
		h2: 'my-4 md:my-8 first:mt-0 text-2xl md:text-3xl font-lexend-regular',
		h3: 'my-4 md:my-5 text-xl md:text-2xl',
		h4: 'my-4 md:my-5 text-lg md:text-xl',
		h5: 'my-4 md:my-5 text-lg md:text-xl',
		h6: 'my-4 md:my-5 text-lg md:text-xl',
	};

	return (
		<section
			className={clsx(
				rteContentStyles.container,
				containerClassName,
				borderTop || borderBottom
					? 'border-solid border-blue-200 my-xl-f py-xl-f'
					: null,
				borderTop ? 'border-t-1' : null,
				borderBottom ? 'border-b-1' : null
			)}
		>
			<StructuredText
				data={content}
				renderInlineRecord={({ record }) => (
					<InlineRecord
						record={record}
						buttonFormRef={buttonFormRef || ''}
						drawerRef={drawerRef || ''}
					/>
				)}
				customNodeRules={[
					renderNodeRule(isHeading, ({ node, children, key }) => {
						const HeadingTag = `h${node.level}`;
						const headingClassNames = headingStyles[HeadingTag];

						let id = null;
						if (node.level === 2) {
							id = `t-${headingId}`;
							headingId += 1;
						}

						return (
							<HeadingTag
								className={clsx(headingClassNames)}
								key={key}
								id={id}
							>
								{children}
							</HeadingTag>
						);
					}),
					renderNodeRule(isLink, ({ node, children, key }) => (
						<PBSLink to={node.url} variant="Link" key={key}>
							{children}
						</PBSLink>
					)),
					renderNodeRule(isList, ({ node, children, key }) => {
						let ListTag;
						let listClassName;

						if (node.style === 'bulleted') {
							ListTag = 'ul';
							listClassName = 'list-disc';
						}
						if (node.style === 'numbered') {
							ListTag = 'ol';
							listClassName = 'list-decimal';
						}

						return (
							<ListTag
								className={clsx(
									'mb-4 md:mb-5 text-lg font-lexend-light md:text-xl list-inside',
									listClassName,
									rteContentStyles.list
								)}
								key={key + node.style + rteContentStyles.list}
							>
								{children}
							</ListTag>
						);
					}),
					renderNodeRule(
						isParagraph,
						({
							adapter: { renderNode },
							node,
							children,
							key,
							ancestors,
						}) => {
							if (node.children[0].type === 'inlineItem') {
								return (
									<React.Fragment key={key}>
										{children}
									</React.Fragment>
								);
							}
							if (isRoot(ancestors[0])) {
								return renderNode(
									'p',
									{
										key,
										className:
											'mb-4 text-lg md:mb-5 font-lexend-light md:text-xl last-of-type:mb-0',
									},
									children
								);
							}
							return (
								<React.Fragment key={key}>
									{children}
								</React.Fragment>
							);
						}
					),
				]}
				renderBlock={({ record }) => {
					// eslint-disable-next-line no-underscore-dangle
					switch (record.__typename) {
						case 'DatoCmsTickListContainer':
							return (
								<TickList
									className="grid text-lg gap-y-5 gap-x-8 md:grid-cols-2 mt-lg-f md:text-xl"
									listItemClassName="flex items-start !mb-0"
									textClassName="mb-[6px]"
									points={record.listItems}
								/>
							);
						default:
							return null;
					}
				}}
				customMarkRules={[
					renderMarkRule('strong', ({ children, key }) => (
						<span className="font-lexend-regular" key={key}>
							{children}
						</span>
					)),
				]}
			/>

			<div className="mt-12 lg:mt-16 flex flex-row justify-center items-center">
				<SocialShare
					title={shareContent.title}
					url={shareContent.pageUrl}
					className="flex items-center lg:mt-0 [&>li]:mr-4 [&>li:last-child]:mr-0"
				/>
			</div>
		</section>
	);
};

export { RteContent };

RteContent.defaultProps = {
	containerClassName: '',
	drawerRef: () => {},
	buttonFormRef: () => {},
	borderTop: false,
	borderBottom: false,
};

RteContent.propTypes = {
	content: structuredTextContent.isRequired,
	containerClassName: PropTypes.string,
	drawerRef: refProp,
	buttonFormRef: refProp,
	borderTop: PropTypes.bool,
	borderBottom: PropTypes.bool,
};
