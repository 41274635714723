// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';

const upArrowSvg = (
	<svg
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 6.66667C12.6362 6.66667 6.66665 12.6362 6.66665 20C6.66665 27.3638 12.6362 33.3333 20 33.3333C27.3638 33.3333 33.3333 27.3638 33.3333 20C33.3333 12.6362 27.3638 6.66667 20 6.66667ZM3.33331 20C3.33331 10.7953 10.7952 3.33333 20 3.33333C29.2047 3.33333 36.6666 10.7953 36.6666 20C36.6666 29.2047 29.2047 36.6667 20 36.6667C10.7952 36.6667 3.33331 29.2047 3.33331 20ZM20 19.0237L23.8215 22.8452C24.4723 23.4961 25.5276 23.4961 26.1785 22.8452C26.8294 22.1943 26.8294 21.139 26.1785 20.4882L21.1785 15.4882C20.5276 14.8373 19.4723 14.8373 18.8215 15.4882L13.8215 20.4882C13.1706 21.139 13.1706 22.1943 13.8215 22.8452C14.4723 23.4961 15.5276 23.4961 16.1785 22.8452L20 19.0237Z"
			fill="#3954E0"
		/>
	</svg>
);

const downArrowSvg = (
	<svg
		width="30"
		height="30"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 33.3333C27.3638 33.3333 33.3334 27.3638 33.3334 20C33.3334 12.6362 27.3638 6.66666 20 6.66666C12.6362 6.66666 6.66668 12.6362 6.66669 20C6.66669 27.3638 12.6362 33.3333 20 33.3333ZM36.6667 20C36.6667 29.2047 29.2048 36.6667 20 36.6667C10.7953 36.6667 3.33335 29.2047 3.33335 20C3.33335 10.7953 10.7953 3.33333 20 3.33333C29.2048 3.33333 36.6667 10.7953 36.6667 20ZM20 20.9763L16.1785 17.1548C15.5277 16.5039 14.4724 16.5039 13.8215 17.1548C13.1706 17.8057 13.1706 18.861 13.8215 19.5118L18.8215 24.5118C19.4724 25.1627 20.5277 25.1627 21.1785 24.5118L26.1785 19.5118C26.8294 18.861 26.8294 17.8057 26.1785 17.1548C25.5277 16.5039 24.4724 16.5039 23.8215 17.1548L20 20.9763Z"
			fill="#3954E0"
		/>
	</svg>
);

const VapLink = ({ vapLinkShow, setVapLinkShow, text, className }) => (
	<button
		type="button"
		onClick={() => {
			setVapLinkShow(!vapLinkShow);
			window.location.href = '#vapform';
		}}
	>
		{text ? (
			<div
				className={`inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-lg transition-colors font-lexend-medium leading-6 text-blue-400 underline justify-center w-full mt-4 sm:w-max sm:mt-0 sm:ml-4 ${className}`}
			>
				<div className="mr-2">{text}</div>
				<div className="">{downArrowSvg}</div>
			</div>
		) : (
			<div className="mt-6 text-blue-400 text-lg">{upArrowSvg}</div>
		)}
	</button>
);

export default VapLink;
