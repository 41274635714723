import React from 'react';

function usePrivacyLink(locale) {
	const [privacyLink, setPrivacyUrl] = React.useState('');
	React.useEffect(() => {
		const caUrl =
			'https://www.peninsulagrouplimited.com/ca/privacy-policy/';
		const ieUrl =
			'https://www.peninsulagrouplimited.com/ie/privacy-policy/';
		const auUrl =
			'https://www.datocms-assets.com/133532/1730075122-privacy-policy-aus.pdf';
		const enUrl =
			'https://www.peninsulagrouplimited.com/legal/privacy-policy/';
		switch (locale) {
			case 'ca':
				setPrivacyUrl(caUrl);
				break;
			case 'au':
				setPrivacyUrl(auUrl);
				break;
			case 'ie':
				setPrivacyUrl(ieUrl);
				break;
			case 'en-CA':
				setPrivacyUrl(caUrl);
				break;
			default:
				setPrivacyUrl(enUrl);
				break;
		}
	}, [locale]);
	return { privacyLink };
}

export default usePrivacyLink;
