import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import { Button } from '../../atoms/button';
import { InputVariants } from '../../atoms/text-input';
import { sendTrackingData } from '../../../utils';
import {
	annualCost,
	hsDocs,
	costOfLatnessAbsence,
	staffTurnover,
	draftingPolicy,
	averageTribunal,
	generalHr,
	totalCost,
	costPerEmployee,
	annualRoi,
	draftingContract,
	fineContract,
	hsFines,
} from './dataIE';
import { getLocale } from '../../../utils/locale';

const VapCalculatorIE = ({
	setPriceCalculated,
	setEmployeeCount,
	setShowLargeBusiness,
	setShow,
	title,
	subtitle,
}) => {
	const elName = 'employeeCount';
	const buttonText = 'Calculate cost';
	const [inputValue, setInputValue] = useState('');

	const handleOnChange = (e) => {
		setInputValue(e.target.value);
	};
	const location = useLocation();
	const locale = getLocale(location.pathname);

	const getCurrency = (count = null) => {
		let currency = '£';

		switch (locale) {
			case 'ie':
				currency = '€';
				break;
			case 'ca':
				currency = '$';
				break;
			default:
				currency = '£';
				break;
		}

		if (count > 99) {
			return '';
		}
		return currency;
	};
	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-calculator',
			click_type: 'primary',
			click_action: 'change state',
			click_title: title,
			click_text: buttonText,
		});
	};

	const submitHandler = async (event) => {
		event.preventDefault();

		const formData = new FormData(event.target);
		const employeeCount = formData.get('employeeCount');
		if (!employeeCount) {
			return;
		}

		setEmployeeCount(parseInt(employeeCount, 10));
		setShow(true);
		setShowLargeBusiness(employeeCount > 49);

		setPriceCalculated({
			drafting_contract: `${getCurrency()}${draftingContract}`,
			drafting_policy: `${getCurrency()}${draftingPolicy}`,
			hs_docs: `${getCurrency()}${hsDocs}`,
			fine_contract: `${getCurrency()}${fineContract}`,
			lateness_absence: `${getCurrency()}${costOfLatnessAbsence(
				employeeCount
			)}`,
			staff_turnover: `${getCurrency()}${staffTurnover}`,
			general_hr: `${getCurrency()}${generalHr}`,
			hs_fines: `${getCurrency()}${hsFines}`,
			average_tribunal: `${getCurrency()}${averageTribunal}`,
			total_cost: `${getCurrency()}${totalCost(employeeCount)}`,
			annual_cost: `${getCurrency(employeeCount)}${annualCost(
				employeeCount
			)}`,
			cost_per_employee: `${getCurrency(employeeCount)}${costPerEmployee(
				employeeCount
			)}`,
			annual_roi: `${getCurrency(employeeCount)}${annualRoi(
				employeeCount
			)}`,
		});
		handleClickTracking();
	};

	return (
		<form
			onSubmit={submitHandler}
			className="flex flex-col items-center pb-xl-f"
		>
			<label
				htmlFor={elName}
				className="block mb-10 text-xl md:text-2xl font-lexend-light text-center"
			>
				{subtitle}
			</label>
			<div className="flex flex-col items-center xs:items-stretch xs:flex-row">
				<input
					className={clsx(
						InputVariants.Default,
						'h-auto mb-0 appearance-none transition-colors duration-200',
						'xs:mr-5',
						'hover:bg-brand-pale-400',
						'focus:border-blue-400 focus:placeholder:text-brand-blue-400',
						'motion-reduce:transition-none',
						inputValue.length > 0
							? 'border-brand-blue-400 bg-brand-pale-300'
							: ''
					)}
					min={1}
					type="number"
					id={elName}
					name={elName}
					required
					aria-required="true"
					onChange={handleOnChange}
					value={inputValue}
					placeholder="e.g. 22"
				/>
				<Button
					type="submit"
					className="!font-lexend-regular -mt-[5px] md:-mt-[7px] h-fit text-xs"
				>
					{buttonText}
				</Button>
			</div>
		</form>
	);
};

export { VapCalculatorIE };

VapCalculatorIE.defaultProps = {
	setEmployeeCount: () => {},
	setPriceCalculated: () => {},
	setShowLargeBusiness: () => {},
	setShow: () => {},
	title: '',
	subtitle: '',
};

VapCalculatorIE.propTypes = {
	setPriceCalculated: PropTypes.func,
	setEmployeeCount: PropTypes.func,
	setShowLargeBusiness: PropTypes.func,
	setShow: PropTypes.func,
	title: PropTypes.string,
	subtitle: PropTypes.string,
};
