/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from '@reach/router'; // Import useLocation for Gatsby

// import { MegaMenuTwoGroup } from '../../molecules/mega-menu-two-group';
import { MegaMenuOneGroup } from '../../molecules/mega-menu-one-group-ppc-v2-ca';
import { NavLink } from '../../atoms/nav-link';
import { Backdrop } from '../../atoms/backdrop-ca';
import { sendTrackingData } from '../../../utils/index';

import * as navStyles from './index.module.css';

export const NavDesktopVariants = {
	Default: navStyles.megalist,
	Unset: '',
};

const NavDesktop = ({
	linkTitle,
	links,
	drawerOpen,
	megaMenuVariant,
	...navAttributes
}) => {
	const linkRefs = useRef({});
	const megaRefs = useRef({});
	const location = useLocation(); // useLocation to detect route changes
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [hiddenMega, setHiddenMega] = useState(true);
	const [scrollDisabled, setScrollDisabled] = useState(false);

	// Helper function to get the width of the scrollbar
	const getScrollbarWidth = () =>
		window.innerWidth - document.documentElement.clientWidth;

	const enableScroll = useCallback(() => {
		if (scrollDisabled) {
			document.body.style.overflow = '';
			document.body.style.paddingRight = '';
			setScrollDisabled(false);
		}
	}, [scrollDisabled]);

	const disableScroll = useCallback(() => {
		if (!scrollDisabled) {
			const scrollbarWidth = getScrollbarWidth();
			document.body.style.overflow = 'hidden';
			document.body.style.paddingRight = `${scrollbarWidth}px`;
			setScrollDisabled(true);
		}
	}, [scrollDisabled]);

	const toggleOpen = (index) => {
		if (selectedIndex === index) {
			setSelectedIndex(null);
			setHiddenMega(true);
			enableScroll();
		} else {
			setSelectedIndex(index);
			setHiddenMega(false);
			disableScroll();

			const megaRef = megaRefs.current[index];
			const linkRef = linkRefs.current[index];
			const siblingLinkRef = linkRefs.current[index + 1];

			const firstFocusableElement =
				megaRef.querySelectorAll('button, a')[0];
			const focusableContent = megaRef.querySelectorAll('button, a');
			const lastFocusableElement =
				focusableContent[focusableContent.length - 1];

			document.addEventListener('keydown', (e) => {
				if (e.key === 'Escape') {
					setSelectedIndex(null);
					setHiddenMega(true);
					linkRef.focus();
					enableScroll();
				}

				if (
					e.shiftKey &&
					document.activeElement === firstFocusableElement
				) {
					linkRef.focus();
					e.preventDefault();
				} else if (document.activeElement === lastFocusableElement) {
					siblingLinkRef.focus();
					e.preventDefault();
				}
			});

			// Focus the first focusable element in the mega menu
			firstFocusableElement.focus();
		}
	};

	// Handle scroll restoration when drawer is open or when route changes
	useEffect(() => {
		if (drawerOpen) {
			setSelectedIndex(null);
			setHiddenMega(true);
			enableScroll(); // Restore scrolling if drawer is opened
		}
	}, [drawerOpen, enableScroll]);

	// Cleanup effect to re-enable scroll on navigation or unmount
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		// Re-enable scroll when navigating to a new page
		return () => enableScroll();
	}, [location, enableScroll]);

	const handleClickTracking = (clickTitle) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'navigation',
			click_action: 'open section',
			click_type: 'link',
			click_text: clickTitle,
			click_title: clickTitle,
		});
	};

	return (
		<nav {...navAttributes}>
			<ul className={clsx('flex', navStyles.navdesktop)}>
				{Array.isArray(links) &&
					links?.map((link, index) => {
						if (
							link?.treeChildren &&
							link?.treeChildren.length > 0
						) {
							return (
								<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
									<NavLink
										className="relative"
										as="button"
										// eslint-disable-next-line no-return-assign
										ref={(ref) =>
											(linkRefs.current[index] = ref)
										}
										onClick={() => {
											toggleOpen(index);
											handleClickTracking(link.title);
										}}
										aria-expanded={selectedIndex === index}
										hasMegaMenu
									>
										{link.title}
									</NavLink>
									<MegaMenuOneGroup
										aria-label={`${link.title} menu`}
										hidden={
											selectedIndex !== index ||
											hiddenMega
										}
										// eslint-disable-next-line no-return-assign
										ref={(ref) =>
											(megaRefs.current[index] = ref)
										}
										className={navStyles.megamenu}
										listClassName={clsx(
											megaMenuVariant === 'Unset'
												? null
												: NavDesktopVariants.Default
										)}
										linkTitle={linkTitle}
										links={link.treeChildren}
										backdrop={
											<Backdrop
												onClick={() => {
													setSelectedIndex(null);
													setHiddenMega(true);
													enableScroll();
												}}
											/>
										}
									/>
								</li>
							);
						}
						return (
							<NavLink
								// eslint-disable-next-line no-return-assign
								ref={(ref) => (linkRefs.current[index] = ref)}
								to={link.link}
							>
								{link.title}
							</NavLink>
						);
					})}
				{/* <li>
					<BackButton />
				</li> */}
			</ul>
		</nav>
	);
};

export { NavDesktop };

NavDesktop.defaultProps = {
	megaMenuVariant: 'Default',
};

NavDesktop.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			link: PropTypes.string,
			treeChildren: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subText: PropTypes.string.isRequired,
					link: PropTypes.string.isRequired,
					icon: PropTypes.string.isRequired,
				})
			),
		})
	).isRequired,
	drawerOpen: PropTypes.bool.isRequired,
	megaMenuVariant: PropTypes.oneOf(['Default', 'Unset']),
};
