// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceBannerPricingCA } from '../components/organisms/advice-banner-pricing-ca';
import { AdviceForm } from '../components/organisms/advice-form';
import { PricingSection } from '../components/organisms/pricing-section';
import { SuccessForm } from '../components/organisms/success-form';
import { Testimonials } from '../components/organisms/testimonials';
import { TestimonialsPricingCA } from '../components/organisms/testimonials-pricing-ca';
import { Head } from '../components/templates/head';
import { DefaultLayout } from '../layouts/default';
import { DefaultLayout as DefaultLayoutPpcV2 } from '../layouts/default/ppc-v2';
import { pageTypes } from '../utils/url-helpers';

import { Drawer } from '../components/atoms/drawer';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { PricingHeroCA } from '../components/organisms/pricing-hero-ca';
import { PricingSectionCA } from '../components/organisms/pricing-section-ca';

import { useDrawer } from '../contexts/drawer-context';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';

import { BusinessReviewHorizontal } from '../components/atoms/business-reviews';
import { ClientLogosCA } from '../components/atoms/client-logos-ca';
import { FaqCA } from '../components/organisms/faq-ca';
import { HrefLang } from '../components/templates/hrefLang';

// markup
const PricingPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const { locale: urlLocale } = data.datoCmsPricingPage;

	const metaContent = {
		noIndex: data.datoCmsPricingPage.noIndex,
		noFollow: data.datoCmsPricingPage.noFollow,
		metaInformation: data.datoCmsPricingPage.metaInformation,
		fallbackTitle: data.datoCmsPricingPage.title,
		fallbackDescription: data.datoCmsPricingPage.longIntroduction,
		canonicalUrl: data.datoCmsPricingPage?.canonicalUrl || location.href,
	};

	const testimonialsContent = {
		...data.datoCmsPricingPage.testimonials,
		title: data.datoCmsPricingPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsPricingPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const adviceBanner = {
		...data.datoCmsPricingPage.adviceBanner,
		title: data.datoCmsPricingPage.adviceBanner.title.value.document
			.children,
	};

	const pricingContent = {
		title: data.datoCmsPricingPage.heading,
		subtitle: data.datoCmsPricingPage.subheading,
		cards: data.datoCmsPricingPage.cards,
		quoteButtonCopy: data.datoCmsPricingPage.quoteButtonCopy,
		feeGuide: JSON.parse(data.datoCmsPricingPage.feeGuide),
	};

	const pricingContentCanada = {
		title: data.datoCmsPricingPage.headingCa,
		subtitle: data.datoCmsPricingPage.subheading,
		cards: data.datoCmsPricingPage.canadaCards,
		quoteButtonCopy: data.datoCmsPricingPage.quoteButtonCopy,
		title2: data.datoCmsPricingPage.headingTwo,
		subtitle2: data.datoCmsPricingPage.subheadingTwo,
		table: data.datoCmsPricingPage.pricingTable,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsPricingPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsPricingPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsPricingPage?.customFormField[0]?.customFormButton,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.pricing,
			location,
		}),
	};

	const faqContentCA = {
		title: data.datoCmsPricingPage?.faqContainer?.title || '',
		subText: data.datoCmsPricingPage?.faqContainer?.subText || '',
		questions:
			data.datoCmsPricingPage?.faqContainer?.questions?.map(
				({ question, answer }) => ({
					question,
					answer: answer.value.document.children,
				})
			) || [],
	};

	const heroContentCA = {
		title: data.datoCmsPricingPage?.heroTitle,
		subtitles: data.datoCmsPricingPage?.heroSubtitleRows,
		googleReview: data.siteSettings.googleReview,
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPricingPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{urlLocale === 'en-CA' &&
				data.datoCmsPricingPage?.heroDisplayOnPage && (
					<>
						<PricingHeroCA
							heroContentCA={heroContentCA}
							locale={urlLocale}
							customFormField={customFormField}
						/>
					</>
				)}

			{urlLocale === 'en-CA' ? (
				<>
					<div className="bg-gradient-to-b from-white to-[#f6f6f8]">
						<PricingSectionCA
							pricingContent={pricingContentCanada}
							locale={urlLocale}
							customFormField={customFormField}
						/>
						<ClientLogosCA
							pathname={location.pathname}
							className="hidden lg:block md:-mt-20 md:-mb-5 lg:-mt-12 lg:-mb-10 xl:-mt-28  xl:pb-14 xl:mb-0"
						/>
						<ClientLogosCA
							pathname={location.pathname}
							className="block lg:hidden -my-10"
							isMobile
						/>
					</div>

					{data.datoCmsPricingPage.faqDisplayOnPage && (
						<>
							<FaqCA faqContent={faqContentCA} />
							<div>
								<BusinessReviewHorizontal
									googleReview={
										data.siteSettings?.googleReview
									}
									onlyGR
									className="flex items-center justify-center"
								/>
							</div>
							<div className="pt-16 pb-5 mx-auto w-4/5">
								<hr className="border-[#D7DDF9]" />
							</div>
						</>
					)}
				</>
			) : (
				<PricingSection
					locale={urlLocale}
					pricingContent={pricingContent}
					rating={data.datoCmsTrustpilotRating}
				/>
			)}
			{urlLocale === 'en-CA' ? (
				<TestimonialsPricingCA
					testimonialsContent={testimonialsContent}
					className="mb-10 mx-auto max-w-m-screen mx-auto px-4 xl:px-2 xl:mx-7 2xl:mx-auto 2xl:px-10"
				/>
			) : (
				<Testimonials
					drawerRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			{urlLocale === 'en-CA' ? (
				<AdviceBannerPricingCA adviceBannerContent={adviceBanner} />
			) : (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBanner}
				/>
			)}
			{urlLocale !== 'en-CA' ? (
				<TrustpilotReviews
					pageName="homepage"
					showRatings
					reviews={data.allDatoCmsTrustpilotReview.nodes}
				/>
			) : null}
			{urlLocale !== 'en-CA' && (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedPricing = ({ location, data }) => {
	const { locale } = data.datoCmsPricingPage;
	const [categoryParam, setCategoryParam] = useState(null);
	const [ppcHeaderIndex, setPpcHeaderIndex] = useState(-1);

	// Check if the code is running in the browser
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(window.location.search);
			const category = urlParams.get('category');
			if (category) {
				// Clean category value by removing slashes
				setCategoryParam(category.replace(/^\/+|\/+$/g, ''));
			}
		}
	}, []);

	// Update ppcHeaderIndex when categoryParam changes
	useEffect(() => {
		if (categoryParam) {
			const ppcHeaderArr = data?.allDatoCmsPpcPage?.nodes || [];
			const index = ppcHeaderArr.findIndex(
				(elm) => elm?.siteNavigation[0]?.category === categoryParam
			);
			setPpcHeaderIndex(index);
		}
	}, [categoryParam, data?.allDatoCmsPpcPage?.nodes]);

	useEffect(() => {
		if (locale === 'en-CA') {
			document.documentElement.style.scrollBehavior = 'smooth';
		}
	}, [locale]);

	const footerImages = {
		footerImageCA: data.datoCmsSiteSpecificSetting.footerImageCa,
		footerImage1: data.datoCmsSiteSpecificSetting.footerImage1,
		footerImage2: data.datoCmsSiteSpecificSetting.footerImage2,
		footerImage3: data.datoCmsSiteSpecificSetting.footerImage3,
		footerImage4: data.datoCmsSiteSpecificSetting.footerImage4,
		footerImage5: data.datoCmsSiteSpecificSetting.footerImage5,
	};

	// Conditional rendering based on locale and ppcHeaderIndex
	return locale === 'en-CA' && ppcHeaderIndex !== -1 ? (
		<DefaultLayoutPpcV2
			siteSettings={data.siteSettings}
			navigation={
				data?.allDatoCmsPpcPage?.nodes[ppcHeaderIndex]
					?.siteNavigation[0]
			}
			footerData={data.footerItems}
			footerImages={footerImages}
			pageName="PeninsulaNow"
		>
			<PricingPage location={location} data={data} />
		</DefaultLayoutPpcV2>
	) : (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			pageName="PeninsulaNow"
		>
			<PricingPage location={location} data={data} />
		</DefaultLayout>
	);
};

export default WrappedPricing;

export const query = graphql`
	query pricing($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
			googleReview {
				url
				alt
			}
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			footerImageCa {
				url
				alt
			}
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		allDatoCmsTrustpilotReview(
			sort: { order: ASC, fields: rating }
			filter: { source: { ne: "trustpilot" }, locale: { eq: $locale } }
		) {
			nodes {
				id
				isVerified
				locale
				originalId
				pageTag
				source
				personName
				content {
					value
				}
				rating
				title
				date(fromNow: true)
			}
		}
		datoCmsPricingPage(locale: { eq: $locale }) {
			originalId
			locale
			feeGuide
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			id
			heroDisplayOnPage
			heroTitle {
				value
			}
			heroSubtitleRows {
				structuredTextBlock {
					value
				}
			}
			heading
			headingCa {
				value
			}
			subheading
			headingTwo
			subheadingTwo
			quoteButtonCopy
			cards {
				id
				title
				cardType
				prePriceCopy
				postPriceCopy
				tickListHeader
				tickList {
					id
					text
				}
			}
			quoteButtonCopy
			pricingTable {
				title
				humanResources
				healthAndSafety
				fullCompliance
			}
			canadaCards {
				id
				title
				cardType
				description
				price
				services {
					text
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			faqDisplayOnPage
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
		}
		allDatoCmsPpcPage(filter: { locale: { eq: $locale } }) {
			nodes {
				siteNavigation {
					title
					category
					homeLink
					headerNavigationItems {
						title
						link
						treeChildren {
							title
							link
							position
							icon
						}
					}
					footerNavigationItems {
						title
						link
					}
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
