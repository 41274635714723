/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
	StructuredText,
	renderNodeRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isList } from 'datocms-structured-text-utils';
import clsx from 'clsx';
import { PBSLink } from '../../../atoms/link';
import SessionCardBg1 from '../../../../images/session-card-bg-1.png';
import SessionCardBg2 from '../../../../images/session-card-bg-2.png';
import SessionCardBg3 from '../../../../images/session-card-bg-3.png';
import { buildLink } from '../../../../utils/locale';

const bgImageMap = [SessionCardBg1, SessionCardBg2, SessionCardBg3];

const TiltedCardContainer = ({ cards, className, filter, locale }) => (
	<div
		className={clsx(
			'flex flex-col gap-24 md:gap-12 py-16 md:flex-row',
			className
		)}
	>
		{cards
			.filter((card) => card.title !== filter)
			.map((card, index) => (
				<div
					className="relative flex flex-col bg-white rounded-sm shadow-slight md:w-1/3"
					key={card.title}
				>
					<img
						src={bgImageMap[index] || bgImageMap[0]}
						className="absolute bottom-full"
						alt="Multiple card background images"
					/>
					<GatsbyImage
						image={card.image.gatsbyImageData}
						alt={card.image.alt || ''}
						style={{
							clipPath: 'polygon(0 0, 100% 0, 100% 73%, 0% 100%)',
						}}
					/>
					<div className="flex flex-col px-8 py-5 grow">
						<h3 className="text-xl font-bold text-brand-red-400">
							{card.title}
						</h3>
						<h3 className="mb-5 text-xl font-bold font-lexend-regular">
							e-learning sessions
						</h3>
						<p>{card.content}</p>
						<StructuredText
							data={card.description}
							customNodeRules={[
								renderNodeRule(
									isList,
									({ node, children, key }) => {
										let ListTag;
										let listClassName;

										if (node.style === 'bulleted') {
											ListTag = 'ul';
											listClassName = 'list-disc';
										}
										if (node.style === 'numbered') {
											ListTag = 'ol';
											listClassName = 'list-decimal';
										}

										return (
											<ListTag
												className={clsx(
													'my-4 md:my-5 font-lexend-light pl-4',
													listClassName
												)}
												key={key + node.style}
											>
												{children}
											</ListTag>
										);
									}
								),
							]}
						/>
						<PBSLink
							to={buildLink(locale, card.ctaLink)}
							className="!text-sm w-full text-center !block mb-4 mt-auto"
							size="Small"
						>
							{card.ctaText}
						</PBSLink>
					</div>
				</div>
			))}
	</div>
);

export default TiltedCardContainer;
