import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import clsx from 'clsx';
import { Select } from '../../atoms/select';
import { Button } from '../../atoms/button';

function CaseStudySearch({
	title,
	service,
	industry,
	setData,
	businessSizesGrouped,
	servicesGrouped,
}) {
	const [industrySelected, setIndustrySelected] = useState('');
	const [serviceIncluded, setServiceIncluded] = useState('');
	const [businessSize, setBusinessSize] = useState('');
	const industryRef = useRef(null);
	const serviceRef = useRef(null);
	const businessSizeRef = useRef(null);
	const submitHandler = async (event) => {
		event.preventDefault();
		const industryValue = industryRef.current.value;
		const serviceValue = serviceRef.current.value;
		const businessSizeValue = businessSizeRef.current.value;
		setData({ industryValue, serviceValue, businessSizeValue });
	};
	const isSearch = !!(
		serviceIncluded !== '' ||
		businessSize !== '' ||
		industrySelected !== ''
	);
	const resetData = () => {
		setIndustrySelected('');
		setServiceIncluded('');
		setBusinessSize('');
		setData(null);
	};
	const businessSizeList = [
		{
			value: 'small',
			label: 'Small',
			disabled: false,
		},
		{
			value: 'medium',
			label: 'Medium',
			disabled: false,
		},
		{
			value: 'large',
			label: 'Large',
			disabled: false,
		},
	];
	return (
		<section className="relative w-full">
			<div className="px-3 mx-auto max-w-7xl">
				<section id="vapform">
					<form
						onSubmit={submitHandler}
						className="flex flex-col items-center pb-xl-f"
					>
						<div className="flex flex-col bg-brand-pale-400 p-5 m-5 w-full text-center focus-within:outline-blue-400/[0.20] border-2 border-blue-300/40 px-s-f py-base-f rounded-sm w-5xl">
							<div className="basis-1/3">
								<p className="lg:text-5xl font-notosans-medium text-2xl pb-10 pt-5">
									{title}
								</p>
								<div className="flex flex-col lg:flex-row xl:flex-row items-center justify-center">
									<div className="w-full lg:w-1/3">
										<div className="max-w-full mr-5 pr-5">
											<Select
												name="industry"
												id="industry"
												aria-label="Industry"
												value={industrySelected}
												onChange={(e) => {
													setIndustrySelected(
														e.currentTarget.value
													);
													setData({
														industryValue:
															e?.currentTarget
																?.value,
														serviceValue: '',
														businessSizeValue: '',
													});
												}}
												className="mb-9 ml-5 w-full text-xl text-black"
												aria-required="true"
												placeholder="Industry"
												options={industry.map(
													(item) => ({
														value: item.originalId,
														label: item.name,
													})
												)}
												validationMessage="Please make a selection"
												ref={industryRef}
												required
											/>
										</div>
									</div>
									<div className="w-full lg:w-1/3">
										<div className="max-w-full mr-5 pr-5">
											<Select
												name="businessSize"
												id="businessSize"
												value={businessSize}
												onChange={(e) => {
													setBusinessSize(
														e.currentTarget.value
													);
													setData({
														industryValue:
															industrySelected,
														serviceValue: '',
														businessSizeValue:
															e?.currentTarget
																?.value,
													});
												}}
												aria-label="Business Size"
												className="mb-9 ml-5 w-full text-xl text-black "
												aria-required="true"
												placeholder="Business Size"
												options={businessSizeList.map(
													(item) => ({
														value: item.value,
														label: item.label,
														disabled:
															industrySelected
																? !businessSizesGrouped[
																		item
																			.value
																  ]
																: false,
													})
												)}
												validationMessage="Please make a selection"
												ref={businessSizeRef}
												required
											/>
										</div>
									</div>
									<div className="w-full lg:w-1/3 bg-gray-500">
										<div className="max-w-full mr-5 pr-5">
											<Select
												name="service"
												id="service"
												aria-label="Services included"
												value={serviceIncluded}
												onChange={(e) =>
													setServiceIncluded(
														e.currentTarget.value
													)
												}
												className="mb-9 m-5 w-full text-xl text-black"
												aria-required="true"
												placeholder="Services included"
												options={service.map(
													(item) => ({
														value: item.originalId,
														label: item.name,
														disabled: businessSize
															? !servicesGrouped[
																	item
																		.originalId
															  ]
															: false,
													})
												)}
												validationMessage="Please make a selection"
												ref={serviceRef}
												required
											/>
										</div>
									</div>
									<div className="lg:basis-1/5 text-white mb-5 w-full">
										<div
											className={clsx(
												'flex justify-center',
												isSearch
													? 'gap-2 translate-x-3 transition-all duration-300'
													: ''
											)}
										>
											<Button
												type="submit"
												className="rounded-sm !py-3 !px-12 hover:!text-white !border-blue-300/60 focus:!border-blue-300/60 focus:!outline-blue-300/60 focus:!ring-2 focus:!ring-blue-300/60 focus:!text-white"
											>
												Search
											</Button>
											{isSearch ? (
												<Button
													type="reset"
													onClick={() => resetData()}
													className="rounded-sm !py-3 !px-2 bg-brand-red-400 hover:!bg-brand-red-500 !text-white hover:!text-white !border-blue-300/60 focus:!border-blue-300/60 focus:!outline-blue-300/60 focus:!ring-2 focus:!ring-blue-300/60 focus:!text-white"
												>
													Reset
												</Button>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</section>
			</div>
		</section>
	);
}

CaseStudySearch.defaultProps = {
	title: '',
	service: [],
	industry: [],
	setData: () => {},
	businessSizesGrouped: {},
	servicesGrouped: {},
};

CaseStudySearch.propTypes = {
	title: PropTypes.string,
	service: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			id: PropTypes.string,
			originalId: PropTypes.string,
		})
	),
	industry: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			id: PropTypes.string,
			originalId: PropTypes.string,
		})
	),
	setData: PropTypes.func,
	businessSizesGrouped: PropTypes.shape({
		small: PropTypes.number,
		medium: PropTypes.number,
		large: PropTypes.number,
	}),
	servicesGrouped: PropTypes.shape({
		originalId: PropTypes.number,
	}),
};

export default CaseStudySearch;
