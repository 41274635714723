import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { CallUs } from '../../atoms/call-us';
import { SidebarHeader } from '../../atoms/sidebar-header';
import { Textarea } from '../../atoms/textarea';
import { Select } from '../../atoms/select';
import { RadioButton } from '../../atoms/radio-button';

import { useSalesforceApi } from '../../../hooks/salesforce';

import { useDrawer } from '../../../contexts/drawer-context';

import { sleep, sendTrackingData, pageUrlFomater } from '../../../utils';

import { industries, services } from './data';
import { getLocale } from '../../../utils/locale';
import usePrivacyLink from '../../../hooks/usePrivacyLink';

import { useSalesforceAzureApi } from '../../../hooks/salesforce-azure-secondform-ca';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const PricingDetailsForm = ({ reference, response, setReference }) => {
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const { setDrawerRef } = useDrawer();
	const { privacyLink } = usePrivacyLink(locale);

	const [selectedIndustry, setSelectedIndustry] = useState('');
	const [selectedService, setSelectedService] = useState('');
	const [topHrIssues, setTopHrIssues] = useState('');
	const [topWorkplaceIssue, setTopWorkplaceIssue] = useState('');

	const [hasWorkplaceIssue, setHasWorkplaceIssue] = useState(false);

	const { executeRecaptcha } = useGoogleReCaptcha();
	const { fetchSalesforce } = useSalesforceApi();

	const submitButtonText = 'Submit extra details';
	const returnButtonText = 'Return home';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = (clickText) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-form',
			click_type: 'primary',
			click_title: response,
			click_text: clickText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		setSubmitting(true);

		const payloadData = {
			id: reference,
			industry: selectedIndustry,
			service: selectedService,
			hrIssues: topHrIssues,
			workplaceIssue: hasWorkplaceIssue,
			currentIssue: topWorkplaceIssue,
			// locale,
			// MSCLKID: getCookie('msclkid'),
			// MSCLKID_last: getCookie('msclkid_last'),
			// GCLID: getCookie('gclid'),
			// GCLID_last: getCookie('gclid_last'),
			pageUrl,
		};

		// if (locale === '' && (locale !== 'ca' || locale !== 'ie')) {
		// 	payloadData.utmCampaign = getCookie('utm_campaign');
		// 	payloadData.utmMedium = getCookie('utm_medium');
		// 	payloadData.utmSource = getCookie('utm_source');
		// 	payloadData.utmTerm = getCookie('utm_term');
		// }

		const sfResponse = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'PATCH'
		);

		if (locale === 'ca' && sfResponse.status !== 200) {
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		setIsSubmitted(true);
		handleClickTracking(submitButtonText);

		switch (sfResponse.status) {
			case 200:
				setIsError(false);
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	return isSubmitted ? (
		<div className="flex flex-col h-full max-h-screen">
			<SidebarHeader
				heading={
					isError
						? "We've run into a technical error with your submission"
						: 'Thank you for contacting Peninsula. We will get back to you shortly.'
				}
				text={
					isError
						? "Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
						: ''
				}
				ariaLive="assertive"
			/>
			{isError ? (
				<div className="px-4 pt-10">
					<CallUs />
				</div>
			) : (
				<div className="flex flex-col justify-between h-full px-4 pt-8 pb-14">
					<Button
						variant="White"
						className="w-max"
						onClick={() => {
							setDrawerRef('');
							setReference('');
							handleClickTracking(returnButtonText);
						}}
					>
						{returnButtonText}
					</Button>
				</div>
			)}
		</div>
	) : (
		<>
			<form onSubmit={submitHandler}>
				<SidebarHeader
					heading={response}
					text="Feel free to provide us with a bit more about yourself so we can tailor your quote more accurately before we get in contact"
				/>
				<div className="flex flex-col px-4 py-10 bg-white grow">
					<Select
						className="mb-8"
						labelText="What industry do you work in? - optional"
						options={industries}
						id="pricingIndustries"
						name="industries"
						value={selectedIndustry}
						onChange={(e) =>
							setSelectedIndustry(e.currentTarget.value)
						}
					/>

					<Select
						className="mb-8"
						labelText="Which Peninsula service are you most intersted in? - optional"
						options={services}
						id="pricingServices"
						name="services"
						value={selectedService}
						onChange={(e) =>
							setSelectedService(e.currentTarget.value)
						}
					/>

					<Textarea
						className="mb-8"
						labelText="What are the biggest HR or health & safety issues for your business? - optional"
						id="pricingTopHrIssues"
						value={topHrIssues}
						name="topHrIssues"
						onChange={(e) => setTopHrIssues(e.currentTarget.value)}
						rows={6}
						maxLength={400}
					/>

					<fieldset className="mb-8">
						<legend className="mb-4 text-brand-blue-400 text-base-f font-lexend-medium">
							Are you facing a particular workplace issue right
							now?
						</legend>

						<div className="flex flex-wrap gap-2">
							<RadioButton
								defaultChecked
								labelText="No"
								id="pricingIssueNo"
								value="issueNo"
								name="workplaceIssue"
								onChange={() => setHasWorkplaceIssue(false)}
							/>
							<RadioButton
								labelText="Yes"
								id="pricingIssueYes"
								value="issueYes"
								name="workplaceIssue"
								onChange={() => setHasWorkplaceIssue(true)}
							/>
						</div>

						<div className="mt-8" hidden={!hasWorkplaceIssue}>
							<Textarea
								className="before:bg-blue-300"
								labelText="Tell us a little about the issue you’re facing - optional"
								id="pricingtopWorkplaceIssue"
								value={topWorkplaceIssue}
								name="topWorkplaceIssue"
								onChange={(e) =>
									setTopWorkplaceIssue(e.currentTarget.value)
								}
								rows={6}
								maxLength={400}
							/>
						</div>
					</fieldset>

					<p className="mb-8 text-base-f">
						View our{' '}
						<PBSLink to={privacyLink} variant="Link">
							privacy notice
						</PBSLink>
						.
					</p>

					<div className="flex gap-2">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</>
	);
};

PricingDetailsForm.defaultProps = {
	response: '',
};

PricingDetailsForm.propTypes = {
	reference: PropTypes.string.isRequired,
	response: PropTypes.string,
	setReference: PropTypes.func.isRequired,
};

export { PricingDetailsForm };
