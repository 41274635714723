import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';

import { TagsList } from '../tags-list';

import { cardContents, cardVariants } from '../../../types';
import { truncateCategories } from '../../../utils';
import CalendarIcon from '../../../assets/calendar.inline.svg';

import LogoIcon from '../../../assets/logo.inline.svg';

import * as styles from './index.module.css';
import { buildLink } from '../../../utils/locale';

const Card = ({
	cardContent,
	variant,
	onClickProp,
	index,
	location,
	loading,
	locale,
}) => {
	const {
		as,
		id,
		// publishedDateOrdinal,
		// publishedDate,
		downloadType,
		author,
		thumbnail,
		title,
		externalLink,
		intro,
		categories,
		className,
	} = cardContent;

	const { updatedAtOrdinal, updatedAt } = cardContent.meta || {};

	const Element = as || 'article';

	const linkStyles = `inline bg-gradient-to-r [background-size:0_2px] [background-position:0_100%] bg-no-repeat transition-all duration-300 motion-reduce:transition-none before:absolute before:top-0 before:left-0 before:w-full before:h-full focus:[background-size:100%_2px] group-hover:[background-size:100%_2px] focus:outline-none`;

	if (loading) {
		return (
			<Element
				className={clsx(
					'group relative bg-white h-full w-full',
					'border-2 border-solid rounded-[0.75rem] shadow-card',
					styles.borderLoading,
					className
				)}
				aria-labelledby={`${id}-card`}
			>
				<div
					className={clsx('w-full h-[244px] block', styles.bgLoading)}
				/>

				<div className="p-4 pt-8">
					{variant !== 'Download' ? (
						<div
							className={clsx(
								'flex h-[22px] w-[50%]',
								styles.bgLoading
							)}
						/>
					) : null}
					<div className="mb-4">
						{variant !== 'Download' ? (
							<div
								className={clsx(
									'mt-4 flex h-[28px]',
									styles.bgLoading
								)}
							/>
						) : null}

						<div
							className={clsx(
								'mt-4 flex h-[100px]',
								styles.bgLoading
							)}
						/>
					</div>

					<div
						className={clsx(
							'flex py-4 border-t',
							styles.breakBorderLoading
						)}
					>
						<div
							className={clsx(
								'flex w-12 h-12 rounded-[100%] mr-4',
								styles.bgLoading
							)}
						/>
						<div className="flex flex-col w-[50%]">
							<div
								className={clsx(
									'flex h-[20px] w-[50%]',
									styles.bgLoading
								)}
							/>
							<div
								className={clsx(
									'mt-2 flex h-[20px]',
									styles.bgLoading
								)}
							/>
						</div>
					</div>

					<div className="flex flex-row">
						<div
							className={clsx(
								'flex h-[30px] w-[35%]',
								styles.bgLoading
							)}
						/>
						<div
							className={clsx(
								'ml-3 flex h-[30px] w-[9%]',
								styles.bgLoading
							)}
						/>
					</div>
				</div>
			</Element>
		);
	}

	const Header = () => {
		if (variant !== 'Download') {
			if (!thumbnail)
				return (
					<LogoIcon className="max-w-full aspect-[408/245] p-12" />
				);

			if (thumbnail.responsiveImage) {
				return (
					<picture className="">
						<source
							type="image/webp"
							sizes={thumbnail.responsiveImage.sizes}
							srcSet={thumbnail.responsiveImage.webpSrcSet}
						/>
						<img
							srcSet={thumbnail.responsiveImage.srcSet}
							sizes={thumbnail.responsiveImage.sizes}
							src={thumbnail.responsiveImage.src}
							alt={thumbnail.responsiveImage.alt || ''}
							width={thumbnail.responsiveImage.width}
							height={thumbnail.responsiveImage.height}
							loading="lazy"
							className="w-full"
						/>
					</picture>
				);
			}

			return (
				<GatsbyImage
					image={thumbnail.gatsbyImageData}
					alt={
						thumbnail.alt ||
						'Peninsula HR & Health and Safty Support'
					}
					className="w-full rounded-t-[0.75rem]"
				/>
			);
		}

		return (
			<div
				className={clsx(
					'flex items-end px-4 pb-4 h-64 rounded-tr-[140px] mx-4 mt-4 gradient',
					styles.gradient
				)}
			>
				<h3
					className="pt-4 overflow-hidden text-xl font-lexend-medium line-clamp-2"
					id={`${id}-card`}
				>
					<Link
						to={
							location === '/'
								? externalLink
								: buildLink(
										locale,
										`/resource-hub/${
											categories[0]?.slug
												? `${categories[0]?.slug}/`
												: null
										}${cardContent.slug}`
								  )
						}
						className={clsx(
							linkStyles,
							'text-white from-white to-white !before:color-white'
						)}
						onClick={() =>
							onClickProp({
								click_text: title,
								click_index: index + 1,
							})
						}
					>
						{title}
					</Link>
				</h3>
			</div>
		);
	};

	const truncatedCategoriesArray = truncateCategories({ categories });

	return (
		<Element
			className={clsx(
				'flex flex-col',
				'group relative bg-white h-full w-full',
				'border-2 border-blue-200 border-solid rounded-[0.75rem] shadow-card',
				'transition-shadow duration-300 motion-reduce:transition-none',
				'hover:shadow-default-hover focus-within:shadow-default-hover',
				'focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2',
				className
			)}
			aria-labelledby={`${id}-card`}
		>
			<Header />
			<p
				className={clsx(
					'absolute px-2 py-1 text-sm text-blue-400 bg-white rounded-md top-4 left-4 font-notosans-medium',
					variant === 'Download' && 'm-4'
				)}
			>
				{variant === 'Download' ? downloadType : variant}
			</p>
			<div className="flex flex-col justify-between p-6 grow">
				<div className="mb-8">
					{variant !== 'Download' ? (
						<time
							className="flex flex-row text-sm font-lexend-regular text-grey-500"
							dateTime={updatedAt}
						>
							<CalendarIcon
								className="mt-[2px] mr-2 text-grey-500 w-[18px] h-[18px]"
								aria-hidden="true"
							/>
							{updatedAtOrdinal}
						</time>
					) : null}

					{variant !== 'Download' ? (
						<h3
							className="pt-4 overflow-hidden text-2xl font-lexend-regular line-clamp-2"
							id={`${id}-card`}
						>
							<Link
								to={
									location === '/' && externalLink !== null
										? externalLink
										: buildLink(
												locale,
												`resource-hub/${
													categories[0]?.slug
														? `${categories[0]?.slug}/`
														: null
												}${cardContent.slug}`
										  )
								}
								className={clsx(
									linkStyles,
									variant === 'Blog' && 'from-black to-black',
									variant === 'Guide' &&
										'from-blue-400 to-blue-400 hover:text-blue-400'
								)}
								onClick={() =>
									onClickProp({
										click_text: title,
										click_index: index + 1,
									})
								}
							>
								{title}
							</Link>
						</h3>
					) : null}
					{intro ? (
						<p
							className={clsx(
								'overflow-hidden text-base-f font-notosans-light line-clamp-3',
								variant !== 'Download' && 'pt-4'
							)}
						>
							{intro}
						</p>
					) : null}
				</div>

				<div>
					{Object.keys(author).length !== 0 ? (
						<div className="flex flex-row py-4 border-t border-brand-pale-500">
							{author.image.responsiveImage ? (
								<img
									src={author.image.responsiveImage.src}
									width={author.image.responsiveImage.width}
									height={author.image.responsiveImage.height}
									alt={author.image.responsiveImage.alt || ''}
									className="w-12 h-12 rounded-[100%] mr-4"
									loading="lazy"
								/>
							) : (
								<GatsbyImage
									image={author.image.gatsbyImageData}
									alt={author.image.alt || ''}
									className="w-12 h-12 mr-4 rounded-md"
									imgClassName="rounded-[100%]"
								/>
							)}

							<div className="flex flex-col">
								<span className="text-sm font-lexend-medium">
									{author.name}
								</span>
								<span className="pt-1 text-sm font-lexend-regular">
									{author.jobTitle}
								</span>
							</div>
						</div>
					) : null}

					<div>
						{truncatedCategoriesArray?.length > 0 ? (
							<TagsList tags={truncatedCategoriesArray} />
						) : null}
					</div>
				</div>
			</div>
		</Element>
	);
};

export { Card };

Card.defaultProps = {
	cardContent: {
		as: 'article',
	},
	variant: 'Blog',
	index: 1,
	onClickProp: () => {},
	location: '',
	loading: false,
	locale: 'en',
};

Card.propTypes = {
	cardContent: cardContents,
	variant: cardVariants,
	onClickProp: PropTypes.func,
	index: PropTypes.number,
	location: PropTypes.string,
	loading: PropTypes.bool,
	locale: PropTypes.string,
};
