/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from '../../atoms/nav-link';

const NavMobile = ({
	linkTitle,
	links,
	className,
	listClassName,
	...navAttributes
}) => (
	<nav className="pb-8 " {...navAttributes}>
		<div className="flex-grow border-blue-300 border-b-1 mb-5" />
		<ul className={clsx('w-full', listClassName)}>
			<div className="flex items-center pb-2">
				<p className="text-xs text-blue-300">
					{linkTitle || 'Resources'}
				</p>
				<div className="flex-grow ml-4 border-blue-300 border-b-1" />
			</div>
			{links?.map((link) =>
				link?.treeChildren && link?.treeChildren?.length > 0 ? (
					<>
						{link?.treeChildren
							.sort((a, b) => a.position - b.position)
							.map((treeChild) => (
								<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
									<NavLink
										className="w-full text-xl rounded-none font-lexend-regular"
										to={treeChild?.link}
									>
										{treeChild?.title}
									</NavLink>
								</li>
							))}
						<div className="flex items-center pb-2 pt-1">
							<p className="text-xs text-blue-300">Company</p>
							<div className="flex-grow ml-4 border-blue-300 border-b-1" />
						</div>
					</>
				) : (
					<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
						<NavLink
							className="w-full text-xl rounded-none font-lexend-regular"
							to={link.link}
						>
							{link.title}
						</NavLink>
					</li>
				)
			)}
		</ul>
	</nav>
);

export { NavMobile };

NavMobile.defaultProps = {
	className: '',
	listClassName: '',
};

NavMobile.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			link: PropTypes.string,
			treeChildren: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subText: PropTypes.string.isRequired,
					link: PropTypes.string.isRequired,
					icon: PropTypes.string.isRequired,
				})
			),
		})
	).isRequired,
	className: PropTypes.string,
	listClassName: PropTypes.string,
};
