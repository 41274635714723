import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Player from '@vimeo/player';

import * as vimeoClasses from './index.module.css';

import { useScreenResizer } from '../../../contexts/screen-resize-context';

import ClockIcon from '../../../assets/clock.inline.svg';

const Vimeo = (props) => {
	const {
		id,
		video,
		containerTitle,
		containerSubHeading,
		videoTitle,
		videoLength,
		titleGroupClassNames,
		className,
		containerClassName,
		fillContainer,
	} = props;

	const videoType = /^https?:/i.test(video) ? 'url' : 'id';
	if (videoType === 'url' && !video.includes('vimeo')) {
		return <></>;
	}

	let Component = 'h2';
	if (containerTitle) Component = 'h3';

	let containerAttributes = {};
	if (videoTitle)
		containerAttributes = {
			'aria-labelledby': id,
		};

	const containerRef = useRef();
	const iframeContainerRef = useRef();
	const vimeoRef = useRef();

	const { width } = useScreenResizer();

	const [iframeWidth, setIframeWidth] = useState();
	const [resize, triggerResize] = useState(false);

	const getInitialOptions = useCallback(
		() =>
			/**
			 * Embed option parameters dictated by @vimeo/player NPM package
			 * @see https://www.npmjs.com/package/@vimeo/player#embed-options
			 */
			({
				[videoType]: video,
				autopause: props.autopause,
				autoplay: props.autoplay,
				background: props.background,
				byline: props.byline,
				color: props.color,
				controls: props.controls,
				dnt: props.dnt,
				height: props.height,
				interactive_params: props.interactive_params,
				keyboard: props.keyboard,
				loop: props.loop,
				maxheight: props.maxheight,
				maxwidth: props.maxwidth,
				muted: props.muted,
				paused: props.paused,
				pip: props.pip,
				playsinline: props.playsinline,
				portrait: props.portrait,
				quality: props.quality,
				responsive: props.responsive,
				speed: props.speed,
				texttrack: props.texttrack,
				title: props.title,
				transparent: props.transparent,
				width: props.width,
			}),
		[video, videoType, props]
	);

	useEffect(() => {
		/**
		 * Events dictated by @vimeo/player NPM package
		 * @see https://www.npmjs.com/package/@vimeo/player#events
		 * @see https://www.npmjs.com/package/@vimeo/player#table-of-contents
		 */
		const eventNames = {
			bufferstart: props.onBufferStart,
			bufferend: props.onBufferEnd,
			camerachange: props.onCameraChange,
			chapterchange: props.onChapterChange,
			cuechange: props.onCueChange,
			cuepoint: props.onCuePoint,
			durationchange: props.onDurationChange,
			ended: props.onEnd,
			enterpictureinpicture: props.onEnterPictureInPicture,
			error: props.onError,
			fullscreenchange: props.onFullScreenChange,
			interactivehotspotclicked: props.onInteractiveHotspotClicked,
			interactiveoverlaypanelclicked:
				props.onInteractiveOverlayPanelClicked,
			leavepictureinpicture: props.onLeavePictureInPicture,
			loaded: props.onLoaded,
			play: props.onPlay,
			playing: props.onPlaying,
			pause: props.onPause,
			playbackratechange: props.onPlaybackRateChange,
			progress: props.onProgress,
			resize: props.onResize,
			seeking: props.onSeeking,
			seeked: props.onSeeked,
			texttrackchange: props.onTextTrackChange,
			timeupdate: props.onTimeUpdate,
			volumechange: props.onVolumeChange,
			qualitychange: props.onQualityChange,
		};

		const createPlayer = () => {
			vimeoRef.current = new Player(
				iframeContainerRef.current,
				getInitialOptions()
			);

			Object.keys(eventNames).forEach((event) => {
				const eventFn = eventNames[event];
				if (eventFn() !== null) {
					vimeoRef.current.on(event, eventFn);
				}
			});

			vimeoRef.current.ready().then(
				() => {
					if (props.onReady) {
						props.onReady(vimeoRef.current);
					}
				},
				(err) => {
					if (props.onError) {
						props.onError(err);
					} else {
						throw err;
					}
				}
			);

			if (typeof props.start === 'number') {
				vimeoRef.current.setCurrentTime(props.start);
			}

			if (typeof props.volume === 'number') {
				vimeoRef.current.setVolume(props.volume);
			}
		};

		createPlayer();
	}, [getInitialOptions, iframeContainerRef, props]);

	useEffect(() => {
		setIframeWidth(
			(containerRef.current.clientWidth /
				containerRef.current.clientHeight) *
				100
		);
	}, [containerRef]);

	useEffect(() => {
		triggerResize(true);
		return () => triggerResize(false);
	}, [width]);

	return (
		<div
			ref={containerRef}
			className={containerClassName}
			key={() => resize}
		>
			{containerTitle || containerSubHeading ? (
				<hgroup className={clsx('mb-8', titleGroupClassNames)}>
					{containerTitle ? (
						<h2 className="mb-4 text-lg-f md:text-2xl font-lexend-regular">
							{containerTitle}
						</h2>
					) : null}
					{containerSubHeading ? (
						<p className="text-lg md:text-xl font-notosans-light">
							{containerSubHeading}
						</p>
					) : null}
				</hgroup>
			) : null}
			<div
				ref={iframeContainerRef}
				style={
					fillContainer
						? { '--iframe-width': `${iframeWidth}%` }
						: null
				}
				className={clsx(
					className,
					fillContainer ? vimeoClasses.iframeContainer : null
				)}
				{...containerAttributes}
			/>
			{videoTitle || videoLength ? (
				<div className="px-4 mt-5 md:px-8 lg:px-0">
					{videoTitle ? (
						<Component id={id} className="inline mr-2 text-xl">
							{videoTitle}
						</Component>
					) : null}
					{videoLength ? (
						<>
							<p className="inline-flex items-center text-sm leading-3 text-blue-400">
								<ClockIcon
									className="w-3 h-3 mr-1 text-blue-400"
									aria-hidden
								/>
								{videoLength}
							</p>
						</>
					) : null}
				</div>
			) : null}
		</div>
	);
};

Vimeo.propTypes = {
	video: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	id: PropTypes.string,
	containerClassName: PropTypes.string,
	containerTitle: PropTypes.string,
	containerSubHeading: PropTypes.string,
	titleGroupClassNames: PropTypes.string,
	videoTitle: PropTypes.string,
	videoLength: PropTypes.string,
	className: PropTypes.string,
	fillContainer: PropTypes.bool,
	autopause: PropTypes.bool,
	autoplay: PropTypes.bool,
	background: PropTypes.bool,
	byline: PropTypes.bool,
	color: PropTypes.string,
	controls: PropTypes.bool,
	dnt: PropTypes.bool,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	interactive_params: PropTypes.string,
	keyboard: PropTypes.bool,
	loop: PropTypes.bool,
	maxheight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxwidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	muted: PropTypes.bool,
	paused: PropTypes.bool,
	pip: PropTypes.bool,
	playsinline: PropTypes.bool,
	portrait: PropTypes.bool,
	quality: PropTypes.string,
	responsive: PropTypes.bool,
	start: PropTypes.number,
	speed: PropTypes.bool,
	title: PropTypes.bool,
	texttrack: PropTypes.string,
	transparent: PropTypes.bool,
	volume: PropTypes.number,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onReady: PropTypes.func,
	onError: PropTypes.func,
	onBufferStart: PropTypes.func,
	onBufferEnd: PropTypes.func,
	onCameraChange: PropTypes.func,
	onChapterChange: PropTypes.func,
	onCueChange: PropTypes.func,
	onCuePoint: PropTypes.func,
	onDurationChange: PropTypes.func,
	onEnd: PropTypes.func,
	onEnterPictureInPicture: PropTypes.func,
	onFullScreenChange: PropTypes.func,
	onInteractiveHotspotClicked: PropTypes.func,
	onInteractiveOverlayPanelClicked: PropTypes.func,
	onLeavePictureInPicture: PropTypes.func,
	onLoaded: PropTypes.func,
	onPlay: PropTypes.func,
	onPlaying: PropTypes.func,
	onPause: PropTypes.func,
	onPlaybackRateChange: PropTypes.func,
	onProgress: PropTypes.func,
	onResize: PropTypes.func,
	onSeeking: PropTypes.func,
	onSeeked: PropTypes.func,
	onTextTrackChange: PropTypes.func,
	onTimeUpdate: PropTypes.func,
	onVolumeChange: PropTypes.func,
	onQualityChange: PropTypes.func,
};

Vimeo.defaultProps = {
	id: '',
	containerTitle: '',
	containerSubHeading: '',
	titleGroupClassNames: '',
	videoTitle: '',
	videoLength: '',
	autopause: true,
	autoplay: false,
	background: false,
	controls: true,
	dnt: false,
	fillContainer: false,
	keyboard: true,
	loop: false,
	muted: false,
	pip: false,
	playsinline: true,
	responsive: false,
	byline: true,
	portrait: true,
	title: true,
	speed: false,
	transparent: true,
	className: '',
	containerClassName: '',
	width: '',
	height: '',
	paused: true,
	volume: 0,
	start: 0,
	color: '',
	quality: '',
	texttrack: '',
	interactive_params: '',
	maxheight: '',
	maxwidth: '',
	onReady: () => {},
	onError: () => {},
	onBufferStart: () => null,
	onBufferEnd: () => null,
	onCameraChange: () => null,
	onChapterChange: () => null,
	onCueChange: () => null,
	onCuePoint: () => null,
	onDurationChange: () => null,
	onEnd: () => null,
	onEnterPictureInPicture: () => null,
	onFullScreenChange: () => null,
	onInteractiveHotspotClicked: () => null,
	onInteractiveOverlayPanelClicked: () => null,
	onLeavePictureInPicture: () => null,
	onLoaded: () => null,
	onPlay: () => null,
	onPlaying: () => null,
	onPause: () => null,
	onPlaybackRateChange: () => null,
	onProgress: () => null,
	onResize: () => null,
	onSeeking: () => null,
	onSeeked: () => null,
	onTextTrackChange: () => null,
	onTimeUpdate: () => null,
	onVolumeChange: () => null,
	onQualityChange: () => null,
};

export { Vimeo };
