/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Select } from '../../atoms/select';
import { useTimesRange } from '../../../hooks/times-range';
import { DatePicker } from '../../atoms/date-picker-ca';

export const TimesRange = ({
	callFromIsValid,
	callToIsValid,
	isDisabled,
	handleDateChange,
}) => {
	// const [selectedDate, setSelectedDate] = useState('');
	const callFromRef = useRef(null);
	const callToRef = useRef(null);

	// const handleDateChange = (date) => {
	// 	setSelectedDate(date); // Update the selected date in the parent component
	// };

	const { fromOptions, toOptions, onOptionSelected, fromValue, toValue } =
		useTimesRange();

	useEffect(() => {
		if (!callFromIsValid && callFromRef.current) {
			return callFromRef.current.focus();
		}

		if (!callToIsValid && callToRef.current) {
			return callToRef.current.focus();
		}
	}, [callFromIsValid, callToIsValid]);

	return (
		<div className="wrapper-small">
			<div
				className={clsx(
					'relative wrapper-small  before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none',
					!callFromIsValid || !callToIsValid
						? 'before:bg-alert-400'
						: ''
				)}
			>
				<div className="pb-5">
					<div className="pb-1 text-brand-blue-400 text-md font-lexend-medium">
						Choose a call date
						<span className="p-1 text-brand-red-500">*</span>
					</div>
					<DatePicker
						onDateChange={handleDateChange}
						className="w-full bg-brand-pale-300 border-[#D8E3F6] border-1 p-3 rounded-xs"
					/>
				</div>
				<fieldset>
					<div className="text-brand-blue-400 text-md font-lexend-medium">
						What time are you available?
						<span className="p-1 text-brand-red-500">*</span>
					</div>

					<div className="flex flex-col justify-between sm:flex-row sm:gap-x-6">
						<Select
							disabled={isDisabled}
							labelText="From"
							id="callFrom"
							name="callFrom"
							className="flex-1 "
							aria-required="true"
							options={fromOptions}
							onChange={onOptionSelected}
							validationMessage="Please select a time"
							showValidation={!callFromIsValid}
							ref={callFromRef}
							labelClassName="!font-lexend-regular !text-s-f"
							value={fromValue}
							selectClassName="border-1 sm:border-[#D8E3F6]"
							labelWrapperClassName="sm:m-1"
						/>

						<Select
							disabled={isDisabled}
							labelText="To"
							id="callTo"
							name="callTo"
							className="flex-1"
							options={toOptions}
							onChange={onOptionSelected}
							validationMessage="Please select a time"
							showValidation={!callToIsValid}
							ref={callToRef}
							labelClassName="!font-lexend-regular !text-s-f"
							value={toValue}
							selectClassName="border-1 sm:border-[#D8E3F6]"
							labelWrapperClassName="sm:m-1"
						/>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

TimesRange.defaultProps = {
	isDisabled: true,
	callFromIsValid: true,
	callToIsValid: true,
};

TimesRange.propTypes = {
	isDisabled: PropTypes.bool,
	callFromIsValid: PropTypes.bool,
	callToIsValid: PropTypes.bool,
};
