/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isHeading,
	isParagraph,
	isLink,
	isRoot,
	isList,
} from 'datocms-structured-text-utils';

import { useLocation } from '@reach/router';
import { PBSLink } from '../link';

import {
	datoGatsbyImageProp,
	refProp,
	structuredTextContent,
	titleProp,
} from '../../../types';
import * as textWithImageStyles from './index.module.css';
import { getLocale, buildLink } from '../../../utils/locale';
import { useDrawer } from '../../../contexts/drawer-context';
import { sendTrackingData } from '../../../utils';
import { Button } from '../button';

const TextWithImage = ({ textWithImageContent, flip, buttonVariant }) => {
	const {
		id,
		title,
		content,
		as,
		titleProps,
		image,
		buttonLink,
		buttonCopy,
		className,
		showForm,
		drawerRef,
	} = textWithImageContent;
	const location = useLocation();
	const { setDrawerRef } = useDrawer();
	const locale = getLocale(location?.pathname);
	const Heading = as || 'h2';
	const Alt = image.alt || title || 'Peninsula HR & Health and Safty Support';
	const imageData = getImage(image);
	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-banner',
			click_type: 'primary',
			...trackData,
		});
	};
	return (
		<div
			className={clsx(
				'lg:grid lg:grid-cols-[1fr_46%] lg:gap-[60px]',
				className
			)}
		>
			<div className={clsx(flip ? 'order-2' : null)}>
				{title || titleProps ? (
					<Heading
						id={id}
						className="mb-5 text-3xl lg:text-4xl font-lexend-regular"
						{...titleProps}
					>
						{title}
					</Heading>
				) : null}
				<StructuredText
					data={content}
					customNodeRules={[
						renderNodeRule(isHeading, ({ node, children, key }) => {
							const HeadingTag = `h${node.level}`;
							return (
								<HeadingTag
									className="mb-5 text-2xl lg:text-3xl font-lexend-regular"
									key={key}
								>
									{children}
								</HeadingTag>
							);
						}),
						renderNodeRule(isLink, ({ node, children, key }) => (
							<PBSLink
								to={buildLink(locale, node.url)}
								variant="Link"
								key={key}
							>
								{children}
							</PBSLink>
						)),
						renderNodeRule(isList, ({ node, children, key }) => {
							let ListTag;
							let listClassName;

							if (node.style === 'bulleted') {
								ListTag = 'ul';
								listClassName = 'list-disc';
							}
							if (node.style === 'numbered') {
								ListTag = 'ol';
								listClassName = 'list-decimal';
							}

							return (
								<ListTag
									className={clsx(
										'mb-4 text-lg font-lexend-light md:text-xl list-inside',
										listClassName,
										textWithImageStyles.list
									)}
									key={
										key +
										node.style +
										textWithImageStyles.list
									}
								>
									{children}
								</ListTag>
							);
						}),
						renderNodeRule(
							isParagraph,
							({ children, key, ancestors }) => {
								if (isRoot(ancestors[0])) {
									return (
										<p
											className="mb-4 text-xl font-notosans-regular last-of-type:mb-0"
											key={key}
										>
											{children}
										</p>
									);
								}
								return children;
							}
						),
					]}
					customMarkRules={[
						renderMarkRule('strong', ({ children, key }) => (
							<span className="font-lexend-regular" key={key}>
								{children}
							</span>
						)),
					]}
				/>
				{buttonLink && buttonCopy && buttonLink?.startsWith('/') ? (
					<p className="mt-8 text-2xl leading-9 text-center lg:mt-10 lg:mb-0 lg:text-left">
						{showForm ? (
							<Button
								onClick={() => {
									setDrawerRef(drawerRef);

									handleClickTracking({
										click_title: buttonCopy,
										click_text: buttonCopy,
									});
								}}
							>
								{buttonCopy}
							</Button>
						) : (
							<PBSLink to={buildLink(locale, buttonLink)}>
								{buttonCopy}
							</PBSLink>
						)}
					</p>
				) : null}
				{buttonLink && buttonCopy && buttonLink?.startsWith('tel:') && (
					<a
						href={buttonLink}
						className={`inline-flex items-center transition delay-150 duration-1000 ease-in-out transition-colors px-6 py-4 text-lg text-white transition-colors border-2 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-lexend-medium focus:outline-0 focus:shadow-focus w-max mt-8 text-2xl leading-9 text-center lg:mt-5 lg:mb-0 lg:text-left ${
							buttonVariant === 'Red'
								? 'bg-[#E30138] border-[#E30138]'
								: 'bg-blue-400 border-blue-400'
						}`}
					>
						<span>{buttonCopy}</span>
					</a>
				)}
			</div>
			<div
				className={clsx(
					'text-center mt-8 lg:mt-0 lg:text-text',
					flip ? 'hidden lg:block order-1' : null
				)}
			>
				<GatsbyImage
					className={clsx(
						'border-8',
						'border-white',
						'drop-shadow-image',
						'self-start',
						'justify-self-center',
						'md:justify-self-start'
					)}
					image={imageData}
					alt={Alt}
				/>
			</div>
		</div>
	);
};

TextWithImage.defaultProps = {
	textWithImageContent: {
		as: 'h2',
		titleProps: {},
		id: '',
		showForm: false,
	},
	flip: false,
};

TextWithImage.propTypes = {
	textWithImageContent: PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
		as: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.string,
			PropTypes.func,
			titleProp,
		]),
		titleProps: PropTypes.shape({
			id: PropTypes.string,
			title: titleProp,
			headingClassNames: PropTypes.string,
		}),
		content: structuredTextContent.isRequired,
		image: datoGatsbyImageProp.isRequired,
		buttonLink: PropTypes.string,
		buttonCopy: PropTypes.string,
		showForm: PropTypes.bool,
		drawerRef: refProp,
		className: PropTypes.string,
	}),
	flip: PropTypes.bool,
};

export { TextWithImage };
