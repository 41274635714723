import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { datoGatsbyImageProp } from '../../../types';

import * as logoClasses from './index.module.css';

const ClientLogosPartner = ({
	pathname,
	isMobile = false,
	className,
	className2,
	logoContent,
}) => {
	const clientLogosMobile = [
		{
			src: logoContent.logoOne.url,
			alt: logoContent.logoOne.alt,
			width: 100,
			height: 61,
		},
		{
			src: logoContent.logoTwo.url,
			alt: logoContent.logoTwo.alt,
			width: 120,
			height: 46,
		},
		{
			src: logoContent.logoThree.url,
			alt: logoContent.logoThree.alt,
			width: 50,
			height: 65,
		},
		{
			src: logoContent.logoFour.url,
			alt: logoContent.logoFour.alt,
			width: 180,
			height: 100,
		},
		{
			src: logoContent.logoFive.url,
			alt: logoContent.logoFive.alt,
			width: 180,
			height: 100,
		},
		{
			src: logoContent.logoSix.url,
			alt: logoContent.logoSix.alt,
			width: 180,
			height: 100,
		},
	];
	const clientLogos = [
		{
			src: logoContent.logoOne.url,
			alt: logoContent.logoOne.alt,
			width: 194,
			height: 61,
		},
		{
			src: logoContent.logoTwo.url,
			alt: logoContent.logoTwo.alt,
			width: 170,
			height: 46,
		},
		{
			src: logoContent.logoThree.url,
			alt: logoContent.logoThree.alt,
			width: 170,
			height: 46,
		},
		{
			src: logoContent.logoFour.url,
			alt: logoContent.logoFour.alt,
			width: 170,
			height: 46,
		},
		{
			src: logoContent.logoFive.url,
			alt: logoContent.logoFive.alt,
			width: 170,
			height: 46,
		},
		{
			src: logoContent.logoSix.url,
			alt: logoContent.logoSix.alt,
			width: 170,
			height: 46,
		},
	];

	const logoCopy = logoContent.title;
	const getLogos = (logos) =>
		logos
			.slice(0, logoContent.logoNumber)
			.map(({ src, alt, width, height }) => {
				const iconSrc = src;
				return (
					<li
						className={clsx(
							'flex',
							'mt-4',
							'max-w-[26%] ml-6',
							'md:ml-8 md:mt-8',
							'lg:ml-0 lg:mt-0',
							logoClasses.clientLogo
						)}
						key={alt}
					>
						<img
							src={iconSrc}
							alt={alt}
							width={width}
							height={height}
						/>
					</li>
				);
			});

	return isMobile ? (
		// For mobile
		<section
			className={clsx('block sm:hidden px-4 pb-8 md:py-20', className)}
			aria-label="Logos of clients that we have worked with"
		>
			<div className="flex flex-row items-center justify-center pb-5 text-sm font-black text-center mt-7 font-lexend-light">
				{logoCopy}
			</div>
			<div className="ml-6 md:w-[768px] lg:w-auto md:mx-auto max-w-m-screen lg:px-8">
				<ul
					className={clsx(
						'flex',
						'flex-wrap',
						'items-center',
						'justify-center',
						'lg:justify-between',
						'justify-center',
						'-ml-4 -mt-4 mx-auto',
						'md:-ml-8 md:-mt-8',
						'lg:ml-0 lg:mt-0 lg:justify-between'
					)}
				>
					{getLogos(clientLogosMobile, 'png')}
				</ul>
			</div>
		</section>
	) : (
		// For Desktop or larger screen
		<section
			className={clsx(
				'hidden md:block px-4 py-10 md:pb-20',
				pathname !== '/' ? 'hidden md:block' : null,
				className2
			)}
			aria-label="Logos of partners that we have worked with"
		>
			<div className="flex flex-row items-center justify-center pb-10 text-3xl font-lexend-regular lg:text-5xl mt-7">
				{logoCopy}
			</div>
			<div className="md:max-w-2xl lg:max-w-7xl lg:w-auto md:mx-auto max-w-m-screen lg:px-8">
				<ul
					className={clsx(
						'flex',
						'flex-wrap',
						'md:justify-center',
						'items-center',
						'justify-between',
						'-ml-4 -mt-4 mx-auto',
						'md:-ml-8 md:-mt-8',
						'lg:ml-0 lg:mt-0 lg:justify-between'
					)}
				>
					{getLogos(clientLogos, 'png')}
				</ul>
			</div>
		</section>
	);
};

ClientLogosPartner.propTypes = {
	pathname: PropTypes.string.isRequired,
	isMobile: PropTypes.bool.isRequired,
	logoContent: PropTypes.shape({
		title: PropTypes.string.isRequired,
		logoOne: datoGatsbyImageProp.isRequired,
		logoTwo: datoGatsbyImageProp.isRequired,
		logoThree: datoGatsbyImageProp.isRequired,
		logoFour: datoGatsbyImageProp.isRequired,
		logoFive: datoGatsbyImageProp.isRequired,
		logoSix: datoGatsbyImageProp.isRequired,
		logoNumber: PropTypes.number.isRequired,
	}).isRequired,
	// eslint-disable-next-line react/require-default-props
	className2: PropTypes.string,
	// eslint-disable-next-line react/require-default-props
	className: PropTypes.string,
};

export { ClientLogosPartner };
