import React from 'react';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { PBSLink } from '../../atoms/link';
import { categoryCardProp } from '../../../types';
import { resolveUrl } from '../../../utils/url-helpers';
import { buildLink } from '../../../utils/locale';

const CategoryCard = ({
	title,
	thumbnail,
	categories,
	internal,
	slug,
	onClickProp,
	index,
	locale,
}) => {
	const alt =
		thumbnail.alt || title || 'Peninsula HR & Health and Safty Support';
	const imageData = getImage(thumbnail);
	const showViewAllLink = title !== 'Other';
	const categoriesToDisplayArr = categories.slice(0, 5);

	return (
		<article
			className={clsx(
				'flex flex-col group relative bg-white h-full w-full',
				'border-2 border-blue-200 border-solid rounded-sm shadow-card',
				'focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2'
			)}
		>
			<GatsbyImage className={clsx()} image={imageData} alt={alt} />
			<div className="flex flex-col items-start justify-between py-5 md:py-base-f px-s-f grow">
				<div>
					<h3 className="pt-2 leading-tight pb-s-f md:pb-6 md:pt-0 text-lg-f">
						{title}
					</h3>
					<ul
						className={clsx(
							'text-base-f',
							showViewAllLink && 'pb-base-f'
						)}
					>
						{categoriesToDisplayArr.map((category) => (
							<li
								key={category.id}
								className="text-base-f pb-xs-f last:pb-0"
							>
								<PBSLink
									to={buildLink(
										locale,
										`${resolveUrl(category)}`
									)}
									variant="Link"
									className="hover:underline !font-lexend-regular"
									onClick={() =>
										onClickProp({
											component_name: 'category-card',
											click_type: 'link',
											click_title: title,
											click_text: category.name,
											click_index: index + 1,
										})
									}
								>
									{category.name}
								</PBSLink>
							</li>
						))}
					</ul>
				</div>
				{showViewAllLink ? (
					<PBSLink
						to={buildLink(
							locale,
							`${resolveUrl({ internal, slug })}`
						)}
						variant="White"
						className="!text-s-f !font-lexend-regular !px-4 sm!py-3 !py-2 md:-mb-[5px] text-center leading-none"
						onClick={() =>
							onClickProp({
								component_name: 'category-card',
								click_type: 'button',
								click_title: title,
								click_text: `View all ${title.toLowerCase()}`,
								click_index: index + 1,
							})
						}
					>
						{`View all ${title.toLowerCase()}`}
					</PBSLink>
				) : null}
			</div>
		</article>
	);
};

CategoryCard.defaultProps = {
	onClickProp: () => {},
	index: 1,
};

CategoryCard.propTypes = categoryCardProp;

export { CategoryCard };
