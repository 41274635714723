/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';

import { trackPageChange } from './src/utils/userTracking';

let lastPath = '';

export const onRouteUpdate = ({ location }) => {
	if (lastPath !== location.pathname) {
		lastPath = location.pathname;
		// only track if path starts with /elearning
		if (
			location.pathname.startsWith('/elearning') ||
			location.pathname.startsWith('/ie/elearning/')
		) {
			trackPageChange(window.location.href);
		}
	}
};
