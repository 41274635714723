/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import openQuote from './quotes/open_quote.svg';
import closeQuote from './quotes/close_quote.svg';
import largeQuotes from './quotes/quotes-large.png';
import { Button } from '../../atoms/button-ca';
import { sendTrackingData } from '../../../utils';

const TestimonialsLegislation = ({
	testimonialsLegislation = {},
	firstChildClassName = '',
	phoneNumber,
}) => {
	const {
		title = {},
		subText = '',
		testimonialCards = [],
		primaryLinkCopy = '',
		ctaTagline,
	} = testimonialsLegislation;

	// Determine the number of cards to show
	const cardsToShow = Array.isArray(testimonialCards)
		? testimonialCards
		: Array.isArray(testimonialCards)
		? testimonialCards?.slice(0, 3)
		: [];

	const handleClickTracking = () => {
		if (!window.dataLayer?.find((element) => element['gtm.start'])) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-content',
			click_type: 'arrow',
			click_action: 'right',
			click_title: 'advice-',
		});
	};

	// Extract structured text
	const extractText = (variant, textBlock) =>
		createHeadingHighlightCA({
			variant,
			headings: textBlock?.value?.document?.children[0]?.children,
		});

	const ctaTaglineText = extractText('heading-blue', ctaTagline);

	const scrollToAdviceForm = () => {
		document.getElementById('advice-form')?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const handleButtonClick = () => {
		// Always send click tracking
		handleClickTracking();

		// Detect mobile devices
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		if (isMobile && phoneNumber) {
			// Open the phone dialer with the provided number
			window.location.href = `tel:${phoneNumber}`;
		} else {
			// Scroll to the form section on larger screens
			scrollToAdviceForm();
		}
	};

	return (
		<div
			className={`flex flex-col items-center px-6 pb-0 rounded-[0.75rem] lg:px-10 py-16 mx-auto lg:max-w-m-screen border-1 border-[#DDDDDD] bg-[#F5F5F5] ${firstChildClassName}`}
		>
			{/* title */}
			<div className="text-3xl leading-tight text-center md:text-5xl font-lexend-regular">
				{createHeadingHighlightCA({
					headings: title?.value?.document?.children[0]?.children,
					variant: 'brand-blue-underline',
				})}
			</div>
			{/* subText */}
			<div className="text-center text-[20px] font-lexend-light pt-6 pb-10 lg:pb-16">
				{subText || ''}
			</div>
			{/* cards */}
			<div className="grid grid-cols-1 gap-5 px-2 lg:gap-16 md:grid-cols-2 lg:grid-cols-10 font-lexend-regular">
				{/* for desktop */}
				{testimonialCards.length > 0 && (
					<>
						<div
							key={testimonialCards[0]?.id}
							className="lg:col-span-6 relative rounded-[.75rem] bg-[#9CAAF0] text-[#1F237A] font-lexend-medium shadow-sm hidden md:flex flex-col justify-center items-center text-center p-6 sm:p-8 md:p-10 md:pb-16"
						>
							{/* Background Image */}
							<div
								className="absolute top-0 left-0 z-0 w-full h-full bg-center bg-no-repeat bg-contain md:bg-stretch"
								style={{
									backgroundImage: `url(${largeQuotes})`,
								}}
							/>

							{/* Large testimonial text */}
							<div className="text-lg sm:text-xl md:text-2xl xl:text-3xl leading-snug max-w-xs sm:max-w-sm md:max-w-[80%] lg:max-w-[70%] text-left md:-mt-10">
								{testimonialCards[0]?.content || ''}
							</div>

							{/* Bottom section */}
							<div className="flex items-end justify-between w-full mt-6 md:mt-10 md:absolute md:bottom-4">
								{/* Name & Job - Stacks on small screens, absolute on md+ */}
								<div className="flex flex-col text-left sm:bottom-2 sm:left-4 md:absolute md:bottom-4 md:left-6 lg:left-16">
									<div className="text-[#3954E0] font-bold font-lexend-regular text-sm sm:text-base md:text-lg lg:text-xl">
										{testimonialCards[0]?.name || ''}
									</div>
									<div className="text-[#1F237A] text-xs sm:text-sm md:text-base lg:text-lg md:max-w-[250px] lg:max-w-[300px] xl:max-w-[450px]">
										{testimonialCards[0]?.job || ''}
									</div>
								</div>

								{/* Profile Image - Bottom Right */}
								<div className="absolute md:right-6 md:bottom-4 lg:right-16">
									<img
										src={
											testimonialCards[0]?.profile?.url ||
											''
										}
										alt=""
										className="h-12 w-12 sm:h-16 sm:w-16 md:h-[74px] md:w-[74px] rounded-full shadow-md"
									/>
								</div>
							</div>
						</div>

						<div className="flex flex-col gap-5 lg:col-span-4">
							{testimonialCards.slice(1).map((elm) => (
								<div
									key={elm?.id}
									className="h-fit bg-white rounded-[.75rem] border-1 border-[#dddddd] p-5 text-3xl-f text-[16px] leading-[1.35rem] font-lexend-regular relative shadow-sm hidden md:block"
								>
									<div className="font-lexend-regular">
										<span className="inline">
											<img
												src={openQuote || ''}
												className="inline pb-1 pr-2 -mt-2"
												alt=""
											/>
											{elm.content || ''}
											<img
												src={closeQuote}
												className="inline pb-1 pl-2"
												alt=""
											/>
										</span>
									</div>
									<div className="flex flex-row items-center justify-between mt-5">
										<div className="flex flex-col font-bold font-lexend-regular">
											<div className="font-lexend-regular text-[#3954E0]">
												{elm?.name || ''},
											</div>
											<div className="text-[#1f237a] max-w-[250px]">
												{elm?.job || ''}
											</div>
										</div>
										<div>
											<img
												src={elm.profile?.url || ''}
												alt=""
												className="min-h-[74px] min-w-[74px]"
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					</>
				)}

				{/* for mobile */}
				{cardsToShow.length > 0 &&
					cardsToShow?.map((elm) => (
						<div
							key={elm.id}
							className="col-span-1 h-fit bg-white rounded-[.75rem] border-1 border-[#dddddd] p-5 text-3xl-f text-[16px] leading-[1.35rem] font-lexend-regular relative shadow-sm block md:hidden"
						>
							<div className="font-lexend-regular">
								<span className="inline">
									<img
										src={openQuote}
										className="inline pb-1 pr-2 -mt-2"
										alt=""
									/>
									{elm?.content || ''}
									<img
										src={closeQuote}
										className="inline pb-1 pl-2"
										alt=""
									/>
								</span>
							</div>
							<div className="flex flex-row items-center justify-between mt-5">
								<div className="flex flex-col font-bold font-lexend-regular">
									<div className="font-lexend-regular text-[#3954E0]">
										{elm?.name || ''},
									</div>
									<div className="text-[#1f237a] max-w-[250px]">
										{elm?.job || ''}
									</div>
								</div>
								<div>
									<img
										src={elm.profile?.url || ''}
										alt=""
										className="min-h-[74px] min-w-[74px]"
									/>
								</div>
							</div>
						</div>
					))}
			</div>
			{/* Horizontal line */}
			<div className="w-full border-t border-[#DDDDDD] mt-16 mx-10 lg:mx-0" />
			<div className="flex justify-center pt-10 pb-16">
				<div className="flex flex-col items-center justify-center w-full gap-4 lg:flex-row lg:gap-8 lg:justify-between">
					<div className="text-center lg:text-left text-lg font-lexend-regular font-bold text-[#1F237A] w-full lg:w-[60%] ">
						{ctaTaglineText}
					</div>

					{/* Button */}
					<Button
						onClick={handleButtonClick}
						className="inline-flex items-center py-4 lg:ml-4"
						variant="RedBook"
					>
						{primaryLinkCopy || 'Speak to an expert'}
					</Button>
				</div>
			</div>
		</div>
	);
};

export { TestimonialsLegislation };
