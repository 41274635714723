import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LogoIcon from '../../../assets/logo.inline.svg';
import { getLocale, buildLink } from '../../../utils/locale';

const Logo = ({ url, title, ...logoAttributes }) => {
	const [locale, setLocale] = useState();

	useEffect(() => {
		setLocale(
			getLocale(
				typeof window !== 'undefined' ? window.location.pathname : ''
			)
		);
	}, [locale]);
	const getLogoUrl = () => {
		if (locale === 'au') {
			return 'https://peninsulagrouplimited.com.au/';
		}
		return buildLink(locale, '/') || url;
	};

	return (
		<a
			className="block focus:outline-0 focus:shadow-focus focus:rounded-xs"
			href={getLogoUrl()}
			title={title}
		>
			<LogoIcon {...logoAttributes} className="max-w-full" />
		</a>
	);
};

Logo.defaultProps = {
	url: '/',
};

Logo.propTypes = {
	url: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export { Logo };
