import clsx from 'clsx';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { StructuredText, renderNodeRule } from 'react-datocms';
import TickIcon from '../../../assets/tick.inline.svg';
import { useDrawer } from '../../../contexts/drawer-context';
import { datoGatsbyImageProp, refProp, titleProp } from '../../../types';
import { sendTrackingData } from '../../../utils';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import { Button } from '../../atoms/button';
import * as headingStyles from './index.module.css';

function SafeCheckTextImageContent({ textImageContent, drawerRef, header }) {
	const {
		id,
		content,
		ctaText,
		checkListField,
		ctaOpenForm,
		as,
		image,
		headingClassNames,
	} = textImageContent;
	const { setDrawerRef } = useDrawer();
	const Component = as || 'h1';
	const Alt =
		image.alt ||
		header[0].children.map(({ value }) => value).join('') ||
		'Peninsula HR & Health and Safty Support';
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});
	const imageData = getImage(image);
	const handleClickTracking = (text = null) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: text || ctaText,
		});
	};
	return (
		<section
			className="max-w-m-screen mx-auto lg:container"
			aria-labelledby={id}
		>
			<div className="bg-white grid md:grid-cols-2 gap-4 xl:mt-20 md:mt-10 mt-8 grid-cols-1">
				<div className="flex px-4">
					<div
						className={clsx(
							'text-center mt-8 lg:mt-0 lg:text-text'
						)}
					>
						<GatsbyImage
							image={imageData}
							alt={Alt}
							className={clsx(
								'border-8',
								'border-white',
								'drop-shadow-image',
								'self-start',
								'justify-self-center',
								'md:justify-self-start'
							)}
						/>
					</div>
				</div>
				<div className="flex flex-col md:px-10 px-4">
					<div
						className={clsx(
							'flex flex-1 flex-col shrink !pl-[-40px] mb-5',
							headingStyles.headingContainer
						)}
					>
						<Component
							id={id}
							className={clsx(
								'!leading-[1.3] font-lexend-regular md:!ml-[-16px]',
								'text-3xl md:text-4xl xl:text-4xl',
								headingClassNames
							)}
						>
							{titleText}
						</Component>
					</div>

					<StructuredText
						data={content}
						customNodeRules={[
							renderNodeRule(
								isParagraph,
								({ children, key, ancestors }) => {
									if (isRoot(ancestors[0])) {
										return (
											<p
												className="mb-0 text-lg font-notosans-light md:text-xl last-of-type:mb-0"
												key={key}
											>
												{children}
											</p>
										);
									}
									return children;
								}
							),
						]}
					/>

					<div className="my-8">
						{checkListField.map((item) => (
							<div
								key={item.id}
								className="font-lexend-bold text-lg"
							>
								<div className=" inline-flex items-center space-x-3">
									<TickIcon
										className={clsx(
											'flex items-center w-3 h-auto shrink-0 text-blue-400'
										)}
										aria-hidden="true"
									/>
									<span className="flex items-center">
										{item.text}
									</span>
								</div>
							</div>
						))}
					</div>

					{ctaOpenForm && (
						<div>
							<div
								className={clsx(
									'flex',
									'flex-wrap',
									'flex-col',
									'items-start',
									'justify-center',
									'md:justify-start',
									'md:items-start',
									'space-y-4',
									'lg:space-y-0',
									'lg:space-x-8',
									'lg:flex-row',
									'lg:items-center'
								)}
							>
								<Button
									onClick={() => {
										setDrawerRef(drawerRef);
										handleClickTracking(ctaText);
									}}
									variant="White"
								>
									{ctaText}
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
}

SafeCheckTextImageContent.defaultProps = {
	textImageContent: {
		as: 'h1',
		headingClassNames: '',
	},
};

SafeCheckTextImageContent.propTypes = {
	textImageContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		image: datoGatsbyImageProp.isRequired,
		content: PropTypes.string.isRequired,
		ctaText: PropTypes.string.isRequired,
		ctaOpenForm: PropTypes.bool.isRequired,
		checkListField: PropTypes.arrayOf(PropTypes.string).isRequired,
		as: PropTypes.string,
		headingClassNames: PropTypes.string,
	}),
	drawerRef: refProp.isRequired,
	header: titleProp.isRequired,
};

export { SafeCheckTextImageContent };
