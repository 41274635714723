import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { IconCardList } from '../../molecules/icon-card-list-bdm';

import { refProp } from '../../../types';

const IconCardsBDM = ({
	uspTitle,
	id,
	variant,
	showContentLinks,
	iconCardsContent,
	containerClassName,
	wrapperClassName,
	borderBottom,
	borderClassNames,
	isTimeline,
	bdmNumber,
	drawerRef,
}) => {
	const { uspCards } = iconCardsContent;

	return (
		<section
			className={clsx(
				variant === 'Dark'
					? 'bg-blue-500 py-lg-f md:py-2xl-f'
					: 'my-lg-f lg:my-2xl-f',
				containerClassName
			)}
			aria-labelledby={id}
		>
			<div
				className={clsx(
					'pl-4 mx-auto md:px-8 max-w-m-screen',
					wrapperClassName
				)}
			>
				<h2 className="mb-5 mr-3 text-4xl text-center lg:mr-0 lg:text-4xl font-lexend-regular">
					{uspTitle}
				</h2>
				<div className="-ml-[8px] -mt-[8px]">
					<IconCardList
						cards={uspCards}
						variant={variant}
						showContentLinks={showContentLinks}
						isTimeline={isTimeline}
						bdmNumber={bdmNumber}
						drawerRef={drawerRef}
					/>
				</div>
			</div>
			{borderBottom ? <hr className={clsx(borderClassNames)} /> : null}
		</section>
	);
};

IconCardsBDM.defaultProps = {
	id: 'hr-services',
	showContentLinks: true,
	variant: 'Default',
	containerClassName: '',
	wrapperClassName: '',
	borderBottom: false,
	borderClassNames: '',
	isTimeline: false,
	uspTitle: '',
	bdmNumber: '',
	drawerRef: '',
};

IconCardsBDM.propTypes = {
	id: PropTypes.string,
	variant: PropTypes.string,
	isTimeline: PropTypes.bool,
	showContentLinks: PropTypes.bool,
	iconCardsContent: PropTypes.shape({
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	containerClassName: PropTypes.string,
	wrapperClassName: PropTypes.string,
	borderBottom: PropTypes.bool,
	borderClassNames: PropTypes.string,
	uspTitle: PropTypes.string,
	bdmNumber: PropTypes.string,
	drawerRef: refProp,
};

export { IconCardsBDM };
