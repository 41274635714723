const trailingSlash = (url) => (url.substr(-1) !== '/' ? `${url}/` : url);

const DEFAULT_LOCALE = 'en';

const SITE_URL = 'https://www.peninsulagrouplimited.com';

const LOCALE_PATHS = {
	'en': 'en',
	'en-CA': 'ca',
	'en-AU': 'au',
	'en-NZ': 'nz',
	'en-IE': 'ie',
	'ca': 'ca',
	'ie': 'ie',
	'au': 'au',
};

const LOCALE_CURRENCY = {
	en: '£',
	ca: '$',
	au: '$',
	nz: '$',
	ie: '€',
};

const parseDatoLocale = {
	'en': 'en',
	'ca': 'en_CA',
	'en-CA': 'en_CA',
	'en-IE': 'en_IE',
	'ie': 'en_IE',
	'en-AU': 'en_AU',

	'au': 'en_AU',
};

const LOCALES = Object.values(LOCALE_PATHS);

const LOCALE_REGEX = /\/(en|au|nz|ie|ca)(?:\/|$)/i;

const getLocale = (pathname) => {
	const matches = pathname.match(LOCALE_REGEX);
	const map =
		matches &&
		Object.entries(LOCALE_PATHS).find(([, v]) => v === matches[1]);

	return map ? map[1] : '';
};

const buildLink = (locale, pagePath) => {
	const pagePathNormalised = pagePath === '/' ? '' : pagePath;

	if (pagePath?.startsWith('http')) {
		return pagePath;
	}

	const link =
		locale === DEFAULT_LOCALE || !locale
			? `/${pagePathNormalised}`
			: `/${LOCALE_PATHS[locale] || locale}/${pagePathNormalised}`;

	return trailingSlash(link.replace(/\/\//, '/'));
};

exports.LOCALE_PATHS = LOCALE_PATHS;
exports.LOCALE_CURRENCY = LOCALE_CURRENCY;
exports.LOCALES = LOCALES;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
exports.buildLink = buildLink;
exports.getLocale = getLocale;
exports.parseDatoLocale = parseDatoLocale;
exports.SITE_URL = SITE_URL;
