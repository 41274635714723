/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { Button } from '../../../../../../atoms/button-ca';
import { CallUs } from '../../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../../atoms/sidebar-header-pricing-ca';
import { TextInput } from '../../../../../../atoms/inline-text-input-ca';

import { useSalesforceApiCA } from '../../../../../../../hooks/salesforce-ca';
import { sendTrackingData, pageUrlFomater } from '../../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../../hooks/salesforce-azure-secondform-ca';
import { useFormValidation } from '../../../../../../../hooks/form-validation-ca';
import { RadioButton } from '../../../../../../atoms/radio-button-ppc-ca';
import { TimesRange } from '../../../../../../molecules/times-range-ppc-ca';

const initialValidations = {
	callWhen: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
};

export const SuccessFormCA = ({
	formTitle,
	reference,
	response,
	className,
}) => {
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);

	const numberEmployeesRef = useRef(null);

	const [chooseTime, setChooseTime] = useState(false);
	const [selectedDate, setSelectedDate] = useState('');
	const [numberEmployees, setnumberEmployees] = useState('');
	const [subscription, setSubscription] = useState(false);

	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	const { executeRecaptcha } = useGoogleReCaptcha();
	const { fetchSalesforceCA } = useSalesforceApiCA();

	const submitButtonText = 'Book my free advice call';

	let isSelectedTimes = false;

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = (clickText) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: response,
			click_text: clickText,
		});
	};

	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}

		event.preventDefault();
		const { formEntries, isValid } = validateForm(event.currentTarget);
		const todaysDate = new Date().toISOString().split('T')[0];

		if (!chooseTime) {
			setSelectedDate(todaysDate);
		}

		if (chooseTime && !isValid) return;

		isSelectedTimes =
			chooseTime && formEntries?.callFrom && formEntries?.callTo;
		const selectedTime = isSelectedTimes
			? `${formEntries.callFrom}-${formEntries.callTo}`
			: 'asap';

		const payloadData = {
			id: reference,
			selectedDate,
			selectedTime,
			globalSubscriptionPreference: subscription,
			numberOfEmployees: numberEmployees,
			pageUrl,
		};

		const sfResponse = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'PATCH'
		);

		if (sfResponse.status !== 200) {
			await fetchSalesforceAzure(payloadData);
		}

		setSubmitting(true);
		handleClickTracking(submitButtonText);

		switch (sfResponse.status) {
			case 200:
				setIsError(false);
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	return submitting ? (
		<>
			<SidebarHeader
				className="h-[563px] shadow-lg bg-white rounded-[0.75rem] border-1 border-blue-300"
				heading={
					isError
						? "We've run into a technical error with your submission"
						: 'Thank you'
				}
				text={
					isError
						? "Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
						: 'One of our advisors will call you in the next few minutes.'
				}
				ariaLive="assertive"
				isError={isError}
			/>
			{isError ? (
				<div className="px-4 pt-5 pb-3">
					<CallUs />
				</div>
			) : null}
		</>
	) : (
		<>
			<div className="flex flex-col shadow-lg bg-white rounded-[0.75rem] border-1 border-blue-300">
				{/* title + status */}
				<div>
					{/* title */}
					<div className="bg-[#3954E0] rounded-t-[0.75rem] text-white p-5 sm:px-8 sm:py-5 font-lexend-regular text-[20px] sm:text-[26px]">
						{formTitle}
					</div>
					{/* status bars */}

					<div className="flex flex-row w-full h-2">
						<div className="w-1/2 bg-blue-200">&nbsp;</div>
						<div className="w-1/2 bg-blue-300">&nbsp;</div>
					</div>
				</div>
				<form
					onSubmit={submitHandler}
					className={`flex flex-col justify-between w-full mx-auto grow ${className}`}
					id="book_a_demo"
				>
					<div className="flex flex-col grow">
						<fieldset
							className="flex flex-col px-8 pt-3 bg-white grow"
							disabled={submitting}
						>
							<div className="w-full wrapper-small">
								<fieldset
									className={clsx(chooseTime ? 'mb-4' : null)}
									disabled={submitting}
								>
									<TextInput
										type="number"
										labelText="Number of employees"
										id="numberEmployees"
										name="numberEmployees"
										aria-required="true"
										className="w-full wrapper-small"
										validationMessage="Please enter the number of employees within your company"
										ref={numberEmployeesRef}
										value={numberEmployees}
										onChange={(e) =>
											setnumberEmployees(
												e.currentTarget.value
											)
										}
									/>
									<div className="flex flex-col mt-4">
										<div className="text-brand-blue-400 text-md font-lexend-medium">
											When shall we call you?
											<span className="p-1 text-brand-red-500">
												*
											</span>
										</div>
										<div className="font-lexend-light text-[14px] text-[#8D8D8D]">
											Callbacks can be as quick as within
											8 minutes
										</div>

										<div className="flex flex-wrap gap-3 pt-3">
											<RadioButton
												defaultChecked
												labelText="As soon as possible"
												id="callAsap"
												value="callAsap"
												onClick={() => {
													setChooseTime(false);
												}}
												checked={!chooseTime}
											/>
											<RadioButton
												labelText="Choose a time"
												id="chooseTime"
												value="chooseTime"
												onClick={() => {
													setChooseTime(true);
												}}
												checked={chooseTime}
											/>
										</div>
										<div className="pt-6 pb-2">
											<hr className="border-1/2 border-[#DDDDDD]" />
										</div>
									</div>
								</fieldset>
								<div hidden={!chooseTime}>
									<TimesRange
										callFromIsValid={
											chooseTime &&
											validations.callWhen &&
											validations.callWhen.isValid &&
											validations.callFrom &&
											validations.callFrom.isValid
										}
										callToIsValid={
											chooseTime &&
											validations.callWhen &&
											validations.callWhen.isValid &&
											validations.callTo &&
											validations.callTo.isValid
										}
										isDisabled={!selectedDate}
										handleDateChange={handleDateChange}
									/>
								</div>
							</div>
						</fieldset>
					</div>
					<div className="max-w-md px-8 py-4">
						<label className="flex flex-row gap-3 font-lexend-light text-[15px] leading-4 pb-6 cursor-pointer">
							<input
								type="checkbox"
								name="subscribe"
								className="cursor-pointer w-7 h-7 accent-blue-400"
								checked={subscription}
								onChange={() => setSubscription(!subscription)}
							/>
							<span>
								Send me HR advice, legislative updates, offers &
								events from Peninsula Canada via email and text.
							</span>
						</label>
						<div className="wrapper-small">
							<Button
								type="submit"
								className={clsx(
									submitting && 'justify-center loading'
								)}
								variant="Red"
							>
								<span
									className={clsx(
										submitting ? 'invisible' : null
									)}
								>
									{submitButtonText || 'Talk to us today'}
								</span>
								{submitting ? (
									<span
										className="sr-only"
										aria-live="assertive"
									>
										Submitting your details, please wait...
									</span>
								) : null}
							</Button>
							<div className="flex flex-row items-center justify-between py-1">
								<div className="mt-4 mb-0 text-xs">
									View our privacy policy{' '}
									<a
										target="_self"
										href="/ca/privacy-policy"
										className="inline-flex items-center text-xs text-center text-blue-400 transition-colors hover:bg-brand-blue-400 focus:bg-brand-blue-500 focus:outline-0 focus:shadow-focus hover:bg-transparent focus:bg-transparent decoration-1 hover:text-brand-blue-400 focus:outline-focus"
									>
										here
									</a>
									.
								</div>
								<div className="text-[#A5A5A5] text-lg sm:text-xl">
									2 of 2
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

SuccessFormCA.defaultProps = {
	response: '',
};

SuccessFormCA.propTypes = {
	reference: PropTypes.string.isRequired,
	response: PropTypes.string,
};
