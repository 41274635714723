// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-cycle */
import { useLocation } from '@reach/router';
import { clsx } from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDrawer } from '../../../contexts/drawer-context';
import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { getLocale } from '../../../utils/locale';
import { AdviceForm } from '../../organisms/advice-form';
import { AdviceFormCA } from '../../organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../../organisms/ca/forms/salesforce/success';
import { SuccessForm } from '../../organisms/success-form';
import { Drawer } from '../drawer';

export const CallUs = ({
	className,
	altMobile,
	onClickProp,
	number,
	pageName,
	callFrom,
}) => {
	const data = useStaticQuery(graphql`
		query {
			datoCmsSiteSpecificSetting {
				phoneNumberCopy
			}
		}
	`);
	const location = useLocation();
	const locale = getLocale(location.pathname);
	const [fallBackNumber, setFallBackNumber] = useState('');
	const Component = 'a';
	useEffect(() => {
		if (!number) {
			if (locale === 'ca') {
				setFallBackNumber('1 (833) 247-3652');
			} else if (locale === 'ie') {
				setFallBackNumber('1800 719 247');
			} else if (locale === 'au') {
				setFallBackNumber('1300 660 930');
			} else {
				setFallBackNumber('0800 158 2313');
			}
		}
	}, [locale, number]);
	const { drawerRef: callDrawer, setDrawerRef } = useDrawer();
	const callAdviceFormRef = React.useRef(null);
	const anchorRef = React.useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const { width } = useScreenResizer();
	const handlePhoneModal = (text) => {
		if (
			width > 500 &&
			locale !== 'ca' &&
			locale !== 'ie' &&
			callFrom === 'header'
		) {
			anchorRef.current.href = '#';
			setDrawerRef(callAdviceFormRef);
		}
		onClickProp({
			click_text: text,
		});
	};
	return (
		<>
			{locale === '' && pageName === 'safecheck' && (
				<a
					ref={anchorRef}
					href={`${
						width > 500 && locale !== 'ca' && locale !== 'ie'
							? '#'
							: 'tel:0844 892 2486'
					}`}
					onClick={() => {
						handlePhoneModal('0844 892 2486');
					}}
					className={clsx(
						'text-lg',
						'sm:text-xl',
						'lg:text-2xl',
						'phone-number',
						'font-lexend-medium',
						'text-brand-red-400',
						locale === 'ca' ? '' : 'ruler-click',
						'focus:outline-0 focus:shadow-focus',
						altMobile
							? 'inline-block leading-7 bg-white border-2 border-blue-400 rounded-lg pt-1 pb-2 px-4 lg:p-0 lg:bg-transparent lg:border-0 text-center lg:text-left'
							: 'flex flex-col leading-none',
						className
					)}
				>
					<span
						className={`${locale === 'ca' ? '' : 'ruler-landing'}`}
					>
						0844 892 2486
					</span>
					<span
						className={clsx(
							'text-xs text-black md:text-sm font-lexend-regular',
							altMobile ? 'flex items-center' : 'inline-block'
						)}
					>
						<span className="inline-block ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
						Book your audit now
					</span>
				</a>
			)}
			{locale === '' && pageName === 'workplace-mediation' ? (
				<a
					ref={anchorRef}
					href={`${
						width > 500 && locale !== 'ca' && locale !== 'ie'
							? '#'
							: 'tel:0161 827 8561'
					}`}
					onClick={() => {
						handlePhoneModal('0161 827 8561');
					}}
					className={clsx(
						'text-lg',
						'sm:text-xl',
						'lg:text-2xl',
						'phone-number',
						'font-lexend-medium',
						'text-brand-red-400',
						// locale === 'ca' ? '' : 'ruler-click',
						'focus:outline-0 focus:shadow-focus',
						altMobile
							? 'inline-block leading-7 bg-white border-2 border-blue-400 rounded-lg pt-1 pb-2 px-4 lg:p-0 lg:bg-transparent lg:border-0 text-center lg:text-left'
							: 'flex flex-col leading-none',
						className
					)}
				>
					<span
					// className={`${locale === 'ca' ? '' : 'ruler-landing'}`}
					>
						0161 827 8561
					</span>
					<span
						className={clsx(
							'text-xs text-black md:text-sm font-lexend-regular',
							altMobile ? 'flex items-center' : 'inline-block'
						)}
					>
						<span className="inline-block ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
						Book your audit now
					</span>
				</a>
			) : (
				<>
					{pageName !== 'safecheck' && (
						<Component
							ref={anchorRef}
							href={`tel:${
								number?.replaceAll(' ', '') ||
								fallBackNumber?.replaceAll(' ', '')
							}`}
							onClick={() => {
								handlePhoneModal(number);
							}}
							className={clsx(
								'text-lg',
								'sm:text-xl',
								'lg:text-2xl',
								'phone-number',
								'font-lexend-medium',
								'text-brand-red-400',
								locale === 'ca' && locale === 'au'
									? ''
									: 'ruler-click',
								'focus:outline-0 focus:shadow-focus',
								altMobile
									? 'inline-block leading-7 bg-white border-2 border-blue-400 rounded-lg pt-1 pb-2 px-4 lg:p-0 lg:bg-transparent lg:border-0 text-center lg:text-left'
									: 'flex flex-col leading-none',
								className
							)}
						>
							<span
								className={`${
									locale === 'ca' && locale === 'au'
										? ''
										: 'rulertel'
								}`}
							>
								{number || fallBackNumber}
							</span>
							<span
								className={clsx(
									'text-xs text-black md:text-sm font-lexend-regular',
									altMobile
										? 'flex items-center'
										: 'inline-block'
								)}
							>
								<span className="inline-block ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
								{
									data.datoCmsSiteSpecificSetting
										.phoneNumberCopy
								}
							</span>
						</Component>
					)}
				</>
			)}
			{callDrawer && (
				<GoogleReCaptchaProvider
					reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
				>
					{locale !== 'ca' ? (
						<Drawer
							label="Book my free advice call"
							ref={callAdviceFormRef}
							open={callDrawer === callAdviceFormRef}
							hasClose
							className="!p-0"
							elevation={20}
						>
							{reference === '' ? (
								<AdviceForm
									setReference={setReference}
									setResponse={setResponse}
								/>
							) : (
								<SuccessForm
									reference={reference}
									response={response}
								/>
							)}
						</Drawer>
					) : (
						<Drawer
							label="Book my free advice call"
							ref={callAdviceFormRef}
							open={callDrawer === callAdviceFormRef}
							hasClose
							className="!p-0"
							elevation={20}
						>
							{reference === '' ? (
								<AdviceFormCA
									setReference={setReference}
									setResponse={setResponse}
								/>
							) : (
								<SuccessFormCA
									reference={reference}
									response={response}
								/>
							)}
						</Drawer>
					)}
				</GoogleReCaptchaProvider>
			)}
		</>
	);
};

CallUs.defaultProps = {
	className: '',
	altMobile: false,
	onClickProp: () => {},
	number: '',
	pageName: '',
	callFrom: '',
};

CallUs.propTypes = {
	className: PropTypes.string,
	altMobile: PropTypes.bool,
	onClickProp: PropTypes.func,
	number: PropTypes.string,
	pageName: PropTypes.string,
	callFrom: PropTypes.string,
};
