export const hrData = {
	servicesTitle: {
		value: {
			schema: 'dast',
			document: {
				type: 'root',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								type: 'span',
								value: 'Here’s how we can ',
							},
							{
								type: 'span',
								marks: ['highlight'],
								value: 'support ',
							},
							{
								type: 'span',
								value: 'your business',
							},
						],
					},
				],
			},
		},
	},
	servicesSubtitle:
		'For everyone from entrepreneurs to enterprise, anywhere in Canada',
	servicesList: [
		{
			text: 'Unlimited 24/7 expert advice on all HR matters',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27',
							srcSet: 'https://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=0.26&fm=webp&q=85&w=27 7w,\nhttps://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=0.52&fm=webp&q=85&w=27 14w,\nhttps://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27 27w',
							sizes: '(min-width: 27px) 27px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAAXNSR0IArs4c6QAAAgRQTFRFR3BMNlHXOlTfN0nbOVTiOVTgOVTgOVXjQED/MzPMOlXgM0zmOFThJEnbQEDfOlTgOVTgOVPgKlXUOlTgOVXjOFLgOVPfOFTgOlTfM2bMAID/OFTgOFTgOlbhOlPgSUn/OVPhVVWqOFTeAAAAOlTiOlXgO1HhOlPgOVXgOVTgOVLgOVPgOlHcAAD/OVThOVTgOVXhOVXhM1nmOFThN1LkOVTgO1XeOFXeRFXdOFLfQEC/OVbiOlTeOVXhPFTfQFXqOFTfOVXfM1nZO07YOVTfPFXhOVTeOVTgOlLeOVPgOFTfOVTgOVTgO07iN1XhOVThNFXeOFTgN1LfOVLfOFXgOlPgOFPgOlHgOFLhOFTgOFLcOlLeOFThOVTgM1XdOlXiOFPfOVXgOVPgOlTgO1LgOVXjOVPhOFTgOFThOlXhOVTfN1PdOVXdOVLhN1PfOVPfOVTdOVXjN1DcOFPfOVPfN1PgOFXgOFTfQGDfOFThOFTgOVXjOVTfOVThOVTfOVTfOFLfOlXhOlPhOlXhOlHiOFPgOFThOFLhOlXfOVThOFTgOFTgOVPfOVPhOFTfOlTfNlXgOlXgPFLhAACAO07rOVHfVVX/OVPgOFPeN1TfOlLeOVTgOVXfOlXgOFPeM1LgOFTfOlfiOlDbOVTfOFPgOFTfOVXfO1TgOVPgMFDfO1XeOVTguOKFlAAAAKx0Uk5TABNYDoKmdAkEBUIKiAcIfIKiBnMSMlmaYQUCklt3jQeHA4MBWDlFllGzY5wWAaquuMEUdkHITk4PiARHhZBADH8/FBpnM17AH4pAt7waKqsnpDh5tXK1OURSO2bJjg9glquUakEksoxtXY4lLXmBR0NIM5evi7u6CJeVLZhMsK2J62VULJabO2BDhZ5+qYixIY0iAg0/A2JlcF2ruEtNGYkjI6askUhJphAntAyni1AAAAEtSURBVBjTRU9jlwMBEEu53dpuz7Z9Pdu2bdu2bfNP3nbbvpsvySQzeTOAvfwlYXzCznkWgqQgRZ0kC3Z3oyWVrM5gM3XJQWyxlRAry5OObSKeHUABa1+9CJSUC40sKqWYSUnXO/pV1jRH0dgzNgys3T3i7PAEuvWlKWUvhiZUwJMR55oLbO+SGBwHmBskPg242sOpeR6oGgDEty/4+YJpC8RcH5BT0T8L8wcYvzBpQTQVAKFppSM4fsD3Gy650GdH2c6SLxzh+RWbMzxE+pG0VD3Kw/07GGX5EHnFCCglPLMSOLgBuFIgOiGXq9WE8IGiQiXA8PGkJmoU7IxUIJbja03wiEh0vC0QurrQJC6vtsGKovRAZye7mVXf0dLaLWnmeOO/2pnSzq42Od38AQ7fO5f5KlNpAAAAAElFTkSuQmCC',
					},
					width: 27,
					height: 30,
				},
			},
		},
		{
			text: 'Clear and legally compliant HR documentation',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28',
							srcSet: 'https://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=28 7w,\nhttps://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=0.5&fm=webp&q=85&w=28 14w,\nhttps://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28 28w',
							sizes: '(min-width: 28px) 28px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAMAAABYi/ZGAAAAAXNSR0IArs4c6QAAAVZQTFRFR3BMM1XdOFTgOVTfOFTgOVTgM0zmM2bMOVTgN1PjN1XhOVPgOVXfOFTeOVTeNlTiOlLiOVTgOFThQED/OVTfKlXUOVTfOVXjAAD/OVTfOVTgOFfgSUnbPFLhOVHfOVTgOFLhOFXgNlXgNVXfNlHeOFPfN1npOVXgOVPfN1TgOFTgO1PiOVPhOVThOVPhN1ThOVTgNlHdOVTgOlHgOVXhRFXdOVLgOVPgOlTiOlXkOVThNlXgOVTePFXdOVPfOlLhN1LkN1TfOVTfOlTgOVXiOVTgOlXhOlTfOlLeOlLeVVX/OFbgOVXjN1vbOFPgOFPfOVXgM0zMQFDfOFLhOlDiO1TeLl3ROVXfOVThOFPeJEnbMzPMLkbROVbiOlTiLl3oPFHdQEDfOVHjO1PfQGDfM2b/N07eOlbkNlHkOVXjOlXjNlfeOVXfOlHcOlTgOFbiOVTgPlXo6SbAbQAAAHJ0Uk5TAA+St1uOCgWdLjwxsoV9PT6tkQS5Bq4JAaE6MgciP7M7tSEYL6UXY8GdUjTKqpCYtCa9Od4PgqJPOZdCXh5ZXRxPztS3q1SxPh8Dg0gOjV+KChBEIz0LuLOyBwULhkYLJgg/XwgFF3wTG7UvgRaFen0hRZTQgAAAANxJREFUGNNjYGBQ12AGAxEnewYoEFbVYgQDBxFna6gYuyY/hMEoaSNiDBXjEGAQlYlKFPZ1tZU20oaKWXpGKCpmsQZ6u1iZ6ChDxDxkZJWUCjMlgnwcLXQ5IWJyDHAgqgcVU8jIyeMFgdhgdiaYWG5+AR8IpMeJCkHFkhB642Hq+FOzucFAOi0FJiaVICYmCARi8snRQpj2hsHUCTCwQUCMMBtcb0ikPA8IhPt7we1gDOUCAwFZcYReOGCD2mHOjxAT11cBURJm7gGsUOAnaaAGljN1Y4EDO0MGBgYAVJcett1RP5wAAAAASUVORK5CYII=',
					},
					width: 28,
					height: 30,
				},
			},
		},
		{
			text: 'Smart software to simplify everyday HR tasks',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28',
							srcSet: 'https://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=28 7w,\nhttps://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=0.5&fm=webp&q=85&w=28 14w,\nhttps://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28 28w',
							sizes: '(min-width: 28px) 28px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAMAAABYi/ZGAAAAAXNSR0IArs4c6QAAAeBQTFRFR3BMN1TiOVPgOVPgOlTiOFPeN1TfOVTgOFTgOFPfQFXqOVThOFTgOFPfOVXgOVTfOVTfNVXfOVThOVTfN1TeOVPeOVPgN1XeOlTgOFTfM0zmOVPfOVTgOVTcO07iOFPfOVPeO1TiOVXgNlXdOlThOVPgOVXiOVLfOlXiOFTgN1XdOFTgN07eN1TfOFPhOlPgOFLfOFHgPFXdOlXkOFTfOVPeN1PdQFXUOVThOlTeQED/OVPfOFTbOVXfOVTeO1jiOVTgOVXfO07rN1PdN1LgVVWqO1TeOVXeOlXhOlHgOlXgOlTgOlXfOVPgPVLWKlXUOVTgOVPgOFDfOFLhN1ffOFPhOVPiQFDfOFPgOVTfOlLiOVTeOVTgOVTgOVTgOVThOVTgOVXgSUnbM1XdOFTgOVTdN1PhOFTfOVTgN1XhOVXfOFXgOVXhN1TgOFTgOlLiOVPhOVPgOVTfOlXgNlXgPFXmOFXhOVXgOlLeN1LbOVXjOVTgOVTfOFXiOVTgOlTfOVPgOFPgOlXkOVTgOVTgOlXhOFPfOVTgOlPfOVTgOlTfOVXgOVXhOVTgOVThPVzgOVTfOVThOVXfOVTgOFTgOVLgOVTfOFTfOVTgNFLhOVTgOlTiOFTfN1TfOVXgOVPgffx4CgAAAKB0Uk5TAEacokZlT3SenwyXmmhaj6AYQ6FGdWtFm6AKsrQ6GjecPXVLgGK3cJakPEkXoV+Wdikew56iUwxeRgSZQIFeGr0/DSVBAz02XTlLozDNGQyLdSA7OFZHEHK/NbzGxauGrWMHD95DXEC8RbiEfoKjPooxZzk5Hp/WHxwJ1HBOs7rEo5CmqmZfjmk6WK+QrpgZuaCylFtRtpedIraeulilbJUzhtUAAAELSURBVBjTY7CQEWaGAAGFMgVJiTzpYAZTM3MuCKhUrFAszU8OFGXgtGSAAr7ymuqilCAPdgYTLZhYuHxVYVIIA58og6YaExTwcIOghLEYg7CKMhsIiKeHgWltDXUGTiGIzramTAhDlZ2BkwVIS+XyzelpTotwBbJZoWI53fNmCPYKZIQiiXVNFpzPP6W/JRUhJj97mtL0ubN4JxTIwcWy2+UmzmRg6RPM8oKL8da1CkxlUOIXi3SHi/kn1ndOkmksjvW0QtgREF/bIcse5W2HZK+bb0JDSbS0gyGSGIOzVFwMu70RA0RM0gbiJT8fJwhDz4DB2lacEQRcHIVAlIiIPi+DLDMHCtDl1wEAeus2R1SeL8oAAAAASUVORK5CYII=',
					},
					width: 28,
					height: 30,
				},
			},
		},
		{
			text: 'Legal assistance with employee claims. And more',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27',
							srcSet: 'https://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=0.26&fm=webp&q=85&w=27 7w,\nhttps://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=0.52&fm=webp&q=85&w=27 14w,\nhttps://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27 27w',
							sizes: '(min-width: 27px) 27px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAAXNSR0IArs4c6QAAAe9QTFRFR3BMN1PdOFTgN0nbAAD/OVTgOFLdOVXjOFTfOFPgOVTeKlXUJEnbOVXgO07YOFLhOVPfNlXgM1XdOVTgOlTePFrhOVPfOVTgQED/Ll3oN1XhOVThOVTgOVThOFTfOFTgOVTfOVTfOlLeLkbROVXfNVXfOFPfOVTgM0zMOlXhNlXYQFXqQFXUOVXfOFTeOVXgOVXgOVXUOVTgOVXfO07rOFPeOlbgOVXgOVThOFXhOFjfOFXeN1bgOlTgOFPhNlHkN1PgOVThOVTgOVTfNlHkOlLeOVXgOVThOlHcOFTfN1neOVTgN1bkOVbfOlTiOlTgOlTgAAAAOFTfOlbiPFLhOFTfOlXgO1XeOVTfOVPgOFPeN1LgOlfiO1LgNVXfNlXgOFPfOlTfN1XeOFPfO1PgNlXgOFPeOVPfOFLhOVXjOVPfOlTgN1XdOFbiOFXgM1nZOlThOFDfOVbhOVTgOFPfOlXgOlfiOVXcOFPgQGDfOFThMzPMOFPfOFbgN1PgN1LkOVPeNlXgOFTgOFXgN1XbOVThOVXhOVXhOlfiOFLcPEvhOVTgOVTfOlLeOVXgOVXhOVXhOFTeQFDfOFXgOVbhPVXnNFLaOlTfNlHeOVPgN1TfOlTgOVThOVXgOlPfOFLgOFTeN1PfOVTgOVXgOVXeqxn9PQAAAKV0Uk5TAEqjDgE6RBKJhH0GB6INRJlCD4tGEYGrBAsqQ32gp4N5oD4LshhuqgpmIQwMvYPbqxLlyg2NpFG5nyBOU9SRE1OqwLkvXWxMFnYXnUFQYXN8AZ4+IpE5J6GdhEEjQTAhlmFFvko5Vkd/CX5qPGiEFHcghpSorDUkowhtBXGDShxsS0m1KqGZbyw7EdGwH5zBopUQlqEVIrEvYo+kqVpuMm14y9M2mQLq4wAAAS9JREFUGNNN0GNzA3EQBvA9NP/eXeykMWtbqW3btm3btj5oL9ebaZ5Xu7/dmZ1ZAD7JEviPNkSMEEpN9yCkCg3kKDgsJgk3JqbF46LIiKC/teysKEKXEiePFSdEKznRF0tzAGThbKmQGkgvjQ53lgMIKBKKquob5awsPz3Mdwg56lPDtIClu2eGXGhjCcixLVgzs/TqAnhcAgkF47tH4DTZQeu2IfRzvyijyM3LK3R7poSPbxGOi742mod2zh0X+M3JMbx9MgTB7LtsA5NWp46wnzpg/eUd4GCmvboJLAYhCFdXAALcxr3ZVu/Fwanta80Ew55UHc4R7ISqBFI/0uLzjxKzTwMFhTRtsXbTFbQ6g6d8E4b19HdhGJan4am0odePS11ZJk81Cn8+tbkA8At71Dj9Qi1pwAAAAABJRU5ErkJggg==',
					},
					width: 27,
					height: 30,
				},
			},
		},
	],
	servicesImage: {
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: 'https://www.datocms-assets.com/64336/1733778040-hr-2.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=399',
					srcSet: 'https://www.datocms-assets.com/64336/1733778040-hr-2.png?auto=format%2C%20compresss&dpr=0.26&fm=webp&q=85&w=399 100w,\nhttps://www.datocms-assets.com/64336/1733778040-hr-2.png?auto=format%2C%20compresss&dpr=0.51&fm=webp&q=85&w=399 200w,\nhttps://www.datocms-assets.com/64336/1733778040-hr-2.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=399 399w',
					sizes: '(min-width: 399px) 399px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAASCAMAAAB2Mu6sAAAAAXNSR0IArs4c6QAAAotQTFRFOVThN1LhN1PhPljhOFThM1DhOVTiOVThOFPhNlLhNFHhhpbrQFndOFPdPVfhbX/nYHPlOFPgPFbQSWDTSWDiSGDSQFnhNVHheovpPlfhWGzkSF/id4jpUGbjXnLliprsb4HnUmjjf5DqgJDqe4zpf4/qbn/nQVrgSmHXN1LfO1bgU2jZN1PPPVfWjZrfP1jUNlLZS2LTME7PO1XTOFPfN1LPPljRPFfhPljiRV3hQFnRRFzSOlXXQlvUU2neR1/SO1biOFPiQFrfN1PbN1PiQFnTVmrYRFzdME/gK0zgJkngRV7UQFnXOFPWQFnYP1nahpPhSWDeTGLUcoDWRV3gWWvTOVTgKUnNNlHPwcnpOlTbUGXfa3vZQVrSQFrSYnPdNFHcRl7VhZHgNlLfKEnNL07fP1jWPVfcYXPYR1/UQlrTiZXbO1bZUmjZSmHTQlvfQlvQPVfQP1jRSWHTQVrdaXzmNVHYRVzgN1LTQFrgV2zkVWrkOlXcPFbYTGHiW23U1t7yP1jQMU7OP1nWN1LgO1XQMU7ggpLqMk/cvsfrNFDSNVHeanvYQ1zXQ1vSPFbRRl7TV2vWNVHXRV3UN1LSbX7nYnXmQVrRSGDTX3PlXG/lSGDih5frUWfjR17iPlfScoToS2LiPFbhKEnWQFrhq7XmQ1zik5/hQ13TgpLrXG/kN1LWhJXrME7fipbbX3LlT2XjfY7pZnjmSmHiTWPjSWDUr7jnc4XoTGPjaHrmWW7kUWbjJ0jXRl7iNFDhHkTfiZnseorpXHDkWW3kLk3gNlLdfY7qMU7XdIbpVWrjcYPoZnnmZXjmZXfmbH7oVWnki5vskJ/thZXrjp7tcILnanzmgpPrfo7qaHvmNcq9DQAAANl0Uk5T7////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////1B4vF4AAAF0SURBVBjTY2DHACxgkgGIOSB8Dg5HFnYmTwcLqASHII8WSNzXh8c/nTM6wM3OHCKhrqGjb8LOllBUWFyQmBoTGeztBDKCgUNbz8jUhoWxYvqMicI8yVmhEUHuEAldQzMr17Dy+SuWL+1i4khLCRcNZARJsBkIWdvHVoqt3rpt3WI2jhLhzNwQF7Admsa2fjXMnFxHj+9Zy8JRl1/bnBPFKwhylaU0BwvfjkMc507s5GNmbpki1JgX58wG9AczHzs752FVgYsqyrvZ2bv7+1qbsuNZQR6UXMR65OSlK5cvXOfcxyk5WXRCfUOSF0hCXPGgmuJpLqUzCtJSW5h4J/X2dGZ4gCTaFDbukr16/oaa3N7tq/hnTZPrKOMFhxXTPH6lA6ry184qy6yRnynDzV3NCglE1tIFK7lVjp3av4mbf85UiXYJEUZo6IoJzF22fsNmziULZ4sIyFZJgQORDSTDKs7EysnIzsHJyMjKycUFlgDLYAEAbF5jS43JqggAAAAASUVORK5CYII=',
			},
			width: 399,
			height: 298,
		},
	},
	servicesCtaLabel: 'Get a free demo',
	servicesBackgroundImage: {
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: 'https://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=772',
					srcSet: 'https://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=772 193w,\nhttps://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=0.5&fm=webp&q=85&w=772 386w,\nhttps://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=772 772w',
					sizes: '(min-width: 772px) 772px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAASCAMAAACHObUhAAAAAXNSR0IArs4c6QAAAelQTFRFR3BM////3eDx5ub/3d/w3uHw////3N7w3N/w3t7w////293u////////3+Hw3d/w3uHy3uHy7e324eTz39/w7+/3293u9PT54+P08fH44OD14uX04+Px3+Ly9PT/3Ojz5OT04eHw3t7w3d3w3uHx3uHx6urq3N/v5eXz+Pj/+vr/3d3u8vL45uby3N/v297x4ODx3+Lw4+Px4+P04+P23+Ly4eHw5eXy////4+Px6Ojo8P//////6Ov13t7x29vb3uHy6+v44uLz6ur26en04uXz4///5eX139/x7Oz15un04+Px7Oz25+fz4+P05OTy3uDx5+v36+v13N/y4eTy7+/36ur44eHy2t3w6Ojw3Nzw9vb/4Obz3uDx9vb28PD38fH66uz04+by3+Hz293x4eX18fH46/X/3+Hy8/P539/y7+//8vL26Ojy7Oz54OPx5OTy5+f18fH25OTx7u745uv16u/3////6ur35+fz6ur18PD64ODy5ub04OPz8PD/5Of03t7v4eHy7Oz14uLy6Oj14OLy////5+vz5un04uLy5Ofz6+v36Ojz7PH63t7x6Oj3////5uby5+f34eHz6u/06en28fHx6ur339//8PX64uby6Ov16e345evy3uHw4OLx2t3u6en2MDLabAAAAKN0Uk5TAAJqCmhmBmZnZgFpCQdnaWZlOGdnH2ouWyUZWTdhFxYvRVRTXF4MYGwkOB4mKF9rW2kSSBtgMycNJAsRBUxeB00nLFYvVwlNWWlGSTcqWjlsQBlgZiAlX2giZx0pax1DNmBkZ2pNJBpfKV8QOkwoW2ZJOF1LMz4EPkAxNGNdUhFgXTs1Tk9iEEBbUGlBQjdsIQpkIFYwURI9CDI9TkYnZWpoOe6u6B0AAAErSURBVBjTY2CAAsYET0cGDOAWF12UGIohzBYWL55Tko4qaC7KFiORl1ZW2SHHhSSszSnsGllYUFrR2z2pNZsRJmykysLJLZtbLNTTN3eWdEMmTNxYn5OdXSS8tqtTdtrM2QuaYeK6ggLs7OwcHCwL58+ZLLloajlUnFePjxUowc7JP2O6nMyUCdWiEHFmAw5OViBMbuRlYJjX31aTFOhiAxTXEmQHWsDJLpJVz8AwsYWfgy9WWNyai0HTkB1sDntEfpNUex0LO1+IjJezBYOOBrcASAc7O0sKf1UqS1SwP08QD9BeIWVWJiYWDg4OoKsyJDg4/KR9JCEhKa+gqKSibsohJsbNwR7g7WvLAw8MLjZmNRNLJw8WJncpBjaMcLWyc7BnwAIYzZihTAD6pzc0Vu0p9gAAAABJRU5ErkJggg==',
			},
			width: 772,
			height: 597,
		},
	},
	servicesDownloadTitle: 'Download expert documentation for FREE:',
	servicesDownloadList: [
		{
			text: 'Policy templates',
		},
		{
			text: 'Employment contracts',
		},
		{
			text: 'Employer guides',
		},
	],
	servicesDownloadCtaLabel: 'Click to download',
	servicesDownloadCtaLink: '/ca/resource-hub/downloads/',
	servicesDownloadImage: {
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: 'https://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=129',
					srcSet: 'https://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=129 32w,\nhttps://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=0.51&fm=webp&q=85&w=129 65w,\nhttps://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=129 129w',
					sizes: '(min-width: 129px) 129px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAMAAABVlYYBAAAAAXNSR0IArs4c6QAAAmpQTFRFR3BMGyhRGCRVHCpQAABVHipQHChPGylSGipMHCpPHShPHSdTHilSHilRAAAAHylSHCZMnKvyn6zxnKzymarunKrwm6fuoKjwnqzznKrxqqr/nqvyGixPHCpRnKrwHydNm6jvHSlSGydSHiZSGyZRnqzzn6zxHShQm6jsHSpSICpKHSlSIDBQHSpRnqvyGipVHShTGjNMnqXwHSpRHSlSnarxMzNmnavyjZfbm6nuAEBAHShQHipPhpLbHipS5ub/4On0HSlRGyhNnKnv4ujzKipVnartHilRHipTnazynavyHypRHShRHBxVHCdTGypQHCpQkJTfHSpRnqvynKrwHSlRHSlSHSpSnqzy4+35nKrx3OP2AAAAnKrwhIzbmKbwmqnt4On2mKfxaIDonazzn6z4l6fvnKrx4On2amqqHSlQHipRnqftVVVVmqztnKnwnKrwGydNGCNJIChQgICAnarwEiBDFyhPGyVOnKnwnKjunKnvnaru4ej15Or2m6rwnKrwmqjxnavxmKTsnKvxm6rw6Oz44Of4zNPxjpfgqLLxnqvznKnwnKrwoK7wmKbv6O/4mcz/nKnynq30m6rwnKjwnavxm6nxn6r0m6rvm6jvnanwm6vvn63znKrxuMLxm6nxnqzxo7Dxm6rxmKfwk6Lwm6jw3OL15u33lKbtnazwoa7wn6vyn6zynKrwnqzzobD4nKrxoK72n671navyoK71HipTn631HytU6fH+j5fenazynavx5+776e/6nq307vP77/T96e/75+34oK/2tcD8oa/4n630mqjxnKr0y9T3wcr6rrrywsv5HAUIZAAAAM50Uk5TADkVNgNDLTgeN0c0REUBGUpklF8P4D0j+vgMiB1I2yFPV0EiL34lRin7GLsQYe4eRwoiYtP5BesxWQRZKirvClugTPQsBjmKK6L0Qt0JLkNcN9yY7s33c9xTtVEC77jBR1LGFvUlxflRDFA8HQMrhq9CSSAC10gtS5ss5TxMzOH0n+9cg5ZE/m1zNWen0OK0kwU7y0VkXlktrmFlXpVsNm6W8u40Y85Q4CtlnT0o//////////////////////////////////////////+wMVWRAAABR0lEQVQY02NggAMWFUZ2EGD0Y0EIMrBy63MAAT8TtzdC0MwJQrMFMHCBGWLSqhoMrgJgNpedj68/iCEssXmruVuMAAOPB4OupokNAy9QUER0/bp126Nyi/hCg9y3aWsxgEXFJTeuW7e7NLOMITtsh6MhUIQHiKXkgGo3F5RUMDBkhfOBjDQAEQp669dH5DEUMhfzWzEwKcszKwEFjdQsPYMTUnMYyjmt1VllOTnZWBgmVnqZOofEpm9MyWdgMFaUgTh6yvr1W9at21KflhSfzKAD89LUTb3VezY2dNVmJEa6wD0quGF6z/71/W2H9u3d6QAXnb1h2sxNm5ZOOHigLtoeSe2clsnL5q843N4RZwsXnbWhddKalctPnenr3GUBF53RdOT4sQWrT55Y3ByIFNhV3fNWLVl0+uzRRgY0sHahkNDcGoQAABwBYnJfNCzDAAAAAElFTkSuQmCC',
			},
			width: 129,
			height: 122,
		},
	},
};

export const hsData = {
	servicesTitle: {
		value: {
			schema: 'dast',
			document: {
				type: 'root',
				children: [
					{
						type: 'paragraph',
						children: [
							{
								type: 'span',
								value: 'Here’s how we can ',
							},
							{
								type: 'span',
								marks: ['highlight'],
								value: 'support ',
							},
							{
								type: 'span',
								value: 'your business',
							},
						],
					},
				],
			},
		},
	},
	servicesSubtitle:
		'For everyone from entrepreneurs to enterprise, anywhere in Canada',
	servicesList: [
		{
			text: 'Unlimited 24/7 expert advice on all Health & Safety matters ',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27',
							srcSet: 'https://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=0.26&fm=webp&q=85&w=27 7w,\nhttps://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=0.52&fm=webp&q=85&w=27 14w,\nhttps://www.datocms-assets.com/64336/1733778094-vector-3.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27 27w',
							sizes: '(min-width: 27px) 27px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAAXNSR0IArs4c6QAAAgRQTFRFR3BMNlHXOlTfN0nbOVTiOVTgOVTgOVXjQED/MzPMOlXgM0zmOFThJEnbQEDfOlTgOVTgOVPgKlXUOlTgOVXjOFLgOVPfOFTgOlTfM2bMAID/OFTgOFTgOlbhOlPgSUn/OVPhVVWqOFTeAAAAOlTiOlXgO1HhOlPgOVXgOVTgOVLgOVPgOlHcAAD/OVThOVTgOVXhOVXhM1nmOFThN1LkOVTgO1XeOFXeRFXdOFLfQEC/OVbiOlTeOVXhPFTfQFXqOFTfOVXfM1nZO07YOVTfPFXhOVTeOVTgOlLeOVPgOFTfOVTgOVTgO07iN1XhOVThNFXeOFTgN1LfOVLfOFXgOlPgOFPgOlHgOFLhOFTgOFLcOlLeOFThOVTgM1XdOlXiOFPfOVXgOVPgOlTgO1LgOVXjOVPhOFTgOFThOlXhOVTfN1PdOVXdOVLhN1PfOVPfOVTdOVXjN1DcOFPfOVPfN1PgOFXgOFTfQGDfOFThOFTgOVXjOVTfOVThOVTfOVTfOFLfOlXhOlPhOlXhOlHiOFPgOFThOFLhOlXfOVThOFTgOFTgOVPfOVPhOFTfOlTfNlXgOlXgPFLhAACAO07rOVHfVVX/OVPgOFPeN1TfOlLeOVTgOVXfOlXgOFPeM1LgOFTfOlfiOlDbOVTfOFPgOFTfOVXfO1TgOVPgMFDfO1XeOVTguOKFlAAAAKx0Uk5TABNYDoKmdAkEBUIKiAcIfIKiBnMSMlmaYQUCklt3jQeHA4MBWDlFllGzY5wWAaquuMEUdkHITk4PiARHhZBADH8/FBpnM17AH4pAt7waKqsnpDh5tXK1OURSO2bJjg9glquUakEksoxtXY4lLXmBR0NIM5evi7u6CJeVLZhMsK2J62VULJabO2BDhZ5+qYixIY0iAg0/A2JlcF2ruEtNGYkjI6askUhJphAntAyni1AAAAEtSURBVBjTRU9jlwMBEEu53dpuz7Z9Pdu2bdu2bfNP3nbbvpsvySQzeTOAvfwlYXzCznkWgqQgRZ0kC3Z3oyWVrM5gM3XJQWyxlRAry5OObSKeHUABa1+9CJSUC40sKqWYSUnXO/pV1jRH0dgzNgys3T3i7PAEuvWlKWUvhiZUwJMR55oLbO+SGBwHmBskPg242sOpeR6oGgDEty/4+YJpC8RcH5BT0T8L8wcYvzBpQTQVAKFppSM4fsD3Gy650GdH2c6SLxzh+RWbMzxE+pG0VD3Kw/07GGX5EHnFCCglPLMSOLgBuFIgOiGXq9WE8IGiQiXA8PGkJmoU7IxUIJbja03wiEh0vC0QurrQJC6vtsGKovRAZye7mVXf0dLaLWnmeOO/2pnSzq42Od38AQ7fO5f5KlNpAAAAAElFTkSuQmCC',
					},
					width: 27,
					height: 30,
				},
			},
		},
		{
			text: 'Clear and legally compliant Health & Safety documentation',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28',
							srcSet: 'https://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=28 7w,\nhttps://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=0.5&fm=webp&q=85&w=28 14w,\nhttps://www.datocms-assets.com/64336/1733778114-usp-cards-and-banners.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28 28w',
							sizes: '(min-width: 28px) 28px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAMAAABYi/ZGAAAAAXNSR0IArs4c6QAAAVZQTFRFR3BMM1XdOFTgOVTfOFTgOVTgM0zmM2bMOVTgN1PjN1XhOVPgOVXfOFTeOVTeNlTiOlLiOVTgOFThQED/OVTfKlXUOVTfOVXjAAD/OVTfOVTgOFfgSUnbPFLhOVHfOVTgOFLhOFXgNlXgNVXfNlHeOFPfN1npOVXgOVPfN1TgOFTgO1PiOVPhOVThOVPhN1ThOVTgNlHdOVTgOlHgOVXhRFXdOVLgOVPgOlTiOlXkOVThNlXgOVTePFXdOVPfOlLhN1LkN1TfOVTfOlTgOVXiOVTgOlXhOlTfOlLeOlLeVVX/OFbgOVXjN1vbOFPgOFPfOVXgM0zMQFDfOFLhOlDiO1TeLl3ROVXfOVThOFPeJEnbMzPMLkbROVbiOlTiLl3oPFHdQEDfOVHjO1PfQGDfM2b/N07eOlbkNlHkOVXjOlXjNlfeOVXfOlHcOlTgOFbiOVTgPlXo6SbAbQAAAHJ0Uk5TAA+St1uOCgWdLjwxsoV9PT6tkQS5Bq4JAaE6MgciP7M7tSEYL6UXY8GdUjTKqpCYtCa9Od4PgqJPOZdCXh5ZXRxPztS3q1SxPh8Dg0gOjV+KChBEIz0LuLOyBwULhkYLJgg/XwgFF3wTG7UvgRaFen0hRZTQgAAAANxJREFUGNNjYGBQ12AGAxEnewYoEFbVYgQDBxFna6gYuyY/hMEoaSNiDBXjEGAQlYlKFPZ1tZU20oaKWXpGKCpmsQZ6u1iZ6ChDxDxkZJWUCjMlgnwcLXQ5IWJyDHAgqgcVU8jIyeMFgdhgdiaYWG5+AR8IpMeJCkHFkhB642Hq+FOzucFAOi0FJiaVICYmCARi8snRQpj2hsHUCTCwQUCMMBtcb0ikPA8IhPt7we1gDOUCAwFZcYReOGCD2mHOjxAT11cBURJm7gGsUOAnaaAGljN1Y4EDO0MGBgYAVJcett1RP5wAAAAASUVORK5CYII=',
					},
					width: 28,
					height: 30,
				},
			},
		},
		{
			text: 'Smart software to simplify everyday Health & Safety tasks ',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28',
							srcSet: 'https://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=28 7w,\nhttps://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=0.5&fm=webp&q=85&w=28 14w,\nhttps://www.datocms-assets.com/64336/1733778134-group-509.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=28 28w',
							sizes: '(min-width: 28px) 28px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAMAAABYi/ZGAAAAAXNSR0IArs4c6QAAAeBQTFRFR3BMN1TiOVPgOVPgOlTiOFPeN1TfOVTgOFTgOFPfQFXqOVThOFTgOFPfOVXgOVTfOVTfNVXfOVThOVTfN1TeOVPeOVPgN1XeOlTgOFTfM0zmOVPfOVTgOVTcO07iOFPfOVPeO1TiOVXgNlXdOlThOVPgOVXiOVLfOlXiOFTgN1XdOFTgN07eN1TfOFPhOlPgOFLfOFHgPFXdOlXkOFTfOVPeN1PdQFXUOVThOlTeQED/OVPfOFTbOVXfOVTeO1jiOVTgOVXfO07rN1PdN1LgVVWqO1TeOVXeOlXhOlHgOlXgOlTgOlXfOVPgPVLWKlXUOVTgOVPgOFDfOFLhN1ffOFPhOVPiQFDfOFPgOVTfOlLiOVTeOVTgOVTgOVTgOVThOVTgOVXgSUnbM1XdOFTgOVTdN1PhOFTfOVTgN1XhOVXfOFXgOVXhN1TgOFTgOlLiOVPhOVPgOVTfOlXgNlXgPFXmOFXhOVXgOlLeN1LbOVXjOVTgOVTfOFXiOVTgOlTfOVPgOFPgOlXkOVTgOVTgOlXhOFPfOVTgOlPfOVTgOlTfOVXgOVXhOVTgOVThPVzgOVTfOVThOVXfOVTgOFTgOVLgOVTfOFTfOVTgNFLhOVTgOlTiOFTfN1TfOVXgOVPgffx4CgAAAKB0Uk5TAEacokZlT3SenwyXmmhaj6AYQ6FGdWtFm6AKsrQ6GjecPXVLgGK3cJakPEkXoV+Wdikew56iUwxeRgSZQIFeGr0/DSVBAz02XTlLozDNGQyLdSA7OFZHEHK/NbzGxauGrWMHD95DXEC8RbiEfoKjPooxZzk5Hp/WHxwJ1HBOs7rEo5CmqmZfjmk6WK+QrpgZuaCylFtRtpedIraeulilbJUzhtUAAAELSURBVBjTY7CQEWaGAAGFMgVJiTzpYAZTM3MuCKhUrFAszU8OFGXgtGSAAr7ymuqilCAPdgYTLZhYuHxVYVIIA58og6YaExTwcIOghLEYg7CKMhsIiKeHgWltDXUGTiGIzramTAhDlZ2BkwVIS+XyzelpTotwBbJZoWI53fNmCPYKZIQiiXVNFpzPP6W/JRUhJj97mtL0ubN4JxTIwcWy2+UmzmRg6RPM8oKL8da1CkxlUOIXi3SHi/kn1ndOkmksjvW0QtgREF/bIcse5W2HZK+bb0JDSbS0gyGSGIOzVFwMu70RA0RM0gbiJT8fJwhDz4DB2lacEQRcHIVAlIiIPi+DLDMHCtDl1wEAeus2R1SeL8oAAAAASUVORK5CYII=',
					},
					width: 28,
					height: 30,
				},
			},
		},
		{
			text: 'Support with external health & safety inspections. And more',
			image: {
				gatsbyImageData: {
					images: {
						sources: [],
						fallback: {
							src: 'https://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27',
							srcSet: 'https://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=0.26&fm=webp&q=85&w=27 7w,\nhttps://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=0.52&fm=webp&q=85&w=27 14w,\nhttps://www.datocms-assets.com/64336/1733778153-subtract.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=27 27w',
							sizes: '(min-width: 27px) 27px, 100vw',
						},
					},
					layout: 'constrained',
					placeholder: {
						fallback:
							'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAAXNSR0IArs4c6QAAAe9QTFRFR3BMN1PdOFTgN0nbAAD/OVTgOFLdOVXjOFTfOFPgOVTeKlXUJEnbOVXgO07YOFLhOVPfNlXgM1XdOVTgOlTePFrhOVPfOVTgQED/Ll3oN1XhOVThOVTgOVThOFTfOFTgOVTfOVTfOlLeLkbROVXfNVXfOFPfOVTgM0zMOlXhNlXYQFXqQFXUOVXfOFTeOVXgOVXgOVXUOVTgOVXfO07rOFPeOlbgOVXgOVThOFXhOFjfOFXeN1bgOlTgOFPhNlHkN1PgOVThOVTgOVTfNlHkOlLeOVXgOVThOlHcOFTfN1neOVTgN1bkOVbfOlTiOlTgOlTgAAAAOFTfOlbiPFLhOFTfOlXgO1XeOVTfOVPgOFPeN1LgOlfiO1LgNVXfNlXgOFPfOlTfN1XeOFPfO1PgNlXgOFPeOVPfOFLhOVXjOVPfOlTgN1XdOFbiOFXgM1nZOlThOFDfOVbhOVTgOFPfOlXgOlfiOVXcOFPgQGDfOFThMzPMOFPfOFbgN1PgN1LkOVPeNlXgOFTgOFXgN1XbOVThOVXhOVXhOlfiOFLcPEvhOVTgOVTfOlLeOVXgOVXhOVXhOFTeQFDfOFXgOVbhPVXnNFLaOlTfNlHeOVPgN1TfOlTgOVThOVXgOlPfOFLgOFTeN1PfOVTgOVXgOVXeqxn9PQAAAKV0Uk5TAEqjDgE6RBKJhH0GB6INRJlCD4tGEYGrBAsqQ32gp4N5oD4LshhuqgpmIQwMvYPbqxLlyg2NpFG5nyBOU9SRE1OqwLkvXWxMFnYXnUFQYXN8AZ4+IpE5J6GdhEEjQTAhlmFFvko5Vkd/CX5qPGiEFHcghpSorDUkowhtBXGDShxsS0m1KqGZbyw7EdGwH5zBopUQlqEVIrEvYo+kqVpuMm14y9M2mQLq4wAAAS9JREFUGNNN0GNzA3EQBvA9NP/eXeykMWtbqW3btm3btj5oL9ebaZ5Xu7/dmZ1ZAD7JEviPNkSMEEpN9yCkCg3kKDgsJgk3JqbF46LIiKC/teysKEKXEiePFSdEKznRF0tzAGThbKmQGkgvjQ53lgMIKBKKquob5awsPz3Mdwg56lPDtIClu2eGXGhjCcixLVgzs/TqAnhcAgkF47tH4DTZQeu2IfRzvyijyM3LK3R7poSPbxGOi742mod2zh0X+M3JMbx9MgTB7LtsA5NWp46wnzpg/eUd4GCmvboJLAYhCFdXAALcxr3ZVu/Fwanta80Ew55UHc4R7ISqBFI/0uLzjxKzTwMFhTRtsXbTFbQ6g6d8E4b19HdhGJan4am0odePS11ZJk81Cn8+tbkA8At71Dj9Qi1pwAAAAABJRU5ErkJggg==',
					},
					width: 27,
					height: 30,
				},
			},
		},
	],
	servicesImage: {
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: 'https://www.datocms-assets.com/64336/1734018262-h-s2-2.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=399',
					srcSet: 'https://www.datocms-assets.com/64336/1734018262-h-s2-2.png?auto=format%2C%20compresss&dpr=0.26&fm=webp&q=85&w=399 100w,\nhttps://www.datocms-assets.com/64336/1734018262-h-s2-2.png?auto=format%2C%20compresss&dpr=0.51&fm=webp&q=85&w=399 200w,\nhttps://www.datocms-assets.com/64336/1734018262-h-s2-2.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=399 399w',
					sizes: '(min-width: 399px) 399px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/png;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGKEQAJAAsACwANABEADQASABQAFAASABgAGgAYABoAGAAkACEAHgAeACEAJAA2ACcAKQAnACkAJwA2AFEAMwA7ADMAMwA7ADMAUQBHAFYARwBCAEcAVgBHAIAAZQBZAFkAZQCAAJQAfAB2AHwAlACzAKAAoACzAOEA1QDhASUBJQGK/8IAEQgAFQAYAwEiAAIRAQMRAf/EAC4AAQADAQEAAAAAAAAAAAAAAAACAwUEBwEBAQEBAAAAAAAAAAAAAAAAAQMCBf/aAAwDAQACEAMQAAAA9Hhbk9COwim9cjLSE//EACEQAAEDAwUBAQAAAAAAAAAAAAECAxEAEiEEMUFhgRAU/9oACAEBAAE/AHHnW1qJTKLcW5MiSfK/ei5ICFmeopt8rm1OAogzjbmtS7a1MEg4Mb+RRK7Qq96NsD3itM9ciDMpwSeT1QEfFNgqBk1//8QAGhEBAAIDAQAAAAAAAAAAAAAAAQACEBEhMf/aAAgBAgEBPwCtROkePmNs/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwAf/9k=',
			},
			width: 399,
			height: 353,
		},
	},
	servicesCtaLabel: 'Get a free demo',
	servicesBackgroundImage: {
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: 'https://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=772',
					srcSet: 'https://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=772 193w,\nhttps://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=0.5&fm=webp&q=85&w=772 386w,\nhttps://www.datocms-assets.com/64336/1733778166-peninsula-canada-map-1-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=772 772w',
					sizes: '(min-width: 772px) 772px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAASCAMAAACHObUhAAAAAXNSR0IArs4c6QAAAelQTFRFR3BM////3eDx5ub/3d/w3uHw////3N7w3N/w3t7w////293u////////3+Hw3d/w3uHy3uHy7e324eTz39/w7+/3293u9PT54+P08fH44OD14uX04+Px3+Ly9PT/3Ojz5OT04eHw3t7w3d3w3uHx3uHx6urq3N/v5eXz+Pj/+vr/3d3u8vL45uby3N/v297x4ODx3+Lw4+Px4+P04+P23+Ly4eHw5eXy////4+Px6Ojo8P//////6Ov13t7x29vb3uHy6+v44uLz6ur26en04uXz4///5eX139/x7Oz15un04+Px7Oz25+fz4+P05OTy3uDx5+v36+v13N/y4eTy7+/36ur44eHy2t3w6Ojw3Nzw9vb/4Obz3uDx9vb28PD38fH66uz04+by3+Hz293x4eX18fH46/X/3+Hy8/P539/y7+//8vL26Ojy7Oz54OPx5OTy5+f18fH25OTx7u745uv16u/3////6ur35+fz6ur18PD64ODy5ub04OPz8PD/5Of03t7v4eHy7Oz14uLy6Oj14OLy////5+vz5un04uLy5Ofz6+v36Ojz7PH63t7x6Oj3////5uby5+f34eHz6u/06en28fHx6ur339//8PX64uby6Ov16e345evy3uHw4OLx2t3u6en2MDLabAAAAKN0Uk5TAAJqCmhmBmZnZgFpCQdnaWZlOGdnH2ouWyUZWTdhFxYvRVRTXF4MYGwkOB4mKF9rW2kSSBtgMycNJAsRBUxeB00nLFYvVwlNWWlGSTcqWjlsQBlgZiAlX2giZx0pax1DNmBkZ2pNJBpfKV8QOkwoW2ZJOF1LMz4EPkAxNGNdUhFgXTs1Tk9iEEBbUGlBQjdsIQpkIFYwURI9CDI9TkYnZWpoOe6u6B0AAAErSURBVBjTY2CAAsYET0cGDOAWF12UGIohzBYWL55Tko4qaC7KFiORl1ZW2SHHhSSszSnsGllYUFrR2z2pNZsRJmykysLJLZtbLNTTN3eWdEMmTNxYn5OdXSS8tqtTdtrM2QuaYeK6ggLs7OwcHCwL58+ZLLloajlUnFePjxUowc7JP2O6nMyUCdWiEHFmAw5OViBMbuRlYJjX31aTFOhiAxTXEmQHWsDJLpJVz8AwsYWfgy9WWNyai0HTkB1sDntEfpNUex0LO1+IjJezBYOOBrcASAc7O0sKf1UqS1SwP08QD9BeIWVWJiYWDg4OoKsyJDg4/KR9JCEhKa+gqKSibsohJsbNwR7g7WvLAw8MLjZmNRNLJw8WJncpBjaMcLWyc7BnwAIYzZihTAD6pzc0Vu0p9gAAAABJRU5ErkJggg==',
			},
			width: 772,
			height: 597,
		},
	},
	servicesDownloadTitle: 'Download expert documentation for FREE:',
	servicesDownloadList: [
		{
			text: 'Policy templates',
		},
		{
			text: 'H&S manuals',
		},
	],
	servicesDownloadCtaLabel: 'Click to download',
	servicesDownloadCtaLink: '/ca/resource-hub/downloads/',
	servicesDownloadImage: {
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: 'https://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=129',
					srcSet: 'https://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=0.25&fm=webp&q=85&w=129 32w,\nhttps://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=0.51&fm=webp&q=85&w=129 65w,\nhttps://www.datocms-assets.com/64336/1733778293-hr-1.png?auto=format%2C%20compresss&dpr=2&fm=webp&q=85&w=129 129w',
					sizes: '(min-width: 129px) 129px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAMAAABVlYYBAAAAAXNSR0IArs4c6QAAAmpQTFRFR3BMGyhRGCRVHCpQAABVHipQHChPGylSGipMHCpPHShPHSdTHilSHilRAAAAHylSHCZMnKvyn6zxnKzymarunKrwm6fuoKjwnqzznKrxqqr/nqvyGixPHCpRnKrwHydNm6jvHSlSGydSHiZSGyZRnqzzn6zxHShQm6jsHSpSICpKHSlSIDBQHSpRnqvyGipVHShTGjNMnqXwHSpRHSlSnarxMzNmnavyjZfbm6nuAEBAHShQHipPhpLbHipS5ub/4On0HSlRGyhNnKnv4ujzKipVnartHilRHipTnazynavyHypRHShRHBxVHCdTGypQHCpQkJTfHSpRnqvynKrwHSlRHSlSHSpSnqzy4+35nKrx3OP2AAAAnKrwhIzbmKbwmqnt4On2mKfxaIDonazzn6z4l6fvnKrx4On2amqqHSlQHipRnqftVVVVmqztnKnwnKrwGydNGCNJIChQgICAnarwEiBDFyhPGyVOnKnwnKjunKnvnaru4ej15Or2m6rwnKrwmqjxnavxmKTsnKvxm6rw6Oz44Of4zNPxjpfgqLLxnqvznKnwnKrwoK7wmKbv6O/4mcz/nKnynq30m6rwnKjwnavxm6nxn6r0m6rvm6jvnanwm6vvn63znKrxuMLxm6nxnqzxo7Dxm6rxmKfwk6Lwm6jw3OL15u33lKbtnazwoa7wn6vyn6zynKrwnqzzobD4nKrxoK72n671navyoK71HipTn631HytU6fH+j5fenazynavx5+776e/6nq307vP77/T96e/75+34oK/2tcD8oa/4n630mqjxnKr0y9T3wcr6rrrywsv5HAUIZAAAAM50Uk5TADkVNgNDLTgeN0c0REUBGUpklF8P4D0j+vgMiB1I2yFPV0EiL34lRin7GLsQYe4eRwoiYtP5BesxWQRZKirvClugTPQsBjmKK6L0Qt0JLkNcN9yY7s33c9xTtVEC77jBR1LGFvUlxflRDFA8HQMrhq9CSSAC10gtS5ss5TxMzOH0n+9cg5ZE/m1zNWen0OK0kwU7y0VkXlktrmFlXpVsNm6W8u40Y85Q4CtlnT0o//////////////////////////////////////////+wMVWRAAABR0lEQVQY02NggAMWFUZ2EGD0Y0EIMrBy63MAAT8TtzdC0MwJQrMFMHCBGWLSqhoMrgJgNpedj68/iCEssXmruVuMAAOPB4OupokNAy9QUER0/bp126Nyi/hCg9y3aWsxgEXFJTeuW7e7NLOMITtsh6MhUIQHiKXkgGo3F5RUMDBkhfOBjDQAEQp669dH5DEUMhfzWzEwKcszKwEFjdQsPYMTUnMYyjmt1VllOTnZWBgmVnqZOofEpm9MyWdgMFaUgTh6yvr1W9at21KflhSfzKAD89LUTb3VezY2dNVmJEa6wD0quGF6z/71/W2H9u3d6QAXnb1h2sxNm5ZOOHigLtoeSe2clsnL5q843N4RZwsXnbWhddKalctPnenr3GUBF53RdOT4sQWrT55Y3ByIFNhV3fNWLVl0+uzRRgY0sHahkNDcGoQAABwBYnJfNCzDAAAAAElFTkSuQmCC',
			},
			width: 129,
			height: 122,
		},
	},
};

