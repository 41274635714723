/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import {
	StructuredText,
	renderNodeRule,
	renderMarkRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import {
	isHeading,
	isParagraph,
	isRoot,
	isListItem,
} from 'datocms-structured-text-utils';
import CircleTick from '../../../../assets/circle-tick.svg';

const customNodeRules = [
	renderNodeRule(isHeading, ({ node, children, key }) => {
		const HeadingTag = `h${node.level}`;
		return (
			<HeadingTag
				className="mb-5 text-2xl lg:text-3xl font-lexend-regular"
				key={key}
			>
				{children}
			</HeadingTag>
		);
	}),
	renderNodeRule(isParagraph, ({ children, key, ancestors }) => {
		if (isRoot(ancestors[0])) {
			return (
				<p
					className="mb-4 text-sm font-notosans-light last-of-type:mb-0"
					key={key}
				>
					{children}
				</p>
			);
		}
		return children;
	}),
	renderNodeRule(isListItem, ({ children, key }) => (
		<li
			className="flex items-center mb-4 text-sm last-of-type:mb-0 font-lexend-light"
			key={key}
		>
			<img
				src={CircleTick}
				alt="Tick bullet"
				height="23"
				width="23"
				className="max-h-[23px] mr-2"
			/>

			{children}
		</li>
	)),
];

const customMarkRules = [
	renderMarkRule('highlight', ({ children, key }) => (
		<span className="font-bold text-brand-red-400" key={key}>
			{children}
		</span>
	)),
];

const DoubleTextBlock = ({ textBlocks }) => (
	<div className="text-white bg-brand-blue-400">
		<div className="px-4 py-12 mx-auto max-w-m-screen">
			<div className="flex flex-col gap-20 lg:flex-row">
				<div>
					<StructuredText
						data={textBlocks.firstBlock}
						customNodeRules={customNodeRules}
						customMarkRules={customMarkRules}
					/>
				</div>
				<div>
					<StructuredText
						data={textBlocks.secondBlock}
						customNodeRules={customNodeRules}
						customMarkRules={customMarkRules}
					/>
				</div>
			</div>
		</div>
	</div>
);

export default DoubleTextBlock;
