// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import clsx from 'clsx';
import React from 'react';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';

const CardsContentSectionVideoCampaignCA = (
	{ cardsContentSectionCAContent },
	className
) => {
	const { title, content, primaryLinkCopy, videoCampaignCards } =
		cardsContentSectionCAContent;

	return (
		<div
			className={`bg-[#FBFBF9] py-10 lg:py-28 w-full relative ${className}`}
		>
			<div
				aria-label="video-campaign-page-ca"
				className="grid grid-flow-row bg-[#FBFBF9] lg:grid-flow-col auto-rows-max grid-cols-1 lg:grid-cols-2 gap-10 p-6 lg:p-12 xs:py-0 lg:py-32 mx-auto lg:max-w-m-screen"
			>
				{/* left panel for desktop */}
				<div className="grid grid-rows-2 grid-cols-12 items-left justify-center gap-3 xl:gap-5 col-span-1">
					{videoCampaignCards.map((item, idx) => (
						<span
							key={item}
							className={`border-1 border-[#AEAEAE] rounded-[.75rem] h-full w-full p-4 lg:p-4 xl:p-6 flex text-sm items-end animate-[bounce_${
								idx === 1 ? 10 : 5
							}s_ease-in-out] ${
								idx === 0
									? 'col-span-12 lg:col-span-7'
									: 'col-span-12 lg:col-span-6'
							}`}
						>
							{createHeadingHighlightCA({
								headings:
									item.structuredTextBlock.value.document
										.children[0].children,
								variant: 'black',
								className: `w-full break-words`,
							})}
						</span>
					))}
				</div>

				{/* right panel for desktop and top panel for mobile */}
				<div className="flex flex-col items-left justify-center gap-5 col-span-1">
					<div
						className={clsx(
							'inline !leading-[1.3] font-lexend-regular xl:mx-0',
							'max-h-2/5 flex items-center'
						)}
					>
						{createHeadingHighlightCA({
							headings: title.value.document.children[0].children,
							variant: 'red',
							className: 'text-center',
						})}
					</div>
					<StructuredText
						data={content}
						customNodeRules={[
							renderNodeRule(
								isParagraph,
								({
									adapter: { renderNode },
									node,
									children,
									key,
									ancestors,
								}) => {
									if (
										node.children[0].type === 'inlineItem'
									) {
										return (
											<React.Fragment key={key}>
												{children}
											</React.Fragment>
										);
									}
									if (isRoot(ancestors[0])) {
										return renderNode(
											'p',
											{
												key,
												className:
													'break-word text-xl font-lexend-light font-regular',
											},
											children
										);
									}
									return (
										<React.Fragment key={key}>
											{children}
										</React.Fragment>
									);
								}
							),
						]}
						customMarkRules={[
							renderMarkRule('strong', ({ children, key }) => (
								<span className="font-lexend-regular" key={key}>
									{children}
								</span>
							)),
						]}
					/>
					<div className="max-h-1/5 flex items-center">
						<a
							href="tel:1(833)247-3652"
							className="inline-flex items-center transition delay-150 duration-1000 px-6 py-4 mt-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-lexend-medium focus:outline-0 focus:shadow-focus w-max"
						>
							<span className="rulerclick">
								{primaryLinkCopy}
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardsContentSectionVideoCampaignCA;
