import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import { Button } from '../../../atoms/button';
import { CallUs } from '../../../atoms/call-us';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { TextInput } from '../../../atoms/text-input';

import { useFormValidation } from '../../../../hooks/form-validation';
import { useSalesforceApi } from '../../../../hooks/salesforce';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
	generateUtmData,
} from '../../../../utils';
import { getLocale } from '../../../../utils/locale';

import { useSalesforceAzureApi } from '../../../../hooks/salesforce-azure-ca';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
	company: { isValid: true },
};

const AdviceModalForm = ({ setReference, setResponse }) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const sideBarHeaderText = 'Book my free advice call';
	const submitButtonText = sideBarHeaderText;

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: sideBarHeaderText,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: sideBarHeaderText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) return;

		setSubmitting(true);

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			company: formEntries.company,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			leadSource: 'Website - Become a Client',
			locale,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
		};

		if (locale === '' && (locale !== 'ca' || locale !== 'ie')) {
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ca') {
			payloadData.leadSource = 'Website';
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ie') {
			payloadData.leadSource = 'Website - ROI Become a Client';
			payloadData.utmCampaign = generateUtmData(pageUrl, 'utm_campaign');
			payloadData.utmMedium = generateUtmData(pageUrl, 'utm_medium');
			payloadData.utmSource = generateUtmData(pageUrl, 'utm_source');
			payloadData.utmTerm = generateUtmData(pageUrl, 'utm_term');
		}

		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (locale === 'ca' && response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponse(
					'Brilliant, one of our advisors will call you as soon as possible.'
				);
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto grow"
			>
				<div className="flex flex-row p-4 lg:p-8 bg-brand-blue-400">
					<div className="w-full text-white">
						<h2 className="mb-3 text-2xl lg:text-4xl font-lexend-bold">
							{sideBarHeaderText}
						</h2>
						<p className=" font-notosans-regular">
							Tell us your details and availability - one of our
							professionals will get back to you.
						</p>
					</div>
				</div>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-10 bg-white lg:px-8 pb-7 grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<div className="w-full lg:w-1/2">
								<label
									htmlFor="FirstName"
									className="text-brand-blue-400 font-lexend-medium"
								>
									First name&#42;
								</label>
								<TextInput
									id="callbackFirstName"
									name="firstName"
									aria-required="true"
									className="w-full wrapper-small"
									ref={firstNameRef}
									showValidation={
										!validations.firstName.isValid &&
										!firstNameRef.current.value
									}
									validationMessage={
										!validations.firstName.isValid
											? 'Please enter your first name'
											: null
									}
									space={false}
								/>
							</div>
							<div className="w-full lg:w-1/2">
								<label
									htmlFor="LastName"
									className="text-brand-blue-400 font-lexend-medium"
								>
									Last name&#42;
								</label>
								<TextInput
									id="callbackLastName"
									name="lastName"
									aria-required="true"
									className="w-full wrapper-small"
									ref={lastNameRef}
									showValidation={
										!validations.lastName.isValid &&
										!lastNameRef.current.value
									}
									validationMessage={
										!validations.lastName.isValid
											? 'Please enter your last name'
											: null
									}
									space={false}
								/>
							</div>
						</div>

						<label
							htmlFor="company"
							className="text-brand-blue-400 font-lexend-medium"
						>
							Company
						</label>
						<TextInput
							id="callbackCompany"
							name="company"
							aria-required="false"
							className="w-full wrapper-small"
							ref={companyRef}
							showValidation={
								!validations.company.isValid &&
								!companyRef.current.value
							}
							validationMessage={
								!validations.company.isValid
									? 'Please enter your company'
									: null
							}
							space={false}
						/>

						<label
							htmlFor="email"
							className="text-brand-blue-400 font-lexend-medium"
						>
							Email&#42;
						</label>
						<TextInput
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							ref={emailRef}
							showValidation={
								!validations.email.isValid &&
								!emailRef.current.value
							}
							validationMessage="Please enter your email address"
							space={false}
						/>

						<label
							htmlFor="phoneNumber"
							className="text-brand-blue-400 font-lexend-medium"
						>
							Best number to call you on&#42;
						</label>
						<TextInput
							type="tel"
							id="callbackPhoneNumber"
							title="Please enter numbers only."
							pattern="[0-9]{10,14}"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							ref={phoneNumberRef}
							showValidation={
								!validations.phoneNumber.isValid &&
								!phoneNumberRef.current.value
							}
							validationMessage="Please enter a phone number"
							space={false}
						/>
					</fieldset>
				</div>
				<div className="px-4 pb-4 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									'text-sm',
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</>
	);
};

AdviceModalForm.propTypes = {
	setReference: PropTypes.func.isRequired,
	setResponse: PropTypes.func.isRequired,
};

export { AdviceModalForm };
